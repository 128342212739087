import React, { useState } from 'react';
import withLoadingIndicator from '../../../LoadingIndicator/withLoadingIndicator';
import withPaging from '../../../Table/withPaging';
import Table from '../../../Table/Table.component';
import { Paper, Typography } from '@material-ui/core';
import { getAwardByValue } from '../../Competition/Score/awards';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import useMountEffect from '../../../../Utils/useMountEffect';
import AwardFilter from './AwardFilter.component';

const LoadingTable = withLoadingIndicator()(withPaging()(Table));

const columns = [
    { 
        key: 'title',
        displayName: 'Tittel',
        sortable: true,
    },
    {
        key: 'salon',
        displayName: 'Salong',
        sortable: true,
    },
    {
        key: 'nsffPatronate',
        displayName: 'NSFF Patronate',
    },
    {
        key: 'fiapPatronate',
        displayName: 'FIAP Patronate',
        sortable: true,
    },
    {
        key: 'psaPatronate',
        displayName: 'PSA Patronate',
        sortable: true,
    },
    {
        key: 'country',
        displayName: 'Land',
        sortable: true,
    },
    {
        key: 'award',
        displayName: 'Premie',
        getValue: (props, data) => getAwardByValue(data.award).formDisplayName,
        sortable: true,
    },
];

const PersonExternalResults = ({ location, history, fetchData, header }) => {
    const [loading, setLoading] = useState(true);
    const [currentPaging, setCurrentPaging] = useState({ startPosition: 0, numberOfItems: 10 });
    const [personResults, setPersonResults] = useState([]);
    const [currentAwardFilter, setCurrentAwardFilter] = useState('');
    const [currentOrder, setCurrentOrder] = useState({})

    const internalFetchData = async (awardFilter, paging, order) => {
        setLoading(true);
        var results = await fetchData(awardFilter, paging.startPosition, paging.numberOfItems, order.orderBy, order.direction);
        setPersonResults(results);
        setLoading(false);
    }
    useMountEffect(() => {
        const queryParams = location.search !== "" ? queryString.parse(location.search) : {};
        const awardFilter = queryParams.awardFilter || '';
        const order = { orderBy: "title", direction: "asc" }
        setCurrentOrder(order)
        setCurrentAwardFilter(awardFilter);
        internalFetchData(awardFilter, currentPaging, order);
    });

    const onPageChange = async (paging) => {
        setCurrentPaging(paging);
        internalFetchData(currentAwardFilter, paging, currentOrder);
    }

    const onSetAwardFilter = (filter) => {
        setCurrentAwardFilter(filter);
        history.push({ search: filter ? `?awardFilter=${filter}` : ''});
        internalFetchData(filter, currentPaging, currentOrder);
    }

    const handleSort = (order) => {
        setCurrentOrder(order);
        internalFetchData(currentAwardFilter, currentPaging, order);
    }


    return (
        <Paper style={{margin: 8, padding: 12}}>
        <Typography variant="h5" >
            {header}
        </Typography>
        <AwardFilter
            awardFilter={currentAwardFilter}
            setAwardFilter={onSetAwardFilter}
        />
        <LoadingTable
            ready={!loading}
            dataKey="participationNumber"
            columns={columns}
            data={personResults}
            onPageChange={onPageChange}
            paging={currentPaging}
                handleSort={handleSort}
                order={currentOrder}
        />
        </Paper>
    );
}

export default withRouter(PersonExternalResults);