import React from 'react';
import {
    Paper,
    Typography,
    Grid,
    withStyles,
    Button
} from '@material-ui/core';
import StatisticCard from './StatisticCard.component';

const getStyles = (theme) => ({
    paper: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        marginBottom: 8,
    },
    button: {
        float: 'right',
    }
});


const Statistics = ({ statistics, classes, openMyResults }) => {
    
    const handleCardClick = (awardFilter) => {
        openMyResults(awardFilter);
    } 

    return (
        <Paper className={classes.paper} elevation={3}>
            <Typography variant="h5" style={{ marginBottom: 16 }}>
                Prestasjoner
                <Button 
                    className={classes.button}
                    variant="outlined"
                    color="primary"
                    onClick={openMyResults}
                    >
                        Se alle resultater
                    </Button>
            </Typography>
            <Grid container spacing={1}>
                {statistics.map((card, key) =>
                    <Grid item xs={12} sm={12} md={4} lg={4} key={key}>
                        <StatisticCard cardData={card} onClick={card.awardFilter && (() => handleCardClick(card.awardFilter))} />
                    </Grid>
                )}
            </Grid>
        </Paper>
    )
}

export default withStyles(getStyles)(Statistics);