import { actionCreator } from "../../../../../Utils/actions.utils";
import { competitionResultService } from "../../../../Services/competitionResultService";
export const actionTypes = {
  AWARD_FULLSCREEN_INDEX_CHANGE: "AWARD_FULLSCREEN_INDEX_CHANGE",
  AWARD_SHOW_FULLSCREEN: "AWARD_SHOW_FULLSCREEN",
  AWARD_SHOW_LIST: "AWARD_SHOW_LIST",
  AWARD_SET_CLASS_PARTICIPATION_FILTER: "AWARD_SET_CLASS_PARTICIPATION_FILTER",
  SAVE_AWARD_SUCCESS: "SAVE_AWARD_SUCCESS",
  SAVE_AWARD_FAILED: "SAVE_AWARD_FAILED",
  REQUEST_SAVE_AWARD: "REQUEST_SAVE_AWARD",
  //AWARD_SET_SHOULD_DISPLAY_FULLSCREEN: "AWARD_SET_SHOULD_DISPLAY_FULLSCREEN",
};

export const fullScreenIndexChange = (index) =>
  actionCreator(actionTypes.AWARD_FULLSCREEN_INDEX_CHANGE)({ index });

export const showFullscreen = (fullscreenInitialIndex) =>
  actionCreator(actionTypes.AWARD_SHOW_FULLSCREEN)({ fullscreenInitialIndex });

export const showList = () => actionCreator(actionTypes.AWARD_SHOW_LIST)({});

export const setClassParticipationFilter = (filter) =>
  actionCreator(actionTypes.AWARD_SET_CLASS_PARTICIPATION_FILTER)({ filter });

export function saveAward(competitionID, classParticipationID, award, comment) {
  return (dispatch) => {
    const payload = { competitionID, classParticipationID, award, comment };
    dispatch(actionCreator(actionTypes.REQUEST_SAVE_AWARD)({ ...payload }));

    var promise = competitionResultService.saveAward(
      competitionID,
      classParticipationID,
      award,
      comment
    );

    return promise.then(
      (response) =>
        dispatch(actionCreator(actionTypes.SAVE_AWARD_SUCCESS)({ ...payload })),
      (error) =>
        dispatch(actionCreator(actionTypes.SAVE_AWARD_FAILED)({ ...payload }))
    );
  };
}
