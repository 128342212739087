import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CardActionArea from '@material-ui/core/CardActionArea';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import PlayArrow from '@material-ui/icons/PlayArrow';
import MediaDialog from '../../../Media/MediaDialog.component';

const styles = theme => ({
    root: {
        padding: 10,
        display: "flex",
    },
    actionArea: {
        height: 150,
        width: 150,
        minWidth: 150,
    },
    media: {
        height: 150,
        width: 150,
        minWidth: 150,
        borderRadius: 5,
    },
    details: {
        marginLeft: 16,
    },
    avatar: {
        backgroundColor: "#001789",
    },
    commentsContainer: {
        padding: '0 16px',
    },
    comment: {
        fontStyle: 'italic'
    },
    commentHeader: {
        fontWeight: 600,
    }
  });


class ResultCard extends React.Component {
    state = {
        openDialog: false,
    };

    handleMediaClick = () => {
        this.setState({openDialog: true});
    }

    handleCloseDialog = () => {
        this.setState({ openDialog: false });
    }

    renderListItem = (ListItemIcon, primary, secondary) => {
        const classes = this.props.classes;
        return (
            <ListItem>
                <ListItemAvatar>
                    <Avatar className={classes.avatar}>
                        <ListItemIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={primary} secondary={secondary} />
            </ListItem>
        );
    }

    renderComments = () => {
        const { classes, mediaItem } = this.props;
        const { comments } = mediaItem;
        return comments && (
            <ListItem>
                <div className={classes.commentsContainer}>
                    {comments.map((c, i) => (
                        <div key={i.toString()}>
                            <Typography key={i.toString()} variant="body2" className={classes.commentHeader}>
                                {c.juryName}
                            </Typography>
                            <Typography key={i.toString()} variant="body2" className={classes.comment}>
                                {c.comment}
                            </Typography>
                        </div>
                    ))}
                </div>
            </ListItem>
        );
    }

    render() {
        const { classes, mediaItem } = this.props;

        return (
            <div className={classes.root}>
                <CardActionArea onClick={this.handleMediaClick} className={classes.actionArea}>
                    <img
                        className={classes.media}
                        src={mediaItem.thumbnailUrl}
                        title={mediaItem.itemName}
                        alt={mediaItem.itemName}
                    />
                </CardActionArea>
                <div className={classes.details}>
                    <Typography style={{marginLeft: 16}} variant="h5" component="h2">
                        {mediaItem.name}
                    </Typography>
                    <List style={{paddingTop: 0, paddingBottom: 0}}>
                        {mediaItem.videoUrl && this.renderListItem(PlayArrow, 'Video', mediaItem.videoUrl)}
                        {this.renderComments()}
                    </List>
                </div>
                <MediaDialog mediaItem={mediaItem} isOpen={this.state.openDialog} onClose={this.handleCloseDialog} />
          </div>
        );
    }
}

export default withStyles(styles)(ResultCard);