import React from 'react';
import { withStyles } from '@material-ui/core';
import withLoadingIndicator from '../../LoadingIndicator/withLoadingIndicator';
import Distinctions from './Distinctions.component';
import DistinctionsForPerson from './DistinctionsForPerson.component';
const DistinctionsWithLoading = withLoadingIndicator()(Distinctions);


const getStyles =(theme) => ({
    root: {
        padding: 10,
    }
});

class DistinctionsWrapper extends React.Component {

    componentDidMount() {
        this.props.initializeData();
    }

    render() {
        const { initializeData, showDistinctionsForPerson, classes, ...passOnProps } = this.props;
        return (
            <div className={classes.root}>
                {
                    showDistinctionsForPerson ?
                    <DistinctionsForPerson
                        {...passOnProps}
                    /> :
                    <DistinctionsWithLoading
                        {...passOnProps}
                    />

                }
            </div>
        );
    }
}


export default withStyles(getStyles)(DistinctionsWrapper);