

 

import React from 'react';
import {
    FormControl,
    Button,
    Typography,
    withStyles,
} from '@material-ui/core';
import withInternalChangeHandler from '../../../../../Fields/withInternalChangeHandler';
import ProgressButton from '../../../../../Button/ProgressButton.component';
import TextField from '../../../../../Fields/TextField/TextField.component';

const FormTextField = withInternalChangeHandler()(TextField);


const getStyles = (theme) => ({
    displayItem: {
        paddingTop: 10,
        paddingBottom: 10,
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 800,
        width: '100%',
    },
});
 
class EvaluationFullscreenContent extends React.Component {

    onBlur = (field, value) => {
        this.props.onEvaluationFormFieldUpdated(field,value);
    }

    handleShowEditEvaluation = () => {
        const { comment } = this.props.classParticipation;
        this.props.onViewEditEvaluation({
            comment: comment || '',
        });
    }

    cancelEditEvaluation = () => {
        this.props.onCancelEditEvaluation();
    }

    validateForm = () => {
        const formValues = this.props.formValues;
        if(formValues && formValues.comment) {
            return true;
        }
        this.props.onEvaluationFormInvalid("Kommentar må være satt");
    }

    handleSaveEvaluation = (e) => {
        e.preventDefault();
        const { classParticipation, competitionData, formValues } = this.props;

        if(this.validateForm()) {
            this.props.saveEvaluation(
                competitionData.competitionID,
                classParticipation.classParticipationID,
                formValues.comment
            );
        }
    }

    renderForExisting = () => {
        const { classParticipation, competitionData, showEditEvaluation, classes } = this.props;
        return showEditEvaluation ? this.renderForm(true, "Oppdater") : (
            <div>
                <div className={classes.displayItem}>
                    <Typography variant="h6">
                        Kommentar
                    </Typography>
                    <div>
                        {classParticipation.comment ? classParticipation.comment : "Ingen kommentar satt"}
                    </div>
                </div>
                {!competitionData.juryingClosed &&
                    <div className={classes.displayItem}>
                        <Button onClick={this.handleShowEditEvaluation} color="primary" variant="outlined">Endre</Button>
                    </div>
                }
            </div>
        );
    }

    renderForm = (allowCancel, submitText) => {
        const { classes, classParticipation, validationErrorMessage } = this.props;
        const formValues = this.props.formValues || {
            comment: classParticipation.comment,
        };
        return (
            <form name="form" onSubmit={this.handleSaveEvaluation} noValidate className={classes.form}>
                <FormControl required variant="outlined" className={classes.formControl}>
                    <FormTextField
                        required
                        name="comment"
                        label="Kommentar"
                        onBlur={(ev) => { this.onBlur('comment', ev.target.value);}}
                        fullWidth
                        multiline
                        rows="4"
                        margin="normal"
                        variant="outlined"
                        value={formValues.comment}
                    />
                </FormControl>
                {validationErrorMessage &&
                        <Typography color="error" variant="overline" display="block" align="center">
                            {validationErrorMessage}
                        </Typography>
                    }
                <div>
                    {allowCancel &&
                        <Button
                            variant="contained"
                            type="button"
                            className={classes.button}
                            onClick={this.cancelEditEvaluation}
                            style={{marginRight: 8}}
                        >
                            Avbryt
                        </Button>
                    }
                    <ProgressButton variant="contained" type="submit" color="primary" className={classes.button} loading={this.props.saving}>
                        {submitText}
                    </ProgressButton>
                </div>
            </form>
        );
    }



    render() {
        const { competitionData, classParticipation } = this.props;

        const commentExists = !!classParticipation.comment;
        if(competitionData.juryingClosed) {
            return this.renderForJuryingClosed();
        }


        return commentExists ? this.renderForExisting() : this.renderForm(false, "Lagre");
    }
}

export default withStyles(getStyles)(EvaluationFullscreenContent);