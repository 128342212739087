import React from 'react';
import { Dialog, DialogContentText, DialogContent, DialogTitle, Button, DialogActions, Typography } from '@material-ui/core';
import ProgressButton from '../../../Button/ProgressButton.component';


const CopyCompetitionDialog = (props) => {
    const { onDelete, isDeleting, deleteError, ...passOnProps } = props;

    return (
        <Dialog
            {...passOnProps}
        >
            <DialogTitle>{"Slett konkurranse?"}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Er du sikker på at du vil slette denne konkurransen?
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{justifyContent: "center"}}>
                <Button onClick={props.onClose} color="primary">
                    Avbryt
                </Button>
                <ProgressButton
                    onClick={() => onDelete()}
                    loading={isDeleting}
                    autoFocus
                >
                    Slett
                </ProgressButton>
            </DialogActions>
            {deleteError &&
                <Typography color="error" variant="overline" display="block" align="center" style={{ padding: '0px 10px 10px 10px' }}>
                    {deleteError}
                </Typography>
            }
        </Dialog>
    );
}

export default CopyCompetitionDialog;

