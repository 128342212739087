import { connect } from 'react-redux';
import ImportResults from './ImportResults.component';
import { requestUploadFile, requestSaveUploadedFile, clearUploadedResults } from './ImportResults.actions';
import withLoadingIndicator from '../../../LoadingIndicator/withLoadingIndicator';

const mapStateToProps = (state) => {
    return {
        personID: state.distinctionsForPerson.selectedPerson.personID,
        personName: `${state.distinctionsForPerson.selectedPerson.firstName} ${state.distinctionsForPerson.selectedPerson.lastName}`,
        importedResults: state.importedResults.importedResults,
        loadingImportedResults: state.importedResults.loadingImportedResults,
        uploadedResults: state.importedResults.uploadedResults,
        uploadedFileName: state.importedResults.uploadedFileName,
        loadingImporteErrorMessage: state.importedResults.loadingImporteErrorMessage,
        uploadErrorMessage: state.importedResults.uploadErrorMessage,
        savingImportedResultsErrorMessage: state.importedResults.savingImportedResultsErrorMessage,
        savingImportedResults: state.importedResults.savingImportedResults,
        ready: !state.importedResults.loadingImportedResults && state.importedResults.importedResults,
    }
};

const mapDispatchToProps = (dispatch) => ({
    uploadFile: (personID, file, fileName) => {
        dispatch(requestUploadFile(personID, file, fileName));
    },
    saveUploadedFile: (personID, results) => {
        dispatch(requestSaveUploadedFile(personID, results));
    },
    clearUploadedFile: () => {
        dispatch(clearUploadedResults());
    },
});


export default connect(mapStateToProps, mapDispatchToProps)(withLoadingIndicator()(ImportResults));