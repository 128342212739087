import {
    actionCreator,
} from '../../../../../../Utils/actions.utils';
import {
    competitionScoringService,
} from '../../../../../Services/competitionScoringService';
export const actionTypes = {
    REQUEST_SAVE_SCORE: 'REQUEST_SAVE_SCORE',
    SAVE_SCORE_SUCCESS: 'SAVE_SCORE_SUCCESS',
    SAVE_SCORE_FAILED: 'SAVE_SCORE_FAILED',
    SCORE_FORM_FIELD_UPDATED: 'SCORE_FORM_FIELD_UPDATED',
    ON_VIEW_EDIT_SCORE: 'ON_VIEW_EDIT_SCORE',
    ON_CANCEL_EDIT_SCORE: 'ON_CANCEL_EDIT_SCORE',
    ON_SCORE_FORM_INVALID: 'ON_SCORE_FORM_INVALID',
}



export function saveScore(competitionID, classParticipationID, score, comment) {
    return (dispatch) => {
        const payload = { competitionID, classParticipationID, score, comment };
        dispatch(actionCreator(actionTypes.REQUEST_SAVE_SCORE)({ ...payload }));

        var promise = competitionScoringService.saveScore(competitionID, classParticipationID, score, comment);

        return promise.then(
            response => dispatch(actionCreator(actionTypes.SAVE_SCORE_SUCCESS)({ ...payload })),
            error => dispatch(actionCreator(actionTypes.SAVE_SCORE_FAILED)({ ...payload })),
        );
    }
};


export const onScoreFormFieldUpdated =
    (field, value) => actionCreator(actionTypes.SCORE_FORM_FIELD_UPDATED)({ field, value });

export const onViewEditScore =
    (formValues) => actionCreator(actionTypes.ON_VIEW_EDIT_SCORE)({ formValues });

export const onCancelEditScore =
    () => actionCreator(actionTypes.ON_CANCEL_EDIT_SCORE)({});

export const onScoreFormInvalid =
    (validationErrorMessage) => actionCreator(actionTypes.ON_SCORE_FORM_INVALID)({ validationErrorMessage });