import { connect } from 'react-redux';
import { getActive, getUpcomming, setViewMode, loadCompetitionData, fetchMoreFinished, fetchSearchFinished } from './Competition.actions';
import CompetitionWrapper from './CompetitionWrapper.component';
import { requestFinishedCompetitions } from './Report/Report.actions';
import { push } from 'connected-react-router';

const mapStateToProps = (state) => ({
    viewMode: state.competition.viewMode,
    selectedComp: state.competition.selectedComp,
    registerViewActiveStep: state.competition.registerViewActiveStep,
    isAnyAdmin: state.userProfile.data.isAnyAdmin,
    ready: state.competition.competitionDataLoaded,
    orgs: state.clubs.orgs,
    mainOrganizationID: state.userProfile.data.mainOrganizationID,
});

const mapDispatchToProps = (dispatch) => ({
    fetchActive: () => {
        dispatch(getActive());
    },
    fetchUpcomming: () => {
        dispatch(getUpcomming());
    },
    setViewMode: (mode, selectedComp, registerViewActiveStep) => {
        dispatch(setViewMode(mode, selectedComp, registerViewActiveStep));
    },
    loadData: () => {
        dispatch(loadCompetitionData());
    },
    onLoadMoreFinishedCompetitions: (paging, searchText) => {
        dispatch(fetchMoreFinished(paging, searchText));
    },
    onSearchFinishedCompetitions: (searchText) => {
        dispatch(fetchSearchFinished(searchText));
    },
    fetchCompsForReport: () => {
        dispatch(requestFinishedCompetitions());
    },
    push: (url) => {
        dispatch(push(url));
    }
});


export default connect(mapStateToProps, mapDispatchToProps)(CompetitionWrapper);