import Api from './apiService';
import moment from "moment";
import { isArray } from 'util';

export const competitionService = {
    create,
    update,
    deleteComp,
    getActive,
    getUpcomming,
    getFinished,
    closeJurying,
    closeAwarding,
    finishCompetition,
    setPatronate,
    deletePatronate,
    copyCompetition,
    createPublicJuryMember,
    getCompetition,
    unFinishCompetition,
    openAwarding,
    openJurying,
};

function converteToServer(competition) {

    return {
        ...competition,
        submissionStart: moment(competition.submissionStart).toISOString(),
        submissionEnd: moment(competition.submissionEnd).toISOString(),
        competitionClasses: competition.competitionClasses ? competition.competitionClasses.map(cc => {
            const { tempCompetitionClassID, className, ...rest } = cc;
            return {
                ...rest,
            };
        }) : [],
        competitionAdmins: competition.competitionAdmins ? competition.competitionAdmins.map(compAdmin => {
            return {
                personID: compAdmin.personID,
            }
        }) : [],
        competitionJuryMembers: competition.competitionJuryMembers ? Object.keys(competition.competitionJuryMembers).map(id => {
            return {
                personID: id,
            }
        }) : [],
    };
}

function convertToClient(competition) {
    return {
        ...competition,
        submissionStart: moment.utc(competition.submissionStart).local().format("YYYY-MM-DDTHH:mm:ss"),
        submissionEnd: moment.utc(competition.submissionEnd).local().format("YYYY-MM-DDTHH:mm:ss"),
    }
}

function create(competition) {
    const convertedForServerCompetition = converteToServer(competition);
    return Api.post(`competitions?organizationID=${competition.organizationID}`, convertedForServerCompetition);
}

function update(competition) {
    const convertedForServerCompetition = converteToServer(competition);
    return Api.put(`competitions/${competition.competitionID}`, convertedForServerCompetition);
}

function deleteComp(competitionID) {
    return Api.delete(`competitions/${competitionID}`);
}

function copyCompetition(competitionID, copySettings) {
    return Api.post(`competitions/${competitionID}/copy`, copySettings);
}


function getCompetition(competitionID) {
    return Api.get(`competitions/${competitionID}`).then(result => {
        return convertToClient(result);
    });
}

function getActive() {
    return Api.get('competitions/active').then(result => {
        return result && isArray(result) ? result.map(r => convertToClient(r)) : result;
    });
}

function getUpcomming() {
    return Api.get('competitions/upcomming').then(result => {
        return result && isArray(result) ? result.map(r => convertToClient(r)) : result;
    });
}

function createPublicJuryMember(competitionID) {
    return Api.post(`competitions/${competitionID}/setPublicJuryMemberIfMissing`);
}
function getFinished(paging, searchText) {

    let url = 'competitions/finished';
    let params = [];

    if (paging) {
        params.push(`startPosition=${paging.startPosition}&numberOfItems=${paging.numberOfItems}`)
    }
    if (searchText) {
        params.push(`queryString=${searchText}`)
    }
    url += `?${params.join("&")}`
    return Api.get(url).then(response => {
        const newResponse = {
            ...response,
            result: response.result.map(r => convertToClient(r)),
        };
        return newResponse;
    });
}

function closeJurying(competitionID) {
    return Api.post(`competitions/${competitionID}/closeJurying`);
}

function openJurying(competitionID) {
    return Api.post(`competitions/${competitionID}/openJurying`);
}

function closeAwarding(competitionID) {
    return Api.post(`competitions/${competitionID}/closeAwarding`);
}

function openAwarding(competitionID) {
    return Api.post(`competitions/${competitionID}/openAwarding`);
}

function finishCompetition(competitionID) {
    return Api.post(`competitions/${competitionID}/finish`);
}

function unFinishCompetition(competitionID) {
    return Api.post(`competitions/${competitionID}/unfinish`);
}

function setPatronate(nsffPatronate, compID) {
    return Api.post(`competitions/${compID}/nsffPatronate?patronate=${nsffPatronate}`);
}

function deletePatronate(compID) {
    return Api.delete(`competitions/${compID}/nsffPatronate`);
}