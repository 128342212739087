import {
    actionCreator
} from '../../../../../Utils/actions.utils';

import {
    competitionService
} from '../../../../Services/competitionService';

export const actionTypes = {
    REQUEST_CLOSE_COMPETITION_JURYING: "REQUEST_CLOSE_COMPETITION_JURYING",
    CLOSE_COMPETITION_JURYING_SUCCESS: "CLOSE_COMPETITION_JURYING_SUCCESS",
    CLOSE_COMPETITION_JURYING_FAILED: "CLOSE_COMPETITION_JURYING_FAILED",

    REQUEST_CLOSE_COMPETITION_AWARDING: "REQUEST_FINISH_COMPETITION",
    CLOSE_COMPETITION_AWARDING_SUCCESS: "CLOSE_COMPETITION_AWARDING_SUCCESS",
    CLOSE_COMPETITION_AWARDING_FAILED: "CLOSE_COMPETITION_AWARDING_FAILED",
}


export const closeCompetitionJurying = (competitionID) => (dispatch) => {
    dispatch(actionCreator(actionTypes.REQUEST_CLOSE_COMPETITION_JURYING)({ competitionID }));
    return competitionService.closeJurying(competitionID).then(
        response => dispatch(actionCreator(actionTypes.CLOSE_COMPETITION_JURYING_SUCCESS)({ response, competitionID })),
        error => dispatch(actionCreator(actionTypes.CLOSE_COMPETITION_JURYING_FAILED)({ error, competitionID })), 
    )
}

export const closeCompetitionAwarding = (competitionID) => (dispatch) => {
    dispatch(actionCreator(actionTypes.REQUEST_CLOSE_COMPETITION_AWARDING)({ competitionID }));
    return competitionService.closeAwarding(competitionID).then(
        response => dispatch(actionCreator(actionTypes.CLOSE_COMPETITION_AWARDING_SUCCESS)({ response, competitionID })),
        error => dispatch(actionCreator(actionTypes.CLOSE_COMPETITION_AWARDING_FAILED)({ error, competitionID })), 
    )
}