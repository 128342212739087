import { connect } from 'react-redux';
import { awards } from '../../Competition/Score/awards';
import Statistics from './Statistics.component';
import { createSelector } from 'reselect';
import { push } from 'connected-react-router';


const getPointsByDistinctionTypeID = (state) => state.userProfile.data.pointsByDistinctionTypeID;
const getResultsCountByAward = (state) => state.userProfile.data.resultsCountByAward;
const getDistinctionTypes = (state) => state.distinctions.distinctionTypes;


const distinctionTypeCodesForStats = ['ansff', 'ensff', 'nasjonalplakett', 'internasjonalplakett'];
const distinctionTypeTitlesByCode = {
    ansff: 'Poengsum ANSFF',
    ensff: 'Poengsum ENSFF',
    nasjonalplakett: 'Poengsum nasjonal plakett',
    internasjonalplakett: 'Poengsum internasjonal plakett'
}
const awardTitlesByCode = {
    First: 'Antall 1. plasser',
    Second: 'Antall 2. plasser',
    Third: 'Antall 3. plasser',
}

const makeGetStatistics = () =>
    createSelector(
        getPointsByDistinctionTypeID,
        getResultsCountByAward,
        getDistinctionTypes,
        (pointsByDistinctionTypeID, resultsCountByAward, distinctionTypes) => {
            const distinctionTypeStats = distinctionTypes.filter(dt => distinctionTypeCodesForStats.includes(dt.code)).map((dt) => {
                const distinctionTypePoints = pointsByDistinctionTypeID[dt.distinctionTypeID] || { points:0 };
                return {
                    title: distinctionTypeTitlesByCode[dt.code],
                    value: distinctionTypePoints.points
                }
            });
            const awardStats = awards.map((award) => {
                const awardCount = resultsCountByAward[award.code] || 0;
                return {
                    title: awardTitlesByCode[award.code] || award.formDisplayName,
                    value: awardCount,
                    awardFilter: award.value,
                }
            });
            return [
                ...distinctionTypeStats,
                ...awardStats,
            ];
        },
    );

const makeMapStateToProps = () => {
    const getStatistics = makeGetStatistics();
    return (state) => ({
        statistics: getStatistics(state),
    });
};

const mapDispatchToProps = (dispatch) => ({
    openMyResults: (awardFilter) => {
        dispatch(push(`/myResults?awardFilter=${awardFilter}`));
    }
});

export default connect(makeMapStateToProps, mapDispatchToProps)(Statistics);