import { connect } from 'react-redux';
import { createAndGoBack } from './CreateCompetition.actions';
import { initForm } from '../CompetitionForm/CompetitionForm.actions';
import CreateCompetition from './CreateCompetition.component';

const mapStateToProps = (state) => ({
    isSubmitting: state.createCompetition.isSubmitting,
    submitErrorMessage: state.createCompetition.submitErrorMessage,
    organizations: state.clubs.orgs,
    organizationsByID: state.clubs.orgsByID,
    isAnyAdmin: state.userProfile.data.isAnyAdmin,
});

const mapDispatchToProps = (dispatch) => ({
    onSubmit: (competition, previousLocation) => {
        dispatch(createAndGoBack(competition, previousLocation));
    },
    initForm: () => {
        dispatch(initForm());
    }
});


export default connect(mapStateToProps, mapDispatchToProps)(CreateCompetition);