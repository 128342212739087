import { connect } from 'react-redux';
import { login } from './Login.actions';
import Login from './Login.component';

const mapStateToProps = (state) => ({
    isLoggingIn: state.auth.isLoggingIn,
    isLoggedIn: state.auth.isLoggedIn,
    errorMessage: state.auth.errorMessage,

});

const mapDispatchToProps = (dispatch) => ({
    onLogin: (username, password) => {
        dispatch(login(username, password));
    }
});


export default connect(mapStateToProps, mapDispatchToProps)(Login); 