import { connect } from 'react-redux';
import ControlledExpansionPanels from './ControlledExpansionPanels.component';
import { getAdmins, searchPersonInOrg, setRole, removeRole, clearSearchResult } from './Admin.actions';
import { createSelector } from 'reselect';


const getOrgsFromState = state => state.clubs.orgs;

const makeGetOrgs = () =>
    createSelector(
        getOrgsFromState,
        orgs => orgs.filter(o => o.isAdmin)
    )

const makeMapStateToProps = () => {
    const getOrgs = makeGetOrgs();

    return (state) => ({
        orgs: getOrgs(state),
        adminsByOrgID: state.metaData.adminsByOrgID,
        roles: state.roles.roles,
        rolesLoading: state.roles.isLoading,
        searchResult: state.roles.searchResult,
        isLoadingSearch: state.roles.isLoadingSearch,
        loadingSettingRole: state.roles.loadingSettingRole,
    });
}

const mapDispatchToProps = (dispatch) => ({
    getRolesForOrg: (orgID) => {
        dispatch(getAdmins(orgID));
    },
    setRoleForMember: (orgID, organizationRoleID, person) => {
        dispatch(setRole(orgID, organizationRoleID, person));
    },
    removeRoleForMember: (orgID, organizationRoleID, personID) => {
        dispatch(removeRole(orgID, organizationRoleID, personID));
    },
    searchForMemberInOrg: (searchText, orgID) => {
        dispatch(searchPersonInOrg(searchText, orgID));
    },
    clearSearchResult: () => {
        dispatch(clearSearchResult());
    },
});

export default connect(makeMapStateToProps, mapDispatchToProps)(ControlledExpansionPanels);