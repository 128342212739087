import { simplePromiseAction, actionCreator } from '../../../../Utils/actions.utils';
import { competitionOrgParticipationService } from '../../../Services/competitionOrgParticipationService';
import { getCompetition } from '../Competition.actions';
import { pushPreviousOrDefault } from '../../../../Utils/router.utils';

export const actionTypes = {
    START_LOAD_ORG_PARTICIPATION_PAGE: 'START_LOAD_ORG_PARTICIPATION_PAGE',
    FINISHED_LOADING_ORG_PARTICIPATION_PAGE: 'FINISHED_LOADING_ORG_PARTICIPATION_PAGE',
    REQUEST_COLLECTIVE_ORG_PARTICIPATION: 'REQUEST_COLLECTIVE_ORG_PARTICIPATION',
    REQUEST_COLLECTIVE_ORG_PARTICIPATION_SUCCES: 'REQUEST_COLLECTIVE_ORG_PARTICIPATION_SUCCES',
    REQUEST_COLLECTIVE_ORG_PARTICIPATION_FAILED: 'REQUEST_COLLECTIVE_ORG_PARTICIPATION_FAILED',
    REQUEST_ORG_PARTICIPATION: 'REQUEST_ORG_PARTICIPATION',
    SUCCESS_ORG_PARTICIPATION: 'SUCCESS_ORG_PARTICIPATION',
    FAILED_ORG_PARTICIPATION: 'FAILED_ORG_PARTICIPATION',
    ADD_ORG_PARTICIPATION: 'ADD_ORG_PARTICIPATION',
    REMOVE_ORG_PARTICIPATION: 'REMOVE_ORG_PARTICIPATION',
    REQUEST_SUBMIT_ORG_PARTICIPATION: 'REQUEST_SUBMIT_ORG_PARTICIPATION',
    SUCCESS_SUBMIT_ORG_PARTICIPATION: 'SUCCESS_SUBMIT_ORG_PARTICIPATION',
    FAILED_SUBMIT_ORG_PARTICIPATION: 'FAILED_SUBMIT_ORG_PARTICIPATION',
    CLEAR_ORG_PARTICIPATION: 'CLEAR_ORG_PARTICIPATION',
}

const requestParticipations = () => actionCreator(actionTypes.REQUEST_ORG_PARTICIPATION)({});
const requestParticipationsSuccess = (response, orgID) => actionCreator(actionTypes.SUCCESS_ORG_PARTICIPATION)({ response, orgID });
const requestParticipationsFailed = (error) => actionCreator(actionTypes.FAILED_ORG_PARTICIPATION)({ error });

const requestOrgParticipation = (orgID, competitionID) => {
    return (dispatch) => {
        dispatch(requestParticipations());
        var promise = competitionOrgParticipationService.getOrgParticipations(orgID, competitionID);
        return promise.then(
            response => { 
                dispatch(requestParticipationsSuccess(response, orgID));
                return dispatch(requestCollectiveOrgParticipation(orgID, competitionID));
            },
            error => dispatch(requestParticipationsFailed(error)),
        );
    }
};
const finishedLoadingOrganizationParticipationPage = () => actionCreator(actionTypes.FINISHED_LOADING_ORG_PARTICIPATION_PAGE)({ })
export const loadOrganizationParticipationPage = (competitionID, organizationID) =>
    (dispatch) => {
        dispatch(actionCreator(actionTypes.START_LOAD_ORG_PARTICIPATION_PAGE)({}));
        return dispatch(getCompetition(competitionID))
            .then((action) => {
                if(!action.payload.error) {
                    return dispatch(requestOrgParticipation(organizationID, competitionID)).then(() => dispatch(finishedLoadingOrganizationParticipationPage()))
                }
                return dispatch(finishedLoadingOrganizationParticipationPage());
            });
    }


const requestCollectiveOrgParticipation = (orgID, competitionID) => simplePromiseAction(
    () => competitionOrgParticipationService.getCollectiveOrgParticipation(orgID, competitionID),
    actionTypes.REQUEST_COLLECTIVE_ORG_PARTICIPATION,
    actionTypes.REQUEST_COLLECTIVE_ORG_PARTICIPATION_SUCCES,
    actionTypes.REQUEST_COLLECTIVE_ORG_PARTICIPATION_FAILED,
    {orgID}
);

const addOrgParticipationAction = (personID, participationID) => actionCreator(actionTypes.ADD_ORG_PARTICIPATION)({ personID, participationID });
const removeOrgParticipationAction = (personID, participationID) => actionCreator(actionTypes.REMOVE_ORG_PARTICIPATION)({ personID, participationID });
const clearOrgParticipationAction = () => actionCreator(actionTypes.CLEAR_ORG_PARTICIPATION)();

export const addOrgParticipation = (personID, participationID) => addOrgParticipationAction(personID, participationID);

export const removeOrgParticipation = (personID, participationID) => removeOrgParticipationAction(personID, participationID);

const submitOrgParticipation = (participations, orgID, compID) => simplePromiseAction(
    () => competitionOrgParticipationService.submitOrgParticipations(participations, orgID, compID),
    actionTypes.REQUEST_SUBMIT_ORG_PARTICIPATION,
    actionTypes.SUCCESS_SUBMIT_ORG_PARTICIPATION,
    actionTypes.FAILED_SUBMIT_ORG_PARTICIPATION
);

export const updateAndGoBack = (participations, orgID, compID, previousLocation) => 
    (dispatch) => {
        return dispatch(submitOrgParticipation(participations, orgID, compID)).then((action) => {
            if(!action.payload.error) {
                return dispatch(pushPreviousOrDefault(previousLocation, "/competitions"));                
            }
            return null;
        });
    }

export const clearOrgParticipation = () => clearOrgParticipationAction();