import React from 'react';
import {
    withStyles,
    Grid,
    Button,
    Card,
    CardActions,
    CardContent,
    Typography,
    Tooltip,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Avatar,
    Divider,
    Dialog,
    DialogTitle,
    Menu,
    MenuItem,
    IconButton
} from '@material-ui/core';
import getCompetitionType from '../../../../competition/getCompetitionType';
import PatronageDialog from './PatronageDialog.component';
import SupervisorAccount from '@material-ui/icons/SupervisorAccount';
import PhotoFilter from '@material-ui/icons/PhotoFilter';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import FlagIcon from '@material-ui/icons/Flag';
import WarningIcon from '@material-ui/icons/Warning';
import moment from "moment";
import "moment/locale/nb";
import { Settings } from '@material-ui/icons';

moment.locale("nb");

const styles = theme => ({
    content: {
        paddingBottom: 0,
        flexGrow: 1,
    },
    actions: {
        justifyContent: "center",
        flexWrap: 'wrap',
    },
    avatar: {
        backgroundColor: "#001789",
    },
    grid: {
        display: 'flex',
    },
    card: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    button: {
        margin: 2,
    },
    buttonLabel: {
        display: 'block',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    statusText: {
        padding: "5px 10px",
        borderRadius: 15,
    },
    warningIcon: {
        color: '#efbb09',
        marginRight: 4,
    },
    topContainer: {
        display: 'flex',
        alignItems: 'flex-start',
    },
    topRightContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginTop: -8,
    }
});

class CompetitionListCard extends React.Component {
    state = {
        patronageDialogOpen: false,
        orgParticipationDialogOpen: false,
        secondaryButtonEl: null,
    };

    defaultButtonProps = {
        size: "small",
        variant: "outlined",
        color: "primary",
        className: this.props.classes.button,
        classes: {
            label: this.props.classes.buttonLabel,
        }
    }

    handleOpenPatronageDialog = () => {
        this.setState({ patronageDialogOpen: true });
    }

    handleClosePatronageDialog = () => {
        this.setState({ patronageDialogOpen: false });
    }

    handleSetPatronage = (nsffPatronate, compID) => {
        this.props.setPatronate(nsffPatronate, compID, this.props.competitionListKey);
    }

    handleDeletePatronage = (compID) => {
        this.props.deletePatronate(compID, this.props.competitionListKey);
    }

    handleOpenParticipationList = (compID) => {

    }

    isSubmissionEnded = () => {
        return moment() > moment(this.props.competitionData.submissionEnd);
    }

    isSubmissionStarted = () => {
        return moment() > moment(this.props.competitionData.submissionStart);
    }

    canParticipate = (p) => {
        /*
        const { isJuryLeader, isJuryMember} = this.props.competitionData;
        return !isJuryMember && !isJuryLeader;
        */
    }

    handleOpenOrgParticipationDialog = () => {
        const selectableOrgs = this.getAvailableOrgsForParticipation();

        if (selectableOrgs.length === 1) {
            this.props.openOrganizationParticipation(this.props.competitionData.competitionID, selectableOrgs[0].organizationID);
        } else {
            this.setState({ orgParticipationDialogOpen: true });
        }
    }

    secondaryButtons = [
        {
            key: 'edit',
            getText: () => 'Endre',
            onClick: (p, cd) => p.openEdit(cd.competitionID),
            shouldRender: (p, cd) => cd.isAdmin && !cd.finished,
        },
        {
            key: 'patronage',
            getText: (p, cd) => cd.nsffPatronate ? 'Fjern patronage' : 'Gi patronage',
            onClick: this.handleOpenPatronageDialog,
            shouldRender: (p, cd) => p.isNsffAdmin && !cd.finished && cd.isPublic,
        },
        {
            key: 'participationList',
            getText: (p, cd) => "Se deltakerliste",
            onClick: (p, cd) => p.openParticipationList(cd.competitionID),
            shouldRender: (p, cd) => {
                return p.isAnyAdmin
            },
        },
        {
            key: 'finishCompetition',
            getText: (p, cd) => "Fullfør og publiser",
            onClick: (p, cd) => p.finishCompetition(cd.competitionID),
            shouldRender: (p, cd) => cd.awardingClosed && !cd.finished && cd.isAdmin,
        },
        {
            key: "unFinishCompetition",
            getText: () => "Sett ikke fullført",
            onClick: (p, cd) => p.unFinishCompetition(cd.competitionID),
            shouldRender: (p, cd) => cd.finished && cd.awardingClosed && cd.isAdmin && !cd.nsffPatronate && cd.competitionType !== 4,
        },
        {
            key: "reOpenAwarding",
            getText: () => "Gjennåpne premiering",
            onClick: (p, cd) => p.openAwarding(cd.competitionID),
            shouldRender: (p, cd) => !cd.finished && cd.awardingClosed && cd.isAdmin && !cd.nsffPatronate && cd.competitionType !== 4,
        },
        {
            key: "reOpenJurying",
            getText: () => "Gjennåpne juriering",
            onClick: (p, cd) => p.openJurying(cd.competitionID),
            shouldRender: (p, cd) => !cd.finished && !cd.awardingClosed && cd.juryingClosed && cd.isAdmin && !cd.nsffPatronate && cd.competitionType !== 4,
        },
    ]

    mainButtons = [
        {
            key: 'participation',
            getText: (p, cd) => cd.isParticipating ? 'Min deltagelse' : (this.isSubmissionEnded() ? 'Se mer' : 'Bli med'),
            onClick: (p, cd) => p.openParticipation(cd.competitionID),
            shouldRender: (p, cd) => p.clubIsActive && !cd.finished,
            getDisabled: (p, cd) => cd.ageLimit && (p.age > cd.ageLimit || p.age < 0),
            getDisabledText: (p, cd) => `Kan ikke delta. Konkurransen har en øvre aldersgrense på ${cd.ageLimit}`,
        },
        {
            key: 'orgParticipation',
            getText: () => 'Klubbdeltagelse',
            onClick: this.handleOpenOrgParticipationDialog,
            shouldRender: (p, cd) => !cd.finished && this.isSubmissionEnded() && cd.competitionType === 4 && p.isAnyAdmin,
        },
        {
            key: 'score',
            getText: (p, cd) => cd.isJuryLeader ? 'Juryering / premiering' : 'Juryering',
            onClick: (p, cd) => p.openScore(cd.competitionID),
            shouldRender: (p, cd) => {
                if (cd.awardingClosed) return false;
                if (cd.isJuryLeader) {
                    return this.isSubmissionEnded();
                }

                return !cd.juryingClosed && this.isSubmissionEnded() && (cd.isJuryMember || cd.publicJurying);

            }
        },
        {
            key: 'result',
            getText: () => 'Resultater',
            onClick: (p, cd) => p.openResult(cd.competitionID),
            shouldRender: (p, cd) => cd.finished || ((cd.isJuryLeader || cd.isAdmin) && cd.awardingClosed),
        },
    ];

    renderSecondaryButtons = () => {
        const competitionData = this.props.competitionData || {};
        return (
            <React.Fragment>
                <IconButton aria-controls="secondary-buttons-menu" aria-haspopup="true" onClick={(ev) => { this.setState({ secondaryButtonEl: ev.currentTarget }); }}>
                    <Settings />
                </IconButton>
                <Menu
                    anchorEl={this.state.secondaryButtonEl}
                    open={!!this.state.secondaryButtonEl}
                    onClose={() => { this.setState({ secondaryButtonEl: null }); }}
                    id="secondary-buttons-menu"
                >
                    {this.secondaryButtons.filter(b => b.shouldRender(this.props, competitionData)).map(b => (
                        <MenuItem
                            key={b.key}
                            onClick={() => b.onClick(this.props, competitionData)}
                        >
                            {b.getText(this.props, competitionData)}
                        </MenuItem>
                    ))}
                </Menu>
            </React.Fragment>
        );
    }

    renderMainButtons = () => {
        const competitionData = this.props.competitionData || {};
        return this.mainButtons
            .filter(b => b.shouldRender(this.props, competitionData))
            .map(b => {
                const text = b.getText(this.props, competitionData);
                const disabled = b.getDisabled && b.getDisabled(this.props, competitionData);
                const disabledText = b.getDisabledText && b.getDisabledText(this.props, competitionData);
                const currentButton = (
                    <Button
                        key={b.key}
                        title={text}
                        onClick={() => b.onClick(this.props, competitionData)}
                        disabled={disabled}
                        {...this.defaultButtonProps}
                    >
                        {text}
                    </Button>
                );
                return disabled && disabledText ? (
                    <Tooltip title={disabledText}>
                        <div>
                            {currentButton}
                        </div>
                    </Tooltip>
                ) : currentButton;
            });
    }

    getStatus = () => {
        const competitionData = this.props.competitionData || {};
        const { juryingClosed, awardingClosed, finished } = competitionData;
        const submissionHasStarted = this.isSubmissionStarted();
        const submissionHasPassed = this.isSubmissionEnded();

        if (!submissionHasStarted) {
            return { text: "Ikke startet", color: "#001789", textColor: "white" };
        } else if (!submissionHasPassed) {
            return { text: "Åpen for innlevering", color: "#27ae60", textColor: "white" };
        } else if (submissionHasPassed && !juryingClosed) {
            return { text: "Under juryering", color: "#efbb09", textColor: "white" };
        } else if (submissionHasPassed && !awardingClosed) {
            return { text: "Under premiering", color: "#efbb09", textColor: "white" };
        } else if (submissionHasPassed && !finished) {
            return { text: "Premiert, ikke publisert", color: "#efbb09", textColor: "white" };
        }
        return { text: "Ferdig", color: "#001789", textColor: "white" };
    }

    getWarning = () => {
        const competitionData = this.props.competitionData || {};
        const {
            competitionJuryLeader,
            competitionJuryMembers,
            isAdmin,
            finished,
            publicJurying
        } = competitionData;

        if (!competitionJuryLeader && !publicJurying && competitionJuryMembers.length <= 0 && isAdmin && !finished) {
            return "Konkurranse mangler juryleder og jurymedlemmer";
        } else if (!competitionJuryLeader && isAdmin && !finished) {
            return "Konkurranse mangler juryleder";
        } else if (!publicJurying && competitionJuryMembers.length <= 0 && isAdmin && !finished) {
            return "Konkurranse mangler jurymedlemmer";
        }
        return null;
    }

    handleCloseOrgParticipationDialog = () => {
        this.setState({ orgParticipationDialogOpen: false });
    }

    getAvailableOrgsForParticipation = () => {
        //TODO: Check if org has already participated
        return this.props.orgs.filter(org => org.isAdmin);
    }

    renderOrgParticipationDialog = () => {
        const selectableOrgs = this.getAvailableOrgsForParticipation();

        return (
            <Dialog open={this.state.orgParticipationDialogOpen} onClose={this.handleCloseOrgParticipationDialog}>
                <DialogTitle style={{ paddingBottom: 0 }}>Velg klubb</DialogTitle>
                <List>
                    {selectableOrgs.map(org => (
                        <ListItem button onClick={() => this.props.openOrganizationParticipation(this.props.competitionData.competitionID, org.organizationID)} key={org.organizationID}>
                            <ListItemText primary={org.organizationName} />
                        </ListItem>
                    ))}
                </List>
            </Dialog>
        )
    }


    renderListItem = (ItemAvatar, primaryText, secondaryText, includeDivider) => {
        const classes = this.props.classes;
        return (
            <React.Fragment>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar className={classes.avatar}>
                            <ItemAvatar />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={primaryText} secondary={secondaryText} />
                </ListItem>
                {includeDivider && <Divider variant="inset" component="li" />}
            </React.Fragment>
        );
    }

    getClasses = () => {
        const { classesByID, competitionData } = this.props;
        const classIDsInCompetition = competitionData.competitionClasses ? competitionData.competitionClasses.map(cc => cc.classID).filter((val, i, list) => list.indexOf(val) === i) : [];
        return classesByID && classIDsInCompetition.map((classID, index) => (
            <span key={classID}>
                {classIDsInCompetition.length === index + 1 &&
                    <span>{classesByID[classID].name}</span>
                }
                {classIDsInCompetition.length !== index + 1 &&
                    <span>{classesByID[classID].name}, </span>
                }
            </span>
        ));
    }

    render() {
        const { classes, competitionData, xs, sm, md, lg } = this.props;
        const status = this.getStatus();
        const warning = this.getWarning();

        return (
            <Grid item xs={xs || 12} sm={sm || 6} md={md || 4} lg={lg || 4} className={classes.grid}>
                <Card className={classes.card}>
                    <CardContent className={classes.content}>
                        <div className={classes.topContainer}>
                            <Typography variant="h6" style={{ flexGrow: 1 }}>
                                {competitionData.name}
                            </Typography>
                            <div className={classes.topRightContainer}>
                                {warning &&
                                    <Tooltip title={warning} placement="bottom">
                                        <WarningIcon className={classes.warningIcon} />
                                    </Tooltip>
                                }
                                {this.renderSecondaryButtons()}
                            </div>
                        </div>

                        <Typography variant="body2" component="span" className={classes.statusText} style={{ color: status.textColor ? status.textColor : "", backgroundColor: status.color ? status.color : "" }}>
                            {status.text}
                        </Typography>
                        <List>
                            {this.renderListItem(SupervisorAccount, "Arrangør", competitionData.organizationName, true)}
                            {this.renderListItem(FlagIcon, "Konkurransetype", getCompetitionType(competitionData, true))}
                            {this.renderListItem(EventAvailableIcon, "Innlevering åpner", moment(competitionData.submissionStart).format('LLL'), true)}
                            {this.renderListItem(EventBusyIcon, "Innlevering stenger", moment(competitionData.submissionEnd).format('LLL'), true)}
                            {this.renderListItem(PhotoFilter, "Klasser", this.getClasses())}
                        </List>
                    </CardContent>
                    <CardActions className={classes.actions}>
                        {this.renderMainButtons()}
                    </CardActions>
                </Card>
                <PatronageDialog
                    isOpen={this.state.patronageDialogOpen}
                    onClose={this.handleClosePatronageDialog}
                    handleSetPatronage={this.handleSetPatronage}
                    handleDeletePatronage={this.handleDeletePatronage}
                    compID={competitionData.competitionID}
                    nsffPatronate={competitionData.nsffPatronate}
                />
                {this.renderOrgParticipationDialog()}
            </Grid>
        );
    }
}

export default withStyles(styles)(CompetitionListCard);