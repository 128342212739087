import Api from './apiService';

export const personService = {
    search,
    getMe,
    updateMe,
    getPayments,
    getImageUrl,
};

function search(firstName, lastName) {
    return Api.get(`persons/search?firstName=${firstName}&lastName=${lastName}`);
}

function getMe() {
    return Api.get('persons/me');
}

function updateMe(userData) {
    return Api.put('persons/me', userData);
}

function getPayments(paging) {
    let url = 'persons/me/payments';
    if (paging) {
        url = `${url}?pageNo=${paging.pageNo}&pageSize=${paging.pageSize}`;
    }

    return Api.get(url);
}

function getImageUrl(rubicPersonID) {
    return Api.rubicUrl + "/images/ProfileImage.ashx?PersonID=" + rubicPersonID
};
