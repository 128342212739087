
import React from 'react';
import CompetitionForm from '../CompetitionForm/CompetitionForm.container';
import DeleteCompetitionDialog from './DeleteCompetitionDialog.component';
import CopyCompetitionDialog from './CopyCompetitionDialog.component';
import { Typography, Button, withStyles } from '@material-ui/core';
import Error from '../../../Error/Error.component';


const getStyles = (theme) => ({
    buttonDelete: {
        float: "right",
        color: "#D32F2F",
        borderColor: "#F44336",
        '&:hover': {
            borderColor: "#D32F2F",
            backgroundColor: "#E57373",
        },
    },
    buttonCopy: {
        marginRight: 10,
        float: "right",
    }
});

const EditCompetition = (props) => {
    const {
        classes,
        onDelete,
        onCopyCompetition,
        selectedCompetition,
        isDeleting,
        isCopying, 
        copyError,
        deleteError
    } = props;


    if(!selectedCompetition.isAdmin) {
        return (<Error errorMessage="Du har ikke tilgang til å endre denne konkurransen"/>);
    }
    if(selectedCompetition.finished) {
        return (<Error errorMessage="Kan ikke endre konkurransen da den er fullført"/>);
    }

    const handleDelete = () => {
        onDelete(selectedCompetition.competitionID, props.previousLocation);
    }

    const handleCopy = (copySettings) => {
        onCopyCompetition(selectedCompetition.competitionID, copySettings);
    }

    return (
        <React.Fragment>
            <CompetitionForm
                {...props}
                header={
                    <Typography variant="h5" gutterBottom>
                        Rediger
                        {
                            <React.Fragment>
                                <Button variant="outlined" className={classes.buttonDelete} onClick={() => props.setDeleteDialogOpen(true)}>
                                    Slett
                                </Button>
                                <Button variant="contained" color="primary" className={classes.buttonCopy} onClick={() => props.setCopyDialogOpen(true)}>
                                    Lag kopi
                                </Button>

                            </React.Fragment>

                        }
                    </Typography>
                }
            />
            <CopyCompetitionDialog
                open={props.copyDialogOpen}
                onClose={() => props.setCopyDialogOpen(false)}
                onCopyCompetition={handleCopy}
                isCopying={isCopying}
                selectedCompetition={selectedCompetition}
                copyError={copyError}
            />
            <DeleteCompetitionDialog
                open={props.deleteDialogOpen}
                onClose={() => props.setDeleteDialogOpen(false)}
                onDelete={handleDelete}
                isDeleting={isDeleting}
                deleteError={deleteError}
            />

        </React.Fragment>
    );
}
export default withStyles(getStyles)(EditCompetition);