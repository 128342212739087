
export default (competition) => {
    if(competition.competitionType === 1 && competition.isPublic && competition.nsffPatronate) {
        return "Konkurranse m/ godkjenning";
    } else if(competition.competitionType === 1 && competition.isPublic && !competition.nsffPatronate) {
        return "Konkurranse u/ godkjenning";
    } else if(competition.competitionType === 1 && !competition.isPublic && !competition.nsffPatronate) {
        return "Klubbkonkurranse";
    } else if(competition.competitionType === 2) {
        return "Bildevurdering";
    } else if(competition.competitionType === 3) {
        return "Videovurdering";
    } else if(competition.competitionType === 4) {
        return "Norgesmesterskap";
    }

    return "";
} 