import { connect } from 'react-redux';
import CompetitionListWrapper from './CompetitionListWrapper.component';
const mapStateToProps = (state, props) => {
    const list = state.competition[props.competitionListKey];
    return {
        ready: list && !list.loading,
        errorMessage: list && list.error,
        competitions: list && list.data,
        paging: list && list.paging,
        loadingMore: list && list.loadingMore,
        loadingFinished: list && list.loadingFinished,
    };
};

export default connect(mapStateToProps)(CompetitionListWrapper);