import {
    actionCreator,
} from '../../../../../Utils/actions.utils';

export const actionTypes = {
    EVALUATION_FULLSCREEN_INDEX_CHANGE: "EVALUATION_FULLSCREEN_INDEX_CHANGE",
    EVALUATION_SET_SHOULD_DISPLAY_FULLSCREEN: "EVALUATION_SET_SHOULD_DISPLAY_FULLSCREEN",
    EVALUATION_SHOW_FULLSCREEN: "EVALUATION_SHOW_FULLSCREEN",
    EVALUATION_SHOW_LIST: "EVALUATION_SHOW_LIST",
}



export const fullScreenIndexChange = (index) => actionCreator(actionTypes.EVALUATION_FULLSCREEN_INDEX_CHANGE)({ index });

export const setShouldDisplayFullscreen =
    (showFullscreen, fullscreenInitialIndex) =>
        actionCreator(actionTypes.EVALUATION_SET_SHOULD_DISPLAY_FULLSCREEN)({ showFullscreen, fullscreenInitialIndex });


export const showFullscreen = (fullscreenInitialIndex) =>
    actionCreator(actionTypes.EVALUATION_SHOW_FULLSCREEN)({ fullscreenInitialIndex });

export const showList = () =>
    actionCreator(actionTypes.EVALUATION_SHOW_LIST)({  });