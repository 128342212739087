// @flow

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '../../Fields/TextField/TextField.component';
import { Avatar } from '@material-ui/core';
import logo from '../../../images/nsff_logo_150.png';
import ProgressButton from '../../Button/ProgressButton.component';
import getConfig from '../../../config/config';

const config = getConfig();

const styles = theme => ({
  root: {
    paddingTop: 256,
  },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    flexGrow: 1,
    maxWidth: 270,
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
  },
  buttonContainer: {
    textAlign: "center",
  },
  button: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
  grid: {
    position: 'relative',
    padding: '0px 10px',
  },
  image: {
    width: 120,
    height: 120,
    position: 'absolute',
    top: -60,
  },
  headerText: {
    marginTop: 50,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }
});

type Props = {
  isLoggingIn: ?boolean,
  isLoggedIn: ?boolean,
  classes: Object,
  errorMessage: ?string,
  onLogin: (username: string, password: string) => void,
}

type State = {
  username: string,
  password: string,
  submitted: boolean,
}

class Login extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      submitted: false
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({ submitted: true });
    const { username, password } = this.state;
    if (username && password) {
        this.props.onLogin(username, password);
    }
  }

  render() {
    const { classes, isLoggingIn, errorMessage } = this.props;
    const resetPasswordURL = `${config.rubicUrl}/Organizations/${config.rubicNsffOrganizationID}/UserAccount`;
    
    return (
      <div className={classes.root}>
        <Grid container justify = "center" className={classes.grid}>
          <Avatar
            className={classes.image}
            src={logo}
            />
          <Paper className={classes.paper} elevation={3}>
            <Typography variant="h5" component="h3" className={classes.headerText}>
              Logg inn med Rubic
            </Typography>
            <form name="form" onSubmit={this.handleSubmit} className={classes.form}>
              <div>
                <TextField
                  name="username"
                  onChange={this.handleChange}
                  label="Brukernavn / epost"
                  className={classes.textField}
                  type="text"
                  margin="normal"
                  variant="outlined"
                />
              </div>
              <div>
                <TextField
                  name="password"
                  onChange={this.handleChange}
                  label="Passord"
                  className={classes.textField}
                  type="password"
                  autoComplete="current-password"
                  margin="normal"
                  variant="outlined"
                />
              </div>
              <div className={classes.buttonContainer}>
                <ProgressButton variant="contained" color="primary" type="submit" className={classes.button} loading={isLoggingIn}>
                  Logg inn
                </ProgressButton>
                <a href={resetPasswordURL}>Aktivere bruker eller glemt passord?</a>
              </div>
            </form>
            { errorMessage &&
              <Typography color="error" variant="overline" display="block" align="center">
                {errorMessage}
              </Typography>
            }
          </Paper>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(Login);