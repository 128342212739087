import React from 'react';
import {
    withStyles,
    CircularProgress,
    Typography,
    Button,
} from '@material-ui/core';
import {
    CardNumberElement,
    CardExpiryElement,
    CardCVCElement,
    injectStripe,
} from 'react-stripe-elements';
import StripeElementField from './StripeElementField.component';
import TextField from '../Fields/TextField/TextField.component';
import withInternalChangeHandler from '../Fields/withInternalChangeHandler';
import apiService from '../Services/apiService';
const FormTextField = withInternalChangeHandler()(TextField);


const getStyles = (theme) => ({
    paper: {
        padding: 30,
        display: 'flex',
        justifyContent: 'center',
    },
    container: {
        position: 'relative',
    },
    loadingOverlay: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        flexDirection: 'column',
        top: 0,
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'rgba(0,0,0,0.1)',
    },
    cardElementContainer: {
        flexGrow: 1,
        maxWidth: 500,
        display: 'flex',
        flexDirection: 'column',
    },
    cardDetails: {
        display: 'flex',
    },
    buttonContainer: {
        textAlign: 'center'
    },
    button: {
        margin: 5,
    },
    loadingIndicatorContainer: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
        top: 90,
    },
    loadingIndicator: {
        marginBottom: 10,
    }
});

class CheckoutForm extends React.Component {
    state = {
        fields: {
            cardHolderName: {
                complete: false,
            },
            cardNumber: {
                complete: false,
            },
            expiry: {
                complete: false,
            },
            cvc: {
                complete: false,
            }
        }

    };


    componentDidUpdate = (prevProps) => {
        const internalProcessingPayment = this.props.internalProcessingPayment;
        const prevInternalProcessingPayment = prevProps.internalProcessingPayment;
        if(internalProcessingPayment && internalProcessingPayment !== prevInternalProcessingPayment) {
            this.setState({ processingPayment: false });
        }
    }

    submit = (ev) => {
        // User clicked submit
    }

    renderLoadingOverlay = (text) => {
        const { classes } = this.props;
        return (
            <div className={classes.loadingOverlay}>
                <CircularProgress />
                {text}
            </div>
        );
    }

    setShowCompletedMessage = () => {
        const showNotCompletedMessage = this.state.showNotCompletedMessage;
        if(showNotCompletedMessage && this.isComplete()) {
            this.setState({ showNotCompletedMessage: false });
        }
    }

    handleNameChange = (e) => {
        const value = e.target.value;
        this.setState({
            fields: {
                ...this.state.fields,
                cardHolderName: {
                    complete: !!value,
                    value: value,
                }
            }
        });
        this.setShowCompletedMessage();
    }

    isComplete = () => {
        return Object.keys(this.state.fields).every(k => this.state.fields[k].complete);
    }

    isValid = () => {
        return Object.keys(this.state.fields).every(k => !this.state.fields[k].error);
    }

    handleSubmit = () => {
        if(!this.isValid()) {
            return;
        }
        if(!this.isComplete()) {
            this.setState({ showNotCompletedMessage: true });
            return;
        }
        this.setState({ processingPayment: true });

        this.createPaymentMethod();
    }

    createPaymentMethod = () => {
        const cardHolderName =  this.state.fields.cardHolderName;
        this.props.stripe.createPaymentMethod('card', {billing_details: {name: cardHolderName.value}}).then(({paymentMethod}) => {
            this.createIntent(paymentMethod.id);
        }, (error) => {
            this.setState({ processingPayment: false, processPaymentError: error.message });
        });
    }

    createIntent = (paymentMethodID) => {
        const { organizationID, amount } = this.props;
        apiService.post(`payment/createIntent/`, { paymentMethodID, organizationID, amount }).then((res) => {
            this.validateIntent(res);
        },(error) => {
            this.setState({ processingPayment: false, processPaymentError: error.message });
        });
    }

    reValidateIntent = (intent) => {
        this.validateIntent(intent);
    }
    validateIntent = (intent) => {
        if (intent.status === "requires_action") {
            const cardHolderName =  this.state.fields.cardHolderName;
            this.props.stripe.handleCardPayment(intent.client_secret, null, {
                    payment_method_data: {
                        billing_details: { name: cardHolderName.value }
                    }
                }
              ).then((result) => {
    
                // The card action has been handled
                // The PaymentIntent can be confirmed again on the server
                this.validateIntent(result.paymentIntent);
                
            }, (error) => {
                this.setState({ processingPayment: false, processPaymentError: error.message });
            });
        } else {
            this.props.onProcessPayment(intent.id);
        } 
    }


    handleElementChange = (field) => ({
        complete,
        error = { message: null }
      }) => {
        this.setState({
            fields: {
            ...this.state.fields,
            [field]: { complete, error: error.message }
            }
        });

        this.setShowCompletedMessage();
      };
    


    render() {
        const { classes, internalProcessingPayment, internalProcessPaymentError, amount } = this.props;
        const { fields, processingPayment, processPaymentError } = this.state;

        const error = processPaymentError || internalProcessPaymentError;
        const processing = processingPayment || internalProcessingPayment;
        const paymentButtonText = `Betal ${amount} kr`;
        return (
            <div className={classes.container}>
                {processing &&
                    <div className={classes.loadingIndicatorContainer}>
                        <CircularProgress className={classes.loadingIndicator} />
                        <Typography variant="body1">
                            Utfører betaling...
                        </Typography>
                    </div>
                }
                <div style={{ visibility: processing ? 'hidden' :  'visible'}}>
                    <div className={classes.cardElementContainer}>
                        <FormTextField
                            style={{
                                margin: 0,
                                marginBottom: 10,
                                height: 76,
                            }}
                            required
                            error={fields.cardHolderName.error}
                            label="Navn"
                            onBlur={this.handleNameChange}
                            value={fields.cardHolderName.value || ""}
                            fullWidth
                        />
                        <StripeElementField
                            label="Kortnummer"
                            error={!!fields.cardNumber.error}
                            onChange={this.handleElementChange("cardNumber")}
                            labelErrorMessage={"Ugyldig kortnummer"}
                            component={CardNumberElement}
                        />
                        <div className={classes.cardDetails}>
                            <StripeElementField
                                style={{
                                    marginRight: 3,
                                }}
                                label="Utløpsdato"
                                error={!!fields.expiry.error}
                                onChange={this.handleElementChange("expiry")}
                                labelErrorMessage={"Ugyldig utløpsdato"}
                                component={CardExpiryElement}
                            />
                            <StripeElementField
                                style={{
                                    marginLeft: 3,
                                }}
                                label="CVC"
                                error={!!fields.cvc.error}
                                onChange={this.handleElementChange("cvc")}
                                labelErrorMessage={"Ugyldig CVC"}
                                component={CardCVCElement}
                            />
                        </div>
                    </div>
                    <div>
                        <div className={classes.buttonContainer}>
                            <Button onClick={this.props.onClose} className={classes.button}>Avbryt</Button>
                            <Button onClick={this.handleSubmit} color="primary" variant="contained" className={classes.button}>{paymentButtonText}</Button>
                        </div>
                        {this.state.showNotCompletedMessage &&
                            <Typography color="error" variant="overline" display="block" align="center">
                                Alle felter må fylles ut
                            </Typography>
                        }
                        {error &&
                            <Typography color="error" variant="overline" display="block" align="center">
                                {error}
                            </Typography>
                        }
                    </div>
                                    
                </div>
            </div>
        );
    }
}

export default withStyles(getStyles)(injectStripe(CheckoutForm));