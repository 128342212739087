import { actionTypes } from '../components/Pages/Competition/CompetitionForm/CompetitionForm.actions';

const initState = {

}

const createCompetitionReducer = (state = initState, action) => {
    switch(action.type) {
        case actionTypes.UPDATE_FORM:
            console.log('Updating form');
            return {
                ...state,
                [action.payload.fieldName]: action.payload.value,
            };
        case actionTypes.INIT_FORM:
            console.log('Setting comp in state');
            if(!action.payload.competition) {
                return {};
            }

            const admins = action.payload.competition.competitionAdmins || [];

            const juryMembersByID = action.payload.competition.competitionJuryMembers.reduce((obj, item) => {
                obj[item.personID] = item;
                return obj;
            },{});

            return {
                ...action.payload.competition,
                competitionClasses: action.payload.competition.competitionClasses,
                competitionAdmins: admins,
                competitionJuryMembers: juryMembersByID,
            };
        case actionTypes.REQUEST_SEARCH:
            console.log('Searching for person');
            return {
                ...state,
                searchError: null,
                searching: true,
            };
        case actionTypes.SEARCH_SUCCESS:
            console.log('Searching for person success');
            return {
                ...state,
                searchPersonResult: action.payload.response,
                searching: false,
            };
        case actionTypes.SEARCH_FAILED:
            console.log('Searching for person failed');
            return {
                ...state,
                searchPersonResult: [],
                searchError: action.payload.error.message,
                searching: false,
            };
        case actionTypes.REQUEST_GET_PAYMENT_CONFIGURED:
            console.log('Requesting payment configured');
            return {
                ...state,
                paymentConfiguredLoading: true,
                paymentConfigured: false,
            };
        case actionTypes.GET_PAYMENT_CONFIGURED_SUCCESS:
            console.log('Get payment configured success');
            return {
                ...state,
                paymentConfiguredLoading: false,
                paymentConfigured: action.payload.response,
            };
        case actionTypes.GET_PAYMENT_CONFIGURED_FAILED:
            console.log('Get payment configured failed');
            return {
                ...state,
                paymentConfiguredLoading: false,
                paymentConfiguredError: action.payload.error.message,
            };
        default:
            return state;
    }
}

export default createCompetitionReducer;