import React, { useState } from 'react';
import { withStyles, TextField, Chip } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { Cancel } from '@material-ui/icons';
import clsx from 'clsx';
import './selectfields.css';


const getStyles = (theme) => ({
    input: {
        display: "flex",
        padding: 0,
        height: 56
    },
});


function MultiValue(props) {
    return (
      <Chip
        tabIndex={-1}
        label={props.children}
        className={clsx(props.selectProps.classes.chip, {
          [props.selectProps.classes.chipFocused]: props.isFocused,
        })}
        onDelete={props.removeProps.onClick}
        deleteIcon={<Cancel {...props.removeProps} />}
      />
    );
  }


function inputComponent({ inputRef, ...props }) {
    return <div ref={inputRef} {...props} />;
}

const Control = (props) => {
    const {
        children,
        innerProps,
        innerRef,
        selectProps: { classes, TextFieldProps }
    } = props;

    return (
        <TextField
            fullWidth
            InputProps={{
                inputComponent,
                inputProps: {
                    className: classes.input,
                    ref: innerRef,
                    children,
                    ...innerProps
                }
            }}
            {...TextFieldProps}
        />
    );
}

const components = {
    Control,
}



const AsyncSelectField = (props) => {
    const { ...passOnProps } = props;

    return (
        <AsyncSelect
            cacheOptions
            defaultOptions
            {...passOnProps}
        />
    );
}

const SyncSelectField = (props) => (
    <Select
        {...props}
    />
);


const defaultNoInputValueEmptyText = "Søk";
const defaultHasInputValueEmptyText = "Ingen funnet";

const SelectField = (props) => {
    const theme = useTheme();
    const { label, placeholder, async, value, isMulti, noInputValueEmptyText, hasInputValueEmptyText, ...passOnProps } = props;
    const [focused, setFocused] = useState(false);
    const [inputValue, setInputValue] = useState();


    const currentNoInputValueEmptyText = noInputValueEmptyText || defaultNoInputValueEmptyText;
    const currentHasInputValueEmptyText = hasInputValueEmptyText || defaultHasInputValueEmptyText;
    const selectStyles = {
        input: base => ({
            ...base,
            color: theme.palette.text.primary,
            "& input": {
                font: "inherit"
            }
        })
        
    };

    const textFieldDefaultProps = {
        variant: "outlined",
        InputLabelProps: {
            htmlFor: 'react-select-multiple',
            shrink: focused || (value && value.length > 0),
        },
    }
    let currentComponents = components;
    if(isMulti) {
        currentComponents.MultiValue = MultiValue;
    }

    const commonProps = {
        styles: { menu: base => ({ ...base, zIndex: 9999 }) },
        selectStyles,
        TextFieldProps: {
            ...textFieldDefaultProps,
            onFocus: () => setFocused(true),
            onBlur: () => setFocused(false),
            label: label,
        },
        placeholder: "",
        components,
        isMulti,
        value,
        onInputChange: (v) => setInputValue(v),
        loadingMessage: () => "Søker...",
        noOptionsMessage: () => inputValue ? currentHasInputValueEmptyText : currentNoInputValueEmptyText,
        ...passOnProps,
    }

    if(async) {
        return (
            <AsyncSelectField
                {...commonProps}
            />
        );
    }
    return (
        <SyncSelectField
            {...commonProps}
        
        />
    );
}

export default withStyles(getStyles)(SelectField);