import React from 'react';
import {
    withStyles,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from '@material-ui/core';
import TextField from '../../../Fields/TextField/TextField.component';


const getStyles = ({
    buttonContainer: {
        justifyContent: "center",
    },
});

class PatronageDialog extends React.Component {
    state = {
        nsffPatronate: "",
    };

    onChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSet = () => {
        const { handleSetPatronage, onClose, compID } = this.props;
        handleSetPatronage(this.state.nsffPatronate, compID);
        this.setState({ nsffPatronate: ""});
        onClose();
    }

    handleDelete = () => {
        const { handleDeletePatronage, onClose, compID } = this.props;
        handleDeletePatronage(compID);
        onClose();
    }

    handleClose = () => {
        this.props.onClose();
    }

    render() {
        const { classes, isOpen, onClose, nsffPatronate } = this.props;

        return (
            <Dialog open={isOpen} onClose={onClose}>
                <DialogTitle>{nsffPatronate ? 'Fjern NSFF patronage' : 'Gi NSFF patronage'}</DialogTitle>
                {!nsffPatronate && 
                    <DialogContent>
                        <TextField
                            autoFocus
                            onChange={this.onChange}
                            value={this.state.nsffPatronate}
                            name="nsffPatronate"
                            label="Patronage"
                            type="text"
                        />
                    </DialogContent>
                }
                <DialogActions className={classes.buttonContainer}>
                    <Button variant="text" color="primary" onClick={this.handleClose}>
                        Avbryt
                    </Button>
                    {!nsffPatronate && 
                        <Button variant="contained" color="primary" onClick={this.handleSet} disabled={!this.state.nsffPatronate}>
                            Lagre
                        </Button>
                    }
                    {nsffPatronate &&
                        <Button variant="contained" color="primary" onClick={this.handleDelete}>
                            Fjern patronage
                        </Button>
                    }
                </DialogActions>
            </Dialog>
        );
    }
}


export default withStyles(getStyles)(PatronageDialog);
