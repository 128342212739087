import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ResultCard from './ResultCard.component';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Person from '@material-ui/icons/Person';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ProgressButton from '../../../Button/ProgressButton.component';
import { Menu, MenuItem } from '@material-ui/core';
import downloadFile from '../../../../Utils/download/downloadFile';
import DownloadDialog from '../../../DownloadDialog/DownloadDialog.component';

const styles = theme => ({
    paper: {
        margin: 8,
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    heading: {
      fontSize: theme.typography.pxToRem(24),
      flexBasis: '33.33%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    summary: {
      backgroundColor: "#ebba14",
    },
    details: {
      display: "block",
    },
    noResultText: {
        paddingTop: 16,
    },
    button: {
        float: "right",
    },
    personPaper: {
        marginBottom: 8,
    },
    header: {
        display: 'flex',
    },
    headerText: {
        flexGrow: 1,
        marginBottom: 16
    },
    downloadButton: {
        marginRight: 10,
    }
  });

class Result extends React.Component {
    state = {
        downloadDialogSettings: null,
        expanded: this.props.selectedComp.competitionClasses && this.props.selectedComp.competitionClasses[0].competitionClassID,
    };

    handleChange = panel => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : false,
        });
    };

    handleClickBack = () => {
        this.props.onBackClick(this.props.previousLocation);
    }

    handleDownloadAwarded = () => {
        this.setState({ downloadDialogSettings: null });
        const { competitionID } = this.props.selectedComp;
        const url = `competitionResults/${competitionID}/download`;
        downloadFile(url);
    }

    handleDownloadAll = () => {
        this.setState({ downloadDialogSettings: null });
        const { competitionID } = this.props.selectedComp;
        const url = `competitions/${competitionID}/downloadParticipations`;
        downloadFile(url);
    }

    handleDownloadCancel = () => {
        this.setState({ downloadDialogSettings: null });
    }

    handleExport = () => {
        const { competitionID } = this.props.selectedComp;
        const url = `competitionResults/${competitionID}/competitionResults/export`;
        downloadFile(url);
    }


    renderCompetitionClassResults = (competitionClass) => {
        const { selectedCompResults, classesByID, classes } = this.props; 
        const { expanded } = this.state;
        const competitionClassID = competitionClass.competitionClassID;
        const competitionClassResults = selectedCompResults.competitionClassResultsByID[competitionClassID] || [];
        const headerText = `${classesByID[competitionClass.classID].name} - ${competitionClass.theme}`;
        return (
            <ExpansionPanel TransitionProps={{ unmountOnExit: true, timeout: 0 }} key={competitionClassID} expanded={expanded === competitionClassID} onChange={this.handleChange(competitionClassID)}>
                <ExpansionPanelSummary className={classes.summary} expandIcon={<ExpandMoreIcon />}>
                    <Typography className={classes.heading}>{headerText}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.details}>
                    {competitionClassResults.length > 0 && competitionClassResults.map((media, index) =>
                        <div key={index}>
                            <ResultCard mediaItem={media} />
                            {index < competitionClassResults.length - 1 &&
                                <Divider style={{marginBottom: 16}} />
                            }
                        </div>
                    )}
                    {competitionClassResults.length <= 0 &&
                        <Typography className={classes.noResultText} variant="subtitle1" align="center">
                            Ingen resultater i denne klassen
                        </Typography>   
                    }
                </ExpansionPanelDetails>
            </ExpansionPanel>
        )
    }

    handleDownloadMenuOpen = (event) => {
        this.setState({ anchorEl: event.currentTarget });
      }
    
    handleDownloadMenuClose = () => {
        this.setState({ anchorEl: null });
    }

    showDownloadAllButton = () => {
        const { selectedComp, isNsffAdmin } = this.props;

        return isNsffAdmin || selectedComp.isAdmin || selectedComp.isJuryLeader;

    }

    renderDownloadButton = () => {
        const { classes, selectedComp, selectedCompResults } = this.props;
        const { downloadingFile, anchorEl } = this.state;

        const downloadAllSettings = {
            onConfirm: this.handleDownloadAll,
            onCancel: this.handleDownloadCancel,
            fileCount: selectedComp.participationCount,
            avgFileSize: 2
        }

        const downloadAwardedSettings = {
            onConfirm: this.handleDownloadAwarded,
            onCancel: this.handleDownloadCancel,
            fileCount: selectedCompResults.resultCount,
            avgFileSize: 2
        }

        return (
            <React.Fragment>
                <ProgressButton onClick={this.handleDownloadMenuOpen} className={classes.downloadButton} loading={downloadingFile} aria-controls="download-menu" aria-haspopup="true" >
                    Last ned
                </ProgressButton>
                <Menu
                    id="download-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={this.handleDownloadMenuClose}
                >
                    <MenuItem onClick={() => { this.setState({ downloadDialogSettings: downloadAwardedSettings })}}>Last ned premierte</MenuItem>
                    {this.showDownloadAllButton() && <MenuItem onClick={() => { this.setState({ downloadDialogSettings: downloadAllSettings })}}>Last ned alle</MenuItem>}
                </Menu>
            </React.Fragment>
        );
    }

    renderExportButton = () => {
        const { classes } = this.props;
        return (
            <Button onClick={this.handleExport} className={classes.downloadButton}>
                Eksporter
            </Button>
        )
    }

    render() {
        const { classes, selectedComp, selectedCompOrgResults } = this.props;
        const { expanded } = this.state;


        
        return (
        <Paper className={classes.paper} elevation={1}>
            <div className={classes.header}>
                <Typography variant="h5" className={classes.headerText}>
                    Resultater for {selectedComp.name}
                </Typography>
                <div>
                    {this.renderExportButton()}
                    {this.renderDownloadButton()}
                    <Button variant="outlined" color="primary" onClick={this.handleClickBack}>
                        Tilbake
                    </Button>
                </div>
            </div>
            {selectedComp && selectedComp.competitionJuryLeader &&
                <div>
                    <Typography variant="h6">
                        Juryleder
                    </Typography>
                    <Paper elevation={1} key={selectedComp.competitionJuryLeader.personID} className={classes.personPaper}>
                        <ListItem>
                            <ListItemIcon>
                                <Person />
                            </ListItemIcon>
                            <ListItemText
                                primary={selectedComp.competitionJuryLeader.firstName + ' ' + selectedComp.competitionJuryLeader.lastName}
                            />
                        </ListItem>
                    </Paper>
                </div>
            }
            {selectedComp && selectedComp.competitionJuryMembers && selectedComp.competitionJuryMembers.length > 0 && 
                <div>
                    <Typography variant="h6">
                        Jurymedlemmer
                    </Typography>
                    {selectedComp.competitionJuryMembers.map(person => 
                        <Paper elevation={1} key={person.personID} className={classes.personPaper}>
                            <ListItem>
                                <ListItemIcon>
                                    <Person />
                                </ListItemIcon>
                                <ListItemText
                                    primary={person.firstName + ' ' + person.lastName}
                                />
                            </ListItem>
                        </Paper>
                    )}
                </div>
            }
            <Typography style={{marginTop: 24}} variant="h6">
                Resultater
            </Typography>
            {selectedComp.competitionClasses && selectedComp.competitionClasses.map((competitionClass) =>
                this.renderCompetitionClassResults(competitionClass)
            )}
            {selectedComp.competitionType === 4 &&
                <ExpansionPanel expanded={expanded === "orgResult"} onChange={this.handleChange("orgResult")}>
                    <ExpansionPanelSummary className={classes.summary} expandIcon={<ExpandMoreIcon />}>
                        <Typography className={classes.heading}>Klubb-resultater</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.details}>
                        <div>
                            <Table className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell padding="none">Plassering</TableCell>
                                        <TableCell>Klubb</TableCell>
                                        <TableCell align="right">Poeng</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {selectedCompOrgResults.map((org) => (
                                        <TableRow key={org.organizationID}>
                                            <TableCell padding="none" >{org.position}</TableCell>
                                            <TableCell>
                                                {org.organizationName}
                                            </TableCell>
                                            <TableCell align="right">{org.scoreSum}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            }
            {this.state.downloadDialogSettings && <DownloadDialog {...this.state.downloadDialogSettings} open />}
        </Paper>
        );
  }
}

export default withStyles(styles)(Result);