import Api from './apiService';

export const startService = {
    getClasses,
    getAdmins,
    getOrganizations,
};

function getClasses() {
    return Api.get('classes');
}

function getAdmins() {
    return Api.get('organizations/admins');
}

function getOrganizations() {
    return Api.get('organizations');
}