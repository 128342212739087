import React from 'react';
import { connect } from 'react-redux';

import { MuiThemeProvider } from "@material-ui/core/styles";
import theme from './styles/uiTheme';
import { Provider } from 'react-redux';
import { getStore, history } from './store/getStore';
import Start from './components/Pages/Start/Start.container';
import Login from './components/Pages/Login/Login.container';
import PrivateRoute from './components/Routes/PrivateRoute.component';
import LoginRoute from './components/Routes/LoginRoute.component';
import './App.css';
import { initializeData } from './components/Pages/Start/Start.actions';

import withLoadingIndicator from './components/LoadingIndicator/withLoadingIndicator';
import withErrorMessage from './components/Error/withErrorMessage';
import { ConnectedRouter } from 'connected-react-router';
import { Switch } from 'react-router-dom';
const store = getStore();


const mapStateToProps = (state) => {
  const isLoggedIn = localStorage.getItem('user');
  return {
    ready: !isLoggedIn || (state.metaData && !state.metaData.appLoading),
    errorMessage: state.metaData.errorMessage,
  }
}

const Entry = connect(mapStateToProps)(withLoadingIndicator()(withErrorMessage()(ConnectedRouter)));


class App extends React.Component {
  componentDidMount() {
    if(localStorage.getItem('user')) {
      store.dispatch(initializeData());
    }
    
  }
  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <div>
          <Provider store={store}>
            <Entry history={history}>
                <div>
                  <Switch>
                    <LoginRoute path="/login" component={Login} exact/>
                    <PrivateRoute path="/" component={Start} />     
                  </Switch>          
                </div>
            </Entry>
          </Provider>
        </div>
      </MuiThemeProvider>
    );
  }
}

export default App;
