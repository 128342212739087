import React from 'react';
import classNames from 'classnames';
import {
    withStyles,
    Button,
    CircularProgress,
} from '@material-ui/core';




const getStyles = (theme) => ({
    wrapper: {
        display: 'inline-flex',
        position: 'relative',
        justifyContent: 'center',
    },
    button: {
        width: '100%',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
      },
});


class ProgressButton extends React.Component {
    
    render(){
        const { loading, disabled, children, classes, buttonProps, buttonClassName, className, ...passOnProps } = this.props;
        return (
            <div className={classNames(classes.wrapper, className)}>
                <Button
                    disabled={loading || disabled}
                    className={classNames(classes.button, buttonClassName)}
                    {...passOnProps}
                >
                    {children}
                </Button>
                {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
        );
    }
}


export default withStyles(getStyles)(ProgressButton);