import React from 'react';
import {
    List,
    withStyles,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails
} from '@material-ui/core';
import {
    ExpandMore,
    NewReleases,
} from '@material-ui/icons';
import DistinctionItemListItem from './DistinctionItemListItem.container';
import {getAvailableDistinctions } from '../availableDistinctions';

const getStyles = (theme) => ({
    distinctionItemsList: {
        flexGrow: 1,
        maxWidth: 800,
    },
    distinctionTypeSummary: {
        fontWeight: 500,
        padding: '10px 20px',
    },
    distinctionTypeHeader: {
        flexGrow: 1,
    },
    availableIndicator: {
        marginRight: 10,
    },
    panel: {
        borderRadius: '0px!important',
    }
});

const panelsWithAvailableIndicator = ['ansff', 'ensff', 'nasjonalplakett', 'internasjonalplakett'];

class DistinctionTypePanel extends React.Component {

    handleYearChange = (distinctionItemID, year) => {
        this.props.onYearChange(this.props.distinctionType.distinctionTypeID, distinctionItemID, year);
    }

    showPoints = () => {
        const { displaySettings } = this.props.distinctionType;
        return displaySettings && displaySettings.showEarnedPointsForPerson;
    }

    displayAvailableIndicator = () => {
        const { displaySettings } = this.props.distinctionType;
        return displaySettings && displaySettings.showAvailableDistinctionIndicatorForPerson;
    }

    renderForDistinctionItem = (distinctionItem, index, lastItemIndex) => {
        const {
            distinctionTypePoints
        } = this.props;
        return (
            <DistinctionItemListItem
                key={distinctionItem.distinctionItemID}
                distinctionItem={distinctionItem}
                index={index}
                lastItemIndex={lastItemIndex}
                onYearChange={this.handleYearChange}
                points={distinctionTypePoints.points}
                displayAvailableIndicator={this.displayAvailableIndicator()}
            />
        );
    }

    renderAvailableIndicator = () => {
        const { distinctionTypePoints, distinctionsByItemID, distinctionType, classes } = this.props;
        if(panelsWithAvailableIndicator.includes(distinctionType.code)) {
            const availableDistinctions = getAvailableDistinctions(
                distinctionTypePoints.points, 
                distinctionsByItemID,
                distinctionType.distinctionItems,
            );
            return !!availableDistinctions && (
                <div className={classes.availableIndicator}>
                    <NewReleases color="secondary" />
                </div>
            );
        }
        return null;
    }

    render() {
        const {
            classes,
            distinctionTypePoints,
            distinctionType,
            isOpen,
            onTogglePanel,
            distinctionsByItemID,
        } = this.props;
        const id = distinctionType.distinctionTypeID;        
        const distinctionItemCount = distinctionType.distinctionItems.length;
        const distinctionCount = Object.keys(distinctionsByItemID).filter(itemID => distinctionsByItemID[itemID].archievedYear).length;
        return (
            <ExpansionPanel key={id} expanded={isOpen} onChange={onTogglePanel} className={classes.panel}>
                <ExpansionPanelSummary className={classes.distinctionTypeSummary} expandIcon={<ExpandMore />}>
                    <div className={classes.distinctionTypeHeader}>
                        {`${distinctionType.name} ${this.showPoints() ? '(' + distinctionTypePoints.points + 'p)' || '(0p)' : ''}`}
                    </div>
                    {this.renderAvailableIndicator()}
                    <div>
                        {`${distinctionCount} / ${distinctionItemCount}`}
                    </div>
                </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.distinctionDetails}>
                <List className={classes.distinctionItemsList}>
                    {distinctionType.distinctionItems.map((di, i) => this.renderForDistinctionItem(di, i, distinctionItemCount -1))}
                </List>
            </ExpansionPanelDetails>
        </ExpansionPanel>
        )
    }
}

export default withStyles(getStyles)(DistinctionTypePanel);