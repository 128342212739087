import { simplePromiseAction, actionCreator } from '../../../Utils/actions.utils';
import { competitionService } from '../../Services/competitionService';

export const actionTypes = {
    REQUEST_ACTIVE_COMPETITIONS: 'REQUEST_ACTIVE_COMPETITIONS',
    REQUEST_ACTIVE_COMPETITIONS_SUCCESS: 'REQUEST_ACTIVE_COMPETITIONS_SUCCESS',
    REQUEST_ACTIVE_COMPETITIONS_FAILED: 'REQUEST_ACTIVE_COMPETITIONS_FAILED',
    REQUEST_UPCOMMING_COMPETITIONS: 'REQUEST_UPCOMMING_COMPETITIONS',
    REQUEST_UPCOMMING_COMPETITIONS_SUCCESS: 'REQUEST_UPCOMMING_COMPETITIONS_SUCCESS',
    REQUEST_UPCOMMING_COMPETITIONS_FAILED: 'REQUEST_UPCOMMING_COMPETITIONS_FAILED',
    REQUEST_FINISHED_COMPETITIONS: 'REQUEST_FINISHED_COMPETITIONS',
    REQUEST_FINISHED_COMPETITIONS_SUCCESS: 'REQUEST_FINISHED_COMPETITIONS_SUCCESS',
    REQUEST_FINISHED_COMPETITIONS_FAILED: 'REQUEST_FINISHED_COMPETITIONS_FAILED',
    REQUEST_MORE_FINISHED_COMPETITIONS: 'REQUEST_MORE_FINISHED_COMPETITIONS',
    REQUEST_MORE_FINISHED_COMPETITIONS_SUCCESS: 'REQUEST_MORE_FINISHED_COMPETITIONS_SUCCESS',
    REQUEST_MORE_FINISHED_COMPETITIONS_FAILED: 'REQUEST_MORE_FINISHED_COMPETITIONS_FAILED',
    SET_VIEW_MODE: 'SET_VIEW_MODE',
    START_LOAD_COMPETITION_DATA: 'START_LOAD_COMPETITION_DATA',
    LOAD_COMPETITION_DATA_FINISHED: 'LOAD_COMPETITION_DATA_FINISHED',
    SET_SELECTED_COMP: 'SET_SELECTED_COMP',
    REQUEST_CREATE_PUBLIC_JURYMEMBER: 'REQUEST_CREATE_PUBLIC_JURYMEMBER',
    REQUEST_CREATE_PUBLIC_JURYMEMBER_SUCCESS: 'REQUEST_CREATE_PUBLIC_JURYMEMBER_SUCCESS',
    REQUEST_CREATE_PUBLIC_JURYMEMBER_FAILED: 'REQUEST_CREATE_PUBLIC_JURYMEMBER_FAILED',
    REQUEST_COMPETITION: 'REQUEST_COMPETITION',
    REQUEST_COMPETITION_SUCCESS: 'REQUEST_COMPETITION_SUCCESS',
    REQUEST_COMPETITION_FAILED: 'REQUEST_COMPETITION_FAILED',
    REQUEST_SEARCH_FINISHED_COMPETITIONS: 'REQUEST_SEARCH_FINISHED_COMPETITIONS',
    REQUEST_SEARCH_FINISHED_COMPETITIONS_SUCCESS: 'REQUEST_SEARCH_FINISHED_COMPETITIONS_SUCCESS',
    REQUEST_SEARCH_FINISHED_COMPETITIONS_FAILED: 'REQUEST_SEARCH_FINISHED_COMPETITIONS_FAILED',
}

export const getActive = () => simplePromiseAction(
    () => competitionService.getActive(),
    actionTypes.REQUEST_ACTIVE_COMPETITIONS,
    actionTypes.REQUEST_ACTIVE_COMPETITIONS_SUCCESS,
    actionTypes.REQUEST_ACTIVE_COMPETITIONS_FAILED,
);

export const getUpcomming = () => simplePromiseAction(
    () => competitionService.getUpcomming(),
    actionTypes.REQUEST_UPCOMMING_COMPETITIONS,
    actionTypes.REQUEST_UPCOMMING_COMPETITIONS_SUCCESS,
    actionTypes.REQUEST_UPCOMMING_COMPETITIONS_FAILED,
);

export const getFinished = () => simplePromiseAction(
    () => competitionService.getFinished(),
    actionTypes.REQUEST_FINISHED_COMPETITIONS,
    actionTypes.REQUEST_FINISHED_COMPETITIONS_SUCCESS,
    actionTypes.REQUEST_FINISHED_COMPETITIONS_FAILED,
);

export const getCompetition = (competitionID) => simplePromiseAction(
    () => competitionService.getCompetition(competitionID),
    actionTypes.REQUEST_COMPETITION,
    actionTypes.REQUEST_COMPETITION_SUCCESS,
    actionTypes.REQUEST_COMPETITION_FAILED,
);

export const fetchMoreFinished = (paging, searchText) => simplePromiseAction(
    () => competitionService.getFinished(paging, searchText),
    actionTypes.REQUEST_MORE_FINISHED_COMPETITIONS,
    actionTypes.REQUEST_MORE_FINISHED_COMPETITIONS_SUCCESS,
    actionTypes.REQUEST_MORE_FINISHED_COMPETITIONS_FAILED,
);

export const fetchSearchFinished = (searchText) => simplePromiseAction(
    () => competitionService.getFinished(null, searchText),
    actionTypes.REQUEST_SEARCH_FINISHED_COMPETITIONS,
    actionTypes.REQUEST_SEARCH_FINISHED_COMPETITIONS_SUCCESS,
    actionTypes.REQUEST_SEARCH_FINISHED_COMPETITIONS_FAILED,
);

export const loadCompetitionData = () => (dispatch) => {
    dispatch(actionCreator(actionTypes.START_LOAD_COMPETITION_DATA)({}));
    return dispatch(getActive())
        .then(() => dispatch(getUpcomming())
            .then(() => dispatch(getFinished())
                .then(() => dispatch(actionCreator(actionTypes.LOAD_COMPETITION_DATA_FINISHED)({}))
                )
            )
        );
}

export const createPublicJuryMember = (competitionID) => simplePromiseAction(
    () => competitionService.createPublicJuryMember(competitionID),
    actionTypes.REQUEST_CREATE_PUBLIC_JURYMEMBER,
    actionTypes.REQUEST_CREATE_PUBLIC_JURYMEMBER_SUCCESS,
    actionTypes.REQUEST_CREATE_PUBLIC_JURYMEMBER_FAILED,
);

const setViewModeAction = (mode, selectedComp, registerViewActiveStep) => actionCreator(actionTypes.SET_VIEW_MODE)({ mode, selectedComp, registerViewActiveStep });

export const setViewMode = (mode, selectedComp, registerViewActiveStep) => setViewModeAction(mode, selectedComp, registerViewActiveStep);

const setSelectedCompAction = (selectedComp) => actionCreator(actionTypes.SET_SELECTED_COMP)({ selectedComp });

export const setSelectedComp = (selectedComp) => setSelectedCompAction(selectedComp);
