import React from 'react';
import CompetitionList from './CompetitionList.component';
import withLoadingIndicator from '../../../LoadingIndicator/withLoadingIndicator';
import withErrorMessage from '../../../Error/withErrorMessage';
import {
    withStyles,
    Typography,
    Paper,
    Button,
} from '@material-ui/core';

const CompetitionListWithLoading = withLoadingIndicator()(withErrorMessage()(CompetitionList));

const styles = theme => ({
    header: {
        marginBottom: 16,
    },
    root: {
        padding: 10,
    },
    paper: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        marginBottom: 8,
        position: "relative",
    },
    button: {
        float: 'right',
    },
});
class CompetitionListWrapper extends React.Component {
    render() {
        const { headerText, classes, isAnyAdmin, ready, showCreateNew, ...passOnProps } = this.props;
        return (
            <Paper className={classes.paper} elevation={1}>
                <Typography variant="h5" className={classes.header}>
                    {headerText}
                    {isAnyAdmin && ready && showCreateNew &&
                        <Button variant="outlined" color="primary" className={classes.button} onClick={this.props.newCompFunction}>
                            Opprett ny
                        </Button>
                    }
                </Typography>
                <CompetitionListWithLoading
                    ready={ready}
                    isAnyAdmin={isAnyAdmin}
                    {...passOnProps}
                />
            </Paper>
        );
    }
}

export default withStyles(styles)(CompetitionListWrapper);