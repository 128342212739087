import { connect } from 'react-redux';
import UserProfileEdit from './UserProfileEdit.component';
import { formFieldUpdate } from '../../../Form/Form.actions';
import { requestSaveProfile, cancelEditProfile } from '../Dashboard.actions';


const formId = 'userProfileForm';

const mapStateToProps = (state) => {

    return {
        userDataForm: state.forms[formId],
        saving: state.userProfile.saving,
        error: state.userProfile.saveError,
        organizations: state.clubs.orgs && state.clubs.orgs.filter(o => o.isMember),
    }
};

const mapDispatchToProps = (dispatch) => ({
    onFormFieldUpdate: (field, value) => {
        dispatch(formFieldUpdate(formId, field, value));
    },
    requestSaveProfile: (userFormData) => {
        dispatch(requestSaveProfile(userFormData));
    },
    onCancelEditProfile: () => {
        dispatch(cancelEditProfile());
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(UserProfileEdit);