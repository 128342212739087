import { actionTypes } from '../components/Pages/Distinctions/Import/ImportResults.actions';
import { actionTypes as distinctionsForPersonActionTypes } from '../components/Pages/Distinctions/EditDistinctionsForPerson/EditDistinctionsForPerson.actions';

const initState = {

}

const importResultsReducer = (state = initState, action) => {
    switch(action.type) {
        case actionTypes.REQUEST_UPLOAD:
            console.log('Uploading file');
            return {
                ...state,
                isUploading: true,
            };
        case actionTypes.REQUEST_UPLOAD_SUCCESS:
            console.log('Upload success');
            return {
                ...state,
                isUploading: false,
                uploadedResults: action.payload.response,
                uploadedFileName: action.meta.fileName,
            };
        case actionTypes.REQUEST_UPLOAD_FAILED:
            console.log('Upload failed');
            return {
                ...state,
                isUploading: false,
                uploadErrorMessage: action.payload.error.message,
            };
        case distinctionsForPersonActionTypes.GET_IMPORTED_RESULTS:
            console.log('Fetching imported results');
            return {
                ...state,
                loadingImportedResults: true,
            };
        case distinctionsForPersonActionTypes.GET_IMPORTED_RESULTS_SUCCESS:
            console.log('Fetching imported results success');
            return {
                ...state,
                loadingImportedResults: false,
                importedResults: action.payload.response,
            };
        case distinctionsForPersonActionTypes.GET_IMPORTED_RESULTS_FAILED:
            console.log('Fetching imported results failed');
            return {
                ...state,
                loadingImportedResults: false,
                loadingImporteErrorMessage: action.payload.error.message,
            };
        case actionTypes.REQUEST_SAVE_UPLOAD:
                console.log('Saving uploaded results');
                return {
                    ...state,
                    savingImportedResults: true,
                };
        case actionTypes.REQUEST_SAVE_UPLOAD_SUCCESS:
            console.log('Saving uploaded results success');
            const newImportedResults = action.meta.results.concat(state.importedResults);
            return {
                ...state,
                savingImportedResults: false,
                importedResults: newImportedResults,
                uploadedResults: null,
                uploadedFileName: null,
            };
        case actionTypes.REQUEST_SAVE_UPLOAD_FAILED:
            console.log('Saving uploaded results failed');
            return {
                ...state,
                savingImportedResults: false,
                savingImportedResultsErrorMessage: action.payload.error.message,
            };
        case actionTypes.CLEAR_UPLOADED_RESULTS:
            console.log('Clearing uploaded results');
            return {
                ...state,
                uploadedResults: null,
                uploadedFileName: null,
            };
        case distinctionsForPersonActionTypes.GO_BACK_TO_PERSON_DISTINCTIONS_LIST:
            return {};
        default:
            return state;
    }
}

export default importResultsReducer;



