import { connect } from 'react-redux';
import Payments from './Payments.component';
import { fetchPayments } from '../Dashboard.actions';
const mapStateToProps = (state) => {
    
    return {
        payments: state.payments && state.payments.data,
        paging: state.payments && state.payments.paging,
        ready: state.payments && !state.payments.loading,
    }
};

const mapDispatchToProps = (dispatch) => ({
    onPageChange: (paging) => {
        dispatch(fetchPayments(paging));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Payments);