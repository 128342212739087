import React from 'react';
import Competition from './Competition.component';
import withLoadingIndicator from '../../LoadingIndicator/withLoadingIndicator';

const CompetitionWithLoading = withLoadingIndicator()(Competition);

class CompetitionWrapper extends React.Component {

    componentDidMount() {
        this.props.loadData();
        this.props.setViewMode("compList");
    }

    componentDidUpdate(prevProps) {
        if (this.props.viewMode === "compList" && this.props.viewMode !== prevProps.viewMode) {
            this.props.loadData();
        }
    }

    render() {
        const { match, ...passOnProps } = this.props;

        return (
            <div>
                <CompetitionWithLoading match={match} {...passOnProps} />
            </div>
            
        );
    }
}

export default CompetitionWrapper;