import React from "react";
import TextField from "@material-ui/core/TextField";
import StripeInput from "./StripeInput.component";
import { withStyles } from "@material-ui/styles";



const getStyles = () =>({
  textField: {
    height: 76,
    marginBottom: 10,
  }
});

const StripeElementField = (props) => {
  const {
    classes,
    component,
    InputLabelProps,
    InputProps,
    fullWidth = true,
    label,
    labelErrorMessage,
    error,
    ...other
  } = props;
  return (
    <TextField
      className={classes.textField}
      fullWidth={fullWidth}
      label={label}
      error={error}
      variant="outlined"
      helperText={error ? labelErrorMessage || "Ugyldig" : ""}
      InputLabelProps={{
        ...InputLabelProps,
        shrink: true
      }}
      InputProps={{
        ...InputProps,
        inputProps: {
          component,
        },
        inputComponent: StripeInput
      }}
      {...other}
    />
  );
}

export default withStyles(getStyles)(StripeElementField);