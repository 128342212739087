// @flow
import { createMuiTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import yellow from '@material-ui/core/colors/yellow';

const primary = {
    main: '#001789',
    dark: '#021571',
    light: '#63A4FF',
    lightest: '#EAF4FF',
};


const theme = createMuiTheme({
    palette: {
        primary,
        secondary: {
            main: '#efbb09',
            dark: '#d5a604',
            light: '#48A999',
            lightest: '#B2DFDB',
        },
        error: {
            lighter: '#FBEAE5',
            light: red[300],
            main: '#E53935',
            dark: red[700],
        },
        warning: {
            ...yellow,
            lighter: yellow[100],
            light: yellow[300],
            main: '#F19C02',
            dark: yellow[900],
        },
        success: {
            main: '#3D9305',
        },
        info: {
            main: '#EAF4FF',
        },
    },
    typography: {
        useNextVariants: true,
    },
});

export default theme;