import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  AccordionDetails,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
  Button,
} from "@material-ui/core";
import ClubRow from "./ClubRow";
import downloadFile from "../../../Utils/download/downloadFile";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import "moment/locale/nb";
import MomentUtils from "@date-io/moment";
import { useEffect } from "react";

const styles = (theme) => ({
  root: {
    margin: 8,
  },
  heading: {
    textAlign: "center",
    fontSize: theme.typography.pxToRem(24),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  list: {
    justify: "center",
    paddingTop: 0,
    with: "100%",
  },
  avatar: {
    backgroundColor: "#001789",
  },
  orgText: {
    display: "flex",
    alignItems: "center",
    paddingBottom: 8,
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  topActionContainer: {
    display: "flex",
    justifyContent: "flex-end",
    margin: 16,
    marginRight: 32,
    with: 200,
  },
});

function Clubs(props) {
  const {
    setOrganizationExpireDate,
    getAdminOrganizations,
    adminOrgs,
    classes,
  } = props;

  const handleExpireDateUpdate = (org, expireDate) => {
    let updatedOrg = org;
    updatedOrg.organizationExpireDate = expireDate;
    setOrganizationExpireDate(org.organizationID, updatedOrg);
  };

  const exportAsExcel = () => {
    const url =
      `organizations/admin/` +
      moment(compareDate).format("YYYY-MM-DD") +
      `/export`;
    downloadFile(url);
  };

  useEffect(() => {
    getAdminOrganizations();
  }, []);

  useEffect(() => {}, [adminOrgs]);

  const handleGlobalDateChange = (momentDate) => {
    setCompareDate(momentDate._d);
    getAdminOrganizations(momentDate.format("YYYY-MM-DD"));
  };
  const [compareDate, setCompareDate] = useState(new Date());
  return (
    <div className={classes.root}>
      <h1 className={classes.heading}>Lokalklubber</h1>
      <div className={classes.topActionContainer}>
        <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
          <DatePicker
            style={{ margin: 8 }}
            margin="normal"
            inputVariant="outlined"
            value={compareDate}
            cancelLabel="Avbryt"
            label="Grenseverdi nye medlemmer"
            format="DD. MMM yyyy"
            onChange={handleGlobalDateChange}
          />
        </MuiPickersUtilsProvider>
        <Button onClick={exportAsExcel} variant="outlined">
          Eksporter til excel
        </Button>
      </div>
      <AccordionDetails>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan="2">Klubb</TableCell>
                <TableCell align="right">Hovedmedlemmer 25+</TableCell>
                <TableCell align="right">Hovedmedlemmer 25-</TableCell>

                <TableCell align="right">Nye medlemmer 25+</TableCell>
                <TableCell align="right">Nye medlemmer 25-</TableCell>

                <TableCell>Utløpsdato</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!adminOrgs && <></>}
              {adminOrgs &&
                adminOrgs
                  .sort((a, b) =>
                    a.organizationName < b.organizationName ? -1 : 1
                  )
                  .map((org) => (
                    <ClubRow
                      classes={classes}
                      style={{ margin: 8 }}
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      key={org.organizationID}
                      org={org}
                      handleExpireDateUpdate={handleExpireDateUpdate}
                    />
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </div>
  );
}

export default withStyles(styles)(Clubs);
