
import React from 'react';
import { Typography } from '@material-ui/core';

const Error = (props) => {
    return (
        <Typography color="error" variant="overline" display="block" align="center">
            {props.errorMessage}
        </Typography>
    );
}


export default Error;