// @flow
import getConfig from '../../config/config';

const appConfig = getConfig();

const apiMethods = {
    GET: 'GET',
    PUT: 'PUT',
    POST: 'POST',
    DELETE: 'DELETE',
}


const staticHeaders = {
    'Content-Type': 'application/json',
}

class Api {
    get baseUrl() {
        return appConfig.apiUrl;
    }

    get rubicUrl() {
        return appConfig.rubicUrl;
    }

    getUrl(url: string) {
        return `${this.baseUrl}/${url}`;
    }

    get(url: string, options?: ?Object) {
        return this.request({
            url,
            method: apiMethods.GET,
            ...options,
        });
    }

    put(url: string, data?: any, options?: ?Object) {
        return this.request({
            url,
            body: JSON.stringify(data || {}),
            method: apiMethods.PUT,
            ...options,
        });
    }

    post(url: string, data?: any, options?: ?Object) {
        return this.request({
            url,
            body: JSON.stringify(data || {}),
            method: apiMethods.POST,
            ...options,
        });
    }

    delete(url: string, data?: ?Object, options?: ?Object) {
        return this.request({
            url,
            method: apiMethods.DELETE,
            ...options,
        });
    }

    getAuthorizationHeader() {
        const user = localStorage.getItem('user');
        if (user) {
            const parsed = JSON.parse(user);
            if (parsed && parsed.token) {
                return `Bearer ${parsed.token}`;
            }
        }
        return null;
    }

    getDefaultHeaders() {
        let headers: any = { ...staticHeaders };
        const authorizationHeader = this.getAuthorizationHeader();
        if (authorizationHeader) {
            headers.Authorization = authorizationHeader;
        }
        return headers;
    }

    request(config: Object) {
        const { url, ...options } = config;
        const { useCustomHeader, ...passOnOptions } = options || {};
        const requestOptions = {
            ...passOnOptions,
            headers: !useCustomHeader ? {
                ...this.getDefaultHeaders(),
                ...passOnOptions.headers,
            } : { ...passOnOptions.headers },
        }

        return fetch(this.getUrl(url), requestOptions).then(response => {
            if (response.status === 401) {
                localStorage.removeItem('user');
                window.location = '/login';
                return Promise.reject();
            }

            return response.text().then(textResult => {
                const jsonResult = textResult ? JSON.parse(textResult) : {};
                if (response.status === 403 && !jsonResult.message) {
                    return Promise.reject({
                        message: "Ingen tilgang",
                    });
                }
                if (response.status >= 400) {
                    return Promise.reject(jsonResult);
                }

                return jsonResult;
            }, (err) => { console.log(err); });
        });
    }
}

export default new Api();