import { simplePromiseAction, actionCreator } from '../../../Utils/actions.utils';
import { startService } from '../../Services/startService';
import { getProfile } from '../Dashboard/Dashboard.actions';
import { getDistinctionTypes } from '../Distinctions/Distinctions.actions';
import { getOrganizations } from "../Clubs/Clubs.actions"

export const actionTypes = {
    REQUEST_CLASSES: 'REQUEST_CLASSES',
    REQUEST_CLASSES_SUCCESS: 'REQUEST_CLASSES_SUCCESS',
    REQUEST_CLASSES_FAILED: 'REQUEST_CLASSES_FAILED',
    REQUEST_ADMINS: 'REQUEST_ADMINS',
    REQUEST_ADMINS_SUCCESS: 'REQUEST_ADMINS_SUCCESS',
    REQUEST_ADMINS_FAILED: 'REQUEST_ADMINS_FAILED',
    INIT_DATA: "INIT_DATA",
    INIT_DATA_FINISHED: "INIT_DATA_FINISHED",
    INIT_DATA_FAILED: "INIT_DATA_FAILED",
}

export const getClasses = () => simplePromiseAction(
    () => startService.getClasses(),
    actionTypes.REQUEST_CLASSES,
    actionTypes.REQUEST_CLASSES_SUCCESS,
    actionTypes.REQUEST_CLASSES_FAILED,
);

export const getAdmins = () => simplePromiseAction(
    () => startService.getAdmins(),
    actionTypes.REQUEST_ADMINS,
    actionTypes.REQUEST_ADMINS_SUCCESS,
    actionTypes.REQUEST_ADMINS_FAILED,
);

const createHandleNext = (dispatch, errorAction) =>
    (next) =>
        (action) => {
            if (action.payload.error) {
                console.error(action.payload.error);
                dispatch(errorAction);
                return Promise.reject();
            }
            return dispatch(next());
        }

export const initializeData = () => (dispatch) => {
    const handleNext = createHandleNext(dispatch, actionCreator(actionTypes.INIT_DATA_FAILED)({ error: 'En feil under oppstart har oppstått' }));
    dispatch(actionCreator(actionTypes.INIT_DATA)({}));
    return dispatch(getClasses())
        .then((a) => handleNext(getAdmins)(a)
            .then((a) => handleNext(getOrganizations)(a)
                .then((a) => handleNext(getProfile)(a)
                    .then((a) => handleNext(getDistinctionTypes)(a)
                        .then((a) => handleNext(() => actionCreator(actionTypes.INIT_DATA_FINISHED)({}))(a)
                        )
                    )
                )
            )
        );
}