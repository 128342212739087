import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import AddAdminMember from './AddAdminMember.component';
import AdminCard from './AdminCard.component';
import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CircularProgress from '@material-ui/core/CircularProgress';
import getConfig from '../../../config/config';

const config = getConfig();

const styles = theme => ({
    root: {
        margin: 8,
    },
    heading: {
        fontSize: theme.typography.pxToRem(24),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    subHeader: {
        marginBottom: 16,
        display: "flex",
        justifyContent: "space-between",
        "& button": {
            marginLeft: 5
        },
        '@media (max-width: 480px)': {
            flexDirection: "column"
        },
    },
    subHeaderMembers: {
        marginTop: 16,
        marginBottom: 8,
    },
    summary: {
        backgroundColor: "#ebba14",
    },
    orgDetails: {
        display: "block",
    },
    loadingContainer: {
        textAlign: "center",
    },
    openDialogButton: {
        float: 'right',
    },
    loadingSpinner: {
        textAlign: 'center',
        paddingTop: 16,
    },
});

class ControlledExpansionPanels extends React.Component {
    state = {
        expanded: 0,
        searchDialogOpen: false,
        selectedOrgID: "",
    };

    componentDidMount() {
        if (this.props.orgs && this.props.orgs.length > 0) {
            this.setState({selectedOrgID: this.props.orgs[0].organizationID}, () => {
                this.props.getRolesForOrg(this.state.selectedOrgID);
            });
        }

    }

    handleChange = panel => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : false,
            selectedOrgID: this.props.orgs[panel].organizationID,
        }, () => {
            this.props.getRolesForOrg(this.state.selectedOrgID);
        });
    };

    handleOpenSearchDialog = () => {
        this.setState({searchDialogOpen: true});
    }

    handleCloseSearchDialog = () => {
        this.props.clearSearchResult();
        this.setState({searchDialogOpen: false});
    }

    handleSearch = (searchText) => {
        this.props.searchForMemberInOrg(searchText, this.state.selectedOrgID);
    }

    handleSetRole = (organizationRoleID, person) => {
        this.props.setRoleForMember(this.state.selectedOrgID, organizationRoleID, person);
    }

    handleRemoveRole = (organizationRoleID, person) => {
        this.props.removeRoleForMember(this.state.selectedOrgID, organizationRoleID, person);
    }

    //Gets the URL for an organizatinons member list.
    getMemberListURL = (rubicOrgID) => {
        return `${config.rubicUrl}/Organizations/${rubicOrgID}/Members`
    }

    render() {
        const {
            classes,
            orgs,
            adminsByOrgID,
            roles,
            rolesLoading,
            searchResult,
            isLoadingSearch,
            loadingSettingRole
        } = this.props;
        const {expanded, searchDialogOpen} = this.state;

        return (
            <div className={classes.root}>
                {orgs && adminsByOrgID && orgs.map((item, key) =>
                    <ExpansionPanel key={key} expanded={expanded === key} onChange={this.handleChange(key)}>
                        <ExpansionPanelSummary className={classes.summary} expandIcon={<ExpandMoreIcon/>}>
                            <Typography className={classes.heading}>
                                {item.organizationName}
                            </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className={classes.orgDetails}>
                            {!rolesLoading &&
                                <div>
                                    <Typography variant="h5" className={classes.subHeader}>
                                        Klubbstyre
                                        <div>
                                        <Button color="primary" variant="outlined" target="_blank"
                                                href={this.getMemberListURL(item.rubicOrganizationID)}>
                                            Gå til medlemsliste
                                        </Button>
                                        <Button color="primary" variant="outlined" className={classes.openDialogButton}
                                                onClick={this.handleOpenSearchDialog}>
                                            Legg til
                                        </Button>
                                        </div>
                                    </Typography>
                                    {roles.map((role, key) =>
                                        <div key={key}>
                                            {role.organizationRoleMembers.map((person, key) =>
                                                <AdminCard key={key} role={role} person={person}
                                                           removeRoleFunction={this.handleRemoveRole}/>
                                            )}
                                        </div>
                                    )}
                                    {loadingSettingRole &&
                                        <div className={classes.loadingSpinner}>
                                            <CircularProgress/>
                                        </div>
                                    }
                                </div>
                            }
                            {rolesLoading &&
                                <div className={classes.loadingContainer}>
                                    <CircularProgress className={classes.progress}/>
                                </div>
                            }
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                )}
                {!rolesLoading &&
                    <AddAdminMember
                        open={searchDialogOpen}
                        closeDialogFunction={this.handleCloseSearchDialog}
                        roles={roles}
                        setRoleFunction={this.handleSetRole}
                        searchFunction={this.handleSearch}
                        searchResult={searchResult}
                        isLoading={isLoadingSearch}
                    />
                }
            </div>
        );
    }
}

ControlledExpansionPanels.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ControlledExpansionPanels);