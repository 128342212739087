import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import UploadedImageCard from '../UploadStep/UploadedImageCard.component';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

import {
    PhotoCamera,
    Person, Info as InfoIcon,
} from '@material-ui/icons';

const styles = {
    imgContainer: {
        marginBottom: 8,
    },
    root: {

    },
    sectionText: {
        marginTop: 16,
    },
    avatar: {
        backgroundColor: "#001789",
    },
};

class ConfirmRegistration extends React.Component {
    getUserPaticipationOrg = () => {
        const {competitionData, organizationsByID, userProfile } = this.props;
        if(userProfile.mainOrganizationID && competitionData.isPublic) {
            return organizationsByID[userProfile.mainOrganizationID].organizationName;
        } else {
            return organizationsByID[competitionData.organizationID].organizationName;
        }
    }

    renderUploadedImagesByCompetitionClass = (competitionClass) => {
        const { classes, participation, classesByID } = this.props;
        const competitionClassID = competitionClass.competitionClassID;
        const classID = competitionClass.classID;
        const classParticipations = participation.classParticipations[competitionClassID];
        return (
        <div key={competitionClassID}>
            <Typography className={classes.sectionText} variant="h6">
                {competitionClass.theme && `Opplastinger i ${classesByID[classID].name} - ${competitionClass.theme}`}
                {!competitionClass.theme && `Opplastinger i ${classesByID[classID].name}`}
            </Typography>
            {classParticipations && classParticipations.length > 0 && classParticipations.map(img =>
                <div key={img.classParticipationID} className={classes.imgContainer}>
                    <UploadedImageCard
                        name={img.name}
                        imgRef={img.thumbnailUrl ? img.thumbnailUrl : img.previewImageUrl}
                        imgID={img.classParticipationID}
                        imgNumber={img.classParticipationNumber}
                        noDelete={true}
                    />
                </div>
            )}
            {(!classParticipations || classParticipations.length === 0) &&
                <Typography variant="subtitle1">
                    Ingen opplastinger i denne klassen
                </Typography>
            }
        </div>
        );
    }

    render() {
        const { classes, userProfile, competitionClasses, competitionData } = this.props;

        return (
            <div className={classes.root}>
                <div>
                    <Typography variant="h6">
                        Info
                    </Typography>
                    <List style={{paddingTop: 0, paddingBottom: 0}}>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar className={classes.avatar}>
                                    <Person />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Navn" secondary={userProfile.firstName + " " + userProfile.lastName} />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar className={classes.avatar}>
                                    <PhotoCamera />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Organisasjon" secondary={this.getUserPaticipationOrg()} />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar className={classes.avatar}>
                                    <InfoIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Beskrivelse" secondary={competitionData.description} />
                        </ListItem>
                    </List>
                    {competitionClasses.map(compClass => this.renderUploadedImagesByCompetitionClass(compClass))}
                </div>
            </div>
        );
    }
}

ConfirmRegistration.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ConfirmRegistration);