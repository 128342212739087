import React from 'react';
import EditDistinctionsForPerson from './EditDistinctionsForPerson/EditDistinctionsForPerson.container';
import ViewDistinctionsForPerson from './ViewDistinctionsForPerson/ViewDistinctionsForPerson.container';

class DistinctionsForPerson extends React.Component {

    render() {
        return this.props.editMode ? (
            <EditDistinctionsForPerson
                {...this.props}
            />
        ) :
        (
            <ViewDistinctionsForPerson
                {...this.props}
            />
        );
    }
}


export default DistinctionsForPerson;