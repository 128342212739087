
import React from 'react';

import {
    withStyles,
} from '@material-ui/core';
import Error from './Error.component';


const getStyles = theme => ({
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
        padding: 10,
    },
});

const getErrorMessageHandler = () =>
    (InnerComponent: React.ComponentType<any>) =>
        withStyles(getStyles)(
            class DefaultFieldChangeHandler extends React.Component {
                render() {
                    const { errorMessage, classes, ...passOnProps } = this.props;
                    return !errorMessage ?
                        (
                            <InnerComponent
                                {...passOnProps}
                            />
                        ) :
                        (
                            <div className={classes.progressContainer}>
                                <Error errorMessage={errorMessage} />
                            </div>
                        );
                }
            }
        )



export default getErrorMessageHandler;