import { actionCreator, simplePromiseAction } from '../../../Utils/actions.utils';
import { organizationService } from '../../Services/organizationService';

export const actionTypes = {

    REQUEST_ORGANIZATIONS: "REQUEST_ORGANIZATIONS",
    REQUEST_ORGANIZATIONS_SUCCESS: "REQUEST_ORGANIZATIONS_SUCCESS",
    REQUEST_ORGANIZATIONS_FAILED: "REQUEST_ORGANIZATIONS_FAILED",

    REQUEST_ADMIN_ORGANIZATIONS: "REQUEST_ADMIN_ORGANIZATIONS",
    REQUEST_ADMIN_ORGANIZATIONS_SUCCESS: "REQUEST_ADMIN_ORGANIZATIONS_SUCCESS",
    REQUEST_ADMIN_ORGANIZATIONS_FAILED: "REQUEST_ADMIN_ORGANIZATIONS_FAILED",
    
    REQUEST_SET_EXPIRE_DATE: "REQUEST_SET_EXPIRE_DATE",
    REQUEST_SET_EXPIRE_DATE_FAILED: "REQUEST_SET_EXPIRE_DATE_FAILED",
    REQUEST_SET_EXPIRE_DATE_SUCCESS: "REQUEST_SET_EXPIRE_DATE_SUCCESS",
}

export const getOrganizations = (dt) => simplePromiseAction(
    () => organizationService.getOrganizations(dt),
    actionTypes.REQUEST_ORGANIZATIONS,
    actionTypes.REQUEST_ORGANIZATIONS_SUCCESS,
    actionTypes.REQUEST_ORGANIZATIONS_FAILED,
);

export const getAdminOrganizations = (dt) => simplePromiseAction(
    () => organizationService.getAdminOrganizations(dt),
    actionTypes.REQUEST_ADMIN_ORGANIZATIONS,
    actionTypes.REQUEST_ADMIN_ORGANIZATIONS_SUCCESS,
    actionTypes.REQUEST_ADMIN_ORGANIZATIONS_FAILED,
);


const requestSetExpireDate = () => actionCreator(actionTypes.REQUEST_SET_EXPIRE_DATE)({})
const requestSetExpireDateSuccess = (response, orgID, organizationExpireDate) => actionCreator(actionTypes.REQUEST_SET_EXPIRE_DATE_SUCCESS)({ response, orgID, organizationExpireDate })
const requestSetExpireDateFailed = (error) => actionCreator(actionTypes.REQUEST_SET_EXPIRE_DATE_FAILED)({ error })

export const setOrganizationExpireDate = (orgID, org) => {
    return (dispatch) => {
        dispatch(requestSetExpireDate());
        var promise = organizationService.setOrganizationExpireDate(orgID, org);
        return promise.then(
            response => dispatch(requestSetExpireDateSuccess(response, orgID, org.organizationExpireDate)),
            error => dispatch(requestSetExpireDateFailed(error))
        )
    }
};