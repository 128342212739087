import Api from './apiService';

export const distinctionService = {
    getPersonDistinctions,
    getDistinctionTypes,
    setPersonDistinction,
};

function getPersonDistinctions(paging, order, searchString, availableItemsDistinctionType, clubFilter) {
    const payload = {
        paging,
        order,
        searchString,
        availableItemsDistinctionType,
        clubFilter
    }
    return Api.post(`distinction/personDistinctions`, payload);
}

function setPersonDistinction(personID, distinctionTypeID, distinctionItemID, year) {
    return Api.post(`distinction/personDistinctions/set`, { archievedYear: year, personID, distinctionItemID });
}

function getDistinctionTypes() {
    return Api.get(`distinction/types`);
}