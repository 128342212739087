import { actionTypes } from '../components/Pages/Competition/Score/Score.actions';
import { actionTypes as competitionStatusActionTypes } from '../components/Pages/Competition/Score/CompetitionStatus/CompetitionStatus.actions';
const initState = {

}

const createCompetitionReducer = (state = initState, action) => {
    switch(action.type) {
        case actionTypes.REQUEST_MY_SCORE_STATUS:
            console.log('Requesting my score status');
            return {
                ...state,
                myScoreStatus: {
                    loading: true,
                }
            };
        case actionTypes.GET_MY_SCORE_STATUS_SUCCESS:
            console.log('My score status success');
            const myScoreStatusByClassID = action.payload.response.reduce((accStatuses, classStatus) => ({
                ...accStatuses,
                [classStatus.competitionClassID]: { ...classStatus }
            }),{});
            return {
                ...state,
                myScoreStatus: {
                    loading: false,
                    data: myScoreStatusByClassID,
                }
            };
        case actionTypes.GET_MY_SCORE_STATUS_FAILED:
            console.log('My score status failed');
            return {
                ...state,
                myScoreStatus: {
                    loading: false,
                    errorMessage: action.payload.error.message,
                }
            };
        case actionTypes.REQUEST_JURY_SCORE_STATUS:
            console.log('Requesting jury score status');
            return {
                ...state,
                competitionStatus: {
                    loading: true,
                }
            };
        case actionTypes.GET_JURY_SCORE_STATUS_SUCCESS:
            console.log('Jury score status success');
            const juryScoreStatusByClassID = action.payload.response.reduce((accStatuses, classStatus) => ({
                ...accStatuses,
                [classStatus.competitionClassID]: { ...classStatus }
            }),{});
            return {
                ...state,
                competitionStatus: {
                    loading: false,
                    data: juryScoreStatusByClassID,
                }
            };
        case actionTypes.GET_JURY_SCORE_STATUS_FAILED:
            console.log('Jury score status failed');
            return {
                ...state,
                competitionStatus: {
                    loading: false,
                    errorMessage: action.payload.error.message,
                }
            };

        case competitionStatusActionTypes.REQUEST_CLOSE_COMPETITION_JURYING:
            return {
                ...state,
                competitionStatus: {
                    ...state,
                    juryingClosing: true,
                }
            }

        case competitionStatusActionTypes.CLOSE_COMPETITION_JURYING_SUCCESS:
            return {
                ...state,
                competitionStatus: {
                    ...state,
                    juryingClosing: false,
                }
            }

        case competitionStatusActionTypes.CLOSE_COMPETITION_JURYING_FAILED:
            return {
                ...state,
                competitionStatus: {
                    ...state,
                    juryingClosing: false,
                    changeStatusError: action.payload.error.message
                }
            }
        case competitionStatusActionTypes.REQUEST_CLOSE_COMPETITION_AWARDING:
            return {
                ...state,
                competitionStatus: {
                    ...state,
                    awardingClosing: true,
                }
            }

        case competitionStatusActionTypes.CLOSE_COMPETITION_AWARDING_SUCCESS:
            console.log("Closing competition");
            return {
                ...state,
                competitionStatus: {
                    ...state,
                    awardingClosing: false,
                }
            }

        case competitionStatusActionTypes.CLOSE_COMPETITION_AWARDING_FAILED:
            console.log("Closing competition");
            return {
                ...state,
                competitionStatus: {
                    ...state,
                    awardingClosing: false,
                    changeStatusError: action.payload.error.message
                }
            }
        default:
            return state;
    }
}

export default createCompetitionReducer;