import React from 'react';
import withPaging from '../../../Table/withPaging';
import Table from '../../../Table/Table.component';
import { getAwardByValue } from '../../Competition/Score/awards';
import { Paper, Typography, withStyles, TableCell, Button } from '@material-ui/core';

const TableWithPaging = withPaging()(Table);


const columns = [
    { 
        key: 'title',
        displayName: 'Tittel',
    },
    {
        key: 'salon',
        displayName: 'Salong',
    },
    {
        key: 'nsffPatronate',
        displayName: 'NSFF patronage',
    },
    {
        key: 'fiapPatronate',
        displayName: 'FIAP patronage',
    },
    {
        key: 'psaPatronate',
        displayName: 'PSA patronage',
    },
    {
        key: 'country',
        displayName: 'Land',
    },
    {
        key: 'award',
        displayName: 'Premie',
        getDataCell: (props, data) => {
            const award = getAwardByValue(data.award);
            return (
                <TableCell key={data.externalCompetitionResultID}>
                    {award.formDisplayName}
                </TableCell>
            );
        }
    },
];

const getStyles = theme => ({
    paper: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        marginBottom: 8,
        marginTop: 10,
    },
    importButton: {
        float: 'right',
    },
    importBox: {
        border: 'solid 2px #001789',
        borderRadius: 5,
        padding: 10,
        marginTop: 16,
    },
    buttonContainer: {
        textAlign: 'center',
        marginTop: 16,
    }
});
class ImportResults extends React.Component {

    handlePageChange = (paging) => {
        this.props.onPageChange({
            pageNo: (paging.startPosition / paging.numberOfItems) +1,
            pageSize: paging.numberOfItems,
        });
    }

    onUploadFile = (e) => {
        e.preventDefault();
        const file = e.target.files[0];

        if(file) {
            this.setState({fileName: file.name});
            this.props.uploadFile(this.props.personID, file, file.name);
        }
    }

    onSaveUpload = () => {
        this.props.saveUploadedFile(this.props.personID, this.props.uploadedResults);
        document.getElementById("fileInput").value = "";
    }

    onDiscardUpload = () => {
        this.props.clearUploadedFile();
        document.getElementById("fileInput").value = "";
    }

    render() {
        const { classes, uploadedResults, uploadedFileName, importedResults, loadingImportedResults, personName, loadingImporteErrorMessage, uploadErrorMessage, savingImportedResultsErrorMessage, savingImportedResults } = this.props;
        return (
            <Paper className={classes.paper}>
                <Typography variant="h5" style={{ marginBottom: 16 }}>
                    Importerte restultater for {personName}
                    <input id="fileInput"
                           type="file"
                           accept=".xlsx, .xls"
                           ref={(ref) => this.upload = ref}
                           style={{display: 'none'}}
                           onChange={this.onUploadFile.bind(this)}
                    />
                    <Button onClick={()=>{this.upload.click()}} color="primary" variant="outlined" className={classes.importButton}>
                        Importer
                    </Button>
                </Typography>
                {uploadedResults && uploadedFileName &&
                    <div>
                        <div className={classes.importBox}>
                            <Typography variant="h6">
                                Resultater fra {uploadedFileName}
                            </Typography>
                            <TableWithPaging
                                columns={columns}
                                data={uploadedResults}
                                dataKey="externalCompetitionResultID"
                                paging={false} 
                            />
                        </div>
                        <div className={classes.buttonContainer}>
                            <Button onClick={this.onDiscardUpload} color="primary" style={{ marginRight: 8 }}>
                                Forkast
                            </Button>
                            <Button onClick={this.onSaveUpload} color="primary" variant="outlined" disabled={savingImportedResults}>
                                Lagre
                            </Button>
                        </div>
                    </div>
                }
                {uploadErrorMessage &&
                    <Typography color="error" variant="overline" display="block" align="center">
                        {uploadErrorMessage}
                    </Typography>
                }
                {savingImportedResultsErrorMessage &&
                    <Typography color="error" variant="overline" display="block" align="center">
                        {savingImportedResultsErrorMessage}
                    </Typography>
                }
                {importedResults && !loadingImportedResults &&
                    <div>
                        <Typography variant="h6" style={{ marginTop: 16 }}>
                            Tidligere resultater
                        </Typography>
                        <TableWithPaging
                            columns={columns}
                            data={importedResults}
                            dataKey="externalCompetitionResultID"
                            paging={false}
                        />
                    </div>
                }
                {loadingImporteErrorMessage &&
                    <Typography color="error" variant="overline" display="block" align="center">
                        {loadingImporteErrorMessage}
                    </Typography>
                }
            </Paper>
        );
    }
}

export default withStyles(getStyles)(ImportResults);