import React, { useState } from 'react';
import { competitionResultService } from '../../../Services/competitionResultService';
import { withRouter } from 'react-router-dom';
import PersonExternalResults from './PersonExternalResults.component';
import PersonResultsComponent from './PersonInternalResults.component';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';


const MyResults = ({ location, history }) => {

    const [value, setValue] = useState(0);

    function TabPanel(props) {
        const { children, value, index, ...other } = props;
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    const fetchInternalData = (awardFilter, startPosition, numberOfItems, orderBy, direction) => {
        return competitionResultService.getMyCompetitionResults(awardFilter, startPosition, numberOfItems, orderBy, direction);
    }

    const fetchExternalData = (awardFilter, startPosition, numberOfItems, orderBy, direction) => {
        return competitionResultService.getMyExternalCompetitionResults(awardFilter, startPosition, numberOfItems, orderBy, direction);
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <div>
            <AppBar position="static" style={{ color: "blue", backgroundColor: "white" }} >
                <Tabs value={value} onChange={handleChange} >
                    <Tab label="NSFF resultater"
                        {...a11yProps(0)}
                    />
                    <Tab label="Eksterne resultater"
                        {...a11yProps(1)}
                    />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <PersonResultsComponent fetchData={fetchInternalData} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <PersonExternalResults fetchData={fetchExternalData} />
            </TabPanel>
        </div>
    );
}

export default withRouter(MyResults);