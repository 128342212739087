

import {
    actionTypes
} from '../components/Pages/Competition/Score/ViewAndScore/ViewAndScore.actions';

import {
    actionTypes as competitionScoreActionTypes
} from '../components/Pages/Competition/Score/Score.actions';
import {
    actionTypes as fullscreenActionTypes
} from '../components/Pages/Competition/Score/ViewAndScore/ScoreFullscreen/ScoreFullscreen.actions';

const initState = {
    loading: true,
}

const sortDataList = (data) => {
    if(data) {
        return data.sort((a, b) =>  b.score - a.score);
    }
    return null;

}

const createCompetitionReducer = (state = initState, action) => {
    switch(action.type) {
        case actionTypes.SCORE_FULLSCREEN_INDEX_CHANGE:
            console.log('fullscreen index changed')
            return {
                ...state,
                scoreFullscreen: {},
            }

        case actionTypes.SCORE_SHOW_FULLSCREEN:
            console.log('show fullscreen')
            return {
                ...state,
                showFullscreen: true,
                fullscreenInitialIndex: action.payload.fullscreenInitialIndex,
                scoreFullscreen: {},
            }

        case actionTypes.SCORE_SHOW_LIST:
            console.log('show list')
            return {
                ...state,
                showFullscreen: false,
                fullscreenInitialIndex: null,
                scoreFullscreen: {},
                data: sortDataList(state.data),
            }

        case fullscreenActionTypes.REQUEST_SAVE_SCORE:
            console.log('Is submitting score');
            return {
                ...state,
                scoreFullscreen: {
                    ...state.scoreFullscreen,
                    saving: true,
                    error: null,
                }
            };
        case fullscreenActionTypes.SAVE_SCORE_SUCCESS:
            console.log('scoreing successful');
            
            return {
                ...state,
                data: state.data.map(i => {
                    if(i.classParticipationID === action.payload.classParticipationID) {
                        return { ...i, score: action.payload.score, comment: action.payload.comment };
                    }
                    return i;
                }),
                scoreFullscreen: {
                    saving: false,
                    values: null,
                    error: null,
                }
            };
        case fullscreenActionTypes.SAVE_SCORE_FAILED:
            console.log('scoreing failed');
            return {
                ...state,
                scoreFullscreen: {
                    ...state.scoreFullscreen,
                    saving: false,
                    error: action.payload.error.message,
                }
            };
        
        case fullscreenActionTypes.SCORE_FORM_FIELD_UPDATED:
            console.log('score form field updated');
            return {
                ...state,
                scoreFullscreen: {
                    ...state.scoreFullscreen,
                    formValues: {
                        ...state.scoreFullscreen.formValues,
                        [action.payload.field]: action.payload.value,
                    }
                }
            };
        case fullscreenActionTypes.ON_VIEW_EDIT_SCORE: 
            console.log('Init view edit score');
            return {
                ...state,
                scoreFullscreen: {
                    showEditScore: true,
                    formValues: {
                        ...action.payload.formValues,
                    },
                },
            };
        case fullscreenActionTypes.ON_CANCEL_EDIT_SCORE:
            return {
                ...state,
                scoreFullscreen: {},
            };
        case fullscreenActionTypes.ON_SCORE_FORM_INVALID:
            return {
                ...state,
                scoreFullscreen: {
                    ...state.scoreFullscreen,
                    validationErrorMessage: action.payload.validationErrorMessage,
                }
            };
        case competitionScoreActionTypes.REQUEST_MY_CLASS_PARTICIPATION_SCORING_STATUS:
            return {
                loading: true,
            };
        case competitionScoreActionTypes.GET_MY_CLASS_PARTICIPATION_SCORING_SUCCESS:
            return {
                loading: false,
                data: sortDataList(action.payload.response),
            };
        case competitionScoreActionTypes.GET_MY_CLASS_PARTICIPATION_SCORING_FAILED:
            return {
                loading: false,
                errorMessage: action.payload.error.message,
            };

        default:
            return state;
    }
}


export default createCompetitionReducer;