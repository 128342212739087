import React from "react";
import { TableRow, TableCell } from "@material-ui/core";
import MomentUtils from "@date-io/moment";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import "moment/locale/nb";
import Avatar from "@material-ui/core/Avatar";
import { PhotoCamera } from "@material-ui/icons";
moment.locale("nb");

function ClubListItem(props) {
  const { org, handleExpireDateUpdate, classes } = props;

  const handleDateChange = (momentDate) => {
    if (momentDate) {
      handleExpireDateUpdate(org, momentDate.format("YYYY-MM-DD"));
    }
  };

  return (
    <>
      <TableRow>
        <TableCell component="th" scope="row">
          <Avatar className={classes.avatar}>
            <PhotoCamera />
          </Avatar>
        </TableCell>
        <TableCell>{org.organizationName}</TableCell>
        <TableCell align="right">{org.numMainMembersOver25}</TableCell>
        <TableCell align="right">{org.numMainMembersUnder25}</TableCell>

        <TableCell align="right">{org.numNewMembersOver25}</TableCell>
        <TableCell align="right">{org.numNewMembersUnder25}</TableCell>

        <TableCell>
          <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
            <DatePicker
              style={{ margin: 8 }}
              fullWidth
              margin="normal"
              variant="outlined"
              format="DD. MMM yyyy"
              value={org.organizationExpireDate}
              onChange={handleDateChange}
            />
          </MuiPickersUtilsProvider>
        </TableCell>
      </TableRow>
    </>
  );
}

export default ClubListItem;
