import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Person from "@material-ui/icons/Person";
import EvaluationListItem from "./EvaluationListItem.component";
import Error from "../../../Error/Error.component";

const styles = (theme) => ({
  paper: {
    margin: 8,
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  heading: {
    fontSize: theme.typography.pxToRem(24),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  summary: {
    backgroundColor: "#ebba14",
  },
  details: {
    display: "block",
  },
  noResultText: {
    paddingTop: 16,
  },
  button: {
    float: "right",
  },
  personPaper: {
    marginBottom: 8,
  },
});

class Result extends React.Component {
  state = {
    expanded: 0,
  };

  handleChange = (panel) => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  handleClickBack = () => {
    this.props.onBackClick(this.props.previousLocation);
  };

  renderJuryMembers = () => {
    const { classes, competition } = this.props;
    const { competitionJuryMembers } = competition;
    return (
      competitionJuryMembers && (
        <div>
          <Typography variant="h6">Jurymedlemmer</Typography>
          {competitionJuryMembers.map((person) => (
            <Paper
              elevation={1}
              key={person.personID}
              className={classes.personPaper}
            >
              <ListItem>
                <ListItemIcon>
                  <Person />
                </ListItemIcon>
                <ListItemText
                  primary={person.firstName + " " + person.lastName}
                />
              </ListItem>
            </Paper>
          ))}
        </div>
      )
    );
  };

  renderEvaluations = () => {
    const { evaluations, userID } = this.props;
    const myEvaluations = evaluations.filter((e) => e.participantID === userID);
    if (!myEvaluations || myEvaluations.length === 0) {
      return (
        <Typography variant="subtitle1">
          Ingen av dine bilder har blitt vurdert ennå
        </Typography>
      );
    }

    return (
      myEvaluations &&
      myEvaluations.map((item) => (
        <React.Fragment key={item.classParticipationID}>
          <EvaluationListItem mediaItem={item} />
          <Divider />
        </React.Fragment>
      ))
    );
  };
  renderAllEvaluations = () => {
    const { evaluations, userID } = this.props;
    const allEvaluations = evaluations.filter(
      (e) => e.participantID !== userID
    );
    // Filter out only evaluations with participantID != user.personID
    if (!allEvaluations || allEvaluations.length === 0) {
      return (
        <Typography variant="subtitle1">
          Ingen bilder er blitt vurdert ennå
        </Typography>
      );
    }

    return (
      allEvaluations &&
      allEvaluations.map((item) => (
        <React.Fragment key={item.classParticipationID}>
          <EvaluationListItem mediaItem={item} />
          <Divider />
        </React.Fragment>
      ))
    );
  };

  render() {
    const { classes, competition } = this.props;

    return (
      <Paper className={classes.paper} elevation={1}>
        <Typography variant="h5" style={{ marginBottom: 16 }}>
          {competition.name}
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={this.handleClickBack}
          >
            Tilbake
          </Button>
        </Typography>
        {this.renderJuryMembers()}
        <Typography style={{ marginTop: 24 }} variant="h6">
          Vurderinger av mine bilder
        </Typography>
        {this.renderEvaluations()}
        <Typography style={{ marginTop: 24 }} variant="h6">
          Vurderinger av alle bilder
        </Typography>
        {this.renderAllEvaluations()}
      </Paper>
    );
  }
}

export default withStyles(styles)(Result);
