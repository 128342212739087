import React from 'react';
import EvaluationListCard from './EvaluationListCard.container';
import CompetitionListCard from './CompetitionListCard.container';
import {
    withStyles,
    Grid,
    Typography,
} from '@material-ui/core';
import ProgressButton from '../../../Button/ProgressButton.component';
import CompetitionListView from "./CompetitionListView.container";
import "moment/locale/nb";

const styles = theme => ({
    header: {
        marginBottom: 16,
    },
    root: {
        padding: 10,
    },
    paper: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        marginBottom: 8,
    },
    button: {
        float: 'right',
    },
    loadMoreButton: {
        width: '100%',
        marginTop: 10,
    }
});
class CompetitionList extends React.Component {

    state = { searchText: "" }

    handleSearch = (searchText) => {
        this.setState({ searchText })
        this.props.onSearch(searchText);
    }

    renderListItem = (competition) => {
        const { header, competitions, classes, ...passOnProps } = this.props;
        return competition.competitionType === 1 || competition.competitionType === 4 ?
            (
                <CompetitionListCard
                    key={competition.competitionID}
                    competitionData={competition}
                    clubIsActive={this.props.clubIsActive}
                    {...passOnProps}
                />
            ) :
            (
                <EvaluationListCard
                    key={competition.competitionID}
                    competitionData={competition}
                    clubIsActive={this.props.clubIsActive}
                    {...passOnProps}
                />
            );
    }

    renderNoItems = () => {
        return (
            <Grid item xs={12}>
                <Typography variant="subtitle1" align="center">
                    {this.props.noItemsText}
                </Typography>
            </Grid>
        )
    }

    renderPagingButton = () => {
        const { paging, onLoadMoreClick, loadingMore, competitions, classes } = this.props;
        const next = paging && {
            ...paging,
            startPosition: paging.startPosition + paging.numberOfItems,
        }
        const hideButton = paging && (paging.startPosition + paging.numberOfItems) !== competitions.length;
        return !hideButton && (
            <ProgressButton
                className={classes.loadMoreButton}
                loading={loadingMore}
                onClick={() => onLoadMoreClick && onLoadMoreClick(next, this.state.searchText)}
            >
                Last flere
            </ProgressButton>
        );
    }

    render() {
        const { competitions, usePaging } = this.props;
        if (competitions && competitions.length > 0 && competitions[0].finished) {
            return (
                <div>
                    <CompetitionListView competitions={competitions} paging={this.props.paging} onSearch={this.handleSearch} searchText={this.state.searchText} loadingFinished={this.props.loadingFinished} />
                    {usePaging && this.renderPagingButton()}
                </div>
            );
        } else {
            return (
                <div>
                <Grid container spacing={1}>
                    {competitions && competitions.length > 0 ?
                        competitions.map((c) => this.renderListItem(c)) :
                        this.renderNoItems()
                    }
                </Grid>
                {usePaging && this.renderPagingButton()}
                </div>
            );
        }
    }
}

export default withStyles(styles)(CompetitionList);