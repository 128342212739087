import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Person from '@material-ui/icons/Person';
import Add from '@material-ui/icons/Add';
import Clear from '@material-ui/icons/Clear';
import Search from '@material-ui/icons/Search';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import moment from "moment";
import "moment/locale/nb";
import TextField from '../../../Fields/TextField/TextField.component';
import CheckboxField from '../../../Fields/CheckboxField/CheckboxField.component';

moment.locale("nb");

const styles = theme => ({
    searchListPaper: {
        marginTop: 8,
        padding: "0px 8px",
        overflowY: "scroll",
        textAlign: "center",
        height: 187,
    },
    personPaper: {
        marginBottom: 8,
    },
    juryListContainer: {
        margin: 8,
    }
});

class CompetitionJurySelection extends React.Component {
    state = {
        searchJuryFirstName: "",
        searchJuryLastName: "",
    };

    handleChange = (name, e) => {
        const { value } = e.target;
        this.setState({ [name]: value });
    }

    handleSearch = () => {
        if(!this.state.searchJuryFirstName && !this.state.searchJuryLastName) {
            this.setState({ searchError: "Fornavn og etternavn mangler" });
            return;
        } else if(!this.state.searchJuryFirstName) {
            this.setState({ searchError: "Fornavn mangler" });
            return;
        } else if(!this.state.searchJuryLastName) {
            this.setState({ searchError: "Etternavn mangler" });
            return;
        } else if(this.state.searchJuryFirstName.length < 2) {
            this.setState({ searchError: "Minst to bokstaver for fornavn" });
            return;
        } else if(this.state.searchJuryLastName.length < 2) {
            this.setState({ searchError: "Minst to bokstaver for etternavn" });
            return;
        }
        this.setState({ searchError: null });
        this.props.searchFunction(this.state.searchJuryFirstName, this.state.searchJuryLastName);
    }

    handleAddJuryLeader = () => {
        const { currentSelectedPerson } = this.state;
        this.props.changeLeaderFunction(currentSelectedPerson);
        this.setState({ anchorEl: null, currentSelectedPerson: null });
    }

    handleAddJuryMember = () => {
        const { currentSelectedPerson } = this.state;
        const currentlySelectedJuryMembers = this.props.selectedJuryMembers ? this.props.selectedJuryMembers : {};
        currentlySelectedJuryMembers[currentSelectedPerson.personID] = currentSelectedPerson;
        
        this.props.changeFunction(currentlySelectedJuryMembers);
        this.setState({ anchorEl: null, currentSelectedPerson: null });
    }

    handleAddInBoth = () => {
        this.handleAddJuryLeader();
        this.handleAddJuryMember();
    }

    handleRemoveJuryLeader = () => {
        this.props.changeLeaderFunction(null);
    }

    handleRemoveJuryMember = (personID) => {
        const currentlySelectedJuryMembers = this.props.selectedJuryMembers ? this.props.selectedJuryMembers : {};
        delete currentlySelectedJuryMembers[personID];
        
        this.props.changeFunction(currentlySelectedJuryMembers);
    }

    handleAddJury = (e, currentSelectedPerson) => {
        if(this.props.competitionType === 1 || this.props.competitionType === 4) {
            this.setState({ anchorEl: e.currentTarget, currentSelectedPerson });
        } else {
            this.setState({ currentSelectedPerson }, () => {
                this.handleAddJuryMember();
            });
        }
    }

    handleCloseRoleMenu = (e) => {
        this.setState({ anchorEl: null });
    }


    renderPublicJuryMembersList = () => {
        const { classes } = this.props;
        return (
            <div className={classes.juryListContainer}>
                <Typography variant="h6">
                    Jurymedlemmer
                </Typography>
                <Typography variant="subtitle1" style={{padding: "16px 0px"}}>
                    Ved åpen juryering kan alle med tilgang til konkurransen juryere
                </Typography>
            </div>
        );
    }

    renderJuryList = (juryMap, headerText, emptyListText, onRemove) => {
        const { classes } = this.props;
        const Inner = !juryMap || Object.keys(juryMap).length === 0 ?
        (
            <Typography variant="subtitle1" style={{padding: "16px 0px"}}>
                {emptyListText}
            </Typography>
        ) :
        (
            <List>
                {Object.keys(juryMap).map(juryID => (
                <Paper elevation={1} className={classes.personPaper} key={juryID}>
                    <ListItem>
                        <ListItemIcon>
                            <Person />
                        </ListItemIcon>
                        <ListItemText
                            primary={juryMap[juryID].firstName + ' ' + juryMap[juryID].lastName}
                            secondary={juryMap[juryID].birthDate ? moment(juryMap[juryID].birthDate).format('LL') : null}
                        />
                        <ListItemSecondaryAction>
                            <IconButton color="primary" edge="end" aria-label="Fjern" onClick={() => onRemove(juryMap[juryID].personID)}>
                                <Clear />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                </Paper>
            ))}
        </List>
        );
        return (
            <div className={classes.juryListContainer}>
                <Typography variant="h6">
                    {headerText}
                </Typography>
                {Inner}
            </div>
        );
    }

    render() {
        const {
            classes,
            jurySearchResult,
            selectedJuryMembers,
            selectedJuryLeader, 
            isSearching,
            competitionType,
            publicJurying,
            setPublicJurying,
            existingPublicJurying
        } = this.props;
        return (
            <div>
                <Typography style={{ marginTop: 24 }} variant="h5" gutterBottom>
                    Jury
                </Typography>
                <CheckboxField
                            disabled={existingPublicJurying}
                            label="Åpen juryering"
                            value={publicJurying}
                            onChange={setPublicJurying}
                        />

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} md={6}>
                        <Typography variant="h6" style={{ marginLeft: 8}}>
                            Søk
                        </Typography>
                        <TextField
                            label="Fornavn"
                            onBlur={(e) => this.handleChange("searchJuryFirstName", e)}
                            style={{ marginLeft: 8, marginTop: 8 }}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            onChange={(e) => this.handleChange("searchJuryFirstName", e)}
                        />
                        <TextField
                            label="Etternavn"
                            onBlur={(e) => this.handleChange("searchJuryLastName", e)}
                            style={{ marginLeft: 8, marginTop: 8 }}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            onChange={(e) => this.handleChange("searchJuryLastName", e)}
                        />
                        <Button
                            variant="outlined"
                            color="primary"
                            fullWidth
                            style={{ marginLeft: 8, marginTop: 8 }}
                            size="large"
                            onClick={this.handleSearch}
                        >
                            Søk
                        </Button>
                        {this.state.searchError &&
                            <Typography color="error" variant="overline" display="block" align="center">
                                {this.state.searchError}
                            </Typography>
                        }
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <div style={{ marginLeft: 8}}>
                            <Typography variant="h6">
                                Søkeresultater
                            </Typography>
                            <Paper className={classes.searchListPaper} elevation={1}>
                                {(!jurySearchResult || isSearching) &&
                                    <div>
                                        {isSearching &&
                                            <CircularProgress style={{ marginTop: 70 }} />
                                        }
                                        {!jurySearchResult && !isSearching &&
                                            <div style={{ paddingTop: 50 }}>
                                                <Search style={{ fontSize: 55 }} />
                                                <Typography variant="subtitle1">
                                                    Fyll inn navn for å søke
                                                </Typography>
                                            </div>
                                        }
                                    </div>
                                }
                                {jurySearchResult &&  Object.keys(jurySearchResult).length <= 0 && !isSearching &&
                                    <div style={{ paddingTop: 50 }}>
                                        <ErrorOutline style={{ fontSize: 55 }} />
                                        <Typography variant="subtitle1">
                                            Ingen personer funnet
                                        </Typography>
                                    </div>
                                }
                                {jurySearchResult && Object.keys(jurySearchResult).length > 0 && !isSearching &&
                                    <List>
                                        {jurySearchResult.map(person =>
                                            <Paper elevation={1} key={person.personID} className={classes.personPaper}>
                                                <ListItem>
                                                    <ListItemIcon>
                                                        <Person />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={person.firstName + ' ' + person.lastName}
                                                        secondary={person.birthDate ? moment(person.birthDate).format('LL') : null}
                                                    />
                                                    <ListItemSecondaryAction>
                                                        <IconButton color="primary" edge="end" aria-label="Legg til" onClick={(e) => this.handleAddJury(e, person)}>
                                                            <Add />
                                                        </IconButton>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            </Paper>
                                        )}
                                    </List>
                                }
                            </Paper>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        {(competitionType === 1 || competitionType === 4) && this.renderJuryList(selectedJuryLeader ? { [selectedJuryLeader.personID]: selectedJuryLeader } : null,"Juryleder", "Ingen juryleder valgt", this.handleRemoveJuryLeader)}
                        {publicJurying ? this.renderPublicJuryMembersList() : this.renderJuryList(selectedJuryMembers, "Jurymedlemmer", "Ingen jurymedlemmer valgt", this.handleRemoveJuryMember)}
                    </Grid>
                </Grid>
                <Menu
                    id="roleMenu"
                    anchorEl={this.state.anchorEl}
                    keepMounted
                    open={Boolean(this.state.anchorEl)}
                    onClose={this.handleCloseRoleMenu}
                    style={{paddingTop: 0}}
                >
                    <MenuItem onClick={this.handleAddJuryLeader}>Legg til som juryleder</MenuItem>
                    {!publicJurying &&
                        <React.Fragment>
                            <MenuItem onClick={this.handleAddJuryMember}>Legg til som jurymedlem</MenuItem>
                            <MenuItem onClick={this.handleAddInBoth}>Legg til i begge</MenuItem>
                        </React.Fragment>
                    }
                </Menu>
            </div>
        );
    }
}

export default withStyles(styles)(CompetitionJurySelection);