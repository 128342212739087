import React from 'react';
import { Button, Typography, Paper, withStyles } from '@material-ui/core';

import DistinctionTypePanel from './DistinctionTypePanel.component';
import ImportResults from '../Import/ImportResults.container';

const getStyles = (theme) => ({
    root: {

    },
    paper: {
        padding: 10, 
        marginTop: 10,
    },
    personDetails: {
        margin: 10,
    },
    distinctionTypesContainer: {
        margin: 10,
    },
    importButton: {
        float: 'right',
    },
});

class DistinctionsWrapper extends React.Component {
    state = {
        expandedPanel: null,
    }

    handleBackClick = () => {
        if(this.props.viewMode === 'import') {
            this.props.setViewMode('distinctions');
            return;
        }
        this.props.goBackToPersonDistinctionList();
    }

    handlePanelChange = (id) => {
        const expandedPanel = this.state.expandedPanel;
        if(expandedPanel === id) {
            this.setState({ expandedPanel: null });
            return;
        }
        this.setState({ expandedPanel: id });
    }

    handleYearChange = (distinctionTypeID, distinctionItemID, year) => {
        this.props.onSetPersonDistinction(
            this.props.selectedPerson.personID,
            distinctionTypeID,
            distinctionItemID,
            year,
        );
    }

    handleSetViewMode = (mode) => {
        this.props.setViewMode(mode);
    }

    getImportedResults = (mode) => {
        this.props.getImportedResults(this.props.selectedPerson.personID, mode);
    }

    renderForDistinctionType = (distinctionType) => {
        const { pointsByDistinctionTypeID, distinctionsByItemID } = this.props;
        const id = distinctionType.distinctionTypeID;
        const distinctionTypePoints = pointsByDistinctionTypeID[id] || { points: 0 };

        const distinctionsByItemIDForType = Object.keys(distinctionsByItemID).reduce((accDistItems, itemID) => {
            if(distinctionType.distinctionItems.some(di => di.distinctionItemID === itemID)) {
                return {
                    ...accDistItems,
                    [itemID]: distinctionsByItemID[itemID],
                }
            }
            return accDistItems;
        }, {});
        return (
            <DistinctionTypePanel
                key={id}
                isOpen={this.state.expandedPanel === id}
                onTogglePanel={() => { this.handlePanelChange(id); }}
                distinctionType={distinctionType}
                distinctionTypePoints={distinctionTypePoints}
                distinctionsByItemID={distinctionsByItemIDForType}
                onYearChange={this.handleYearChange}
            />
        );
    }

    renderDistinctionTypes = () => {
        const { distinctionTypes, classes } = this.props;
        return (
            <div className={classes.distinctionTypesContainer}>
                {distinctionTypes.map(dt => this.renderForDistinctionType(dt))}
            </div>
        );
    }

    render() {
        const { classes, selectedPerson, viewMode } = this.props;
        return (
            <div>
                <Button onClick={this.handleBackClick} variant="outlined">Tilbake</Button>
                {viewMode && viewMode === 'distinctions' &&
                    <Paper className={classes.paper}>
                        <div className={classes.personDetails}>
                            <Typography variant="h5">
                                {`${selectedPerson.firstName} ${selectedPerson.lastName}`}
                                <Button onClick={() => this.getImportedResults('import')} color="primary" variant="outlined" className={classes.importButton}>
                                    Import
                                </Button>
                            </Typography>
                            <Typography variant="subtitle1" color="textSecondary">
                                {`${selectedPerson.mainOrganizationName}`}
                            </Typography>
                        </div>
                        {this.renderDistinctionTypes()}
                    </Paper>
                }
                {viewMode && viewMode === 'import' &&
                    <ImportResults />
                }
            </div>

        );
    }
}


export default withStyles(getStyles)(DistinctionsWrapper);