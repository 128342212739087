import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import moment from "moment";
import "moment/locale/nb";
import Table from '../../../Table/Table.component';
import apiService from '../../../Services/apiService';
import { CircularProgress } from '@material-ui/core';
import ProgressButton from '../../../Button/ProgressButton.component';

moment.locale("nb");

const styles = {
    rootPaper: {
        padding: 16,
        margin: 10,
    },
    paper: {
        marginTop: 16,
    },
    table: {
        minWidth: 650,
    },
    headerText: {
        padding: '10px 10px 0px 10px',
    },
    headerContainer: {
        display: 'flex',
    },
    exportButton: {
        marginRight: 10,
    },
    buttonContainer: {
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'flex-end',
    }
};

const organizationColumns = [
    {
        key: 'organizationName',
        displayName: 'Klubb',
    },
    {
        key: 'distinctionScore',
        displayName: 'Nasjonalplakett poeng',
        headerAlignment: 'right',
        dataAlignment: 'right',
    },
    {
        key: 'juryScore',
        displayName: 'Jury poeng',
        headerAlignment: 'right',
        dataAlignment: 'right',
    },
];

const mandatoryPersonColumns = [
    {
        key: 'distinctionScore',
        displayName: 'Nasjonalplakett poeng',
        headerAlignment: 'right',
        dataAlignment: 'right',
    },
    {
        key: 'juryScore',
        displayName: 'Jury poeng',
        headerAlignment: 'right',
        dataAlignment: 'right',
    },
];

class GeneratedReportView extends React.Component {
    state = {
        loading: true,
    }

    getApiData = () => {
        const selectedFields = this.props.selectedFields.map(sf => sf.value);
        const selectedCompetitionIDs = this.props.selectedCompetitions.map(sc => sc.competitionID);
        const isNsffAdmin = this.props.isNsffAdmin;
        return {
            selectedFields,
            selectedCompetitionIDs,
            isNsffAdmin,
        }
    }

    componentDidMount = () => {

        apiService.post(`competitions/getSummary`, this.getApiData()).then((report) => {
            this.setState({ report, loading: false });
        });
    }

    handleExport = () => {
        this.setState({ downloadingFile: true });
        const url = `competitions/getSummaryExcel`;
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(this.getApiData()),
            headers: apiService.getDefaultHeaders()
        };
        fetch(apiService.getUrl(url), requestOptions)
            .then((res,a,b) => {
                for(let entry of res.headers.entries()) {
                    console.log(entry);
                  }
                const filename = `Sammendrag ${moment().format("DD-MM-YYYY HH:mm")}.xlsx`;
                return res.blob().then((blob) => {
                    const fileUrl = window.URL.createObjectURL(new Blob([blob]));
                    const link = document.createElement('a');
                    link.href = fileUrl;
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                    this.setState({ downloadingFile: false });
                });
            });
    }

    renderIndividualReport = () => {
        const selectedFields = this.props.selectedFields;
        const report = this.state.report;
        const fields = [...selectedFields.map(sf => ({ key: sf.value, displayName: sf.label })), ...mandatoryPersonColumns];
        return this.renderReport("Sammenlagt resultater for personer", fields, report.personSummaries, "personID");
    }

    
    renderOrganizationReport = () => {
        const report = this.state.report;
        return this.renderReport("Sammenlagt resultater for klubber", organizationColumns, report.organizationSummaries, "organizationID");
    }

    renderReport = (header, fields, data, dataKey) => {
        const classes = this.props.classes;
        if (!this.props.isNsffAdmin) {
            fields = fields.filter(f => f.key !== "juryScore")
        }

        return (
            <Paper className={classes.paper}>
                <Typography className={classes.headerText} variant="h6">
                    {header}
                </Typography>
                <Table
                    className={classes.table}
                    dataKey={dataKey}
                    columns={fields}
                    data={data}
                    customClasses={classes}
                />
            </Paper>
        );
    }

    render() {
        const { classes, backFunction } = this.props;
        const { loading, downloadingFile } = this.state;
        return loading ?
            (
                <div className={classes.progressContainer}>
                    <CircularProgress />
                </div>
            ) :
            (
                <Paper className={classes.rootPaper}>
                    <div className={classes.headerContainer}>
                        <Typography variant="h6">
                            Rapport
                        </Typography>
                        <div className={classes.buttonContainer}>
                            <ProgressButton loading={downloadingFile} onClick={this.handleExport} className={classes.exportButton} variant="contained" color="primary">Eksporter</ProgressButton>
                            <Button onClick={backFunction} variant="outlined" color="primary">Tilbake</Button>
                        </div>

                    </div>
                    <Typography variant="body1">
                        Her vil du kunne se ett samlet resultat for de valgte konkurransene.
                    </Typography>
                    {this.renderIndividualReport()}
                    {this.renderOrganizationReport()}
                </Paper>
            );
    }
}

export default withStyles(styles)(GeneratedReportView);