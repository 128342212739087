import React from 'react';
import { MenuItem, Select, InputLabel, FormControl, OutlinedInput, withStyles } from '@material-ui/core';


const awardFilterStyles = () => ({
    selectLabel: {
        transform: 'translate(14px, 13px) scale(1)',
    },
    formControl: {
        marginTop: 8,
        marginBottom: 8,
        minWidth: 200,
    }
});

const AwardFilter = withStyles(awardFilterStyles)(
    (props) => {
        return (
            <FormControl variant="outlined" className={props.classes.formControl}>
                <InputLabel htmlFor="awardFilter" className={props.classes.selectLabel}>
                    Filter
                </InputLabel>
                <Select
                    value={props.awardFilter}
                    onChange={(ev) => props.setAwardFilter(ev.target.value)}
                    input={<OutlinedInput labelWidth={45} name="awardFilter" id="awardFilter" margin="dense" />}
                >
                    <MenuItem value=""></MenuItem>
                    <MenuItem value="1">Første plass</MenuItem>
                    <MenuItem value="2">Andre plass</MenuItem>
                    <MenuItem value="3">Tredje plass</MenuItem>
                    <MenuItem value="4">Hederlig omtale</MenuItem>
                    <MenuItem value="5">Antatt</MenuItem>
                </Select>
            </FormControl>
        );
    }
);


export default AwardFilter;