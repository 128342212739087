import React, { useState } from 'react';
import ParticipationList from './ParticipationList.component';
import { competitionService } from '../../../Services/competitionService';
import { withRouter } from 'react-router-dom';
import { competitionParticipationService } from '../../../Services/competitionParticipationService';
import useMountEffect from '../../../../Utils/useMountEffect';

const ParticipationListWrapper = ({ match }) => {
    const [loading, setLoading] = useState(true);
    const [participationsByOrganizations, setParticipationsByOrganization ] = useState([]);
    const [competition, setCompetition] = useState(null);
    const [competitionClasses, setCompetitionClasses ] = useState(null);
    useMountEffect(() => {
        const fetchData = async () => {
            const participationsByOrganizationsResult = await competitionParticipationService.getParticipationsByOrganizations(match.params.id);
            const competition = await competitionService.getCompetition(match.params.id);
            setCompetition(competition);
            setCompetitionClasses(competition.competitionClasses);
            setParticipationsByOrganization(participationsByOrganizationsResult, participationsByOrganizationsResult);
            setLoading(false);
          };
        fetchData();
    });

    return (
        <ParticipationList
            ready={!loading}
            competitionClasses={competitionClasses}
            competition={competition}
            participationsByOrganizations={participationsByOrganizations}
        />
    );

}

export default withRouter(ParticipationListWrapper);