import { connect } from 'react-redux';
import { getActive } from '../Competition/Competition.actions';
import Dashboard from './Dashboard.component';
import { initForm as initFormAction } from '../../Form/Form.actions';
import { showEditProfile, fetchPayments } from './Dashboard.actions';

const mapStateToProps = (state) => ({
    userData: state.userProfile.data,
    showEdit: state.userProfile.showEdit,
    orgs: state.clubs.orgs,
    viewMode: state.competition.viewMode,
    selectedComp: state.competition.selectedComp,
    registerViewActiveStep: state.competition.registerViewActiveStep,
    distinctionTypes: state.distinctions.distinctionTypes,
    mainOrganizationID: state.userProfile.data.mainOrganizationID,
});

const mapDispatchToProps = (dispatch) => ({
    fetchActive: () => {
        dispatch(getActive());
    },
    fetchPayments: () => {
        dispatch(fetchPayments())
    },
    onShowEditProfile: (userData) => {
        dispatch(initFormAction('userProfileForm', userData));
        dispatch(showEditProfile());
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);