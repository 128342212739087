import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const styles = theme => ({
    formControl: {
        margin: 8,
        width: "100%"
    },
});


class MainOrganizationSelector extends React.Component {
    handleChange = (e) => {
        this.props.callBackFunction(e);
    }

    render() {
        const { classes, selectedID, organizations } = this.props;
        return (
            <FormControl required variant="outlined" className={classes.formControl}>
                <InputLabel htmlFor="outlined-age-simple">
                    Hovedklubb
                </InputLabel>
                <Select
                    value={selectedID}
                    onChange={this.handleChange}
                    input={<OutlinedInput labelWidth={150} name="mainOrganizationID" id="outlined-age-simple" />}
                >
                    {organizations.map((org, key) => (
                        <MenuItem key={key} value={org.organizationID} disabled={org.isNsff}>{org.organizationName}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    }
}

export default withStyles(styles)(MainOrganizationSelector);