import React from 'react';
import {
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Button,
    FormLabel,
    Typography,
    withStyles,
} from '@material-ui/core';
import withInternalChangeHandler from '../../../../../Fields/withInternalChangeHandler';
import ProgressButton from '../../../../../Button/ProgressButton.component';
import TextField from '../../../../../Fields/TextField/TextField.component';

const FormTextField = withInternalChangeHandler()(TextField);

const getStyles = (theme) => ({
    displayItem: {
        paddingTop: 10,
        paddingBottom: 10,
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 900,
        width: '100%',
    },
    button: {
        marginRight: 8,
    },
    radioGroup: {
        justifyContent: 'center',
    }
});
 
class ScoreFullscreen extends React.Component {

    onBlur = (field, value) => {
        this.props.onScoreFormFieldUpdated(field,value);
    }

    handleShowEditScore = () => {
        const { score, comment } = this.props.classParticipation;
        this.props.onViewEditScore({
            score: score ? score.toString() : '',
            comment: comment || '',
        });
    }

    cancelEditScore = () => {
        this.props.onCancelEditScore();
    }

    validateForm = () => {
        const formValues = this.props.formValues;
        if(formValues && formValues.score) {
            return true;
        }
        this.props.onScoreFormInvalid("Poeng må være satt");
    }

    handleSaveScore = (e) => {
        e.preventDefault();
        const { classParticipation, competitionData, formValues } = this.props;

        if(this.validateForm()) {
            this.props.saveScore(
                competitionData.competitionID,
                classParticipation.classParticipationID,
                formValues.score,
                formValues.comment
            );
        }
    }

    renderForJuryingClosed() {
        const { classParticipation } = this.props;
        return (
            <div>
                Juryeringen er lukket
                <div>
                    Poeng registrert: {classParticipation.score}
                </div>
            </div>
        );
    }

    getScoreFields = () => {
        const fields = [];
        for(var i = 0; i <= 10; i++) {
            const iAsString = i.toString();
            fields.push((
                <FormControlLabel
                    key={iAsString}
                    value={iAsString}
                    control={<Radio color="primary" />}
                    label={iAsString}
                    labelPlacement="bottom"
                />
            ));
        }
        return fields;
    }

    renderForExisting = () => {
        const { classParticipation, competitionData, showEditScore, classes } = this.props;
        return showEditScore ? this.renderForm(true, "Oppdater") : (
            <div>
                <div className={classes.displayItem}>
                    <Typography variant="h6">
                        Antall poeng
                    </Typography>
                    <Typography variant="h4">
                        {classParticipation.score}
                    </Typography>
                </div>
                {classParticipation.comment &&
                    <div className={classes.displayItem}>
                        <Typography variant="h6">
                            Kommentar
                        </Typography>
                        <div>
                            {classParticipation.comment}
                        </div>
                    </div>
                }
                {!competitionData.juryingClosed &&
                    <div className={classes.displayItem}>
                        <Button onClick={this.handleShowEditScore} color="primary" variant="outlined">Endre</Button>
                    </div>
                }
            </div>
        );
    }

    renderForm = (allowCancel, submitText) => {
        const { classes, validationErrorMessage } = this.props;
        const formValues = this.props.formValues || {};
        return (
            <form name="form" onSubmit={this.handleSaveScore} noValidate className={classes.form}>
                <FormControl component="fieldset">
                    <FormLabel component="legend">Poeng</FormLabel>
                    <RadioGroup
                        aria-label="position"
                        name="position"
                        value={formValues.score || ''}
                        onChange={(ev) => { this.onBlur('score', ev.target.value); }}
                        row
                        className={classes.radioGroup}
                    >
                        {this.getScoreFields()}
                    </RadioGroup>
                </FormControl>
                <FormControl required variant="outlined" className={classes.formControl}>
                    <FormTextField
                        name="comment"
                        label="Kommentar"
                        onBlur={(ev) => { this.onBlur('comment', ev.target.value);}}
                        fullWidth
                        multiline
                        rows="4"
                        value={formValues.comment || ''}
                    />
                </FormControl>
                {validationErrorMessage &&
                        <Typography color="error" variant="overline" display="block" align="center">
                            {validationErrorMessage}
                        </Typography>
                    }
                <div>
                    {allowCancel &&
                        <Button
                            variant="contained"
                            type="button"
                            className={classes.button}
                            onClick={this.cancelEditScore}
                        >
                            Avbryt
                        </Button>
                    }
                    <ProgressButton variant="contained" type="submit" color="primary" loading={this.props.saving}>
                        {submitText}
                    </ProgressButton>
                </div>
            </form>
        );
    }



    render() {
        const { competitionData, classParticipation } = this.props;

        const scoreExists = !!classParticipation.score || classParticipation.score === 0;
        if(competitionData.juryingClosed) {
            return this.renderForJuryingClosed();
        }


        return scoreExists ? this.renderForExisting() : this.renderForm(false, "Lagre");
    }
}

export default withStyles(getStyles)(ScoreFullscreen);