import { simplePromiseAction, actionCreator } from '../../../../Utils/actions.utils';
import { competitionService } from '../../../Services/competitionService';
import { getCompetition } from '../Competition.actions';
import { initForm, getPaymentConfigured } from '../CompetitionForm/CompetitionForm.actions';
import { pushPreviousOrDefault } from '../../../../Utils/router.utils';

export const actionTypes = {
    REQUEST_CREATE_COMPETITION: 'RequestCreateCompetition',
    CREATE_COMPETITION_SUCCESS: 'CreateCompetitionSucces',
    CREATE_COMPETITION_FAILED: 'CreateCompetitionFailed',
    REQUEST_DELETE_COMPETITION: 'REQUEST_DELETE_COMPETITION',
    DELETE_COMPETITION_SUCCESS: 'DELETE_COMPETITION_SUCCESS',
    DELETE_COMPETITION_FAILED: 'DELETE_COMPETITION_FAILED',
    REQUEST_COPY_COMPETITION: 'REQUEST_COPY_COMPETITION',
    COPY_COMPETITION_SUCCESS: 'COPY_COMPETITION_SUCCESS',
    COPY_COMPETITION_FAILED: 'COPY_COMPETITION_FAILED',
    SET_DELETE_DIALOG_OPEN: 'SET_DELETE_DIALOG_OPEN',
    SET_COPY_DIALOG_OPEN: 'SET_COPY_DIALOG_OPEN',
}


const update = (competition) => simplePromiseAction(
    () => competitionService.update(competition),
    actionTypes.REQUEST_CREATE_COMPETITION,
    actionTypes.CREATE_COMPETITION_SUCCESS,
    actionTypes.CREATE_COMPETITION_FAILED
);

export const updateAndGoBack = (competition, previousLocation) => 
    (dispatch) => {
        return dispatch(update(competition)).then((action) => {
            if(!action.payload.error) {
                return dispatch(pushPreviousOrDefault(previousLocation, "/competitions"));                
            }
            return null;
        });
    }

const deleteComp = (competitionID) => simplePromiseAction(
    () => competitionService.deleteComp(competitionID),
    actionTypes.REQUEST_DELETE_COMPETITION,
    actionTypes.DELETE_COMPETITION_SUCCESS,
    actionTypes.DELETE_COMPETITION_FAILED
);

export const deleteAndGoBack = (competitionID, previousLocation) => 
    (dispatch) => {
        return dispatch(deleteComp(competitionID)).then((action) => {
            if(!action.payload.error) {
                return dispatch(pushPreviousOrDefault(previousLocation, "/competitions"));                
            }
            return null;
        });
    }


export const copyCompetition = (competitionID, copySettings) => simplePromiseAction(
    () => competitionService.copyCompetition(competitionID, copySettings),
    actionTypes.REQUEST_COPY_COMPETITION,
    actionTypes.COPY_COMPETITION_SUCCESS,
    actionTypes.COPY_COMPETITION_FAILED
);

export const loadEditCompetition = (competitionID) => (dispatch) => {
    return dispatch(getCompetition(competitionID))
        .then((action) => {
            if(!action.payload.error) {
                dispatch(initForm(action.payload.response));
                return dispatch(getPaymentConfigured(action.payload.response.organizationID));
            }
        });
}

export const setDeleteDialogOpen = (open) => actionCreator(actionTypes.SET_DELETE_DIALOG_OPEN)({ open });
export const setCopyDialogOpen = (open) => actionCreator(actionTypes.SET_COPY_DIALOG_OPEN)({ open });