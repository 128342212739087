// @flow
import * as React from 'react';
import { withStyles, IconButton } from '@material-ui/core';
import {
    ChevronLeft,
    ChevronRight,
} from '@material-ui/icons';

type Props = {
    onChange?: ?(value: any) => void,
    value: any,
};

type State = {
    value: any,
};

const getStyles = (theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        paddingTop: 10,
    },
    displayText: {
        lineHeight: '48px',
    }
});


export default () =>
    (InnerComponent: React.ComponentType<any>) =>
        withStyles(getStyles)(
            class TablePagingHandler extends React.Component<Props, State> {
                
                onPageChange = (paging) => {
                    this.props.onPageChange && this.props.onPageChange(paging);
                }
                
                onNextClick = () => {
                    const { startPosition, numberOfItems } = this.props.paging;
                    const newStartPos = startPosition + numberOfItems;
                    this.onPageChange({
                        startPosition: newStartPos,
                        numberOfItems,
                    });
                }
                onPrevClick = () => {
                    const { startPosition, numberOfItems } = this.props.paging;
                    let newStartPos = startPosition - numberOfItems;
                    if(newStartPos < 0) {
                        newStartPos = 0;
                    }
                    this.onPageChange({
                        startPosition: newStartPos,
                        numberOfItems,
                    });
                }

                getDisplayText = () => {
                    const { paging, data } = this.props;
                    const { startPosition } = paging;
                    const start = startPosition+1;
                    const items = data && data.length;
                    const end = startPosition + (items || start);
                    return `${start} - ${end}`;
                }

                render() {
                    const { paging, classes, ...passOnProps } = this.props;
                    if(!paging) {
                        return (
                            <InnerComponent
                                {...passOnProps}
                            />
                        );
                    }
                    const data = this.props.data;
                    const nextDisabled = !data || data.length === 0 || data.length < paging.numberOfItems;
                    const prevDisabled = paging && paging.startPosition === 0;
                    
                    return (
                        <div>
                            <InnerComponent
                                {...passOnProps}
                            />
                            <div className={classes.container}>
                                <div className={classes.displayText}>
                                    {this.getDisplayText()}
                                </div>
                                <IconButton disabled={prevDisabled} onClick={this.onPrevClick}>
                                    <ChevronLeft />
                                </IconButton>
                                <IconButton disabled={nextDisabled} onClick={this.onNextClick}>
                                    <ChevronRight />
                                </IconButton>
                            </div>
                        </div>

                    );
                }
            }
        )