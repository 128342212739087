

import React, { useEffect } from "react";
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import withErrorMessage from '../Error/withErrorMessage';
import withLoadingIndicator from '../LoadingIndicator/withLoadingIndicator';

const withDataLoader = (dataLoadFunctionPropName) =>
    (InnerComponent) => {
        const WrappedInnerComponent = withErrorMessage()(withLoadingIndicator()(InnerComponent));
        return withRouter((props) => {
            const { dataLoading, location, match, dataLoadErrorMessage, ...passOnProps } = props;
            useEffect(() => {
                const queryParams = location.search !== "" ? queryString.parse(location.search) : {};
                const urlParams = match.params;
                const dataLoadFn = props[dataLoadFunctionPropName];
                dataLoadFn && dataLoadFn(urlParams, queryParams);
                // eslint-disable-next-line react-hooks/exhaustive-deps
              }, []);
            return (
                <WrappedInnerComponent
                    errorMessage={dataLoadErrorMessage}
                    ready={!dataLoading}
                    location={location}
                    match={match}
                    {...passOnProps}
                />
            );
        });
    };

export default withDataLoader;