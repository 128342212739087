import { simplePromiseAction, actionCreator } from '../../../../Utils/actions.utils';
import { paymentService } from '../../../Services/paymentService';
import { personService } from '../../../Services/personService';

export const actionTypes = {
    INIT_FORM: 'INIT_FORM',
    UPDATE_FORM: 'UPDATE_FORM',
    UPDATE_FORM_MULTI: 'UPDATE_FORM_MULTI',
    REQUEST_SEARCH: 'REQUEST_SEARCH',
    SEARCH_SUCCESS: 'SEARCH_SUCCESS',
    SEARCH_FAILED: 'SEARCH_FAILED',
    REQUEST_GET_PAYMENT_CONFIGURED: 'REQUEST_GET_PAYMENT_CONFIGURED',
    GET_PAYMENT_CONFIGURED_SUCCESS: 'GET_PAYMENT_CONFIGURED_SUCCESS',
    GET_PAYMENT_CONFIGURED_FAILED: 'GET_PAYMENT_CONFIGURED_FAILED',
}

const initFromAction = (competition) => actionCreator(actionTypes.INIT_FORM)({ competition });
const updateFormSingleFieldAction = (fieldName, value) => actionCreator(actionTypes.UPDATE_FORM)({ fieldName, value, });
const updateFormMultiFieldAction = (fields) => actionCreator(actionTypes.UPDATE_FORM_MULTI)({ fields });

export const initForm = (competition) => initFromAction(competition);

export const updateFormSigleField = (fieldName, value) => updateFormSingleFieldAction(fieldName, value);

export const updateFormMutliFields = (fields) => updateFormMultiFieldAction(fields);

export const searchPerson = (firstName, lastName) => simplePromiseAction(
    () => personService.search(firstName, lastName),
    actionTypes.REQUEST_SEARCH,
    actionTypes.SEARCH_SUCCESS,
    actionTypes.SEARCH_FAILED
);

export const getPaymentConfigured = (organizationID) => simplePromiseAction(
    () => paymentService.paymentConfigured(organizationID),
    actionTypes.REQUEST_GET_PAYMENT_CONFIGURED,
    actionTypes.GET_PAYMENT_CONFIGURED_SUCCESS,
    actionTypes.GET_PAYMENT_CONFIGURED_FAILED,
    { organizationID },
);