import {
    actionCreator,
} from '../../../../../Utils/actions.utils';

export const actionTypes = {
    SCORE_FULLSCREEN_INDEX_CHANGE: "SCORE_FULLSCREEN_INDEX_CHANGE",
    SCORE_SET_SHOULD_DISPLAY_FULLSCREEN: "SCORE_SET_SHOULD_DISPLAY_FULLSCREEN",
    SCORE_SHOW_FULLSCREEN: "SCORE_SHOW_FULLSCREEN",
    SCORE_SHOW_LIST: "SCORE_SHOW_LIST",
}



export const fullScreenIndexChange = (index) => actionCreator(actionTypes.SCORE_FULLSCREEN_INDEX_CHANGE)({ index });

export const setShouldDisplayFullscreen =
    (showFullscreen, fullscreenInitialIndex) =>
        actionCreator(actionTypes.SCORE_SET_SHOULD_DISPLAY_FULLSCREEN)({ showFullscreen, fullscreenInitialIndex });


export const showFullscreen = (fullscreenInitialIndex) =>
    actionCreator(actionTypes.SCORE_SHOW_FULLSCREEN)({ fullscreenInitialIndex });

export const showList = () =>
    actionCreator(actionTypes.SCORE_SHOW_LIST)({  });