import { createSelector } from 'reselect';
import {
    awards
} from '../awards';

const awardsAllowedOnlyOnce = [];

const getClassParticipationFilter = state => state.viewAndAward && state.viewAndAward.classParticipationFilter;
const getClassParticipations = state => state.viewAndAward && state.viewAndAward.data;

export const makeGetAwards = () =>
    createSelector(
        getClassParticipations,
        (classParticipations) => {
            const accAwards = awards.map(a => ({ ...a}));

            if(classParticipations) {
                classParticipations.forEach((item) => {
                    const awardAsInt = item.award ? parseInt(item.award) : null;
                    if(awardAsInt && awardsAllowedOnlyOnce.includes(awardAsInt)) {
                        const index = awardAsInt -1;
                        accAwards[index].notAvailable = true;
                    }
                });
        
        
            }
            return accAwards;
        }
    );

export const makeGetDisplayClassParticipations = () =>
    createSelector(
        getClassParticipations,
        getClassParticipationFilter,
        (classParticipations, filter) => {
            if(classParticipations) {
                if(filter === 'awarded') {
                    return classParticipations.filter(c => c.award != null);
                }else if(filter ==='notawarded') {
                    return classParticipations.filter(c => c.award == null);
                }
                return classParticipations;
            }
        }
    );