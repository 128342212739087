import { connect } from 'react-redux';
import DistinctionsWrapper from './DistinctionsWrapper.component';
import { initializeData } from './Distinctions.actions';

const mapStateToProps = (state) => {
    const distinctions = state.distinctions || {};
    return {
        ready: state.distinctions && !state.distinctions.initializing,
        distinctionTypes: distinctions.distinctionTypes,
        showDistinctionsForPerson: distinctions.showDistinctionsForPerson,
        editMode: state.userProfile.data.isNsffAdmin,
    }
};

const mapDispatchToProps = (dispatch) => ({
    initializeData: () => {
        dispatch(initializeData());
    },
});


export default connect(mapStateToProps, mapDispatchToProps)(DistinctionsWrapper);