import { connect } from 'react-redux';
import CompetitionForm from './CompetitionForm.component';
import { updateFormSigleField, updateFormMutliFields, searchPerson, getPaymentConfigured } from './CompetitionForm.actions';
import { pushPreviousOrDefault } from '../../../../Utils/router.utils';

const mapStateToProps = (state) => ({
    competitionForm: state.competitionForm,
    isNsffAdmin: state.userProfile.data.isNsffAdmin,
    previousLocation: state.location.previous,
});

const mapDispatchToProps = (dispatch) => ({
    updateFormSigleField: (fieldName, value) => {
        dispatch(updateFormSigleField(fieldName, value));
    },
    updateFormMutliFields: (fields) => {
        dispatch(updateFormMutliFields(fields));
    },
    searchPerson: (firstName, lastName) => {
        dispatch(searchPerson(firstName, lastName));
    },
    getPaymentConfigured: (organizationID) => {
        dispatch(getPaymentConfigured(organizationID));
    },
    onCancel: (previousLocation) => {
        dispatch(pushPreviousOrDefault(previousLocation, "/competitions"));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(CompetitionForm);