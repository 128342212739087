import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ControlledExpansionPanels from './ControlledExpansionPanels.container';

const styles = theme => ({
  root: {
    padding: 10,
  },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  button: {
    float: "right",
    backgroundColor: "#2ecc71",
    color: "white",
  },
});

class Admin extends React.Component {

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
          <ControlledExpansionPanels />
      </div>
    );
  }
}

export default withStyles(styles)(Admin);