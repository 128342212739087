import {
    actionTypes
} from '../components/Form/Form.actions';


const initState = {

}

const createCompetitionReducer = (state = initState, action) => {
    switch(action.type) {
        case actionTypes.INIT_FORM:
            console.log('INIT FORM '+action.payload.formId);
            return {
                ...state,
                [action.payload.formId]: { ...action.payload.formValues },
            };

        case actionTypes.FORM_FIELD_UPDATE:
            console.log('FORM FIELD UPDATE:'+action.payload.formId);
            return {
                ...state,
                [action.payload.formId]: {
                    ...state[action.payload.formId],
                    [action.payload.field]: action.payload.value,
                },
            };
        default:
            return state;
    }
}

export default createCompetitionReducer;



