import { actionTypes } from '../components/Pages/Competition/UserParticipation/UserParticipation.actions';
import { actionTypes as competitionActionTypes } from '../components/Pages/Competition/Competition.actions';
const initState = {

}

const competitionParticipationReducer = (state = initState, action) => {
    switch(action.type) {
        case competitionActionTypes.SET_VIEW_MODE:
            return action.payload.mode === 'participation' ? {
                fetchingParticipation: state.fetchingParticipation,
            } : state;
        case actionTypes.REQUEST_UPLOAD_IMAGE:
            console.log('Uploading image');

            const classParticipationsByIDLocal = state.participation.classParticipations;
            classParticipationsByIDLocal[action.payload.competitionClassID] = [
                ...(state.participation.classParticipations[action.payload.competitionClassID] || []),
                { ...action.payload }
            ];

            return {
                ...state,
                errorMessage: null,
                isUploading: true,
                participation: {
                    ...state.participation,
                    classParticipations: classParticipationsByIDLocal,
                },

            };
        case actionTypes.UPLOAD_IMAGE_SUCCESS:
            console.log('Uploading image success');
            const classParticipationsByClass = state.participation.classParticipations[action.payload.competitionClassID];
            const index = classParticipationsByClass.findIndex(img => img.tempID === action.payload.tempID);
            classParticipationsByClass[index] = {
                ...classParticipationsByClass[index],
                classParticipationID: action.payload.response.classParticipationID,
                classParticipationNumber: action.payload.response.classParticipationNumber,
            }

            let imgListUploadSuccess = state.participation.classParticipations;
            imgListUploadSuccess[action.payload.competitionClassID] = classParticipationsByClass;

            return {
                ...state,
                isUploading: false,
                participation: {
                    ...state.participation,
                    classParticipations: imgListUploadSuccess,
                },
            };
        case actionTypes.UPLOAD_IMAGE_FAILED:
            console.log('Uploading image failed');
            const filterListFailedUpload = state.participation.classParticipations[action.payload.competitionClassID].filter(img => {
                return img.tempID !== action.payload.tempID;
            });

            let imgListFailedUpload = state.participation.classParticipations;
            imgListFailedUpload[action.payload.competitionClassID] = filterListFailedUpload;

            return {
                ...state,
                isUploading: false,
                errorMessage: action.payload.error.message,
                participation: {
                    ...state.participation,
                    classParticipations: imgListFailedUpload,
                },
            };
        case actionTypes.REQUEST_DELETE_IMAGE:
            console.log('Deleting image');

            return {
                ...state,
                errorMessage: null,
            };
        case actionTypes.DELETE_IMAGE_SUCCESS:
            console.log('Delete image success');
            const filterListDeleteSuccess = state.participation.classParticipations[action.payload.competitionClassID].filter(img => {
                return img.classParticipationID !== action.payload.imageID;
            });

            const imgListDeleteSuccess = state.participation.classParticipations;
            imgListDeleteSuccess[action.payload.competitionClassID] = filterListDeleteSuccess;

            return {
                ...state,
                isUploading: false,
                participation: {
                    ...state.participation,
                    classParticipations: imgListDeleteSuccess,
                },
            };
        case actionTypes.DELETE_IMAGE_FAILED:
            console.log('Delete image failed');
            return {
                ...state,
                isUploading: false,
                errorMessage: action.payload.error.message,
            };
        case actionTypes.REQUEST_LOAD_PARTICIPATION_PAGE:
            return {
                participationPageLoading: true,
            }
        case actionTypes.REQUEST_PARTICIPATION:
            console.log('Requesting participation');
            return {
                ...state,
                participationPageLoading: true,
                errorMessage: null,
            };
        case actionTypes.REQUEST_PARTICIPATION_SUCCESS:
            console.log('Requesting participation success');

            const classParticipationsByID = action.payload.response.classParticipations ? action.payload.response.classParticipations.reduce((obj, item) => {
                obj[item.competitionClassID] = [ ...(obj[item.competitionClassID] || []), item];
                return obj;
            },{}) : {};

            return {
                ...state,
                participation: {
                    ...action.payload.response,
                    classParticipations: classParticipationsByID,
                },
                participationPageLoading: false,
            };
        case actionTypes.REQUEST_PARTICIPATION_FAILED:
            console.log('Requesting participation failed');
            return {
                ...state,
                participationPageLoading: false,
                errorMessage: action.payload.error.message,
            };
        case actionTypes.REQUEST_PROCESS_PARTICIPATION_PAYMENT:
                console.log('request process participation payment');
            return {
                ...state,
                processPaymentError: null,
                processingPayment: true,
            };
        case actionTypes.REQUEST_PROCESS_PARTICIPATION_PAYMENT_SUCCESS:
            console.log('request process participation payment success');
            return {
                ...state,
                processingPayment: false,
                paymentFormDialogOpen: false,
                activeStep: 1,
                participation: {
                    ...action.payload.response,
                    classParticipations: {},
                }
            };
        case actionTypes.REQUEST_PROCESS_PARTICIPATION_PAYMENT_FAILED:
            console.log('request process participation payment failed');
            return {
                ...state,
                processingPayment: false,
                processPaymentError: action.payload.error.message,
            };
        case actionTypes.SET_PAYMENT_FORM_DIALOG_OPEN:
            console.log('request process participation payment failed');
            return {
                ...state,
                paymentFormDialogOpen: action.payload.open,
            };
        case actionTypes.SET_ACTIVE_PARTICIPATION_STEP:
            return {
                ...state,
                activeStep: action.payload.step,
            }
        case actionTypes.SET_SELECTED_CLASSID:
            return {
                ...state,
                selectedCompetitionClassID: action.payload.selectedClassID,
            }
        default:
            return state;
    }
}

export default competitionParticipationReducer;