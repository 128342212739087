import { simplePromiseAction, actionCreator } from '../../../../Utils/actions.utils';
import { distinctionService } from '../../../Services/distinctionService';
import { importResultsService } from '../../../Services/importResultsService';

export const actionTypes = {
    REQUEST_SET_PERSON_DISTINCTION: 'REQUEST_SET_PERSON_DISTINCTION',
    SET_PERSON_DISTINCTION_SUCCESS: 'SET_PERSON_DISTINCTION_SUCCESS',
    SET_PERSON_DISTINCTION_FAILED: 'SET_PERSON_DISTINCTION_FAILED',
    GO_BACK_TO_PERSON_DISTINCTIONS_LIST: 'GO_BACK_TO_PERSON_DISTINCTION_LIST',
    SET_VIEW_MODE_DISTINCTIONS: 'SET_VIEW_MODE_DISTINCTIONS',
    GET_IMPORTED_RESULTS: 'GET_IMPORTED_RESULTS',
    GET_IMPORTED_RESULTS_SUCCESS: 'GET_IMPORTED_RESULTS_SUCCESS',
    GET_IMPORTED_RESULTS_FAILED: 'GET_IMPORTED_RESULTS_FAILED',
}

export const goBackToPersonDistinctionList = () => actionCreator(actionTypes.GO_BACK_TO_PERSON_DISTINCTIONS_LIST)({});

export const setViewMode = (mode) => actionCreator(actionTypes.SET_VIEW_MODE_DISTINCTIONS)({mode});

export const requestSetPersonDistinction = (personID, distinctionTypeID, distinctionItemID, year) => {
    const metadata = { personID, distinctionTypeID, distinctionItemID, year };
    return simplePromiseAction(
        () => distinctionService.setPersonDistinction(personID, distinctionTypeID, distinctionItemID, year),
        actionTypes.REQUEST_SET_PERSON_DISTINCTION,
        actionTypes.SET_PERSON_DISTINCTION_SUCCESS,
        actionTypes.SET_PERSON_DISTINCTION_FAILED,
        metadata
    );
}

export const getImportedResults = (personID, viewMode) => {
    const metadata = { personID, viewMode };
    return simplePromiseAction(
        () => importResultsService.getResults(personID),
        actionTypes.GET_IMPORTED_RESULTS,
        actionTypes.GET_IMPORTED_RESULTS_SUCCESS,
        actionTypes.GET_IMPORTED_RESULTS_FAILED,
        metadata
    );
}