import React from 'react';
import CompetitionDetailsStep from './CompetitionDetailsStep/CompetitionDetailsStep.component';
import UploadStep from './UploadStep/UploadStep.container';
import SummaryStep from './SummaryStep/SummaryStep.component';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import moment from "moment";
import PaymentDialog from '../../../Payment/PaymentDialog.component';
import "moment/locale/nb";
import { Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

moment.locale("nb");

const styles = theme => ({
    root: {
        padding: 10,
    },
    paper: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    buttonContainer: {
        textAlign: "center",
    },
    button: {
        marginRight: theme.spacing(),
    },
    instructions: {
        marginTop: theme.spacing(),
        marginBottom: theme.spacing(),
    },
});

const detailsStep = {
    key: 'details',
    label: "Detaljer",
    getComponent: (props, state) => {
        const { classesByID, competitionData, participation } = props;
        return (
            <CompetitionDetailsStep
                participation={participation}
                classesByID={classesByID}
                competitionData={competitionData}
            />
        );
    },
};
const uploadStep = {
    label: "Last opp",
    getComponent: (props) => {
        const { participation, classesByID, competitionData, uploadImage, deleteImage } = props;
        return (
            <UploadStep
                competitionData={competitionData}
                participation={participation}
                classesByID={classesByID}
                competitionClasses={competitionData.competitionClasses}
                competitionID={competitionData.competitionID}
                competitionType={competitionData.competitionType}
                uploadImage={uploadImage}
                deleteImage={deleteImage}
                competitionMaxItems={competitionData.maxItems}
            />
        );
    },
};
const summaryStep = {
    key: 'summary',
    label: "Sammendrag",
    getComponent: (props) => {
        const { participation, classesByID, userProfile, organizationsByID, selectedOrganization, competitionData } = props;
        return (
            <SummaryStep
                competitionData={competitionData}
                participation={participation}
                classesByID={classesByID}
                competitionClasses={competitionData.competitionClasses}
                userProfile={userProfile}
                organizationsByID={organizationsByID}
                selectedOrg={selectedOrganization}
                competitionData={competitionData}
            />
        );
    },
};

const defaultSteps = [
    detailsStep,
    uploadStep,
    summaryStep,
];

const submissionEndedSteps = [
    detailsStep,
    summaryStep,
];

class Participation extends React.Component {
    state = {};
    getInitialStep = () => {
        if(this.props.competitionData.isParticipating) {
            return this.getSteps().length -1;
        }
        return 0;
    }

    getActiveStep = () => {
        const activeStep = this.props.activeStep;
        if(activeStep === 0 || activeStep) {
            return activeStep;
        }
        return this.getInitialStep();
    }

    handleNext = () => {
        this.props.setActiveStep(this.getActiveStep()+1);
    };
    
    handleBack = () => {
        this.props.setActiveStep(this.getActiveStep()-1);
    };

    isCompetitionSubmissionEnded = () => {
        return moment() > moment(this.props.competitionData.submissionEnd);
    }

    isCompetitionSubmissionStarted = () => {
        return moment() > moment(this.props.competitionData.submissionStart);
    }

    renderActiveStep = (steps) => {
        const activeStep = this.getActiveStep();
        const activeStepObj = steps[activeStep];
        
        return (
            <div>
                <h1>{activeStepObj.label}</h1>
                {activeStepObj.getComponent(this.props, this.state)}
            </div>
        );
    }


    getSteps =  () => {
        return this.isCompetitionSubmissionEnded() ? submissionEndedSteps : defaultSteps;
    }

    handleProcessPayment = (intentID) => {
        this.props.processPayment(intentID, this.props.competitionData.competitionID);
    }


    openPaymentFormDialog = () => {
        this.props.setPaymentFormDialogOpen(true);
    }

    closePaymentFormDialog = () => {
        this.props.setPaymentFormDialogOpen(false);
    }

    isSubmissionEnded = () => {
        return moment() > moment(this.props.competitionData.submissionEnd);
    }

    getParticipatingOrganization = () => {
        const {competitionData, organizationsByID, userProfile } = this.props;
        if(userProfile.mainOrganizationID && competitionData.isPublic) {
            return organizationsByID[userProfile.mainOrganizationID];
        } else {
            return organizationsByID[competitionData.organizationID];
        }
    }

    renderNextButton = (step) => {
        const { competitionData, participation, classes } = this.props;
        const paymentCompleted = participation && participation.paymentCompleted;
        const participatingOrganization = this.getParticipatingOrganization();
        if(step.key === 'details' && this.isSubmissionEnded() && !competitionData.isParticipating) {
            return null;
        }

        if(step.key === 'details' && !competitionData.isParticipating && participatingOrganization.isNsff) {
            return (
                <Alert severity="warning">
                    <Typography>
                        Du kan ikke delta i denne konkurransen, fordi {participatingOrganization.organizationName} ikke er en gyldig klubb å delta med. Vennligst sjekk om du er medlem av en underklubb av {participatingOrganization.organizationName}
                    </Typography>
                </Alert>

            );
        }

        if(step.key === 'details' && competitionData.participationPrice > 0 && !paymentCompleted) {
            return (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={this.openPaymentFormDialog}
                    className={classes.button}
                >
                    { 'Betal og gå videre' }
                </Button>
            );
        }
        return (
            <Button
                variant="contained"
                color="primary"
                onClick={this.handleNext}
                className={classes.button}
            >
                { 'Neste' }
            </Button>
        );
    }

    renderBackButton = (activeStep) => {
        const classes = this.props.classes;
        const steps = this.getSteps();
        const step = steps[activeStep];
        if(activeStep === 0) {
            return (
                <Button
                    onClick={this.handleBackClick}
                    className={classes.button}
                >
                    Tilbake
                </Button>
            );
        }
        let buttonTitle = "Forrige";

        if(step.key === 'summary' && !this.isSubmissionEnded()) {
            buttonTitle = "Endre/Legg til";
        }

        return (
            <Button
                disabled={activeStep === 0}
                onClick={this.handleBack}
                className={classes.button}
            >
                {buttonTitle}
            </Button> 
        );
    }

    handleBackClick = () => {
        this.props.onBackClick(this.props.previousLocation);
    }

    render() {
        const { classes, competitionData } = this.props;
        const steps = this.getSteps();
        const activeStep = this.getActiveStep();

        return (
            <div className={classes.root}>
                <Paper className={classes.paper} elevation={1}>
                    <Stepper activeStep={activeStep}>
                        {steps.map((step) => {
                            const props = {};
                            const labelProps = {};

                            return (
                                <Step key={step.label} {...props}>
                                    <StepLabel {...labelProps}>{step.label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                    <div>
                        {this.renderActiveStep(steps)}
                    </div>
                    <div className={classes.buttonContainer}>
                        {this.renderBackButton(activeStep)}
                        { activeStep === steps.length - 1 &&
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={this.handleBackClick}
                                className={classes.button}
                            >
                                    { 'Ok' }
                            </Button>
                        }
                        { activeStep !== steps.length - 1 && this.renderNextButton(steps[activeStep])}
                    </div>
                </Paper>
                <PaymentDialog
                    open={this.props.paymentFormDialogOpen}
                    onClose={this.closePaymentFormDialog}
                    organizationID={competitionData.organizationID}
                    amount={competitionData.participationPrice}
                    onProcessPayment={this.handleProcessPayment}
                    internalProcessingPayment={this.props.processingPayment}
                    internalProcessPaymentError={this.props.paymentError}
                />
            </div>
        );
    }
}

export default withStyles(styles)(Participation);