import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import SelectFieldComponent from '../../../Fields/SelectField/SelectField.component';

const styles = theme => ({
    formControl: {
        margin: 8,
        width: "100%"
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: theme.spacing(0.25),
        backgroundColor: "#efbb09",
    },
});

class CompetitionAdminSelection extends React.Component {
    handleChange = value => {
        const allAdminsByPersonId = this.getAdminsByPersonIDForSelectedOrganization();
        var newValue = value ? value.map(opt => allAdminsByPersonId[opt.value]) : [];
        this.props.onChange(newValue);
    };


    getAdminsByPersonIDForSelectedOrganization = () => {
        return this.props.adminsByOrgID ? this.props.adminsByOrgID[this.props.selectedOrganizationId] : [];
    }

    getOptions = () => {
        const allAdminsByPersonId = this.getAdminsByPersonIDForSelectedOrganization();

        return Object.keys(allAdminsByPersonId).map(id => ({
            label: `${allAdminsByPersonId[id].firstName} ${allAdminsByPersonId[id].lastName}`,
            value: id,
        }));
    }

    render() {
        const { classes } = this.props;
        const options = this.getOptions();
        const value = this.props.selectedAdmins ? this.props.selectedAdmins.map(admin => ({
            label: `${admin.firstName} ${admin.lastName}`,
            value: admin.personID,
        })) : [];
        return (
            <div>
                <FormControl variant="outlined" className={classes.formControl}>
                    <SelectFieldComponent
                        label="Administratorer"
                        isMulti
                        value={value}
                        options={options}
                        onChange={this.handleChange}
                    />
                    <FormHelperText>Brukere som oppretter konkurranser er automatisk administrator.</FormHelperText>
                </FormControl>
            </div>
        );
    }
}

export default withStyles(styles)(CompetitionAdminSelection);