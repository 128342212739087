import React from 'react';
import {
    NewReleases
} from '@material-ui/icons';
import {
    TableCell,
    Tooltip
} from '@material-ui/core';
import {getAvailableDistinctions } from '../availableDistinctions';

const getDistinctionDataCell = (props, data, code, tooltipSingular, tooltipPlural) => {
    const classes = props.customClasses;
    const { distinctionsByItemID, pointsByDistinctionTypeID } = data;
    const distinctionType = props.distinctionTypesByCode[code];

    const distinctionTypePoints = pointsByDistinctionTypeID[distinctionType.distinctionTypeID] || { points: 0 };

    const availableDistinctions = getAvailableDistinctions(
        distinctionTypePoints.points,
        distinctionsByItemID,
        distinctionType.distinctionItems
    );
    return (
        <TableCell key={code} align="right" className={classes.cell}>
            <div className={classes.cellContent}>
                {!!availableDistinctions && props.editMode && 
                    <div className={classes.availableDistinctionsContainer}>
                        <Tooltip
                            title={`${availableDistinctions} ${availableDistinctions > 1 ? tooltipPlural : tooltipSingular} oppnådd`}
                        >
                            <div>
                                <NewReleases color="secondary" />
                                <div className="newReleaseClass"></div>
                            </div>

                        </Tooltip>
                    </div>
                }
                <div>{distinctionTypePoints.points}</div>
            </div>
        </TableCell>
    )

}

const columns = [
    { 
        key: 'firstName',
        displayName: 'Fornavn',
        sortable: true,
    },
    {
        key: 'lastName',
        displayName: 'Etternavn',
        sortable: true,
    },
    {
        key: 'mainOrganizationName',
        displayName: 'Klubb',
        sortable: true,
    },
    {
        key: 'ansff',
        headerAlignment: 'right',
        sortable: true,
        displayName: 'ANSFF',
        getDataCell: (props, data) => 
            getDistinctionDataCell(
                props,
                data,
                'ansff',
                'nytt hederstegn',
                'nye hederstegn',
            ),
    },
    {
        key: 'ensff',
        headerAlignment: 'right',
        sortable: true,
        displayName: 'ENSFF',
        getDataCell: (props, data) => 
            getDistinctionDataCell(
                props,
                data,
                'ensff',
                'nytt hederstegn',
                'nye hederstegn',
            ),
    },
    {
        key: 'nasjonalplakett',
        headerAlignment: 'right',
        sortable: true,
        displayName: 'Nasjonal plakett',
        getDataCell: (props, data) =>
            getDistinctionDataCell(
                props,
                data,
                'nasjonalplakett',
                'ny plakett',
                'nye plaketter',
            ),
    },
    {
        key: 'internasjonalplakett',
        headerAlignment: 'right',
        sortable: true,
        displayName: 'Internasjonal plakett',
        getDataCell: (props, data) =>
            getDistinctionDataCell(
                props,
                data,
                'internasjonalplakett',
                'ny plakett',
                'nye plaketter',
            ),
    },
];

export default columns;