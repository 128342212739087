export function actionCreator(type) {
    return (payload, meta, error) => ({
        type,
        payload,
        meta,
        error,
    });
};


const request = (actionType, metadata) => actionCreator(actionType)(null, metadata);
const success = (actionType, response, metadata) => actionCreator(actionType)({ response }, metadata);
const failed = (actionType, error, metadata) => actionCreator(actionType)({ error }, metadata);

export function simplePromiseAction(getPromise, requestActionType, successActionType, errorActionType, metadata) {
    return (dispatch) => {
        dispatch(request(requestActionType, metadata));
        var promise = getPromise();
        return promise.then(
            response => dispatch(success(successActionType, response, metadata)),
            error => dispatch(failed(errorActionType, error, metadata)),
        );
    }
};
