// @flow

import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Route, Redirect } from 'react-router-dom';




const PrivateRoute = ({ component: Component, push: locationPush, ...rest }: any) => {
        var user = localStorage.getItem('user');
        if(!user) {
            locationPush('/login');
            return false;
        }
        return (
            <Route {...rest} render={props => (
                localStorage.getItem('user')
                    ? <Component {...rest} {...props} />
                    : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            )} />
        );
    }


export default connect(null, {push})(PrivateRoute);