import React, { useState } from 'react'; 
import withLoadingIndicator from '../../../LoadingIndicator/withLoadingIndicator';
import {
    withStyles,
    Typography,
    Button,
    IconButton,
} from '@material-ui/core';
import ParticipantPanel from './ParticipantPanel.component';
import downloadFile from '../../../../Utils/download/downloadFile';
import { GetApp } from '@material-ui/icons';
import DownloadDialog from '../../../DownloadDialog/DownloadDialog.component';

const participationListStyles = () => ({
    headerText: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 8,
    },
    organizationContainer: {
        margin: 16,
        padding: 16,
    },
    topActionContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        margin: 16,
        marginRight: 32,
    }
});

const ParticipationList = withLoadingIndicator()(
    withStyles(participationListStyles)(
        ({ participationsByOrganizations, classes, competition, ...props }) => {
            const [downloadDialogSettings, setDownloadDialogSettings] = useState(null);

            const downloadAll = () => {
                setDownloadDialogSettings(null);
                const { competitionID } = competition;
                const url = `competitions/${competitionID}/downloadParticipationsFromParticipationList`;
                downloadFile(url);
            }

            const downloadForOrganization = (organizationID) => {
                setDownloadDialogSettings(null);
                const { competitionID } = competition;
                const url = `competitions/${competitionID}/downloadParticipationsFromParticipationList`;
                downloadFile(url, [`organizationID=${organizationID}`]);
            }

            const exportAsExcel = () => {
                const { competitionID } = competition;
                const url = `competitions/${competitionID}/participants/export`;
                downloadFile(url);
            }

            const onDownloadCancel = () => {
                setDownloadDialogSettings(null);
            }

            const anyParticipations = participationsByOrganizations && participationsByOrganizations.length > 0;
            const downloadAllSettings = {
                fileCount: anyParticipations ? participationsByOrganizations.reduce((accSum, o) => (accSum + o.count), 0) : 0,
                onConfirm: downloadAll,
                onCancel: onDownloadCancel,
                avgFileSize: 2,
            }

            return participationsByOrganizations && participationsByOrganizations.length > 0 ?
                (
                    <div>
                        <div className={classes.topActionContainer}>
                            <Button onClick={exportAsExcel} variant="outlined">Eksporter til excel</Button>
                            <Button onClick={() => setDownloadDialogSettings(downloadAllSettings)} variant="outlined">Last ned bilder</Button>
                        </div>
                        
                        {
                            participationsByOrganizations.map(o => (
                                <div key={o.organizationID} className={classes.organizationContainer}>
                                    <div>
                                        <Typography variant="h5" className={classes.headerText}>
                                            {o.organizationName}
                                            <IconButton onClick={() => setDownloadDialogSettings({ onConfirm: () => downloadForOrganization(o.organizationID), onCancel: onDownloadCancel, fileCount: o.count, avgFileSize: 2 })}>
                                                <GetApp/>
                                            </IconButton>
                                        
                                        </Typography>
                                    </div>
                                    {o.participationsByPersons.map(p => (
                                        <ParticipantPanel
                                            key={p.personID}
                                            participationsByPerson={p}
                                            competition={competition}
                                            {...props}
                                        />
                                    ))}
                                </div>
                            ))
                        }
                        {downloadDialogSettings && <DownloadDialog {...downloadDialogSettings} open />}
                    </div>
                )
        :
            (<div><Typography variant="h5" style={{display: "flex", justifyContent: "center"}}>Ingen deltakere registrert</Typography></div>)
        }
    ))

export default ParticipationList;


