import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
    card: {
        display: 'flex',
    },
    content: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
    },
    media: {
        height: 150,
        width: 150,
    },
    buttonContainer: {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
    },
    cardContent: {
        display: 'flex',
        flexGrow: 1,
    },
    buttonInnerContainer: {
        display: 'inline-block',
    }
  });

class MediaCardList extends React.Component {
    renderMediaItem = (mediaItem, index) => {
        const { classes, imageMinSize } = this.props;
        return (
            <Grid key={index} item xs={12} sm={12} md={12} lg={6} xl={4}>
                <Card className={classes.card}>
                    <CardMedia
                        className={classes.media}
                        image={mediaItem.thumbnailUrl}
                        title={mediaItem.name}
                        style={{minWidth: imageMinSize}}
                    />
                    {this.props.getMediaItemContent(mediaItem, index)}
                </Card>
            </Grid>
        );
    }

    render() {
        const { classes, mediaItems } = this.props;
        return (
            <div className={classes.root}>
                <Grid container spacing={1}>
                    {mediaItems && mediaItems.map(this.renderMediaItem)}
                </Grid>

          </div>
        );
    }
}

export default withStyles(styles)(MediaCardList);