import React, {useState} from 'react';
import {connect} from 'react-redux';
import {
    withStyles,
    Box,
    Grid
} from '@material-ui/core';
import UploadedImageCard from '../UserParticipation/UploadStep/UploadedImageCard.component';
import UploadedVideoCard from '../UserParticipation/UploadStep/UploadedVideoCard.component';
import MediaDialog from '../../../Media/MediaDialog.component';

const participationDetailsStyles = () => ({
    compClassTitle: {
        marginBottom: 10,
    },
    container: {
        width: '100%',
        marginBottom: 16,
        marginTop: 16,
    },
    imgContainer: {
        flexBasis: '50%',
    }
});

const ParticipationDetails = connect((state) => ({classesByID: state.metaData.classesByID}))(
    withStyles(participationDetailsStyles)(
        ({classParticipations, competitionClasses, classesByID, classes}) => {
            const [showImageInDialog, setShowImageInDialog] = useState(null);
            return competitionClasses.map(competitionClass => {
                const classID = competitionClass.classID;
                const participationsInClass = classParticipations.filter(cp => cp.competitionClassID === competitionClass.competitionClassID);
                return (
                    <div className={classes.container}>
                        <Box className={classes.compClassTitle}>
                            {competitionClass.theme && `Opplastinger i ${classesByID[classID].name} - ${competitionClass.theme}`}
                            {!competitionClass.theme && `Opplastinger i ${classesByID[classID].name}`}

                        </Box>
                        <Grid container spacing={2}>
                            {participationsInClass.length > 0 ?
                                participationsInClass.map(part =>
                                    <Grid item key={part.classParticipationID} xs={12} sm={6}>
                                        {part?.videoUrl
                                            ?
                                            <UploadedVideoCard
                                                name={part.name}
                                                imgRef={part.thumbnailUrl}
                                                imgID={part.classParticipationID}
                                                imgNumber={part.classParticipationNumber}
                                                noDelete={true}
                                                videoUrl={part.videoUrl}
                                                onImageClick={() => setShowImageInDialog(part)}
                                            />
                                            :
                                            <UploadedImageCard
                                                name={part.name}
                                                imgRef={part.thumbnailUrl}
                                                imgID={part.classParticipationID}
                                                imgNumber={part.classParticipationNumber}
                                                noDelete={true}
                                                onImageClick={() => setShowImageInDialog(part)}
                                            />
                                        }
                                    </Grid>
                                ) :
                                <Box fontStyle="italic" style={{marginLeft: 10}}>
                                    Ingen opplastinger i denne klassen
                                </Box>
                            }
                        </Grid>
                        {!!showImageInDialog && <MediaDialog mediaItem={showImageInDialog} isOpen={!!showImageInDialog}
                                                             onClose={() => setShowImageInDialog(null)}/>}
                    </div>
                );
            });
        }));

export default ParticipationDetails;