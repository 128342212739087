import { simplePromiseAction, actionCreator } from '../../../../Utils/actions.utils';
import { competitionResultService } from '../../../Services/competitionResultService';
import { getCompetition } from '../Competition.actions';

export const actionTypes = {
    START_LOAD_RESULT_PAGE: 'START_LOAD_RESULT_PAGE',
    FINISHED_LOADING_RESULT_PAGE: 'FINISHED_LOADING_RESULT_PAGE',
    START_REQUEST_RESULTS: 'START_REQUEST_RESULTS',
    REQUEST_RESULTS_FINISHED: 'REQUEST_RESULTS_FINISHED',
    REQUEST_RESULT: 'REQUEST_RESULT',
    REQUEST_RESULT_SUCCESS: 'REQUEST_RESULT_SUCCESS',
    REQUEST_RESULT_FAILED: 'REQUEST_RESULT_FAILED',
    REQUEST_ORGANIZATION_RESULT: 'REQUEST_ORGANIZATION_RESULT',
    REQUEST_ORGANIZATION_RESULT_SUCCESS: 'REQUEST_ORGANIZATION_RESULT_SUCCESS',
    REQUEST_ORGANIZATION_RESULT_FAILED: 'REQUEST_ORGANIZATION_RESULT_FAILED',
}

const requestResult = (competitionID) => (dispatch) => {
    dispatch(actionCreator(actionTypes.START_REQUEST_RESULTS)({}));
    return dispatch(requestAllResults(competitionID))
        .then(() => dispatch(requestOrganizationResult(competitionID))
            .then(() => dispatch(actionCreator(actionTypes.REQUEST_RESULTS_FINISHED)({}))));
};

export const finishedLoadingResultPage = () => actionCreator(actionTypes.FINISHED_LOADING_RESULT_PAGE)({ })

export const loadResultPage = (competitionID) => (dispatch) => {
    dispatch(actionCreator(actionTypes.START_LOAD_RESULT_PAGE)({}));
    return dispatch(getCompetition(competitionID))
        .then((action) => {
            if(!action.payload.error) {
                return dispatch(requestResult(competitionID)).then(() => dispatch(finishedLoadingResultPage()))
            }
            return dispatch(finishedLoadingResultPage());
        });
}

const requestAllResults = (competitionID) => simplePromiseAction(
    () => competitionResultService.getAllResults(competitionID),
    actionTypes.REQUEST_RESULT,
    actionTypes.REQUEST_RESULT_SUCCESS,
    actionTypes.REQUEST_RESULT_FAILED
);


const requestOrganizationResult = (competitionID) => simplePromiseAction(
    () => competitionResultService.getOrganizationResults(competitionID),
    actionTypes.REQUEST_ORGANIZATION_RESULT,
    actionTypes.REQUEST_ORGANIZATION_RESULT_SUCCESS,
    actionTypes.REQUEST_ORGANIZATION_RESULT_FAILED
)