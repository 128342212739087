import React from 'react';
import { withStyles, ListItem, ListItemText, ListItemIcon, Paper } from '@material-ui/core';


const getStyles = () => ({
    icon: {
        padding: 2,
        borderRadius: 5,
    },
    text: {
        marginLeft: 8,
    },
    noIcon: {
        height: 64,
        width: 64,
        padding: 2,
        borderRadius: 5,
    },
    paper: {
        marginBottom: 8,
        paddingRight: 8,
    },
    listItem: {
        padding: 0,
    },
});

const distinctionItem = (props) => {
    const { distinctionItem, distinction, classes } = props;
    const config = distinctionItem.displaySettings;
    return (
        <Paper className={classes.paper} style={{backgroundColor: config && config.backgroundColor ? config.backgroundColor: ''}} elevation={3}>
            <ListItem className={classes.listItem}>
                {config && config.iconUrl &&
                    <ListItemIcon className={classes.icon} style={{backgroundColor: config && config.iconBackgroundColor ? config.iconBackgroundColor: ''}}>
                        <img src={config.iconUrl} alt="Ikon" height="64" width="64" />
                    </ListItemIcon>
                }
                {(!config || !config.iconUrl) &&
                    <div className={classes.noIcon} style={{backgroundColor: config && config.iconBackgroundColor ? config.iconBackgroundColor: ''}}/>
                }
                <ListItemText
                    className={classes.text}
                    style={{color: config && config.textColor ? config.textColor: ''}}
                    primary={distinctionItem.name}
                    secondary={`Tildelt: ${distinction.archievedYear}`}
                    secondaryTypographyProps={{style: {color: config && config.textColor ? config.textColor: ''}}}
                />
            </ListItem>
        </Paper>


    )
}

export default withStyles(getStyles)(distinctionItem);