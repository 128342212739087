import {
    actionTypes
} from '../components/Pages/Competition/Report/Report.actions';


const initState = {
}

const reportReducer = (state = initState, action) => {
    switch(action.type) {
        case actionTypes.REQUEST_FINISHED_COMPETITIONS_REPORT:
            console.log('Requesting all finished competitions');
            return {
                ...state,
                searchText: action.meta.searchText,
                loading: true,
            };

        case actionTypes.REQUEST_FINISHED_COMPETITIONS_SUCCESS_REPORT:
            console.log('Requesting all finished competitions success');
            return {
                ...state,
                loading: false,
                data: action.payload.response.result,
                paging: action.payload.response.pagingSettings,
                competitions: action.payload.response.result,
            };

        case actionTypes.REQUEST_FINISHED_COMPETITIONS_FAILED_REPORT:
            console.log('Requesting all finished competitions falied');
            return {
                ...state,
                loading: false,
                pageLoading: false,
                error: action.payload.error.message,
            };
        default:
            return state;
    }
}

export default reportReducer;



