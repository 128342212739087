import Api from './apiService';

export const userService = {
    login,
    logout,
    
};

function login(username, password) {
    return Api.post('login', { username, password }).then(result => {
        localStorage.setItem('user', JSON.stringify(result));
    });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}