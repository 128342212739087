import { connect } from 'react-redux';
import UserParticipation from './UserParticipation.component';
import { uploadImage, deleteImage, requestProcessParticipationPayment, setActiveStep, setPaymentFormDialogOpen, loadParticipationPage } from './UserParticipation.actions';
import withDataLoader from '../../../DataLoader/withDataLoader';
import { pushPreviousOrDefault } from '../../../../Utils/router.utils';

const mapStateToProps = (state) => ({
    competitionData: state.competition.selectedComp,
    dataLoading: !state.competition.selectedComp || !state.competitionParticipation || state.competitionParticipation.participationPageLoading,
    dataLoadErrorMessage: state.competition.loadSelectedCompErrorMessage || state.competitionParticipation.errorMessage,
    classesByID: state.metaData.classesByID,
    participation: state.competitionParticipation.participation,
    selectedOrganization: state.competitionParticipation.selectedOrganization,
    userProfile: state.userProfile.data,
    organizationsByID: state.clubs.orgsByID,
    activeStep: state.competitionParticipation.activeStep,
    processingPayment: state.competitionParticipation.processingPayment,
    paymentError: state.competitionParticipation.processPaymentError,
    paymentFormDialogOpen: state.competitionParticipation.paymentFormDialogOpen,
    previousLocation: state.location.previous,
});

const mapDispatchToProps = (dispatch, props) => ({
    uploadImage: (competitionID, competitionClassID, image, imagePreviewUrl, name, videoUrl) => {
        dispatch(uploadImage(competitionID, competitionClassID, image, imagePreviewUrl, name, videoUrl));
    },
    deleteImage: (imageID, competitionClassID, competitionID) => {
        dispatch(deleteImage(imageID, competitionClassID, competitionID));
    },
    processPayment: (paymentToken, competitionID) => {
        dispatch(requestProcessParticipationPayment(paymentToken, competitionID));
    },
    setActiveStep: (step) => {
        dispatch(setActiveStep(step));
    },
    setPaymentFormDialogOpen: (open) => {
        dispatch(setPaymentFormDialogOpen(open));
    },
    loadParticipation: (urlParams, queryParams) => {
        dispatch(loadParticipationPage(urlParams.id));
    },
    onBackClick: (previousLocation) => {
        dispatch(pushPreviousOrDefault(previousLocation, "/competitions"));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(withDataLoader("loadParticipation")(UserParticipation));