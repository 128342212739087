import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import getCompetitionType from '../../../../competition/getCompetitionType';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import HomeIcon from '@material-ui/icons/Home';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import PhotoIcon from '@material-ui/icons/Photo';
import MoneyIcon from '@material-ui/icons/Money';
import InfoIcon from '@material-ui/icons/Info';
import moment from "moment";
import "moment/locale/nb";
import isCompetitionEvaluationType from '../../../../competition/isCompetitionEvaluationType';
import { Button } from '@material-ui/core';
import downloadFile from '../../../../Utils/download/downloadFile';
import DownloadDialog from '../../../DownloadDialog/DownloadDialog.component';

moment.locale("nb");

const styles = theme => ({
    sectionText: {
        marginTop: 16,
    },
    chip: {
        margin: 8,
    },
    paper: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        marginBottom: 8,
    },
    avatar: {
        backgroundColor: "#001789",
    },
    tablePaper: {
        padding: 0,
        marginBottom: 8,
    },
    metadata: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    metadataPaper: {
        marginRight: 8,
        marginBottom: 8,
        flex: 'auto',
    },
});

class CompetitionDetails extends React.Component {
    state = {
        downloadDialogOpen: false,
    }

    renderMetaData = () => {
        const { classes, competitionData } = this.props;
        const metaData = [
            {icon: <HomeIcon />, title: "Arrangør", data: competitionData.organizationName},
            {icon: <EventAvailableIcon />, title: "Start", data: moment(competitionData.submissionStart).format('LLL')},
            {icon: <EventBusyIcon />, title: "Slutt", data: moment(competitionData.submissionEnd).format('LLL')},
            {icon: <PhotoIcon />, title: "Maks antall innleveringer", data: competitionData.maxItems},
        ];

        return(
            <List className={classes.metadata}>
                {metaData.map((data, index) => 
                    <Paper elevation={1} key={index} className={classes.metadataPaper}>
                        <ListItem>
                            <ListItemIcon>
                                {data.icon}
                            </ListItemIcon>
                            <ListItemText
                                primary={data.title}
                                secondary={data.data}
                            />
                        </ListItem>
                    </Paper>
                )}
            </List>
        );
    }

    handleDownloadFile = () => {
        this.setState({ downloadDialogOpen: false});
        const { competitionID } = this.props.competitionData;
        const url = `competitions/${competitionID}/downloadParticipations`;
        downloadFile(url);
    }

    handleOpenDownloadDialog = () => {
        this.setState({ downloadDialogOpen: true });
    }

    handleDownloadCancel = () => {
        this.setState({ downloadDialogOpen: false});
    }

    renderClassSection = () => {
        const { classes, competitionData, classesByID } = this.props;
        return (
            <div>
                <Typography className={classes.sectionText} style={{marginBottom: 8}} variant="h6">
                    Klasser
                </Typography>

                <Paper className={classes.tablePaper} elevation={1}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Klasse</TableCell>
                                <TableCell align="right">Tema</TableCell>
                                <TableCell align="right">Maks antall bilder</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {competitionData.competitionClasses.map((singleClass, key) => (
                                <TableRow key={key}>
                                    <TableCell component="th" scope="row">
                                        {classesByID[singleClass.classID].name}
                                    </TableCell>
                                    <TableCell align="right">{singleClass.theme}</TableCell>
                                    <TableCell align="right">{singleClass.maxItems}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Paper>
            </div>
        );
    }

    render() {
        const { classes, competitionData } = this.props;
        return (
            <div>
                <Paper className={classes.paper} elevation={1}>
                    <div>
                        <div className={classes.name}>
                            <Typography variant="h5">
                                {competitionData.name}
                            </Typography>
                            <Typography variant="subtitle1" color="textSecondary">
                                {getCompetitionType(competitionData)}
                            </Typography>
                        </div>
                        {competitionData.isJuryLeader && <Button onClick={this.handleOpenDownloadDialog} variant="outlined">Last ned alle bilder</Button>}
                    </div>

                    {this.renderMetaData()}
                    <div>
                        <Typography variant="h6">
                            Beskrivelse
                        </Typography>
                        <List style={{paddingTop: 0, paddingBottom: 0}}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar className={classes.avatar}>
                                        <MoneyIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="Pris" secondary={!competitionData.participationPrice || competitionData.participationPrice === 0 ? 'Gratis' : 'kr ' + competitionData.participationPrice} />
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar className={classes.avatar}>
                                        <InfoIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="Beskrivelse" secondary={competitionData.description} />
                            </ListItem>
                        </List>
                    </div>
                    {!isCompetitionEvaluationType(competitionData) && this.renderClassSection()}
                </Paper>
                {this.state.downloadDialogOpen && <DownloadDialog onConfirm={this.handleDownloadFile} onCancel={this.handleDownloadCancel} open fileCount={competitionData.participationCount} avgFileSize={2} />}
            </div>
        );
    }
}

CompetitionDetails.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CompetitionDetails);