import React from 'react';
import classNames from 'classnames';
import {
    withStyles,
    Slide,
    Dialog,
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    CircularProgress
} from '@material-ui/core';
import { Close } from '@material-ui/icons';



const getStyles = ({
    image: {
        width: "100%",
        height: "100%",
        objectFit: "contain",
    },
    imageLoading: {
        display: 'none',
    },    
    title: {
        flex: 1,
        color: "white",
    },
    progressContainer: {
        textAlign: 'center',
        paddingTop: '45%',
    },
    imageContainer: {
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        paddingTop: 68,
        paddingBottom: 4,
    }
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class MediaDialog extends React.Component {
    state = {
        imageReady: false,
    }

    handleImageLoaded = () => {
        this.setState({ imageReady: true });
    }

    render() {
        const { classes, mediaItem, isOpen, onClose } = this.props;
        const { imageReady } = this.state;
        return (
            <Dialog fullScreen open={isOpen} onClose={onClose} TransitionComponent={Transition}>
                <AppBar>
                    <Toolbar>
                        <Typography variant="h6" className={classes.title}>
                            {mediaItem.name ? mediaItem.name : mediaItem.itemName}
                        </Typography>
                        <IconButton edge="end" color="inherit" onClick={onClose} aria-label="Lukk">
                            <Close />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <div className={classes.imageContainer}>
                    {!imageReady && 
                        <div className={classes.progressContainer}>
                            {/*TODO: Find better way of centering spinner*/}
                            <CircularProgress />
                        </div>
                    }
                    <img
                        onLoad={this.handleImageLoaded}
                        className={classNames(classes.image, { [classes.imageLoading]: !imageReady })}
                        src={mediaItem.imageUrl}
                        alt=""
                    />
                </div>
            </Dialog>
        );
    }
}


export default withStyles(getStyles)(MediaDialog);
