

import React, { useEffect } from "react";

import CompetitionForm from "../CompetitionForm/CompetitionForm.container";
import { Typography } from "@material-ui/core";
import Error from "../../../Error/Error.component";


const CreateCompetition = (props) => {
    const { initForm, isAnyAdmin, ...passOnProps } = props;

    useEffect(() => {
        props.initForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if(!isAnyAdmin) {
        return (<Error errorMessage="Du har ikke tilgang til å opprette en konkurranse"/>);
    }

    return (
        <CompetitionForm
            header={
                <Typography variant="h5" gutterBottom>
                    Opprett ny
                </Typography>
            }
            {...passOnProps}
        />
    );
};

export default CreateCompetition;