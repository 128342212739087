export const awards = [
    { displayName: "1", formDisplayName: "Gull", value: 1, code: "First" },
    { displayName: "2", formDisplayName: "Sølv", value: 2, code: "Second" },
    { displayName: "3", formDisplayName: "Bronse", value: 3, code: "Third" },
    { displayName: "Hederlig omtale", formDisplayName: "Hederlig omtale", value: 4, code: "HonorableMention" },
    { displayName: "Antatt", formDisplayName: "Antatt", value: 5, code: "Assumed" },
]

export function getAwardByValue(value) {
    if(value) {
        return awards.find(v => v.value === value);
    }
    return null;
}