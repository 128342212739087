import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CompetitionList from './CompetitionsList/CompetitionList.container';
import Report from './Report/Report.container';
import competitionListKeys from './competitionListKeys';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import AddIcon from '@material-ui/icons/AddCircle';
import DocIcon from '@material-ui/icons/Description';
import { withRouter } from 'react-router-dom';
import { Alert } from '@material-ui/lab';
import moment from 'moment';
import "moment/locale/nb";

moment.locale("nb");

const styles = theme => ({
    root: {
        padding: 10,
    },
});

class Competition extends React.Component {

    handleClickNew = () => {
        this.props.push("/competitions/new");
    }

    handleClickReport = () => {
        this.props.fetchCompsForReport();
        this.props.setViewMode("report");
    }

    isActiveClub = () => {
        const mainClub = this.props.orgs.find(org => org.organizationID === this.props.mainOrganizationID)
        if(!mainClub) return false;
        if (mainClub.organizationExpireDate < moment().format("YYYY-MM-DD")) {
            return false;
        } else {
            return true;
        }
    }

    getMainClubName = () => {
        const mainClub = this.props.orgs.find(org => org.organizationID === this.props.mainOrganizationID);
        return mainClub?.organizationName;
    }

    renderCompetitionLists = () => {
        const { classes, isAnyAdmin } = this.props;
        return (
            <div className={classes.root}>
                {!this.isActiveClub() &&
                    <Alert severity="warning">
                        Din hovedklubb "{this.getMainClubName()} har status inaktiv, du kan derfor ikke delta i noen konkurranser nå.
                </Alert>}

                {isAnyAdmin && this.isActiveClub() &&
                    <ButtonGroup variant="outlined" color="primary" style={{ marginBottom: 10 }} fullWidth>
                        <Button onClick={this.handleClickNew}>
                            <AddIcon style={{ marginRight: 8 }} />
                            Opprett ny
                        </Button>
                        <Button onClick={this.handleClickReport}>
                            <DocIcon style={{ marginRight: 8 }} />
                            Lag rapport
                        </Button>
                    </ButtonGroup>
                }
                <CompetitionList
                    competitionListKey={competitionListKeys.ACTIVE}
                    headerText='Aktive konkurranser'
                    noItemsText='Ingen aktive konkurranser'
                    isAnyAdmin={isAnyAdmin}
                    clubIsActive={this.isActiveClub()}
                />
                <CompetitionList
                    competitionListKey={competitionListKeys.UPCOMMING}
                    headerText='Kommende konkurranser'
                    noItemsText='Ingen kommende konkurranser'
                    clubIsActive={this.isActiveClub()}
                />
                <CompetitionList
                    competitionListKey={competitionListKeys.FINISHED}
                    headerText='Fullførte konkurranser'
                    noItemsText='Ingen fullførte konkurranser'
                    usePaging
                    onLoadMoreClick={this.props.onLoadMoreFinishedCompetitions}
                    onSearch={this.props.onSearchFinishedCompetitions}
                    isAnyAdmin={isAnyAdmin}
                />
            </div>
        );
    }

    render() {
        const { viewMode } = this.props;

        return (
            <div>
                {viewMode === "compList" && this.renderCompetitionLists()}
                {viewMode === "report" &&
                    <Report />
                }
            </div>
        );
    }
}

export default withStyles(styles)(withRouter(Competition));