import { actionTypes } from '../components/Pages/Login/Login.actions';

const initState = {

}

const authReducer = (state = initState, action) => {
    switch(action.type) {
        case actionTypes.REQUEST_LOGIN:
            console.log('Is logging in');
            return {
                ...state,
                errorMessage: null,
                isLoggingIn: true,
                isLoggedIn: false,
            };
        case actionTypes.LOGIN_SUCCESS:
            console.log('Login success');
            return {
                ...state,
                isLoggingIn: false,
                isLoggedIn: true,
            };
        case actionTypes.LOGIN_FAILED:
            console.log('Login failed');
            return {
                ...state,
                isLoggingIn: false,
                isLoggedIn: false,
                errorMessage: action.payload.error.message,
            };
        case actionTypes.LOGOUT:
            console.log('Logged out');
            return {};
        default:
            return state;
    }
}

export default authReducer;