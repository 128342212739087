import { actionTypes } from '../components/Pages/Competition/OrganizationParticipation/OrganizationParticipation.actions';
import { LOCATION_CHANGE } from 'connected-react-router';

const initState = {
    selectedOrgParticipations: {},
    amtOfParticipations: 0,
}

const countParticipations = (participations) => {
    let count = 0;
    Object.keys(participations).forEach(personID => {
        Object.keys(participations[personID]).forEach(() => {
            count++;
        });
    });

    return count;
}

const convertSelectedFromServerToClient = (allParticipations, selectedOrgParticipations) => {

    const selected = selectedOrgParticipations.reduce((accSelected, participationID) => {
        const person = allParticipations.find((p) => p.classParticipations.some((cp) => cp.classParticipationID === participationID));
        if(person) {
            accSelected[person.personID] = {
                ...accSelected[person.personID],
                [participationID]: { participationID },
            };
        }
        return accSelected;
    }, {});

    return selected;
}

const competitionOrgParticipationReducer = (state = initState, action) => {
    switch(action.type) {
        case LOCATION_CHANGE:
            return {
                pageLoading: true,
            }
        case actionTypes.START_LOAD_ORG_PARTICIPATION_PAGE:
            return {
                pageLoading: true,
            }
        case actionTypes.FINISHED_LOADING_ORG_PARTICIPATION_PAGE:
            return {
                ...state,
                pageLoading: false,
            }
        case actionTypes.REQUEST_COLLECTIVE_ORG_PARTICIPATION:
            console.log("Fetching collective organization participation");
            return {
                ...state,
                fetchCollectiveOrgParticipationErrorMessage: null,
                isFetchingCollectiveOrgParticipations: true,
            };
        case actionTypes.REQUEST_COLLECTIVE_ORG_PARTICIPATION_SUCCES:
            console.log("Fetching collective organization participation success");
            const selected = convertSelectedFromServerToClient(state.allOrgParticipations, action.payload.response);
            return {
                ...state,
                isCollectiveFetchingOrgParticipations: false,
                isAlreadyParticipating: action.payload.response && action.payload.response.length > 0,
                selectedOrgParticipations: selected,
            };
        case actionTypes.REQUEST_COLLECTIVE_ORG_PARTICIPATION_FAILED:
            console.log("Fetchung collective organization participation failed");
            return {
                ...state,
                fetchCollectiveOrgParticipationErrorMessage: action.payload.error.message,
                isFetchingCollectiveOrgParticipations: false,
            };
        case actionTypes.ADD_ORG_PARTICIPATION:
            console.log('Adding org participation');
            const {personID, participationID} = action.payload;
            let allParticipations = {...state.selectedOrgParticipations};
            allParticipations[personID] = {...allParticipations[personID], [participationID]: {participationID}};
            let amtOfParticipationsAdd = countParticipations(allParticipations);
            return {
                ...state,
                hasChanges: true,
                selectedOrgParticipations: allParticipations,
                amtOfParticipations: amtOfParticipationsAdd,
            };
        case actionTypes.REMOVE_ORG_PARTICIPATION:
            console.log('Removing org participation');
            let allParticipationsRemove = {...state.selectedOrgParticipations};
            delete allParticipationsRemove[action.payload.personID][action.payload.participationID];
            let amtOfParticipationsRemove = countParticipations(allParticipationsRemove);
            return {
                ...state,
                hasChanges: true,
                selectedOrgParticipations: allParticipationsRemove,
                amtOfParticipations: amtOfParticipationsRemove,
            };
        case actionTypes.REQUEST_ORG_PARTICIPATION:
            console.log("Fetching organization participation");
            return {
                ...state,
                fetchOrgParticipationErrorMessage: null,
                isFetchingOrgParticipations: true,
            };
        case actionTypes.SUCCESS_ORG_PARTICIPATION:
            console.log("Fetching organization participation success");
            return {
                ...state,
                isFetchingOrgParticipations: false,
                allOrgParticipations: action.payload.response,
                selectedOrganization: action.payload.orgID,
            };
        case actionTypes.FAILED_ORG_PARTICIPATION:
            console.log("Fetchung organization participation failed");
            return {
                ...state,
                fetchOrgParticipationErrorMessage: action.payload.error.message,
                isFetchingOrgParticipations: false,
            };
        case actionTypes.REQUEST_SUBMIT_ORG_PARTICIPATION:
            console.log("Posting organization participation");
            return {
                ...state,
                submitErrorMessage: null,
                isSubmitting: true,
            };
        case actionTypes.SUCCESS_SUBMIT_ORG_PARTICIPATION:
            console.log("Posting organization participation success");
            return {
                ...state,
                hasChanges: false,
                isSubmitting: false,
                selectedOrgParticipations: {},
                amtOfParticipations: 0,
            };
        case actionTypes.FAILED_SUBMIT_ORG_PARTICIPATION:
            console.log("Posting organization participation failed");
            return {
                ...state,
                submitErrorMessage: action.payload.error.message,
                isSubmitting: false,
            };
        case actionTypes.CLEAR_ORG_PARTICIPATION:
            console.log("Clearing organization participations");
            return {
                ...state,
                selectedOrgParticipations: {},
                amtOfParticipations: 0,
            };
        default:
            return state;
    }
}

export default competitionOrgParticipationReducer;