import Api from './apiService';

export const competitionScoringService = {
    getMyStatus,
    getJuryStatus,
    getMyClassParticipationScoring,
    getTotalClassParticipationScoring,
    saveScore,
    getEvaluations,
};

function getMyStatus(competitionID) {
    return Api.get(`competitionScoring/${competitionID}/myStatus`);
}

function getJuryStatus(competitionID) {
    return Api.get(`competitionScoring/${competitionID}/juryStatus`);
}

function getMyClassParticipationScoring(competitionID, competitionClassID) {
    return Api.get(`competitionScoring/${competitionID}/classParticipationScoring?competitionClassID=${competitionClassID}`);
}

function getTotalClassParticipationScoring(competitionID, competitionClassID) {
    return Api.get(`competitionScoring/${competitionID}/totalClassParticipationScoring?competitionClassID=${competitionClassID}`);
}

function saveScore(competitionID, classParticipationID, score, comment) {
    return Api.post(`competitionScoring/${competitionID}/classParticipationScoring/${classParticipationID}/score`, { score: score, comment: comment });
}

function getEvaluations(competitionID) {
    return Api.get(`competitionScoring/${competitionID}/evaluations`);
}