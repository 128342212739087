import { simplePromiseAction, actionCreator } from '../../../../Utils/actions.utils';
import { competitionService } from '../../../Services/competitionService';
import { loadCompetitionData } from '../Competition.actions';

export const actionTypes = {
    REQUEST_SET_PATRONATE: 'REQUEST_SET_PATRONATE',
    REQUEST_SET_PATRONATE_SUCCESS: 'REQUEST_SET_PATRONATE_SUCCESS',
    REQUEST_SET_PATRONATE_FAILED: 'REQUEST_SET_PATRONATE_FAILED',
    REQUEST_DELETE_PATRONATE: 'REQUEST_DELETE_PATRONATE',
    REQUEST_DELETE_PATRONATE_SUCCESS: 'REQUEST_DELETE_PATRONATE_SUCCESS',
    REQUEST_DELETE_PATRONATE_FAILED: 'REQUEST_DELETE_PATRONATE_FAILED',
    REQUEST_FINISH_COMPETITION: 'REQUEST_FINISH_COMPETITION',
    FINISH_COMPETITION_SUCCESS: 'FINISH_COMPETITION_SUCCESS',
    FINISH_COMPETITION_FAILED: 'FINISH_COMPETITION_FAILED',
    REQUEST_UNFINISH_COMPETITION: "REQUEST_UNFINISH_COMPETITION",
    UNFINISH_COMPETITION_SUCCESS: "UNFINISH_COMPETITION_SUCCESS",
    UNFINISH_COMPETITION_FAILED: "UNFINISH_COMPETITION_FAILED",
    REQUEST_OPEN_AWARDING: "REQUEST_OPEN_AWARDING",
    OPEN_AWARDING_SUCCESS: "OPEN_AWARDING_SUCCESS",
    OPEN_AWARDING_FAILED: "OPEN_AWARDING_FAILED",
    REQUEST_OPEN_JURYING: "REQUEST_OPEN_JURYING",
    OPEN_JURYING_SUCCESS: "OPEN_JURYING_SUCCESS",
    OPEN_JURYING_FAILED: "OPEN_JURYING_FAILED",
}

export const setPatronate = (nsffPatronate, compID, competitionListKey) => simplePromiseAction(
    () => competitionService.setPatronate(nsffPatronate, compID),
    actionTypes.REQUEST_SET_PATRONATE,
    actionTypes.REQUEST_SET_PATRONATE_SUCCESS,
    actionTypes.REQUEST_SET_PATRONATE_FAILED,
    { nsffPatronate, compID, competitionListKey }
);

export const deletePatronate = (compID, competitionListKey) => simplePromiseAction(
    () => competitionService.deletePatronate(compID),
    actionTypes.REQUEST_DELETE_PATRONATE,
    actionTypes.REQUEST_DELETE_PATRONATE_SUCCESS,
    actionTypes.REQUEST_DELETE_PATRONATE_FAILED,
    { compID, competitionListKey }
);

export const finishCompetition = (competitionID) => (dispatch) => {
    dispatch(actionCreator(actionTypes.REQUEST_FINISH_COMPETITION)({ competitionID }));
    return competitionService.finishCompetition(competitionID).then(
        response => dispatch(loadCompetitionData()),
        error => dispatch(actionCreator(actionTypes.FINISH_COMPETITION_FAILED)({ error, competitionID })),
    )
}

export const unFinishCompetition = (competitionID) => (dispatch) => {
    dispatch(actionCreator(actionTypes.REQUEST_UNFINISH_COMPETITION)({ competitionID }));
    return competitionService.unFinishCompetition(competitionID).then(
        response => dispatch(loadCompetitionData()),
        error => dispatch(actionCreator(actionTypes.UNFINISH_COMPETITION_FAILED)({ error, competitionID }))
    )
}

export const openAwarding = (competitionID) => (dispatch) => {
    dispatch(actionCreator(actionTypes.REQUEST_OPEN_AWARDING)({ competitionID }));
    return competitionService.openAwarding(competitionID).then(
        response => dispatch(loadCompetitionData()),
        error => dispatch(actionCreator(actionTypes.OPEN_AWARDING_FAILED)({ error, competitionID }))
    )
}

export const openJurying = (competitionID) => (dispatch) => {
    dispatch(actionCreator(actionTypes.REQUEST_OPEN_JURYING)({ competitionID }));
    return competitionService.openJurying(competitionID).then(
        response => dispatch(loadCompetitionData()),
        error => dispatch(actionCreator(actionTypes.OPEN_JURYING_FAILED)({ error, competitionID }))
    )
}