import React from 'react';
import {
    withStyles,
    Button,
    Grid,
    Typography,
    Menu,
    MenuItem,
    IconButton,
} from '@material-ui/core';
import {
    Clear
} from '@material-ui/icons';
import TextField from '../../../Fields/TextField/TextField.component';
import withInternalChangeHandler from '../../../Fields/withInternalChangeHandler';
import generateUUID from '../../../../Utils/generateUUID';
import IntegerField from '../../../Fields/NumberField/IntegerField.component';

const FormTextField = withInternalChangeHandler()(TextField);
const FormIntegerField = withInternalChangeHandler()(IntegerField);

const styles = theme => ({
    formControl: {
        width: "100%"
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: theme.spacing(0.25),
        backgroundColor: "#efbb09",
    },
    iconButtonGridItem: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    }
});

class CompetitionClassSelection extends React.Component {
    state = {
        anchorEl: null
    }

    handleCompetitionClassChange = (name, event) => {
        const { id, value } = event.target;
        const newCompClasses = this.props.competitionClasses.reduce((accCompClasses, compClass) => {
            const compClassID = compClass.tempCompetitionClassID || compClass.competitionClassID;
            if(compClassID === id) {
                return [...accCompClasses, { ...compClass, [name]: value }];
            }
            return [...accCompClasses, compClass];
        },[]);
        this.props.onChange(newCompClasses);
    };

    handleRemoveClass = (id) => {
        const competitionClasses = this.props.competitionClasses.filter((cc => cc.tempCompetitionClassID !== id && cc.competitionClassID !== id));
        this.props.onChange(competitionClasses);
    }

    renderMenuItems = () => {
        const { classesByID } = this.props;

        return Object.keys(classesByID).reduce((accClassMenuItems, id) => {
            const currentClass = classesByID[id];
            if(currentClass.evaluationOnly){
                return accClassMenuItems;
            }
            return [
                ...accClassMenuItems,
                (
                    <MenuItem key={currentClass.classID} value={currentClass.classID} onClick={() => this.handleSelectClass(currentClass)}>
                        {currentClass.name}
                    </MenuItem>
                ),
            ]
        }, []);
    }

    handleSelectClass = (selectedClass) => {
        const competitionClasses = [
            ...this.props.competitionClasses,
            {
                classID: selectedClass.classID,
                className: selectedClass.name,
                tempCompetitionClassID: generateUUID(),
            },
        ];
        this.props.onChange(competitionClasses);
        this.setState({ anchorEl: null });
    }

    handleMenuButtonClick = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    }
    
    handleCloseMenu = () => {
        this.setState({ anchorEl: null });
    }


    renderCompetitionClasses = () => {
        const { competitionClasses, classesByID, classes } = this.props;

        return competitionClasses && competitionClasses.map(cc => {
            const id = cc.tempCompetitionClassID || cc.competitionClassID;
            const currentClass = classesByID[cc.classID];
            return (
                <div key={id}>
                    <Typography style={{ marginTop: 8 }} variant="subtitle1">
                        {currentClass.name}
                    </Typography>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={7} md={7}>
                            <FormTextField
                                required
                                id={id}
                                label="Tema"
                                onBlur={(e) => this.handleCompetitionClassChange("theme", e)}
                                style={{ margin: 8 }}
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                value={cc.theme || ""}
                                disabled={this.props.disabled}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                            <FormIntegerField
                                required
                                id={id}
                                label="Maks innleveringer"
                                onBlur={(e) => this.handleCompetitionClassChange("maxItems", e)}
                                style={{ margin: 8 }}
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                min={1}
                                value={cc.maxItems || ""}
                                disabled={this.props.disabled}
                            />
                        </Grid>
                        <Grid item xs={12} sm={1} md={1} className={classes.iconButtonGridItem}>
                            <div>
                                <IconButton onClick={() => this.handleRemoveClass(id)} disabled={this.props.disabled}>
                                    <Clear />
                                </IconButton>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            );
        });
    }

    render() {
        const { anchorEl } = this.state;

        return (
            <div>
                <Typography style={{ marginTop: 24 }} variant="h5" gutterBottom>
                    Klasseoppsett *
                </Typography>
                <Button
                    variant="outlined"
                    color="primary"
                    aria-controls="class-menu"
                    onClick={this.handleMenuButtonClick}
                    aria-haspopup="true"
                    disabled={this.props.disabled}
                >
                    Legg til klasse
                </Button>
                <Menu
                    id="class-menu"
                    anchorEl={anchorEl}
                    open={!!anchorEl}
                    onClose={this.handleCloseMenu}
                >
                    {this.renderMenuItems()}
                </Menu>

                <div>
                    {this.renderCompetitionClasses()}
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(CompetitionClassSelection);