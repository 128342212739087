import { LOCATION_CHANGE } from 'connected-react-router';


const initState = {

}

const locationReducer = (state = initState, action) => {
    switch(action.type) {
        case LOCATION_CHANGE:
            return {
                ...state,
                previous: state.current,
                current: action.payload.location,
            };
        default:
            return state;
    }
}

export default locationReducer;



