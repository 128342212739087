import { simplePromiseAction, actionCreator } from '../../../../Utils/actions.utils';
import { importResultsService } from '../../../Services/importResultsService';

export const actionTypes = {
    REQUEST_UPLOAD: 'REQUEST_UPLOAD',
    REQUEST_UPLOAD_SUCCESS: 'REQUEST_UPLOAD_SUCCESS',
    REQUEST_UPLOAD_FAILED: 'REQUEST_UPLOAD_FAILED',
    REQUEST_SAVE_UPLOAD: 'REQUEST_SAVE_UPLOAD',
    REQUEST_SAVE_UPLOAD_SUCCESS: 'REQUEST_SAVE_UPLOAD_SUCCESS',
    REQUEST_SAVE_UPLOAD_FAILED: 'REQUEST_SAVE_UPLOAD_FAILED',
    CLEAR_UPLOADED_RESULTS: 'CLEAR_UPLOADED_RESULTS',
}

export const requestUploadFile = (personID, file, fileName) => {
    const metadata = { personID, fileName };
    return simplePromiseAction(
        () => importResultsService.uploadFile(personID, file),
        actionTypes.REQUEST_UPLOAD,
        actionTypes.REQUEST_UPLOAD_SUCCESS,
        actionTypes.REQUEST_UPLOAD_FAILED,
        metadata
    );
}

export const requestSaveUploadedFile = (personID, results) => {
    const metadata = { results };
    return simplePromiseAction(
        () => importResultsService.saveUploadedResults(personID, results),
        actionTypes.REQUEST_SAVE_UPLOAD,
        actionTypes.REQUEST_SAVE_UPLOAD_SUCCESS,
        actionTypes.REQUEST_SAVE_UPLOAD_FAILED,
        metadata
    );
}

export const clearUploadedResults = () => actionCreator(actionTypes.CLEAR_UPLOADED_RESULTS)({});