import { simplePromiseAction, actionCreator } from '../../../../Utils/actions.utils';
import { competitionScoringService } from '../../../Services/competitionScoringService';
import { actionTypes as resultActionTypes, finishedLoadingResultPage } from '../Result/Result.actions';
import { getCompetition } from '../Competition.actions';

export const actionTypes = {
    REQUEST_EVALUATIONS: 'REQUEST_EVALUATIONS',
    REQUEST_EVALUATIONS_SUCCESS: 'REQUEST_EVALUATIONS_SUCCESS',
    REQUEST_EVALUATIONS_FAILED: 'REQUEST_EVALUATIONS_FAILED',
}

const requestEvaluations = (competitionID) => simplePromiseAction(
    () => competitionScoringService.getEvaluations(competitionID),
    actionTypes.REQUEST_EVALUATIONS,
    actionTypes.REQUEST_EVALUATIONS_SUCCESS,
    actionTypes.REQUEST_EVALUATIONS_FAILED
);
export const loadEvaluationPage = (competitionID) =>
    (dispatch) => {
        dispatch(actionCreator(resultActionTypes.START_LOAD_RESULT_PAGE)({}));
        return dispatch(getCompetition(competitionID))
            .then((action) => {
                if(!action.payload.error) {
                    return dispatch(requestEvaluations(competitionID)).then(() => dispatch(finishedLoadingResultPage()))
                }
                return dispatch(finishedLoadingResultPage());
            });
    }

