import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import UploadImageDialog from './UploadImageDialog.component';
import UploadVideoDialog from './UploadVideoDialog.component';
import UploadedImageCard from './UploadedImageCard.component';
import UploadedVideoCard from './UploadedVideoCard.component';
import {Avatar, List, ListItem, ListItemAvatar, ListItemText} from "@material-ui/core";
import {Info as InfoIcon} from "@material-ui/icons";

const styles = theme => ({
    root: {
        width: '100%',
        margin: 8,
    },
    summary: {
        backgroundColor: "#ebba14",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    imgLimit: {
        color: "white",
        backgroundColor: "#001789",
        borderRadius: 100,
        padding: 5,
    },
    icon: {
        marginLeft: 8,
    },
    buttonContainer: {
        textAlign: "center",
        marginTop: 16,
    },
    avatar: {
        backgroundColor: "#001789",
    },
});

class ControlledExpansionPanels extends React.Component {
    state = {
        dialogOpen: false,
        dialogOpenVideo: false,
    };

    componentDidMount = () => {
        if(this.props.competitionClasses && this.props.competitionClasses.length > 0 && !this.props.selectedCompetitionClassID) {
            this.props.setSelectedClassID(this.props.competitionClasses[0].competitionClassID); 
        }
    };

    handleChange = competitionClassID => (event, selectedCompetitionClassID) => {
        this.props.setSelectedClassID(competitionClassID);
    };

    handleOpen = (dialogName) => {
        this.setState({
            [dialogName]: true,
        });
    };

    handleClose = () => {
        this.setState({
            dialogOpen: false,
            dialogOpenVideo: false,
        });
    };

    handleUpload = (competitionClassID, name, img, imagePreviewUrl) => {
        this.props.uploadImage(this.props.competitionID, competitionClassID, img, imagePreviewUrl, name);
    };

    handleUploadVideo = (competitionClassID, name, videoUrl, img, imagePreviewUrl) => {
        this.props.uploadImage(this.props.competitionID, competitionClassID, img, imagePreviewUrl, name, videoUrl);
    };

    handleDelete = (imageID) => {
        this.props.deleteImage(imageID, this.props.selectedCompetitionClassID, this.props.competitionID);
    };


    uploadDisabled = (classParticipations, competitionClass) => {
        return (competitionClass.maxItems && classParticipations && 
            classParticipations.length >= competitionClass.maxItems) ||
            this.calcTotalClassParticipations() >= this.props.competitionMaxItems;
    }

    calcTotalClassParticipations = () => {
        const { participation } = this.props;
        let amt = 0;
        
        Object.keys(participation.classParticipations).forEach(classID => {
            participation.classParticipations[classID].forEach(() => {
                amt++;
            });
        });
        return amt;
    }

    renderPhotoClass = (classParticipations, competitionClass) => {
        const { classes, classesByID } = this.props;
        const { dialogOpen } = this.state;
        const bildeVurderingClassID = Object.entries(classesByID).find(obj => obj[1].name === "Bildevurdering")?.[0];
        return (
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12}>
                    <Typography variant="h6">
                        Bilder
                    </Typography>
                </Grid>
                {classParticipations.length === 0 &&
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography variant="subtitle1" align="center">
                            Du har ikke lastet opp noen bilder i denne klassen enda
                        </Typography>
                    </Grid>
                }

                {classParticipations.map((img, key) =>
                    <Grid key={img.classParticipationID ? img.classParticipationID : key} item xs={12} sm={6} md={6}>
                        <UploadedImageCard
                            name={img.name}
                            imgRef={img.thumbnailUrl ? img.thumbnailUrl : img.previewImageUrl}
                            imgID={img.classParticipationID}
                            imgNumber={img.classParticipationNumber}
                            handleDelete={this.handleDelete}
                            noDelete={img.classID === bildeVurderingClassID}
                        />
                    </Grid>
                )}

                <Grid item xs={12} sm={12} md={12} className={classes.buttonContainer}>
                    <Button
                        size="large"
                        variant="contained"
                        color="primary"
                        onClick={() => this.handleOpen("dialogOpen")}
                        disabled={this.uploadDisabled(classParticipations, competitionClass)}
                    >
                        Last opp nytt bilde
                    </Button>
                    <UploadImageDialog
                        competitionClassID={this.props.selectedCompetitionClassID}
                        onClose={this.handleClose}
                        handleUpload={this.handleUpload}
                        open={dialogOpen}
                    />
                </Grid>
            </Grid>
        );
    }

    renderVideoClass = (classParticipations, competitionClass) => {
        const { classes } = this.props;
        const { dialogOpenVideo } = this.state;
        return (
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12}>
                    <Typography variant="h6">
                        Videoer
                            </Typography>
                </Grid>
                {classParticipations.length === 0 &&
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography variant="subtitle1" align="center">
                            Du har ikke lastet opp noen videoer i denne klassen enda
                        </Typography>
                    </Grid>
                }

                {classParticipations.map((media, key) =>
                    <Grid key={media.classParticipationID ? media.classParticipationID : key} item xs={12} sm={6} md={6}>
                        {!media.videoUrl &&
                            <UploadedImageCard
                                name={media.name}
                                imgRef={media.thumbnailUrl ? media.thumbnailUrl : media.previewImageUrl}
                                imgID={media.classParticipationID}
                                imgNumber={media.classParticipationNumber}
                                handleDelete={this.handleDelete}
                            />
                        }
                        {media.videoUrl &&
                            <UploadedVideoCard
                                name={media.name}
                                imgRef={media.thumbnailUrl ? media.thumbnailUrl : media.previewImageUrl}
                                imgID={media.classParticipationID}
                                imgNumber={media.classParticipationNumber}
                                handleDelete={this.handleDelete}
                                videoUrl={media.videoUrl}
                            />
                        }
                    </Grid>
                )}

                <Grid item xs={12} sm={12} md={12} className={classes.buttonContainer}>
                    <Button
                        size="large"
                        variant="contained"
                        color="primary"
                        onClick={() => this.handleOpen("dialogOpenVideo")}
                        disabled={this.uploadDisabled(classParticipations, competitionClass)}
                    >
                        Legg til ny video
                            </Button>
                    <UploadVideoDialog
                        competitionClassID={this.props.selectedCompetitionClassID}
                        onClose={this.handleClose}
                        handleUpload={this.handleUploadVideo}
                        open={dialogOpenVideo}
                    />
                </Grid>
            </Grid>
        );
    }

    renderCompetitionClass = (competitionClass) => {
        const { classes, classesByID, participation, competitionType, competitionMaxItems, selectedCompetitionClassID } = this.props;
        const competitionClassID = competitionClass.competitionClassID;
        const classParticipations = participation.classParticipations[competitionClassID] || [];


        return (
            <ExpansionPanel key={competitionClassID} expanded={selectedCompetitionClassID === competitionClassID} onChange={this.handleChange(competitionClassID)}>
                <ExpansionPanelSummary className={classes.summary} expandIcon={<ExpandMoreIcon />}>
                    <Typography className={classes.heading}>{classesByID[competitionClass.classID].name}</Typography>
                    <Typography className={classes.secondaryHeading}>{competitionClass.theme}</Typography>
                    <div><span className={classes.imgLimit}>{`${classParticipations.length}/${competitionType === 2 || competitionType === 3 ? competitionMaxItems : competitionClass.maxItems}`}</span></div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    {classesByID[competitionClass.classID].type === "Photo" && this.renderPhotoClass(classParticipations, competitionClass)}
                    {classesByID[competitionClass.classID].type === "Video" && this.renderVideoClass(classParticipations, competitionClass)}
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );

    }

    render() {
        const { classes, competitionClasses, competitionMaxItems, competitionData } = this.props;

        return (
            <div className={classes.root}>
                <Typography variant="subtitle1" gutterBottom>Maks antall innleveringer: {competitionMaxItems} - {this.calcTotalClassParticipations()} lastet opp.</Typography>
                <List style={{paddingTop: 0, paddingBottom: 0}}>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar className={classes.avatar}>
                                <InfoIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Beskrivelse" secondary={competitionData.description} />
                    </ListItem>
                </List>
                {competitionClasses.map(compClass => this.renderCompetitionClass(compClass))}
            </div>
        );
    }
}

ControlledExpansionPanels.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ControlledExpansionPanels);