var devConfig = {
  apiUrl: "/api",
  rubicUrl: "https://rubictest.azurewebsites.net",
  stripeKey: "pk_test_uRGKG1GNMuIigRfTeVQ9Yq1F",
  rubicNsffOrganizationID: "382",
};

var devLocalhost = {
  apiUrl: "https://localhost:5001/api",
  rubicUrl: "https://rubictest.azurewebsites.net",
  stripeKey: "pk_test_uRGKG1GNMuIigRfTeVQ9Yq1F",
  rubicNsffOrganizationID: "382",
}

var prodConfig = {
  apiUrl: "/api",
  rubicUrl: "https://app.rubic.no",
  stripeKey: "pk_live_WWNCE5sFjn7kgZtOz3VFFjv5",
  rubicNsffOrganizationID: "8757",
};

// eslint-disable-next-line
var testConfig = {
  apiUrl: "/api",
  rubicUrl: "https://rubictest.azurewebsites.net",
  stripeKey: "pk_test_uRGKG1GNMuIigRfTeVQ9Yq1F",
  rubicNsffOrganizationID: "382",
};

export default function getConfig() {
  if (window.location.host === "localhost:3000") return devLocalhost;
  return prodConfig;

  /*     switch (window.CONFIG) {
        
        case 'production':
            return prodConfig;
        case 'test':
            return testConfig;
        default:
            return devConfig;
    } */
}
