import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { Link, withRouter } from "react-router-dom";
import logo from '../../images/nsff_logo_32.png';

const styles = {
    root: {
        flexGrow: 1,
        position: "fixed",
        top: 0,
        width: "100%",
        zIndex: 1000,
    },
    grow: {
        flexGrow: 1,
        color: "#ffffff",
        marginLeft: 8,
    },
    tab: {
        height: 64,
    },
    icon: {
        color: "white",
    },
};


const sortedTabs = (tabs) => {
    return tabs.sort((a, b) => a.order - b.order);
}

const defaultTabs = [
    {
        label: 'Min side',
        to: '/dashboard',
        order: 0,
    },
    {
        label: 'Konkurranser',
        to: '/competitions',
        order: 1,
    },
    {
        label: 'Hederstegn & plaketter',
        to: '/distinctions',
        order: 4,
    },
    {
        label: 'Logg ut',
        to: '/login',
        order: 100,
        onClick: (props) => { props.onLogout(); },
    },
];

const adminTabs = sortedTabs([
    ...defaultTabs,
    {
        label: 'Administrasjon',
        to: '/admin',
        order: 3,
    },
]);

const nsffTabs = sortedTabs([
    ...adminTabs,
    {
        label: "Klubber",
        to: "/clubs",
        order: 2,
    }
]);

class Navbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = { screenWidth: 0 };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ screenWidth: window.innerWidth });
    }

    handleMenuButtonClick = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    }

    handleCloseMenu = () => {
        this.setState({ anchorEl: null });
    }

    handleLogoutClick = () => {
        this.props.onLogout();
    }

    handleTabClick = (ev, tab, tabIndex, currentValue) => {
        this.currentValue = tabIndex;
        // workaround to make tab refresh reclick
        if (tabIndex === currentValue) {
            ev.preventDefault();
            ev.stopPropagation();
            this.props.pushLocation('/empty');
            //this.props.history.push('/empty');
            setTimeout(() => { this.props.replaceLocation(tab.to); }, 0);
        }
        tab.onClick && tab.onClick(this.props);
    }

    renderTabs = (tabs, value) => {
        const { classes } = this.props;
        return tabs.map((t, index) => (
            <Tab
                key={t.to}
                className={classes.tab}
                label={t.label}
                component={Link}
                to={t.to}
                onClick={(ev) => this.handleTabClick(ev, t, index, value)}
            />
        ));
    }

    renderMenuItems = (tabs) => {
        return tabs.map(t => (
            <MenuItem
                key={t.to}
                component={Link}
                to={t.to}
                onClick={() => { t.onClick && t.onClick(this.props); }}
            >{t.label}</MenuItem>
        ));
    }

    getTabsForUser = () => {
        const { userProfile } = this.props;
        if (userProfile.isNsffAdmin) {
            return nsffTabs;
        }
        return userProfile.isAnyAdmin ? adminTabs : defaultTabs;
    }

    render() {
        const { classes, currentPath } = this.props;
        const { screenWidth, anchorEl } = this.state;
        const tabs = this.getTabsForUser();
        let value = tabs.findIndex(t => t.to === currentPath);
        value = value === -1 ? false : value;
        return (
            <div className={classes.root}>
                <AppBar position="static" color="primary">
                    <Toolbar>
                        <a href="https://www.nsff.no/">
                            <img src={logo} alt="Profile" height="32" width="32"></img>
                        </a>
                        <Typography variant="h6" color="inherit" className={classes.grow}>
                            NSFF
                        </Typography>
                        {screenWidth >= 850 &&
                            <Tabs value={value}>
                                {this.renderTabs(tabs, value)}
                            </Tabs>
                        }
                        {screenWidth < 850 &&
                            <div>
                                <IconButton onClick={this.handleMenuButtonClick}>
                                    <MenuIcon className={classes.icon} />
                                </IconButton>
                                <Menu
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={this.handleCloseMenu}
                                >
                                    {this.renderMenuItems(tabs)}
                                </Menu>
                            </div>
                        }
                    </Toolbar>
                </AppBar>
            </div>
        );
    }
}

Navbar.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(Navbar));