// @flow
import * as React from 'react';
import { withStyles } from '@material-ui/core';

type Props = {
    onChange?: ?(value: any) => void,
    value: any,
};

type State = {
    value: any,
};

const getStyles = (theme) => ({
    errorContainer: {
        color: 'red',
    },
});


export default () =>
    (InnerComponent: React.ComponentType<any>) =>
        withStyles(getStyles)(
            class ErrorMessageHandler extends React.Component<Props, State> {

                render() {
                    const { errorMessage, classes, ...passOnProps } = this.props;

                    return (
                        <div>
                            <InnerComponent
                                {...passOnProps}
                            />
                            { errorMessage &&
                                <div className={classes.errorContainer}>
                                    {errorMessage}
                                </div>
                            }
                        </div>

                    );
                }
            }
        )