import React from 'react';
import PersonDistinctionList from './PersonDistinctionList/PersonDistinctionList.container';

class Distinctions extends React.Component {

    render() {
        return (
            <PersonDistinctionList
                {...this.props}
            />
            
        );
    }
}


export default Distinctions;