import { connect } from 'react-redux';
import AwardFullscreen from './AwardFullscreen.component';

import {
    saveAward,
    removeAward,
    onAwardFormFieldUpdated,
    onViewEditAward,
    onAwardFormInvalid,
    onCancelEditAward,
} from './AwardFullscreen.actions';

const mapStateToProps = (state, props) => {
    const viewAndAward = state.viewAndAward;
    const awardFullscreen = viewAndAward.awardFullscreen || {};
    return {
        saving: awardFullscreen.saving,
        deleting: awardFullscreen.deleting,
        formValues: awardFullscreen.formValues,
        validationErrorMessage: awardFullscreen.validationErrorMessage,
        showEditAward: awardFullscreen.showEditAward,
    }
}

const mapDispatchToProps = (dispatch) => ({
    saveAward: (competitionID, classParticipationID, award, comment) => {
        dispatch(saveAward(competitionID, classParticipationID, award, comment));
    },
    removeAward: (competitionID, classParticipationID) => {
        dispatch(removeAward(competitionID, classParticipationID));
    },
    onAwardFormFieldUpdated: (field, value) => {
        dispatch(onAwardFormFieldUpdated(field, value));
    },
    onViewEditAward: (formValues) => {
        dispatch(onViewEditAward(formValues));
    },
    onCancelEditAward: () => {
        dispatch(onCancelEditAward());
    },
    onAwardFormInvalid: (validationErrorMessage) => {
        dispatch(onAwardFormInvalid(validationErrorMessage));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(AwardFullscreen);