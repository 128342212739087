import React from 'react';
import PropTypes from 'prop-types';
import MediaDialog  from './MediaDialog.component';
import {
    Fab,
    Grid,
    withStyles,
    Typography,
    CircularProgress,
    Paper,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core';

import {
    ChevronLeft,
    ChevronRight,
    PlayArrow,
} from '@material-ui/icons';


const styles = theme => ({
    root: {
        textAlign: "center",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    rootInner: {
        width: '100%',
    },
    img: {
        maxWidth: '100%',
        minHeight: 300,
        maxHeight: 'calc(100vh - 550px)',
        cursor: 'zoom-in',
    },
    fabNext: {
        float: "right",
        zIndex: 1000,
    },
    fabPrev: {
        float: "left",
        zIndex: 1000,
    },
    progressContainer: {
        paddingTop: 6,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    imageGrid: {
        position: 'relative',
    }
  });

class ImageFullscreen extends React.Component {
    state = {
        currentIndex: 0,
        openDialog: false,
        imageStatus: "loading",
        imageStatusDialog: "loading",
    };

    constructor(props){
        super(props);
        this.state.currentIndex = props.initialIndex || 0;
    }

    handleNext = () => {
        var newIndex = this.getCurrentIndex() +1;
        this.props.onIndexChange(newIndex);
        this.setState({currentIndex: newIndex, imageStatus: "loading", imageStatusDialog: "loading"});
    }

    handlePrev = () => {
        var newIndex = this.getCurrentIndex() -1;
        this.props.onIndexChange(newIndex);
        this.setState({currentIndex: newIndex, imageStatus: "loading", imageStatusDialog: "loading"});
    }

    handleOpenDialog = () => {
        this.setState({openDialog: true});
    }

    handleCloseDialog = () => {
        this.setState({openDialog: false, imageStatusDialog: "loading"});
    }

    handleImageLoaded = () => {
        this.setState({imageStatus: "loaded"});
    }

    handleImageLoadedDialog = () => {
        this.setState({imageStatusDialog: "loaded"});
    }
    

    getCurrentIndex = () => {
        const currentIndex = this.state.currentIndex;
        const mediaItems = this.props.mediaItems || [];

        if(currentIndex >= mediaItems.length) {
            return mediaItems.length -1;
        }
        return currentIndex;
    }
    
    render() {
        const { classes, mediaItems, getBottomContent } = this.props;
        const { imageStatus } = this.state;
        const currentIndex = this.getCurrentIndex();
        const currentMediaItem = mediaItems && mediaItems[currentIndex];
        if(!currentMediaItem) {
            return (
                <div>
                    Ingen flere bilder
                </div>
            );
        }
        
        return (
            <div className={classes.root}>
                <div className={classes.rootInner}>
                    <Grid container spacing={0} alignItems="center" justify="center">
                        <Grid item xs={1} sm={1} md={1}>
                            <Fab color="primary" aria-label="Tilbake" className={classes.fabPrev} onClick={this.handlePrev} disabled={currentIndex === 0}>
                                <ChevronLeft />
                            </Fab>
                        </Grid>
                        <Grid item xs={10} sm={10} md={10} className={classes.imageGrid}>
                            <img
                                style={imageStatus === "loading" ? {visibility: "hidden"} : {}}
                                onLoad={this.handleImageLoaded}
                                onClick={this.handleOpenDialog}
                                className={classes.img}
                                src={currentMediaItem.imageUrl}
                                alt=""
                            />
                            {imageStatus && imageStatus === "loading" && 
                                <div className={classes.progressContainer}>
                                    <CircularProgress />
                                </div>
                            }
                        </Grid>
                        <Grid item xs={1} sm={1} md={1}>
                            <Fab color="primary" aria-label="Neste" className={classes.fabNext} onClick={this.handleNext} disabled={currentIndex === mediaItems.length - 1}>
                                <ChevronRight />
                            </Fab>
                        </Grid>
                    </Grid>
                </div>
                <Typography variant="h5">
                    {currentMediaItem.name}
                </Typography>
                <Typography>
                    Id: {currentMediaItem.classParticipationNumber}
                </Typography>
                {currentMediaItem && currentMediaItem.videoUrl &&
                    <List className={classes.videoCard}>
                        <Paper elevation={1}>
                            <ListItem button component="a" target="_blank" href={currentMediaItem.videoUrl} aria-label="Spill video">
                                <ListItemIcon>
                                    <PlayArrow color="primary" />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Klikk for å se videoen"
                                    secondary={currentMediaItem.videoUrl}
                                />
                            </ListItem>
                        </Paper>
                    </List>
                }
                {getBottomContent(currentMediaItem, currentIndex)}
                <MediaDialog onClose={this.handleCloseDialog} isOpen={this.state.openDialog} mediaItem={currentMediaItem} />
            </div>
        );
    }
}

ImageFullscreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ImageFullscreen);