import { connect } from 'react-redux';
import PersonDistinctionList from './PersonDistinctionList.component';

import {
    openDistinctionsForPerson,
} from './PersonDistinctionList.actions';
import { getPersonDistinctions } from '../Distinctions.actions';
import { createSelector } from 'reselect';

const getDistinctionTypes = (state) => state.distinctions.distinctionTypes;

const makeGetDistinctionTypesByCode = () =>
    createSelector(
        getDistinctionTypes,
        (distinctionTypes) => distinctionTypes.reduce((accTypes, t) => ({...accTypes, [t.code]: t }), {}),
    )

const makeMapStateToProps = () => {
    const getDistinctionTypesByCode = makeGetDistinctionTypesByCode();
    return (state) => ({
        ready: !state.distinctions.personDistinctionsLoading,
        availableItemsDistinctionType: state.distinctions.availableItemsDistinctionType,
        distinctionTypesByCode: getDistinctionTypesByCode(state),
        personDistinctions: state.distinctions.personDistinctions,
        paging: state.distinctions.paging,
        order: state.distinctions.order,
        searchString: state.distinctions.searchString,
        clubFilter : state.distinctions.clubFilter,
        orgs: state.clubs.orgs,
    });
};

const mapDispatchToProps = (dispatch) => ({
    openDistinctionsForPerson: (personDistinctionTypeData) => {
        dispatch(openDistinctionsForPerson(personDistinctionTypeData));
    },
    fetchPersonDistinctionList: (paging, order, searchString, availableItemsDistinctionType, clubFilter) => {
        dispatch(getPersonDistinctions(paging, order, searchString, availableItemsDistinctionType, clubFilter));
    }
});


export default connect(makeMapStateToProps, mapDispatchToProps)(PersonDistinctionList);