import React from 'react';
import moment from "moment";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import { personService } from '../../../Services/personService';

import {
    Email,
    Phone,
    PhotoCamera,
    Cake,
    Home,
    Work,
    Info
} from '@material-ui/icons';

const styles = theme => ({
    root: {

    },
    dataContainer: {
        textAlign: "center",
    },
    buttonContainer: {
        textAlign: "right",
    },
    img: {
        borderRadius: "100%",
    },
    dataTitle: {
        fontWeight: "bold",
    },
    orgText: {
        display: 'flex',
        alignItems: 'center',
        paddingBottom: 8,
        '&:last-child': {
            paddingBottom: 0,
        }
    },
    avatar: {
        backgroundColor: "#001789",
    },
    dot: {
        backgroundColor: "#001789",
        height: 6,
        width: 6,
        borderRadius: 3,
        marginLeft: 5,
    }
});

class UserProfileDisplay extends React.Component {

    handleClick = (value) => {
        this.props.handleClick(true);
    }

    renderField = (AvatarComponent, primaryText, secondaryText) => {
        const { classes } = this.props;
        if (!primaryText) {
            primaryText = secondaryText;
            secondaryText = null;
        }
        return (
            <ListItem>
                <ListItemAvatar>
                    <Avatar className={classes.avatar}>
                        <AvatarComponent />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={primaryText} secondary={secondaryText} />
            </ListItem>
        );
    }

    // TODO : Upload image component  next to profile image.
    render() {
        const { classes, userData, userOrgs } = this.props;

        return (
            <div className={classes.root}>
                <div className={classes.buttonContainer}>
                    <Button color="primary" onClick={this.handleClick}>
                        Rediger
                    </Button>
                </div>
                <div className={classes.dataContainer}>
                    <img className={classes.img} src={personService.getImageUrl(userData.rubicPersonID)} alt="Profilbilde" height="128" widt="128" />
                    <Typography variant="h5" gutterBottom>
                        {userData.firstName + " " + userData.lastName}
                    </Typography>
                    <Divider />
                    <List dense={true}>
                        {this.renderField(Email, userData.email)}
                        {this.renderField(Phone, userData.mobile)}
                        {this.renderField(Home, userData.address, (userData.zipCode + ", " + userData.city))}
                        {this.renderField(Cake, moment(userData.birthDate).format('LL'))}
                        {this.renderField(Work, userData.position || "Ikke satt")}
                        {this.renderField(Info, userData.hasApprovedMarketing ? "Ønsker å motta nyhetsbrev" : "Ønsker ikke å motta nyhetsbrev")}
                        <ListItem>
                            <ListItemAvatar >
                                <Avatar className={classes.avatar}>
                                    <PhotoCamera />
                                </Avatar>
                            </ListItemAvatar>
                            <div className={classes.orgContainer}>
                                {userOrgs.map(o => (
                                    <div key={o.organizationID}>
                                        {userData.mainOrganizationID === o.organizationID &&
                                            <Tooltip title="Hovedklubb" placement="right-start">
                                                <Typography variant="subtitle2" className={classes.orgText}>
                                                    {o.organizationName}
                                                    <div className={classes.dot}></div>
                                                </Typography>
                                            </Tooltip>
                                        }
                                        {userData.mainOrganizationID !== o.organizationID &&
                                            <Typography variant="subtitle2" className={classes.orgText}>
                                                {o.organizationName}
                                            </Typography>
                                        }
                                    </div>
                                ))}
                            </div>

                        </ListItem>
                    </List>
                </div>
            </div>
        );
    }
}

UserProfileDisplay.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UserProfileDisplay);