import { actionTypes } from '../components/Pages/Distinctions/Distinctions.actions';
import { actionTypes as personDistinctionListActionTypes } from '../components/Pages/Distinctions/PersonDistinctionList/PersonDistinctionList.actions';
import { actionTypes as distinctionsForPersonActionTypes } from '../components/Pages/Distinctions/EditDistinctionsForPerson/EditDistinctionsForPerson.actions';
const initState = {
    initializing: true,
    personDistinctionsLoading: true,
}

const convertToClientDirection = (direction) => {
    if(direction === 0) {
        return 'desc';
    }
    return 'asc';
}

const requestPersonDistinctionsSuccess = (state, action) => {
    const { value, querySettings } = action.payload.response;
    const { paging, order } = querySettings || {};
    return {
        ...state,
        personDistinctionsLoading: false,
        personDistinctions: value,
        paging: paging,
        order: order ? { direction: convertToClientDirection(order.direction), orderBy: order.orderBy } : null,
    };

}

const createDistinctionReducer = (state = initState, action) => {
    switch(action.type) {
        case actionTypes.INIT_DISTINCTION_DATA_STARTED:
            console.log('Initializing distinction data');
            return {
                initializing: true,
            };
        case actionTypes.INIT_DISTINCTION_DATA_FINISHED:
            console.log('Initializing distinction data finished');
            return {
                ...state,
                initializing: false,
            };
        case actionTypes.REQUEST_DISTINCTION_TYPES_SUCCESS:
            console.log('Request distinction types success');
            return {
                ...state,
                distinctionTypes: action.payload.response,
            };
        case actionTypes.REQUEST_DISTINCTION_TYPES_FAILED:
            console.log('Request distinction types failed');
            return {
                ...state,
                fetchDistinctionTypesError: action.payload.error.message,
            };
        case actionTypes.REQUEST_PERSON_DISTINCTIONS:
            console.log('request person distinctions');
            return {
                ...state,
                personDistinctionsLoading: true,
                searchString: action.meta.searchString,
                availableItemsDistinctionType: action.meta.availableItemsDistinctionType,
                clubFilter: action.meta.clubFilter
            };
        case actionTypes.REQUEST_PERSON_DISTINCTIONS_SUCCESS:
            console.log('request person distinctions success');
            return requestPersonDistinctionsSuccess(state, action);
        case actionTypes.REQUEST_PERSON_DISTINCTIONS_FAILED:
            console.log('request person distinction success');
            return {
                ...state,
                personDistinctionsLoading: false,
                fetchPersonDistinctionsError: action.payload.error.message,
            };
        case personDistinctionListActionTypes.OPEN_DISTINCTIONS_FOR_PERSON:
            return  {
                ...state,
                showDistinctionsForPerson: true,
            }
        case distinctionsForPersonActionTypes.GO_BACK_TO_PERSON_DISTINCTIONS_LIST:
            return  {
                ...state,
                showDistinctionsForPerson: false,
            }
        default:
            return state;
    }
}

export default createDistinctionReducer;