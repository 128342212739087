import React from 'react';
import {
    withStyles,
    Paper,
    CircularProgress,
    Button,
    Typography,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
} from '@material-ui/core';
import OrganizationParticipationImgPreview from './OrganizationParticipationImgPreview.component';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Alert } from '@material-ui/lab';

const styles = theme => ({
    root: {
        padding: 10,
    },
    paper: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        marginBottom: 8,
    },
    alert: {
        marginBottom: 8,

    },
    warningIcon: {
        marginRight: 8,
    },
    header: {
        marginBottom: 16,
    },
    heading: {
        fontSize: theme.typography.pxToRem(24),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    summary: {
        backgroundColor: "#ebba14",
    },
    orgDetails: {
        display: "block",
    },
    buttonContainer: {
        textAlign: "center",
        marginTop: 16,
    },
    button: {
        margin: theme.spacing(0.5),
    },
    loadingContainer: {
        textAlign: "center",
    },
});

class Competition extends React.Component {
    state = {
        expanded: 0,
    };

    handleChange = panel => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : false,
        });
    };

    handleAddOrgParticipation = (personID, participationID, value) => {
        if(value) {
            this.props.addParticipation(personID, participationID);
        } else {
            this.props.removeParticipation(personID, participationID);
        }
    }

    handleSubmit = () => {
        const { selectedOrganizationParticipations , selectedOrganization, competitionData, previousLocation } = this.props;
        this.props.submitParticipation(selectedOrganizationParticipations, selectedOrganization, competitionData.competitionID, previousLocation);
    }

    handleCancel = () => {
        this.props.clearParticipations();
        this.props.onCancel(this.props.previousLocation);
    }

    orgCanParticipate = () => {
        const { allParticipations } = this.props;

        //Hard-coded rule for Klubb-NM (Minimum 5 participants requried).
        if(allParticipations && allParticipations.length >= 5) {
            return true;
        }
        return false;
    }

    render() {
        const { classes,competitionData, amtOfParticipations, isLoadingParticipations, allParticipations, selectedOrganization, orgsByID, isAlreadyParticipating } = this.props;
        const { expanded } = this.state;
        return (
            <div className={classes.root}>
                {!isLoadingParticipations && selectedOrganization &&
                    <Paper className={classes.paper} elevation={1}>
                        <Typography variant="h5" className={classes.header}>
                            Klubbdeltagelser for {orgsByID[selectedOrganization].organizationName} i {competitionData.name}
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Dette er en lagkonkurranse som er uavhengig av NM i foto for enkeltmedlemmer, 
                            og man kan derfor benytte samme bilder til denne. Bildene velges ut blant de allerede innleverte bildene fra klubbens medlemmer til NM i foto. 
                            Klubbens konkurranseansvarlig har ansvar for at det blir gjort, direkte i NSFFs konkurransedatabase. Denne utvelgelsen skal skje ved at klubbens 
                            konkurranseansvarlig logger seg inn på nsff.no senest syv dager etter innleveringsfristens utløp og følger den veiledning som da vil gis. 
                            Klubbene er selv ansvarlig for å stille med en intern jury som skal bistå konkurranseansvarlig under utvelgelsen.
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Dersom bildene ikke er valgt ut innen fristen er klubben ikke å regne som deltager i NM for NSFFs film- og fotoklubber.
                        </Typography>
                        <ul>
                            <li>Klubber med minimum <strong>fem</strong> medlemmer kan delta.</li>
                            <li>Hver klubb deltar med <strong>20</strong> bilder som er valgt ut blant innleverte bilder.</li>
                            <li>Maks <strong>fire</strong> bilder per autor.</li>
                        </ul>
                        {(!allParticipations || !this.orgCanParticipate()) &&
                            <Alert className={classes.alert} severity="warning">Klubben din har ikke nok deltagere i denne konkurransen for å delta i klubbkonkurransen.</Alert> 
                        }
                        {this.orgCanParticipate() && 
                            <React.Fragment>
                                {isAlreadyParticipating && 
                                    <Alert className={classes.alert} severity="success">Klubben din har levert bilder og deltar i denne klubbkonkurransen.</Alert>
                                }
                                {allParticipations.map((orgParticipation, key) =>
                                    <ExpansionPanel key={key} expanded={expanded === key} onChange={this.handleChange(key)}>
                                        <ExpansionPanelSummary className={classes.summary} expandIcon={<ExpandMoreIcon />}>
                                            <Typography className={classes.heading}>{orgParticipation.firstName} {orgParticipation.lastName}</Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails className={classes.orgDetails}>
                                            {orgParticipation.classParticipations.map(participation =>
                                                <OrganizationParticipationImgPreview 
                                                    key={participation.classParticipationID}
                                                    mediaItem={participation}
                                                    addParticipation={this.handleAddOrgParticipation}
                                                    personID={orgParticipation.personID}
                                                    orgParticipations={this.props.selectedOrganizationParticipations}
                                                    amtOfParticipations={amtOfParticipations}
                                                />
                                            )}
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                )}
                            </React.Fragment>
                        }

                        <div className={classes.buttonContainer}>
                            <Button onClick={this.handleCancel} className={classes.button} >
                                Avbryt
                            </Button>
                            <Button onClick={this.handleSubmit} variant="contained" color="primary" className={classes.button} >
                                Lagre
                            </Button>
                            {this.props.submitErrorMessage &&
                                <Typography color="error" variant="overline" display="block" align="center">
                                    {this.props.submitErrorMessage}
                                </Typography>
                            }
                        </div>
                    </Paper>
                }
                {isLoadingParticipations && 
                    <div className={classes.loadingContainer}>
                        <CircularProgress />
                    </div>
                }
            </div>
        );
    }
}

export default withStyles(styles)(Competition);