import { simplePromiseAction, actionCreator } from '../../../../Utils/actions.utils';
import { competitionParticipationService } from '../../../Services/competitionParticipationService';
import generateUUID from '../../../../Utils/generateUUID';
import { getCompetition } from '../Competition.actions';

export const actionTypes = {
    REQUEST_LOAD_PARTICIPATION_PAGE: 'REQUEST_LOAD_PARTICIPATION_PAGE',
    REQUEST_UPLOAD_IMAGE: 'REQUEST_UPLOAD_IMAGE',
    UPLOAD_IMAGE_SUCCESS: 'UPLOAD_IMAGE_SUCCESS',
    UPLOAD_IMAGE_FAILED: 'UPLOAD_IMAGE_FAILED',
    REQUEST_DELETE_IMAGE: 'REQUEST_DELETE_IMAGE',
    DELETE_IMAGE_SUCCESS: 'DELETE_IMAGE_SUCCESS',
    DELETE_IMAGE_FAILED: 'DELETE_IMAGE_FAILED',
    REQUEST_PARTICIPATION: 'REQUEST_PARTICIPATION',
    REQUEST_PARTICIPATION_SUCCESS: 'REQUEST_PARTICIPATION_SUCCESS',
    REQUEST_PARTICIPATION_FAILED: 'REQUEST_PARTICIPATION_FAILED',
    REQUEST_PROCESS_PARTICIPATION_PAYMENT: 'REQUEST_PROCESS_PARTICIPATION_PAYMENT',
    REQUEST_PROCESS_PARTICIPATION_PAYMENT_SUCCESS: 'REQUEST_PROCESS_PARTICIPATION_PAYMENT_SUCCESS',
    REQUEST_PROCESS_PARTICIPATION_PAYMENT_FAILED: 'REQUEST_PROCESS_PARTICIPATION_PAYMENT_FAILED',
    SET_ACTIVE_PARTICIPATION_STEP: 'SET_ACTIVE_PARTICIPATION_STEP',
    SET_SELECTED_CLASSID: 'SET_SELECTED_CLASSID',
    SET_PAYMENT_FORM_DIALOG_OPEN: 'SET_PAYMENT_FORM_DIALOG_OPEN',
};


const requestUploadImage = (competitionClassID, previewImageUrl, name, tempID, videoUrl) => actionCreator(actionTypes.REQUEST_UPLOAD_IMAGE)({ competitionClassID, previewImageUrl, name, tempID, videoUrl });
const uploadImageSuccess = (response, competitionClassID, tempID, classParticipationNumber) => actionCreator(actionTypes.UPLOAD_IMAGE_SUCCESS)({ response, competitionClassID, tempID, classParticipationNumber });
const uploadImageFailed = (error, competitionClassID, tempID) => actionCreator(actionTypes.UPLOAD_IMAGE_FAILED)({ error, competitionClassID, tempID });

const requestDeleteImage = (imageID, competitionClassID) => actionCreator(actionTypes.REQUEST_DELETE_IMAGE)({ imageID, competitionClassID });
const deleteImageSuccess = (response, imageID, competitionClassID) => actionCreator(actionTypes.DELETE_IMAGE_SUCCESS)({ response, imageID, competitionClassID });
const deleteImageFailed = (error) => actionCreator(actionTypes.DELETE_IMAGE_FAILED)({ error });

export const uploadImage = (competitionID, competitionClassID, image, previewImageUrl, name, videoUrl) => {
    return (dispatch) => {
        const tempID = generateUUID();
        dispatch(requestUploadImage(competitionClassID, previewImageUrl, name, tempID, videoUrl));
        var promise = competitionParticipationService.uploadImage(competitionID, competitionClassID, image, name, videoUrl);
        return promise.then(
            response => dispatch(uploadImageSuccess(response, competitionClassID, tempID, response.classParticipationNumber)),
            error => dispatch(uploadImageFailed(error, competitionClassID, tempID)),
        );
    }
};

export const deleteImage = (imageID, competitionClassID, competitionID) => {
    return (dispatch) => {
        dispatch(requestDeleteImage(imageID, competitionClassID));
        var promise = competitionParticipationService.deleteImage(imageID, competitionID);
        return promise.then(
            response => dispatch(deleteImageSuccess(response, imageID, competitionClassID)),
            error => dispatch(deleteImageFailed(error)),
        );
    }
};

export const loadParticipationPage = (competitionID) => (dispatch) => {
    dispatch(actionCreator(actionTypes.REQUEST_LOAD_PARTICIPATION_PAGE)({}));
    return dispatch(getCompetition(competitionID))
        .then((action) => {
            if(!action.payload.error) {
                return dispatch(requestParticipation(competitionID));
            }
        });
}

export const requestParticipation = (competitionID) => simplePromiseAction(
    () => competitionParticipationService.getParticipation(competitionID),
    actionTypes.REQUEST_PARTICIPATION,
    actionTypes.REQUEST_PARTICIPATION_SUCCESS,
    actionTypes.REQUEST_PARTICIPATION_FAILED
);

export const requestProcessParticipationPayment = (paymentToken, competitionID) => {
    const metadata = { competitionID };
    return simplePromiseAction(
        () => competitionParticipationService.processPayment(paymentToken, competitionID),
        actionTypes.REQUEST_PROCESS_PARTICIPATION_PAYMENT,
        actionTypes.REQUEST_PROCESS_PARTICIPATION_PAYMENT_SUCCESS,
        actionTypes.REQUEST_PROCESS_PARTICIPATION_PAYMENT_FAILED,
        metadata
    );
}
export const setActiveStep = (step) => actionCreator(actionTypes.SET_ACTIVE_PARTICIPATION_STEP)({ step });

export const setPaymentFormDialogOpen = (open) => actionCreator(actionTypes.SET_PAYMENT_FORM_DIALOG_OPEN)({ open });

export const setSelectedClassID = (selectedClassID) => actionCreator(actionTypes.SET_SELECTED_CLASSID)({ selectedClassID });