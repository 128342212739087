import React from 'react';
import { Route } from 'react-router-dom';
import CompetitionWrapper from '../Competition/CompetitionWrapper.container';
import Dashboard from '../Dashboard/Dashboard.container';
import Admin from '../Admin/Admin.component';
import Clubs from "../Clubs/Clubs.container";
import Navbar from '../../Navbar/Navbar.container';
import DistinctionsWrapper from '../Distinctions/DistinctionsWrapper.container';
import EditCompetition from '../Competition/EditCompetition/EditCompetition.container';
import CreateCompetition from '../Competition/CreateCompetition/CreateCompetition.container';
import UserParticipation from '../Competition/UserParticipation/UserParticipation.container';
import Score from '../Competition/Score/Score.container';
import Result from '../Competition/Result/Result.container';
import Evaluations from '../Competition/Evaluations/Evaluations.container';
import OrganizationParticipation from '../Competition/OrganizationParticipation/OrganizationParticipation.container';
import ParticipationList from '../Competition/ParticipationList/ParticipationListWrapper.component';
import MyResults from '../Dashboard/MyResults/MyResults.component';


class Start extends React.Component {
  state = {
    tabSelectedValue: 0,
  };

  componentDidMount() {
  }

  handleNavbarClick = (tabSelectedValue) => {
    this.setState({ tabSelectedValue });
  };

  getBasePath = () => {
    const location = this.props.location;
    const splitted = location.pathname ? location.pathname.split("/").filter(v => !!v) : [""];
    if (splitted.length === 0) {
      return '/dashboard';
    }
    const basePath = `/${splitted[0]}`;
    return basePath;
  }

  render() {
    const { history } = this.props;
    const basePath = this.getBasePath();
    return (
      <div>
        <Navbar handleNavbarClick={this.handleNavbarClick} currentPath={basePath} history={history} />
        <div style={{ paddingTop: 72 }}>
          <Route path="/" exact component={Dashboard} />
          <Route path="/dashboard" exact component={Dashboard} />
          <Route path="/myResults" exact component={MyResults} />
          <Route path="/competitions" exact component={CompetitionWrapper} />
          <Route path="/competitions/:id/edit" exact component={EditCompetition} />
          <Route path="/competitions/new" exact component={CreateCompetition} />
          <Route path="/competitions/:id/participation" exact component={UserParticipation} />
          <Route path="/competitions/:id/score" exact component={Score} />
          <Route path="/competitions/:id/results" exact component={Result} />
          <Route path="/competitions/:id/participationList" exact component={ParticipationList} />
          <Route path="/competitions/:id/evaluations" exact component={Evaluations} />
          <Route path="/competitions/:competitionID/organizationParticipation/:organizationID" exact component={OrganizationParticipation} />
          <Route path="/admin" exact component={Admin} />
          <Route path="/clubs" exact component={Clubs} />
          <Route path="/distinctions" exact component={DistinctionsWrapper} />
          <Route path="/empty" exact component={() => (<div />)} />
        </div>
      </div>
    );
  }
}

export default Start;