import { simplePromiseAction } from '../../../../Utils/actions.utils';
import { competitionService } from '../../../Services/competitionService';

export const actionTypes = {
    REQUEST_FINISHED_COMPETITIONS_REPORT: 'REQUEST_FINISHED_COMPETITIONS_REPORT',
    REQUEST_FINISHED_COMPETITIONS_SUCCESS_REPORT: 'REQUEST_FINISHED_COMPETITIONS_SUCCESS_REPORT',
    REQUEST_FINISHED_COMPETITIONS_FAILED_REPORT: 'REQUEST_FINISHED_COMPETITIONS_FAILED_REPORT',
}

export const requestFinishedCompetitions = (searchText, paging) => simplePromiseAction(
    () => competitionService.getFinished(paging, searchText),
    actionTypes.REQUEST_FINISHED_COMPETITIONS_REPORT,
    actionTypes.REQUEST_FINISHED_COMPETITIONS_SUCCESS_REPORT,
    actionTypes.REQUEST_FINISHED_COMPETITIONS_FAILED_REPORT,
    { searchText: searchText }
);