import { connect } from 'react-redux';
import Evaluations from './Evaluations.component';
import withDataLoader from '../../../DataLoader/withDataLoader';
import { loadEvaluationPage } from './Evaluations.actions';
import { pushPreviousOrDefault } from '../../../../Utils/router.utils';

const mapStateToProps = (state) => ({
    competition: state.competition.selectedComp,
    evaluations: state.competitionResults.evaluations && state.competitionResults.evaluations.data,
    classesByID: state.metaData.classesByID,
    dataLoading: state.competitionResults.pageLoading,
    dataLoadErrorMessage: state.competitionResults.error || state.competition.loadSelectedCompErrorMessage,
    previousLocation: state.location.previous,
    userID: state.userProfile.data.personID,
});

const mapDispatchToProps = (dispatch) => ({
    loadPage: (urlParams, queryParams) => {
        dispatch(loadEvaluationPage(urlParams.id));
    },
    onBackClick: (previousLocation) => {
        dispatch(pushPreviousOrDefault(previousLocation, "/competitions"));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(withDataLoader("loadPage")(Evaluations));