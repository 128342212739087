import React from 'react';
import PropTypes from 'prop-types';
import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Collapse,
    Drawer,
    Button,
    Menu,
    MenuItem,
    Divider,
} from '@material-ui/core';
import {
    ExpandLess,
    ExpandMore,
    Photo,
    Folder,
    Info,
    Event,
    Equalizer,
    ArrowBack,
} from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import CompetitionDetails from './CompetitionDetails.component';
import ViewAndScore from './ViewAndScore/ViewAndScore.container';
import ViewAndAward from './ViewAndAward/ViewAndAward.container';
import ViewAndEvaluate from './ViewAndEvaluate/ViewAndEvaluate.container';
import CompetitionStatus from './CompetitionStatus/CompetitionStatus.container';
import MyScoreStatus from './MyScoreStatus/MyScoreStatus.container';
import JuryMemberInfo from './JuryMemberInfo.component';
import Error from '../../../Error/Error.component';


const drawerWidth = 240;

const styles = theme => ({
    root: {
        padding: 10,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
        drawerPaper: {
        width: drawerWidth,
        zIndex: 0,
        marginTop: 64,
    },
        content: {
        flexGrow: 1,
        padding: 12,
    },
    nested: {
        paddingLeft: 32,
    },
});

class Score extends React.Component {
      constructor(props) {
        super(props);

        this.state = { 
            screenWidth: 0,
            selectedMenuItem: "compInfo",
            openImageMenu: true,
        
        };

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }
    
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ screenWidth: window.innerWidth});
    }

    handleClickOpenSubMenu = () => {
        this.setState({ openImageMenu: !this.state.openImageMenu });
    }

    handleMenuButtonClick = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    }

    handleCloseMenu = () => {
        this.setState({ anchorEl: null });
    }

    handleClickMenuItem = (key) => {
        this.setState({ selectedMenuItem: key });
    }


    handleOpenScoreStatus = () => {
        this.handleClickMenuItem("status");
        const { competitionData } = this.props;
        if(competitionData.isJuryLeader) {
            this.props.fetchJuryStatus(competitionData.competitionID);
        } else{
            this.props.fetchMyStatus(competitionData.competitionID);
        }
    }

    handleOpenViewAndScore = (competitionClassID) => {
        this.handleClickMenuItem(competitionClassID);
        const { competitionData } = this.props;
        if(this.showAwarding()) {
            this.props.fetchTotalClassParticipationScoring(competitionData.competitionID, competitionClassID);
        } else {
            this.props.fetchMyClassParticipationScoring(competitionData.competitionID, competitionClassID);
        }
    }


    renderScoreStatus = () => {
        const { classesByID, competitionData } = this.props;
        if(competitionData.isJuryLeader) {
            return (
                <CompetitionStatus competitionData={competitionData} seeResult={this.props.seeResult} classesByID={classesByID} />
            );
        }
        return (
            <MyScoreStatus competitionData={competitionData} classesByID={classesByID} />
        );
    }

    handleBackClick = () => {
        this.props.onBackClick(this.props.previousLocation);
    }

    renderForEvaluation = () => {
        return (
            <ListItem selected={this.state.selectedMenuItem === "compInfo"} button onClick={() => this.handleClickMenuItem("compInfo")}>
                <ListItemIcon><Event /></ListItemIcon>
                <ListItemText primary="Konkurranse" />
            </ListItem>
        )
    }

    renderMediaListItem = () => {
        const { competitionData, classes, classesByID } = this.props;
        const { selectedMenuItem, openImageMenu } = this.state;
        return (
            <React.Fragment>
                <ListItem button onClick={this.handleClickOpenSubMenu}>
                    <ListItemIcon>
                        <Folder />
                    </ListItemIcon>
                    <ListItemText primary="Bilder" />
                    {openImageMenu ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openImageMenu} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {competitionData.competitionClasses.map(competitionClass => (
                            <ListItem button key={competitionClass.competitionClassID} className={classes.nested} selected={selectedMenuItem === competitionClass.competitionClassID} onClick={() => this.handleOpenViewAndScore(competitionClass.competitionClassID)}>
                                <ListItemIcon>
                                    <Photo />
                                </ListItemIcon>
                                <ListItemText primary={classesByID[competitionClass.classID].name} secondary={competitionClass.theme} />
                            </ListItem>
                        ))}
                    </List>
                </Collapse>
            </React.Fragment>

        );
    }

    handleOpenViewAndEvaluate = () => {

    }

    renderEvaluationMediaListItem = () => {
        const { competitionData } = this.props;
        const competitionClass = competitionData.competitionClasses[0];
        return (
            <ListItem button onClick={() => this.handleOpenViewAndScore(competitionClass.competitionClassID)}>
                <ListItemIcon>
                    <Folder />
                </ListItemIcon>
                <ListItemText primary="Bilder" />
            </ListItem>
        );
    }

    showScoring = () => {
        const competitionData = this.props.competitionData;
        return competitionData.isJuryMember && (!competitionData.isJuryLeader || !competitionData.juryingClosed);
    }

    showAwarding = () => {
        const competitionData = this.props.competitionData;
        return competitionData.isJuryLeader && (!competitionData.isJuryMember || competitionData.juryingClosed);
    }

    renderMediaForCompetition = () => {
        const { competitionData } = this.props;
        const { selectedMenuItem } = this.state;
        return (
            <React.Fragment>
                {this.showScoring() && competitionData.competitionClasses.map(competitionClass => (
                    <span key={competitionClass.competitionClassID}>
                        {selectedMenuItem && selectedMenuItem === competitionClass.competitionClassID && 
                            <ViewAndScore competitionClass={competitionClass} competitionData={competitionData} />
                        }
                    </span>
                ))}
                {this.showAwarding() && competitionData.competitionClasses.map(competitionClass => (
                    <span key={competitionClass.competitionClassID}>
                        {selectedMenuItem && selectedMenuItem === competitionClass.competitionClassID && 
                            <ViewAndAward competitionClass={competitionClass} competitionData={competitionData} />
                        }
                    </span>
                ))}
            </React.Fragment>
        );
    }

    renderMediaForEvaluation = () => {
        const { competitionData } = this.props;
        const { selectedMenuItem } = this.state;
        const competitionClass = competitionData.competitionClasses[0];
        return selectedMenuItem && selectedMenuItem === competitionClass.competitionClassID && (
            <ViewAndEvaluate
                competitionClass={competitionClass}
                competitionData={competitionData}
            />
        );
    }

    renderByType = {
        default: {
            renderListItem: this.renderMediaListItem,
            renderMedia: this.renderMediaForCompetition,
        },
        '2': {
            renderListItem: this.renderEvaluationMediaListItem,
            renderMedia: this.renderMediaForEvaluation,
        },
        '3': {
            renderListItem: this.renderEvaluationMediaListItem,
            renderMedia: this.renderMediaForEvaluation,
        },
    };

    render() {
        const { classes, classesByID, competitionData } = this.props;
        const { selectedMenuItem, screenWidth, anchorEl } = this.state;
        const typeString = competitionData.competitionType.toString();
        const renderSettings = this.renderByType[typeString] || this.renderByType.default;

        if(!competitionData.isJuryLeader && !competitionData.isJuryMember) {
            return (<Error errorMessage="Du er ikke juryleder eller jurymedlem i denne konkurransen"/>);
        }

        if(competitionData.finished) {
            return (<Error errorMessage="Kan ikke juryere. Konkurransen er ferdig"/>);
        }

        return (
            <div className={classes.root}>
                {screenWidth >= 850 &&
                    <Drawer
                        className={classes.drawer}
                        variant="permanent"
                        classes={{paper: classes.drawerPaper}}
                    >
                        <List>
                            <ListItem button onClick={this.handleBackClick}>
                                <ListItemIcon><ArrowBack /></ListItemIcon>
                                <ListItemText primary="Tilbake" />
                            </ListItem>
                            <ListItem selected={selectedMenuItem === "compInfo"} button onClick={() => this.handleClickMenuItem("compInfo")}>
                                <ListItemIcon><Event /></ListItemIcon>
                                <ListItemText primary="Konkurranse" />
                            </ListItem>
                            <ListItem selected={selectedMenuItem === "status"} button onClick={() => this.handleOpenScoreStatus()}>
                                <ListItemIcon><Equalizer /></ListItemIcon>
                                <ListItemText primary="Status" />
                            </ListItem>
                            <ListItem selected={selectedMenuItem === "helpInfo"} button onClick={() => this.handleClickMenuItem("helpInfo")}>
                                <ListItemIcon><Info /></ListItemIcon>
                                <ListItemText primary="Info" />
                            </ListItem>
                            {renderSettings.renderListItem()}
                        </List>
                    </Drawer>
                }
                {screenWidth < 850 &&
                    <div style={{marginBottom: 16}}>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={this.handleMenuButtonClick}
                        >
                            Meny
                        </Button>
                        <Menu 
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={this.handleCloseMenu}
                        >
                            <MenuItem
                                onClick={this.handleBackClick}
                            >
                                Tilbake
                            </MenuItem>
                            <MenuItem
                                onClick={() => this.handleClickMenuItem("compInfo")}
                            >
                                Konkurranse
                            </MenuItem>
                            <MenuItem
                                onClick={() => this.handleOpenScoreStatus()}
                            >
                                Status
                            </MenuItem>
                            <MenuItem
                                onClick={() => this.handleClickMenuItem("helpInfo")}
                            >
                                Info
                            </MenuItem>
                            <Divider />
                            {competitionData.competitionClasses.map(competitionClass => (
                                <MenuItem
                                    key={competitionClass.competitionClassID}
                                    onClick={() => this.handleOpenViewAndScore(competitionClass.competitionClassID)}
                                >
                                    {competitionClass.theme && `${classesByID[competitionClass.classID].name} - ${competitionClass.theme}`}
                                    {!competitionClass.theme && `${classesByID[competitionClass.classID].name}`}
                                </MenuItem>
                            ))}
                        </Menu>
                    </div>
                }
                <div style={{marginLeft: screenWidth >= 850 ? drawerWidth : ''}}>
                    {selectedMenuItem && selectedMenuItem === "compInfo" && 
                        <CompetitionDetails competitionData={competitionData} classesByID={classesByID} />
                    }
                    {selectedMenuItem && selectedMenuItem === "status" && this.renderScoreStatus()}
                    {selectedMenuItem && selectedMenuItem === "helpInfo" && 
                        <JuryMemberInfo isJuryLeader={competitionData.isJuryLeader} isJuryMember={competitionData.isJuryMember} />
                    }

                    {renderSettings.renderMedia()}
              
                </div>
            </div>
          );
    }
}

Score.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Score);