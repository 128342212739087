import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import VerticalNumberedList from './VerticalNumberedList.component';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    paper: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    subTitle: {
        marginTop: 16,
    },
    list: {
        width: "90%",
    },
});

const navigationInfo = [
    {label: "Tilbake", description: "Tar deg tilbake til oversikten over dine konkurranser."},
    {label: "Konkurranse", description: "Informasjon om konkurransen."},
    {label: "Status", description: `Her vil du kunne se hvor mange bilder i hver klasse du har gitt poeng. 
             Om du ikke har gitt poeng til alle bilder i en klasse før juryeringen stenger vil dine poeng i den klassen ikke telle.
             Om du er juryleder vil du kunne se alle jurymedlemer sin status. Du vil også ha muligheten til å lukke juryering og fullføre konkurransen.`},
    {label: "Info", description: "Denne siden."},
    {label: "Bilder", description: "Her vil du finne konkurransens bilder sortert på konkurransens klasser."},
];

const scoreInfo = [
    {label: "Velg klasse", description: "Velg hvilken klasse du vil gi poeng for. Dette gjøres under \"Bilde\" i menyen på venstresiden."},
    {label: "Velg bilde", description: "Trykk \"Åpne\"-knappen på bildet du vil gi poeng."},
    {label: "Gi poeng", description: "Under bildet vil du nå ha muligheten til å gi bildet poeng og skrive en kommentar. Om du vil se en større versjon av bilde kan du gjøre det ved å trykke på bildet."},
    {label: "Neste bilde", description: "For å velge et nytt bilde har du nå to valg. Du kan trykke på \"Liste\"-knappen oppe i høyre hjørne eller du kan benytte deg av pilene ved siden av bildet."},
];

const awardInfo = [
    {label: "Velg klasse", description: "Velg hvilken klasse du vil premiere bilder i. Dette gjøres under \"Bilde\" i menyen på venstresiden."},
    {label: "Velg bilde", description: "Her vil du se alle bilder i den valgte klassen og hvor mange poeng hvert bilde har fått. Trykk \"Åpne\"-knappen på bildet du vil gi poeng."},
    {label: "Gi poeng", description: `Forutsatt at juryiering er lukket (gjøres i "Status") vil du nå kunne premiere bildet. Under bildet vil du nå ha muligheten til å sette en plassering for bildet. 
             Om jurymedlemer har skrevet kommentarer på bildet vil disse vises. 
             Du kan selv også skrive en kommentar på bildet. Denne kommentaren vil bli vist sammen med plasseringen når resultatene publiseres.`},
    {label: "Neste bilde", description: "For å velge et nytt bilde har du nå to valg. Du kan trykke på \"Liste\"-knappen oppe i høyre hjørne eller du kan benytte deg av pilene ved siden av bildet."},
    {label: "Ferdig?", description: "Når bilder som skulle premierers er premiert kan konkurransen lukkes i \"Status\". Når konkurransen lukkes blir resultatene publisert."},
];


class JuryMemberInfo extends React.Component {

    render() {
        const { classes, isJuryLeader, isJuryMember } = this.props;
        return (
            <Paper className={classes.paper} elevation={1}>
                <Typography variant="h5">
                    Info
                </Typography>

                <Typography className={classes.subTitle} variant="h6">
                    Hvordan navigerer jeg?
                </Typography>
                <Typography variant="body1">
                    Til venstre vil du se en meny, denne menyen inneholder valgene:
                </Typography>
                <VerticalNumberedList listData={navigationInfo} />

                {isJuryMember &&
                    <div>
                        <Typography className={classes.subTitle} variant="h6">
                            Hvordan gir jeg poeng?
                        </Typography>
                        <VerticalNumberedList listData={scoreInfo} />
                    </div>
                }

                {isJuryLeader &&
                    <div>
                        <Typography className={classes.subTitle} variant="h6">
                            Hvordan premierer jeg bilder?
                        </Typography>
                        <VerticalNumberedList listData={awardInfo} />
                    </div>
                }
            </Paper>
        );
    }
}

export default withStyles(styles)(JuryMemberInfo);