import { actionTypesCreateComp } from '../components/Pages/Competition/CreateCompetition/CreateCompetition.actions';

const initState = {

}

const createCompetitionReducer = (state = initState, action) => {
    switch(action.type) {
        case actionTypesCreateComp.REQUEST_CREATE_COMPETITION:
            console.log('Is submitting competition');
            return {
                ...state,
                submitErrorMessage: null,
                isSubmitting: true,
            };
        case actionTypesCreateComp.CREATE_COMPETITION_SUCCESS:
            console.log('Competition creation successful');
            return {
                ...state,
                isSubmitting: false,
            };
        case actionTypesCreateComp.CREATE_COMPETITION_FAILED:
            console.log('Competition creation failed');
            return {
                ...state,
                isSubmitting: false,
                submitErrorMessage: action.payload.error.message,
            };
        default:
            return state;
    }
}

export default createCompetitionReducer;