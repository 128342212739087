
import React from 'react';
import {
    CircularProgress,
} from '@material-ui/core';

import {
    withStyles,
} from '@material-ui/core/styles';


const getStyles = theme => ({
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
        padding: 10,
    },
});

const getLoadingIndicator = () =>
    (InnerComponent: React.ComponentType<any>) =>
        withStyles(getStyles)(
            class DefaultFieldChangeHandler extends React.Component {
                render() {
                    const { ready, classes, ...passOnProps } = this.props;
                    return ready ?
                        (
                            <InnerComponent
                                {...passOnProps}
                            />
                        ) :
                        (
                            <div className={classes.progressContainer}>
                                <CircularProgress />
                            </div>
                        );
                }
            }
        )



export default getLoadingIndicator;