import { connect } from 'react-redux';
import Result from './Result.component';
import withDataLoader from '../../../DataLoader/withDataLoader';
import { loadResultPage } from './Result.actions';
import { pushPreviousOrDefault } from '../../../../Utils/router.utils';

const mapStateToProps = (state) => {
    const result = state.competitionResults.result || {};

    return {
        isNsffAdmin: state.userProfile.data.isNsffAdmin,
        selectedComp: state.competition.selectedComp,
        selectedCompResults: result.data,
        selectedCompOrgResults: result.orgData,
        fetchErrorResults: result.error,
        classesByID: state.metaData.classesByID,
        dataLoading: !state.competitionResults.result || state.competitionResults.pageLoading,
        dataLoadErrorMessage: result.error || state.competition.loadSelectedCompErrorMessage,
        previousLocation: state.location.previous,
    };
};

const mapDispatchToProps = (dispatch) => ({
    loadPage: (urlParams, queryParams) => {
        dispatch(loadResultPage(urlParams.id));
    },
    onBackClick: (previousLocation) => {
        dispatch(pushPreviousOrDefault(previousLocation, "/competitions"));
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(withDataLoader("loadPage")(Result));