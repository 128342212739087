import React from 'react';
import { FormControlLabel, Checkbox } from '@material-ui/core';

const CheckboxField = (props) => {
    const { value, label, ...passOnProps } = props;
    return (
        <FormControlLabel
            control={
                <Checkbox
                    checked={value || false}
                    color="primary"
                    {...passOnProps}

                />
            }
            label={label}
            
        />
    );
}

export default CheckboxField;