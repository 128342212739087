import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import TextField from '../../../../Fields/TextField/TextField.component';
import Button from '@material-ui/core/Button';

const styles = {
    dialogContent: {
        padding: 16,
    },
    title: {
      fontSize: 14,
    },
    buttonContainer: {
        textAlign: "center",
    },
};

class UploadVideoDialog extends React.Component {
    state = {
        name: null,
        videoUrl: null,
        uploadedImage: null,
        imagePreviewUrl: null,
    };

    handleClose = () => {
        this.setState({ name: null, videoUrl: null, uploadedImage: null, imagePreviewUrl: null, saveError: null });
        this.props.onClose();
    };

    handleSave = () => {
        const { name, videoUrl, uploadedImage, imagePreviewUrl } = this.state;
        const { handleUpload, competitionClassID } = this.props;
        if(name && videoUrl && uploadedImage) {
            //TODO: Handle upload video
            handleUpload(competitionClassID, name, videoUrl, uploadedImage, imagePreviewUrl);
            this.handleClose();
        } else if(!name || !videoUrl || !uploadedImage) {
            this.setState({ saveError: "Mangler tittel, video URL eller bilde" });
        }
    };

    handleChangeName = (e) => {
        const { value } = e.target;
        this.setState({ name: value, saveError: null });
    }

    handleChangeVideoUrl = (e) => {
        const { value } = e.target;
        this.setState({ videoUrl: value, saveError: null });
    }

    onChangeImg = (e) => {
        e.preventDefault();
        const _URL = window.URL || window.webkitURL;
        const file = e.target.files[0];
        const reader = new FileReader();

        if(file) {
            let img = new Image();

            img.onload = () => {
                var filesize = ((file.size/1024)/1024).toFixed(4);

                if(filesize <= 3.0000 && img.width <= 2400 && img.height <= 2400) {
                    reader.onloadend = () => {
                        this.setState({
                            uploadedImage: file,
                            imagePreviewUrl: reader.result,
                            saveError: null,
                        });
                    }
        
                    reader.readAsDataURL(file);
                } else if(filesize > 3.0000) {
                    this.setState({ saveError: "Bilde er over 3MB" });
                } else if(img.width > 2400) {
                    this.setState({ saveError: "Bilde er over 2400px bredt" });
                } else if(img.height > 2400) {
                    this.setState({ saveError: "Bilde er over 2400px høyt" });
                }
            };

            img.src = _URL.createObjectURL(file);

        }
    }
    
    render() {
        const { classes } = this.props;

        return (
            <Dialog onClose={this.handleClose} aria-labelledby="simple-dialog-title" open={this.props.open} fullWidth>
                <div className={classes.dialogContent}>
                    <Typography variant="h5" gutterBottom>
                        Legg til video
                    </Typography>
                    <TextField
                        required
                        name="name"
                        label="Tittel"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        style={{margin: '0px 0px 8px 0px'}}
                        onChange={this.handleChangeName}
                    />
                   <TextField
                        required
                        name="videoUrl"
                        label="Link til video"
                        fullWidth
                        type="url"
                        margin="normal"
                        variant="outlined"
                        style={{margin: '8px 0px 8px 0px'}}
                        onChange={this.handleChangeVideoUrl}
                    />
                    <div>
                        {this.state.imagePreviewUrl &&
                            <img style={{width: "100%"}} alt="Test" src={this.state.imagePreviewUrl} />
                        }
                        <input id="imgInput"
                            type="file"
                            accept="image/jpeg"
                            ref={(ref) => this.upload = ref}
                            style={{display: 'none'}}
                            onChange={this.onChangeImg.bind(this)}
                        />
                        <Button onClick={()=>{this.upload.click()}} variant="outlined" color="primary" fullWidth size="large" style={{marginBottom: 16}}>
                            { !this.state.imagePreviewUrl ? "Velg bilde" : "Velg nytt bilde" }
                        </Button>
                    </div>
                    <div className={classes.buttonContainer}>
                        <Button variant="text" color="primary" onClick={this.handleClose}>
                            Avbryt
                        </Button>
                        <Button variant="contained" color="primary" onClick={this.handleSave} style={{marginLeft: 8}}>
                            Legg til
                        </Button>
                    </div>
                    {this.state.saveError &&
                        <Typography color="error" variant="overline" display="block" align="center">
                            {this.state.saveError}
                        </Typography>
                    }
                </div>
            </Dialog>
          );
    }
}

UploadVideoDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UploadVideoDialog);