import React from 'react';
import {
    Table,
    TableHead,
    TableCell,
    TableRow,
    TableBody,
    TableSortLabel,
    withStyles,
} from '@material-ui/core';


const getStyles = (theme) => ({
    row: {
        cursor: 'pointer',
    },
    tableContainer: {
        overflowX: 'auto',
    },
});

class DataTable extends React.Component {
    handleSort = (key) => {
        const { orderBy, direction } = this.props.order;
        const isDesc = orderBy === key && direction === 'desc';
        const newDirection = isDesc ? 'asc' : 'desc';
        this.props.onSort && this.props.onSort({ orderBy: key, direction: newDirection });
    }
    renderHeaderCells = () => {
        const { columns, headerClassName, order } = this.props;

        const { orderBy, direction } = order || { direction: '' };

        return columns.map(c => {
            const alignment = c.headerAlignment || 'left';
            
            if(c.getHeaderCell) {
                return c.getHeaderCell(this.props);
            }

            if(c.sortable) {
                return (
                    <TableCell
                        key={c.key}
                        align={alignment}
                        sortDirection={orderBy === c.key ? direction : false}
                        className={headerClassName}
                  >
                    <TableSortLabel
                      active={orderBy === c.key}
                      direction={direction || ''}
                      onClick={() => this.handleSort(c.key)}
                    >
                      {c.displayName}
                    </TableSortLabel>
                  </TableCell>

                );
            }
            return (
                <TableCell key={c.key} align={alignment} className={headerClassName}>{c.displayName}</TableCell>
            );
        });
    }


    renderDataCells = (data, rowKey) => {
        const { columns } = this.props;
        return columns.map(c => {
            const key = `${rowKey}-${c.key}`;
            if(c.getDataCell) {
                return c.getDataCell(this.props, data, key);
            }
            const value = c.getValue ? c.getValue(this.props, data) : data[c.key];
            return (
                <TableCell key={key} align={c.dataAlignment || 'left'}>{value}</TableCell>
            );
        });
    }


    renderEmptyTable = () => {
        return (
            <div></div>
        )
    }
    renderRows = () => {
        const { data, dataKey, onRowClick, classes } = this.props;

        if(!data || data.length < 0) {
            return this.renderEmptyTable();
        }

        return data.map(d => {
            const rowKey = d[dataKey];
            return ( 
                <TableRow key={rowKey} onClick={() => onRowClick && onRowClick(d)} className={classes.row} hover>
                    {this.renderDataCells(d, rowKey)}
                </TableRow>
            );
        });
    }

    render() {
        const { classes, data } = this.props;
        const hasData = data && data.length > 0;
        return (
            <div className={classes.tableContainer}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {this.renderHeaderCells()}
                        </TableRow>
                    </TableHead>
                    {hasData &&
                        <TableBody>
                            {this.renderRows()}
                        </TableBody>
                    }
                </Table>
                {!hasData && this.renderEmptyTable()}
            </div>
        );
    }
}

export default withStyles(getStyles)(DataTable);