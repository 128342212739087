import { actionTypes as personDistinctionListActionTypes } from '../components/Pages/Distinctions/PersonDistinctionList/PersonDistinctionList.actions';
import { actionTypes as distinctionsForPersonActionTypes } from '../components/Pages/Distinctions/EditDistinctionsForPerson/EditDistinctionsForPerson.actions';
const initState = {
    viewMode: 'distinctions',
}


const getInitialState = (state, action) => {
    const { distinctionsByItemID, pointsByDistinctionTypeID, ...selectedPerson } = action.payload.selectedPerson;

    const formDistinctionsByItemID = Object.keys(distinctionsByItemID).reduce((accDistinctions, distItemID) => ({
        ...accDistinctions,
        [distItemID]: { ...distinctionsByItemID[distItemID] },
    }), {});

    return {
        ...state,
        selectedPerson,
        distinctionsByItemID,
        formDistinctionsByItemID,
        pointsByDistinctionTypeID,
    }
}


const requestSetPersonDistinction = (state, action) => {
    const { distinctionItemID, year } = action.meta;

    const formDistinctionsByItemID = JSON.parse(JSON.stringify(state.formDistinctionsByItemID));
    formDistinctionsByItemID[distinctionItemID] = {
        ...formDistinctionsByItemID[distinctionItemID],
        saving: true,
        success: false,
        archievedYear: year,
    }
    return {
        ...state,
        formDistinctionsByItemID,
    };
}

const setPersonDistinctionSuccess = (state, action) => {
    const { distinctionItemID, year } = action.meta;
    const formDistinctionsByItemID = JSON.parse(JSON.stringify(state.formDistinctionsByItemID));
    if(year) {
        formDistinctionsByItemID[distinctionItemID] = {
            ...formDistinctionsByItemID[distinctionItemID],
            saving: false,
            success: true,
            errorMessage: null,
        }
    } else {
        delete formDistinctionsByItemID[distinctionItemID];
    }

    const distinctionsByItemID = JSON.parse(JSON.stringify(state.distinctionsByItemID));
    if(year) {
        distinctionsByItemID[distinctionItemID] = {
            ...distinctionsByItemID[distinctionItemID],
            archievedYear: year,
        }
    } else {
        delete distinctionsByItemID[distinctionItemID];
    }

    return {
        ...state,
        formDistinctionsByItemID,
        distinctionsByItemID,
    }
}

const setPersonDistinctionFailed = (state, action) => {
    const { distinctionItemID } = action.meta;
    const distinction = state.distinctionsByItemID[distinctionItemID];

    return {
        ...state,
        distinctionsByItemID: {
            ...state.distinctionsByItemID,
            [distinctionItemID]: {
                ...distinction,
                saving: false,
                success: false,
                errorMessage: action.payload.error.message,
            }
        } 
    };
}

const createDistinctionsForPersonReducer = (state = initState, action) => {
    switch(action.type) {
        case personDistinctionListActionTypes.OPEN_DISTINCTIONS_FOR_PERSON:
            return getInitialState(state, action);
        case distinctionsForPersonActionTypes.REQUEST_SET_PERSON_DISTINCTION:
            return requestSetPersonDistinction(state, action);
        case distinctionsForPersonActionTypes.SET_PERSON_DISTINCTION_SUCCESS:
            return setPersonDistinctionSuccess(state, action);
        case distinctionsForPersonActionTypes.SET_PERSON_DISTINCTION_FAILED:
            return setPersonDistinctionFailed(state, action);
        case distinctionsForPersonActionTypes.SET_VIEW_MODE_DISTINCTIONS:
            console.log("Setting view mode");
            return {
                ...state,
                viewMode: action.payload.mode,
            };
        case distinctionsForPersonActionTypes.GET_IMPORTED_RESULTS:
            console.log("Setting view mode");
            return {
                ...state,
                viewMode: action.meta.viewMode,
            };
        case distinctionsForPersonActionTypes.GET_IMPORTED_RESULTS_FAILED:
            console.log("Fallback imported results fetch failed");
            return {
                ...state,
                viewMode: 'distinctions',
            };
        case distinctionsForPersonActionTypes.GO_BACK_TO_PERSON_DISTINCTIONS_LIST:
            return {
                ...state,
                viewMode: 'distinctions',
            };
        default:
            return state;
    }
}

export default createDistinctionsForPersonReducer;