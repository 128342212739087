import { connect } from 'react-redux';
import ControlledExpansionPanels from './UploadStep.component';
import { setSelectedClassID } from '../UserParticipation.actions';

const mapStateToProps = (state) => ({
    selectedCompetitionClassID: state.competitionParticipation.selectedCompetitionClassID,
});

const mapDispatchToProps = (dispatch) => ({
    setSelectedClassID: (selectedCompetitionClassID) => {
        dispatch(setSelectedClassID(selectedCompetitionClassID));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(ControlledExpansionPanels);