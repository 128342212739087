import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const styles = {
    dialogContent: {
        padding: 16,
    },
    title: {
        fontSize: 10,
    },
    buttonContainer: {
        textAlign: "center",
    },
};

class UploadProfileImageDialog extends React.Component {
    state = {
        uploadedImage: null,
        imagePreviewUrl: null,
    };

    handleClose = () => {
        this.setState({ uploadedImage: null, imagePreviewUrl: null, saveError: null });
        this.props.onClose();
    };

    handleSave = () => {
        const { handleUpload } = this.props;
        const { uploadedImage, imagePreviewUrl } = this.state;
        if (uploadedImage) {
            handleUpload(imagePreviewUrl);
            this.handleClose();
        }
    };

    onChangeImg = (e) => {
        e.preventDefault();
        const _URL = window.URL || window.webkitURL;
        const file = e.target.files[0];
        const reader = new FileReader();

        if (file) {
            let img = new Image();

            img.onload = () => {
                var filesize = ((file.size / 1024) / 1024).toFixed(4);

                if (filesize <= 3.0000) {
                    reader.onloadend = () => {
                        this.setState({
                            uploadedImage: file,
                            imagePreviewUrl: reader.result,
                            saveError: null,
                            saveWarning: null,
                        });
                    }
                    reader.readAsDataURL(file);
                } else if (filesize > 3.0000) {
                    this.setState({ saveError: "Bilde er over 3MB" });
                }
            };
            img.src = _URL.createObjectURL(file);
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <Dialog onClose={this.handleClose} aria-labelledby="simple-dialog-title" open={this.props.open} fullWidth>
                <div className={classes.dialogContent}>
                    <Typography variant="h5" gutterBottom>
                        Last opp profilbilde
                    </Typography>
                    <div>
                        {this.state.imagePreviewUrl &&
                            <img style={{ width: "100%" }} alt="Test" src={this.state.imagePreviewUrl} />
                        }
                        <input id="imgInput"
                            type="file"
                            accept="image/jpeg"
                            ref={(ref) => this.upload = ref}
                            style={{ display: 'none' }}
                            onChange={this.onChangeImg.bind(this)}
                        />
                        <Button onClick={() => { this.upload.click() }} variant="outlined" color="primary" fullWidth size="large" style={{ marginBottom: 16 }}>
                            {!this.state.imagePreviewUrl ? "Velg bilde" : "Velg nytt bilde"}
                        </Button>
                    </div>
                    <div className={classes.buttonContainer}>
                        <Button variant="text" color="primary" onClick={this.handleClose}>
                            Avbryt
                        </Button>
                        <Button variant="contained" color="primary" onClick={this.handleSave} style={{ marginLeft: 8 }}>
                            Last opp
                        </Button>
                    </div>
                    {this.state.saveError &&
                        <Typography color="error" variant="overline" display="block" align="center">
                            {this.state.saveError}
                        </Typography>
                    }
                    {this.state.saveWarning &&
                        <Typography color="secondary" variant="overline" display="block" align="center">
                            {this.state.saveWarning}
                        </Typography>
                    }
                </div>
            </Dialog>
        );
    }
}

UploadProfileImageDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UploadProfileImageDialog);