import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import { createSelector } from 'reselect';
import DistinctionItem from '../../../Distinction/DistinctionItem.component';

const styles = theme => ({
    root: {
        paddingTop: 0,
    },
});

const createGetDistinctionItemsByID = () =>
    createSelector(
        props => props.distinctionTypes,
        (distinctionTypes) => {
            return distinctionTypes.reduce((accItems, type) => {
                var itemsByID = type.distinctionItems.reduce((accInnerItems, item) => ({ ...accInnerItems, [item.distinctionItemID]: { ...item, distinctionTypeID: type.distinctionTypeID}}), {});

                return {
                    ...accItems,
                    ...itemsByID
                }
            }, {});
        }
    )

class UserDistinctions extends React.Component {
    getDistinctionItemsByID = createGetDistinctionItemsByID();

    renderDistinction = (viewObject) => {
        return (
            <DistinctionItem
                distinction={viewObject.distinction}
                distinctionItem={viewObject.distinctionItem}
            />
        );
    }

    getDistinctionViewObjects = (distinctionsByItemID, distinctionTypes) => {
        const itemsByID = this.getDistinctionItemsByID(this.props);
        const distinctionViewObjectByType = Object.keys(itemsByID).reduce((accDistinctionViewObjectByType, distinctionItemID) => {
            const distinction = distinctionsByItemID[distinctionItemID];
            const distinctionItem = itemsByID[distinctionItemID];
            if(distinction) {
                accDistinctionViewObjectByType[distinctionItem.distinctionTypeID] = {
                    distinction: distinctionsByItemID[distinctionItemID],
                    distinctionItem: distinctionItem,
                };
            }
            return accDistinctionViewObjectByType;
        }, {});

        return Object.keys(distinctionViewObjectByType).reduce((accDistinctionViewObjects, typeID) => [...accDistinctionViewObjects, distinctionViewObjectByType[typeID]], []);
    }

    render() {
        const { classes, distinctionsByItemID, distinctionTypes } = this.props;
        const distinctionViewObjects = this.getDistinctionViewObjects(distinctionsByItemID, distinctionTypes);
        return (
            <List className={classes.root}>
                {distinctionViewObjects.map(viewObject => this.renderDistinction(viewObject))}
            </List>
        );
    }
}

export default withStyles(styles)(UserDistinctions);