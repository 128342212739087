import { connect } from 'react-redux';
import DistinctionsForPerson from './ViewDistinctionsForPerson.component';
import { goBackToPersonDistinctionList } from '../EditDistinctionsForPerson/EditDistinctionsForPerson.actions';

const mapStateToProps = (state) => {
    const distinctionsForPerson = state.distinctionsForPerson || {};

    return {
        selectedPerson: distinctionsForPerson.selectedPerson,
        pointsByDistinctionTypeID: distinctionsForPerson.pointsByDistinctionTypeID,
        formDistinctionsByItemID: distinctionsForPerson.formDistinctionsByItemID,
        distinctionsByItemID: distinctionsForPerson.distinctionsByItemID,
        viewMode: distinctionsForPerson.viewMode,
    }
};

const mapDispatchToProps = (dispatch) => ({
    goBackToPersonDistinctionList: () => {
        dispatch(goBackToPersonDistinctionList());
    },
});


export default connect(mapStateToProps, mapDispatchToProps)(DistinctionsForPerson);