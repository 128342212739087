import React from 'react';
import Api from '../../../Services/apiService';
import { FormControl, withStyles, FormHelperText } from '@material-ui/core';
import SelectField from '../../../Fields/SelectField/SelectField.component';

const getStyles = (theme) => ({
    formControl: {
        margin: 8,
        width: "100%"
    },
});

const CompetitionOrganizationSelection = (props) => {
    const { selectedOrganizations } = props;
    const loadOrganizations = (input) => {
        let url = `organizations/search?queryString=${input}`;
        if(props.organizationID) {
            url += `&exclude=${props.organizationID}`;
        }
        return Api.get(url).then((res) => {
            if (res) {
                return res.map(r => ({ value: r.organizationID, label: r.organizationName }));
            }
            return [];
        });
    }
    const handleChange = (value) => {
        const newValue = value ? value.map(opt => ({
            organizationID: opt.value,
            organizationName: opt.label,
        })) : [];
        props.onChange(newValue);
    }
    const value = selectedOrganizations ? selectedOrganizations.map(so => ({
        label: so.organizationName,
        value: so.organizationID,
    })) : [];

    return (
        <FormControl variant="outlined" className={props.classes.formControl}>
            <SelectField
                async
                label={"Klubbtilgang"}
                isMulti
                value={value}
                loadOptions={loadOrganizations}
                defaultOptions={false}
                onChange={handleChange}
                noInputValueEmptyText="Søk for å finne klubber"
            />
            <FormHelperText>Velg andre klubber som kan delta i konkurransen</FormHelperText>
        </FormControl>

    );
}
export default withStyles(getStyles)(CompetitionOrganizationSelection);