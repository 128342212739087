import Api from './apiService';

export const importResultsService = {
    getResults,
    uploadFile,
    saveUploadedResults,
};

function getResults(personID) {
    return Api.get(`externalCompetitionResults/${personID}`);
}

function uploadFile(personID, file) {

    const data = new FormData();
    data.append("file", file);

    return Api.request({
        url: `externalCompetitionResults/upload/${personID}`,
        body: data,
        useCustomHeader: true,
        method: "POST", 
        headers: { Authorization: Api.getAuthorizationHeader() },
    }).then(result => {
        return result;
    });
}

function saveUploadedResults(personID, results) {
    return Api.post(`externalCompetitionResults/save/${personID}`, results);
}