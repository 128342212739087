import Api from './apiService';

export const competitionResultService = {
    saveAward,
    removeAward,
    getClassResults,
    getAllResults,
    closeJurying,
    finishCompetition,
    getOrganizationResults,
    getMyCompetitionResults,
    getMyExternalCompetitionResults,
    unFinishCompetition,
    openAwarding,
    openJurying,
};

const getDirection = (direction) => {
    if (direction === "asc") {
        return 1;
    } else {
        return 0;
    }
}

function saveAward(competitionID, classParticipationID, award, comment) {
    const data = {
        classParticipationID,
        competitionID,
        award,
        comment,
    }
    return Api.post(`competitionResults/${competitionID}/award`, data);
}

function removeAward(competitionID, classParticipationID) {
    return Api.delete(`competitionResults/${competitionID}/award/${classParticipationID}`);
}

function getClassResults(competitionID, classID) {
    return Api.get(`competitionResults/${competitionID}/class/${classID}`)
}

function getAllResults(competitionID) {
    return Api.get(`competitionResults/${competitionID}`)
}

function getMyCompetitionResults(awardFilter, startPosition, numberOfItems, orderBy, direction) {
    let queryUrl = `competitionResults/user?startPosition=${startPosition}&numberOfItems=${numberOfItems}`;
    if (awardFilter) {
        queryUrl += `&awardFilter=${awardFilter}`;
    }
    if (orderBy && direction) {
        queryUrl += `&orderBy=${orderBy}&direction=${getDirection(direction)}`
    }
    return Api.get(queryUrl);
}

function getMyExternalCompetitionResults(awardFilter, startPosition, numberOfItems, orderBy, direction) {
    let queryUrl = `externalCompetitionResults/user?startPosition=${startPosition}&numberOfItems=${numberOfItems}`;
    if (awardFilter) {
        queryUrl += `&awardFilter=${awardFilter}`;
    }
    if (orderBy && direction) {
        queryUrl += `&orderBy=${orderBy}&direction=${getDirection(direction)}`
    }
    return Api.get(queryUrl);
}

function getOrganizationResults(competitionID) {
    return Api.get(`competitionResults/${competitionID}/organizationResults`)
}

function closeJurying(competitionID) {
    return Api.post(`competitions/${competitionID}/closeJurying`);
}

function finishCompetition(competitionID) {
    return Api.post(`competitions/${competitionID}/finish`);
}

function unFinishCompetition(competitionID) {
    return Api.post(`competitions/${competitionID}/unfinish`);
}

function openAwarding(competitionID) {
    return Api.post(`competitions/${competitionID}/openawarding`);
}

function openJurying(competitionID) {
    return Api.post(`competitions/${competitionID}/openjurying`);
}
