import React from 'react';
import {
    TextField as MuiTextField,
} from '@material-ui/core';

class TextField extends React.Component {

    render(){
        const { style, ...passOnProps } = this.props;

        const fieldStyles = {
            margin: 8,
            ...style,
        }
        return (
            <React.Fragment>
                <input {...passOnProps} style={{display: "none"}} />
            
            <MuiTextField
                style={fieldStyles}
                variant="outlined"
                {...passOnProps}
            />
            </React.Fragment>
        );
    }
}


export default TextField;