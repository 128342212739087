import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MainOrganizationSelector from './MainOrganizationSelector.component';
import Typography from '@material-ui/core/Typography';
import TextField from '../../../Fields/TextField/TextField.component';
import CheckboxField from '../../../Fields/CheckboxField/CheckboxField.component';
import Button from '@material-ui/core/Button';
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-pickers";
import ProgressButton from '../../../Button/ProgressButton.component';
import MomentUtils from '@date-io/moment';
import moment from "moment";
import UploadProfileImageDialog from "./UploadProfileImageDialog.component"
import "moment/locale/nb";

moment.locale("nb");

const styles = theme => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: "center",
  },
  button: {
    margin: theme.spacing(1),
  },
  editContainer: {
    margin: theme.spacing(1, 5),
    textAlign: "center",
  },
});

class UserProfileEdit extends React.Component {
  state = {
    dialogOpen: false,
    validationErrorMessage: null,
  };

  handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const updateValue = type === 'checkbox' ? checked : value;
    this.props.onFormFieldUpdate(name, updateValue);
  }
  handleBirthDateChange = (momentDate) => {
    if (momentDate) {
      this.props.onFormFieldUpdate('birthDate', momentDate.format("YYYY-MM-DD[T00:00:00Z]"));
      this.setState({ validationErrorMessage: null });
      return;
    }
    this.props.onFormFieldUpdate('birthDate', null);
  }

  handleMainOrgChange = (e) => {
    const { name, value } = e.target;
    this.props.onFormFieldUpdate(name, value);
  }

  validateForm = () => {
    if (!this.props.userDataForm.birthDate) {
      this.setState({ validationErrorMessage: "Fødselsdato må være satt" });
      return false;
    }
    this.setState({ validationErrorMessage: null });
    return true;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      this.props.requestSaveProfile(this.props.userDataForm);
    }
  }

  handleOpen = (dialogName) => {
    this.setState({
      [dialogName]: true,
    });
  };

  handleClose = () => {
    this.setState({
      dialogOpen: false,
      dialogOpenVideo: false,
    });
  };

  handleUpload = (profilePicture) => {
    this.props.onFormFieldUpdate("picture", profilePicture)
  }

  render() {
    const { classes, userDataForm, saving, organizations } = this.props;
    const { dialogOpen } = this.state;
    return (
      <form name="form" onSubmit={this.handleSubmit}>
        <div className={classes.editContainer}>
          <Typography variant="h5">
            Rediger
          </Typography>
        </div>
        <div className={classes.buttonContainer}>
          <Button
            className={classes.button}
            size="small"
            variant="contained"
            color="primary"
            onClick={() => this.handleOpen("dialogOpen")}>
            Last opp nytt profilbilde
          </Button>
        </div>
        <UploadProfileImageDialog
          onClose={this.handleClose}
          handleUpload={this.handleUpload}
          open={dialogOpen}
        />
        <TextField
          value={userDataForm.firstName}
          name="firstName"
          label="Fornavn"
          onChange={this.handleChange}
          style={{ margin: 8 }}
          fullWidth
          margin="normal"
          variant="outlined"
        />
        <TextField
          value={userDataForm.lastName}
          name="lastName"
          label="Etternavn"
          onChange={this.handleChange}
          style={{ margin: 8 }}
          fullWidth
          margin="normal"
          variant="outlined"
        />
        <MainOrganizationSelector organizations={organizations} selectedID={userDataForm.mainOrganizationID || ""} callBackFunction={this.handleMainOrgChange} />
        <TextField
          value={userDataForm.email}
          name="email"
          label="E-post"
          type="email"
          onChange={this.handleChange}
          style={{ margin: 8 }}
          fullWidth
          margin="normal"
          variant="outlined"
        />
        <TextField
          value={userDataForm.mobile}
          name="mobile"
          label="Telefonnummer"
          type="tel"
          onChange={this.handleChange}
          style={{ margin: 8 }}
          fullWidth
          margin="normal"
          variant="outlined"
        />
        <TextField
          value={userDataForm.address}
          name="address"
          label="Adresse"
          onChange={this.handleChange}
          style={{ margin: 8 }}
          fullWidth
          margin="normal"
          variant="outlined"
        />
        <TextField
          value={userDataForm.zipCode}
          name="zipCode"
          label="Postnummer"
          onChange={this.handleChange}
          style={{ margin: 8 }}
          fullWidth
          margin="normal"
          variant="outlined"
        />
        <TextField
          value={userDataForm.city}
          name="city"
          label="Poststed"
          onChange={this.handleChange}
          style={{ margin: 8 }}
          fullWidth
          margin="normal"
          variant="outlined"
        />
        <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
          <DatePicker
            disableFuture
            required
            variant="outlined"
            value={this.props.userDataForm.birthDate}
            name="birthDate"
            label="Fødselsdato"
            cancelLabel="Avbryt"
            clearLabel="Fjern"
            onChange={this.handleBirthDateChange}
            showTodayButton
            style={{ margin: 8 }}
            fullWidth
            margin="normal"
            format="LL"
            error={false}
            helperText={null}
            clearable
          />
        </MuiPickersUtilsProvider>
        <TextField
          value={userDataForm.position}
          name="position"
          label="Yrke"
          onChange={this.handleChange}
          style={{ margin: 8 }}
          fullWidth
          margin="normal"
          variant="outlined"
        />
        <CheckboxField
          name="hasApprovedMarketing"
          value={userDataForm.hasApprovedMarketing}
          onChange={this.handleChange}
          label={"Motta nyhetsbrev"}
        />
        <div>
          <div className={classes.buttonContainer}>
            <Button
              className={classes.button}
              onClick={this.props.onCancelEditProfile}
              disabled={saving}
            >
              Avbryt
                  </Button>
            <ProgressButton
              variant="contained"
              type="submit"
              color="primary"
              className={classes.button}
              loading={saving}
            >
              Lagre
                  </ProgressButton>
          </div>
          {this.state.validationErrorMessage &&
            <Typography color="error" variant="overline" display="block" align="center">
              {this.state.validationErrorMessage}
            </Typography>
          }
        </div>

      </form>
    );
  }
}

export default withStyles(styles)(UserProfileEdit);