import React from 'react';
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import moment from "moment";
import "moment/locale/nb";
import Grid from '@material-ui/core/Grid';

moment.locale("nb");

class CompetitionDateTimePicker extends React.Component {
    handleStartDateChange = (dateObject) => {
        this.props.onChange({target: {name: "submissionStart", value: dateObject.toISOString(true)}});
    }

    handleEndDateChange = (dateObject) => {
        this.props.onChange({target: {name: "submissionEnd", value: dateObject.toISOString(true)}});
    }

    render() {
        return (
            <MuiPickersUtilsProvider utils={MomentUtils} locale="nb" moment={moment}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} md={6}>
                        <DateTimePicker
                            disablePast
                            required
                            inputVariant="outlined"
                            value={this.props.compStartDate}
                            name="competitionStartTime"
                            label="Start"
                            cancelLabel="Avbryt"
                            clearLabel="Fjern"
                            onChange={this.handleStartDateChange}
                            ampm={false}
                            showTodayButton
                            style={{ margin: 8 }}
                            fullWidth
                            margin="normal"
                            error={false}
                            helperText={null}
                            clearable
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <DateTimePicker
                            disablePast
                            required
                            inputVariant="outlined"
                            value={this.props.compEndDate}
                            name="competitionEndTime"
                            label="Slutt"
                            cancelLabel="Avbryt"
                            clearLabel="Fjern"
                            onChange={this.handleEndDateChange}
                            ampm={false}
                            showTodayButton
                            style={{ margin: 8 }}
                            fullWidth
                            margin="normal"
                            error={false}
                            helperText={null}
                            clearable
                        />
                    </Grid>
                </Grid>
            </MuiPickersUtilsProvider>
        );
    }
}

export default CompetitionDateTimePicker;