import { actionTypes } from '../components/Pages/Competition/Competition.actions';
import {
    actionTypes as competitionStatusActionTypes,
} from '../components/Pages/Competition/Score/CompetitionStatus/CompetitionStatus.actions';
import { actionTypes as  actionTypesEditComp} from '../components/Pages/Competition/EditCompetition/EditCompetition.actions';
import { actionTypes as actionTypesCompCard } from '../components/Pages/Competition/CompetitionsList/CompetitionListCard.actions';
import competitionListKeys from '../components/Pages/Competition/competitionListKeys';
import { LOCATION_CHANGE } from 'connected-react-router';
const initState = {
    viewMode: 'compList'
}

const requestCompetitionList = (state, key) => {
    return {
        ...state,
        [key]: {
            loading: true,
        }
    }
}

const requestCompetitionListSuccess = (state, key, data, paging) => {
    return {
        ...state,
        [key]: {
            loading: false,
            data,
            paging,
        }
    }
}

const requestCompetitionListFailed = (state, key, error) => {
    return {
        ...state,
        [key]: {
            loading: false,
            error,
        }
    }
}

const competitionReducer = (state = initState, action) => {
    switch(action.type) {
        case LOCATION_CHANGE:
            return {
                ...initState,
            }
        case actionTypes.REQUEST_ACTIVE_COMPETITIONS:
            console.log('Fetching active competitions');
            return requestCompetitionList(state, competitionListKeys.ACTIVE);
        case actionTypes.REQUEST_ACTIVE_COMPETITIONS_SUCCESS:
            console.log('Active competitions fetch successful');
            return requestCompetitionListSuccess(state, competitionListKeys.ACTIVE, action.payload.response);
        case actionTypes.REQUEST_ACTIVE_COMPETITIONS_FAILED:
            console.log('Active competitions fetch failed');
            return requestCompetitionListFailed(state, competitionListKeys.ACTIVE, action.payload.error.message);
        case actionTypes.REQUEST_UPCOMMING_COMPETITIONS:
            console.log('Fetching upcomming competitions');
            return requestCompetitionList(state, competitionListKeys.UPCOMMING);
        case actionTypes.REQUEST_UPCOMMING_COMPETITIONS_SUCCESS:
            console.log('Upcomming competitions fetch successful');
            return requestCompetitionListSuccess(state, competitionListKeys.UPCOMMING, action.payload.response);
        case actionTypes.REQUEST_UPCOMMING_COMPETITIONS_FAILED:
            console.log('Upcomming competitions fetch failed');
            return requestCompetitionListFailed(state, competitionListKeys.UPCOMMING, action.payload.error.message);
        case actionTypes.REQUEST_FINISHED_COMPETITIONS:
            console.log('Fetching finished competitions');
            return requestCompetitionList(state, competitionListKeys.FINISHED);
        case actionTypes.REQUEST_FINISHED_COMPETITIONS_SUCCESS:
            console.log('Finished competitions fetch successful');
            return requestCompetitionListSuccess(state, competitionListKeys.FINISHED, action.payload.response.result, action.payload.response.pagingSettings);
        case actionTypes.REQUEST_FINISHED_COMPETITIONS_FAILED:
            console.log('Finished competitions fetch failed');
            return requestCompetitionListFailed(state, competitionListKeys.FINISHED, action.payload.error.message);

        case actionTypes.REQUEST_MORE_FINISHED_COMPETITIONS:
            console.log('Fetching more finished competitions');
            return {
                ...state,
                [competitionListKeys.FINISHED]: {
                    ...state[competitionListKeys.FINISHED],
                    loadingMore: true,
                }
            }
        case actionTypes.REQUEST_MORE_FINISHED_COMPETITIONS_SUCCESS:
            console.log('Finished competitions more fetch successful');
            const newFinishedData = [ ...state[competitionListKeys.FINISHED].data, ...action.payload.response.result];

            return {
                ...state,
                [competitionListKeys.FINISHED]: {
                    ...state[competitionListKeys.FINISHED],
                    loadingMore: false,
                    paging: action.payload.response.pagingSettings,
                    data: newFinishedData,
                }
            }
        case actionTypes.REQUEST_MORE_FINISHED_COMPETITIONS_FAILED:
            console.log('Finished competitions more fetch failed');
            return requestCompetitionListFailed(state, competitionListKeys.FINISHED, action.payload.error.message);

        case actionTypes.REQUEST_SEARCH_FINISHED_COMPETITIONS:
            console.log('Fetching more finished competitions');
            return {
                ...state,
                [competitionListKeys.FINISHED]: {
                    ...state[competitionListKeys.FINISHED],
                    loadingMore: true,
                    loadingFinished: true,
                }
            }
        case actionTypes.REQUEST_SEARCH_FINISHED_COMPETITIONS_SUCCESS:
            console.log('Finished competitions more fetch successful');

            return {
                ...state,
                [competitionListKeys.FINISHED]: {
                    ...state[competitionListKeys.FINISHED],
                    loadingMore: false,
                    loadingFinished: false,
                    paging: action.payload.response.pagingSettings,
                    data: action.payload.response.result,
                }
            }
        case actionTypes.REQUEST_SEARCH_FINISHED_COMPETITIONS_FAILED:
            console.log('Finished competitions more fetch failed');
            return requestCompetitionListFailed(state, competitionListKeys.FINISHED, action.payload.error.message);

        case actionTypes.SET_VIEW_MODE:
            console.log("Setting view mode");
            return {
                ...state,
                viewMode: action.payload.mode,
                selectedComp: action.payload.selectedComp ? action.payload.selectedComp : null,
                registerViewActiveStep: action.payload.registerViewActiveStep ? action.payload.registerViewActiveStep : 0,
            };
        case actionTypes.SET_SELECTED_COMP:
            console.log("Setting selected comp");
            return {
                ...state,
                selectedComp: action.payload.selectedComp ? action.payload.selectedComp : null,
            };
        case actionTypesCompCard.FINISH_COMPETITION_SUCCESS:
            return {
                ...state,
                selectedComp: state.selectedComp && state.selectedComp.competitionID === action.payload.competitionID ?
                    { ...state.selectedComp, finished: true } :
                    state.selectedComp,
            };
        case competitionStatusActionTypes.CLOSE_COMPETITION_JURYING_SUCCESS:
            return {
                ...state,
                selectedComp: state.selectedComp && state.selectedComp.competitionID === action.payload.competitionID ?
                    { ...state.selectedComp, juryingClosed: true } :
                    state.selectedComp,
            };
        case competitionStatusActionTypes.CLOSE_COMPETITION_AWARDING_SUCCESS:
            return {
                ...state,
                selectedComp: state.selectedComp && state.selectedComp.competitionID === action.payload.competitionID ?
                    { ...state.selectedComp, awardingClosed: true } :
                    state.selectedComp,
            };
        case actionTypesEditComp.REQUEST_DELETE_COMPETITION:
            console.log("Deleting competition");
            return {
                ...state,
                isDeleting: true,
                deleteCompError: null,
            };
        case actionTypesEditComp.DELETE_COMPETITION_FAILED:
            console.log("Delete competition failed");
            return {
                ...state,
                isDeleting: false,
                deleteCompError: action.payload.error.message,
            };
        case actionTypesEditComp.REQUEST_COPY_COMPETITION:
            console.log("Copy competition");
            return {
                ...state,
                isCopying: true,
            };
        case actionTypesEditComp.COPY_COMPETITION_SUCCESS:
            console.log("Copy competition success");
            return {
                ...state,
                copyDialogOpen: false,
                isCopying: false,
            };
        case actionTypesEditComp.COPY_COMPETITION_FAILED:
            console.log("Copy competition failed");
            return {
                ...state,
                isCopying: false,
                copyCompError: action.payload.error.message,
            };
        case actionTypesEditComp.SET_COPY_DIALOG_OPEN: {
            return {
                ...state,
                copyDialogOpen: action.payload.open,
            }
        }
        case actionTypesEditComp.SET_DELETE_DIALOG_OPEN: {
            return {
                ...state,
                deleteDialogOpen: action.payload.open,
            }
        }

        case actionTypes.START_LOAD_COMPETITION_DATA:
            return {
                ...state,
                competitionDataLoaded: false,
            }
        case actionTypes.LOAD_COMPETITION_DATA_FINISHED:
            return {
                ...state,
                competitionDataLoaded: true,
            }
        case actionTypesCompCard.REQUEST_SET_PATRONATE:
            console.log("Request set patronate");
            return state;
        case actionTypesCompCard.REQUEST_SET_PATRONATE_SUCCESS:
            console.log("Request set patronate success");
            const { competitionListKey, compID, nsffPatronate } = action.meta;

            const newListData = state[competitionListKey].data.map(comp => comp.competitionID !== compID ? comp : { ...comp, nsffPatronate });

            return {
                ...state,
                [competitionListKey]: {
                    ...state[competitionListKey],
                    data: newListData
                }
            };
        case actionTypesCompCard.REQUEST_SET_PATRONATE_FAILED:
            console.log("Request set patronate failed");
            return {
                ...state,
                patronageError: "Kunne ikke lagre patronage",
            }
        case actionTypesCompCard.REQUEST_DELETE_PATRONATE:
            console.log("Request delete patronate");
            return state;
        case actionTypesCompCard.REQUEST_DELETE_PATRONATE_SUCCESS:
            console.log("Request delete patronate success");

            const newListDataDelete = state[action.meta.competitionListKey].data.map(comp => comp.competitionID !== action.meta.compID ? comp : { ...comp, nsffPatronate: null });

            return {
                ...state,
                [action.meta.competitionListKey]: {
                    ...state[action.meta.competitionListKey],
                    data: newListDataDelete
                }
            };
        case actionTypesCompCard.REQUEST_DELETE_PATRONATE_FAILED:
            console.log("Request delete patronate failed");
            return {
                ...state,
                patronageError: "Kunne ikke slette patronage",
            }
        case actionTypes.REQUEST_CREATE_PUBLIC_JURYMEMBER:
            return {
                ...state,
                creatingJuryMember: true,
            }
        case actionTypes.REQUEST_CREATE_PUBLIC_JURYMEMBER_SUCCESS:
            return {
                ...state,
                selectedComp: { ...state.selectedComp, isJuryMember: true },
                creatingJuryMember: false,
            }
        case actionTypes.REQUEST_CREATE_PUBLIC_JURYMEMBER_FAILED:
            return {
                ...state,
                creatingJuryMember: false,
                creatingJuryMemberError: action.payload.error.message,
            }
        case actionTypes.REQUEST_COMPETITION:
            return {
                ...state,
                selectedComp: null,
                loadingSelectedComp: true,
            }
        case actionTypes.REQUEST_COMPETITION_SUCCESS:
            return {
                ...state,
                selectedComp: action.payload.response,
                loadingSelectedComp: false,
            }
        case actionTypes.REQUEST_COMPETITION_FAILED:
            return {
                ...state,
                selectedComp: action.payload.response,
                loadingSelectedComp: false,
                loadSelectedCompErrorMessage: action.payload.error.message,
            }
        default:
            return state;
    }
}

export default competitionReducer;