import { connect } from 'react-redux';
import DistinctionItemListItem from './DistinctionItemListItem.component';

const mapStateToProps = (state, props) => {
    const distinctionItemID = props.distinctionItem.distinctionItemID;
    return {
        distinction: state.distinctionsForPerson.formDistinctionsByItemID[distinctionItemID],
        formDistinction: state.distinctionsForPerson.formDistinctionsByItemID[distinctionItemID] || {},
    }
};

const mapDispatchToProps = (dispatch) => ({
});
export default connect(mapStateToProps, mapDispatchToProps)(DistinctionItemListItem); 