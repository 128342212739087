import {
    actionTypes as resultActionTypes
} from '../components/Pages/Competition/Result/Result.actions';

import {
    actionTypes as evaluationActionTypes
} from '../components/Pages/Competition/Evaluations/Evaluations.actions';
import { LOCATION_CHANGE } from 'connected-react-router';


const initState = {
    pageLoading: true,
}

const competitionResultReducer = (state = initState, action) => {
    switch(action.type) {
        case LOCATION_CHANGE: {
            return {
                ...state,
                pageLoading: true,
            };
        }
        case resultActionTypes.START_LOAD_RESULT_PAGE:
            return {
                pageLoading: true,
            };
        case resultActionTypes.FINISHED_LOADING_RESULT_PAGE:
            return {
                ...state,
                pageLoading: false,
            }
        case resultActionTypes.START_REQUEST_RESULTS:
            return {
                ...state,
                result: {
                    loading: true,
                }
            };
        
        case resultActionTypes.REQUEST_RESULTS_FINISHED:
            return {
                ...state,
                result: {
                    ...state.result,
                    loading: false,
                }
            };
        case resultActionTypes.REQUEST_RESULT_SUCCESS:
            console.log("Results success");
            return {
                ...state,
                result: {
                    ...state.result,
                    data: action.payload.response,
                },
            };
        case resultActionTypes.REQUEST_RESULT_FAILED:
            console.log("Results failed");
            return {
                ...state,
                result: {
                    ...state.result,
                    error: action.payload.error.message,
                },
            };
        case resultActionTypes.REQUEST_ORGANIZATION_RESULT_SUCCESS:
            console.log("Results success");
            return {
                ...state,
                result: {
                    ...state.result,
                    orgData: action.payload.response,
                }
            };
        case resultActionTypes.REQUEST_ORGANIZATION_RESULT_FAILED:
            console.log("Results failed");
            return {
                ...state,
                result: {
                    ...state.result,
                    error: action.payload.error.message,
                },
            };
        case evaluationActionTypes.REQUEST_EVALUATIONS:
                console.log("Requesting results");
                return {
                    ...state,
                    evaluations: {
                        loading: true,
                    }
                };
        case evaluationActionTypes.REQUEST_EVALUATIONS_SUCCESS:
            console.log("Results success");
            return {
                ...state,
                evaluations: {
                    loading: false,
                    data: action.payload.response,
                }
            };
        case evaluationActionTypes.REQUEST_EVALUATIONS_FAILED:
            console.log("Results failed");
            return {
                ...state,
                evaluations: {
                    loading: false,
                    error: action.payload.error.message,
                }
            };
        default:
            return state;
    }
}

export default competitionResultReducer;





