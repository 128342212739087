import Api from './apiService';

export const competitionParticipationService = {
    getParticipation,
    uploadImage,
    deleteImage,
    processPayment,
    getParticipationsByOrganizations,
};

function getParticipation(competitionID) {
    return Api.get(`participant?competitionID=${competitionID}`);
}

function uploadImage(competitionID, competitionClassID, image, name, videoUrl) {
    const value = {
        competitionClassID,
        name,
        videoUrl,
    }
    const data = new FormData();
    data.append("file", image);
    data.append("value", JSON.stringify(value));

    return Api.request({
        url: `participant/classPartitipations?competitionID=${competitionID}`,
        body: data,
        useCustomHeader: true,
        method: "POST", 
        headers: { Authorization: Api.getAuthorizationHeader() },
    }).then(result => {
        return result;
    });
}

function processPayment(paymentToken, competitionID) {
    return Api.post(`participant/processPayment?competitionID=${competitionID}`, { stripeTokenID: paymentToken });
}

function deleteImage(imageID, competitionID) {
    return Api.delete(`participant/classPartitipations/${imageID}?competitionID=${competitionID}`);
}

function getParticipationsByOrganizations(competitionID) {
    return Api.get(`competitions/${competitionID}/participants`);
}