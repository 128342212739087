import {
    actionCreator,
} from '../../../../../../Utils/actions.utils';
import {
    competitionScoringService,
} from '../../../../../Services/competitionScoringService';
export const actionTypes = {
    REQUEST_SAVE_EVALUATION: 'REQUEST_SAVE_EVALUATION',
    SAVE_EVALUATION_SUCCESS: 'SAVE_EVALUATION_SUCCESS',
    SAVE_EVALUATION_FAILED: 'SAVE_EVALUATION_FAILED',
    EVALUATION_FORM_FIELD_UPDATED: 'EVALUATION_FORM_FIELD_UPDATED',
    ON_VIEW_EDIT_EVALUATION: 'ON_VIEW_EDIT_EVALUATION',
    ON_CANCEL_EDIT_EVALUATION: 'ON_CANCEL_EDIT_EVALUATION',
    ON_EVALUATION_FORM_INVALID: 'ON_EVALUATION_FORM_INVALID',
}



export function saveEvaluation(competitionID, classParticipationID, comment) {
    return (dispatch) => {
        const payload = { competitionID, classParticipationID, comment };
        dispatch(actionCreator(actionTypes.REQUEST_SAVE_EVALUATION)({ ...payload }));

        var promise = competitionScoringService.saveScore(competitionID, classParticipationID, null, comment);

        return promise.then(
            response => dispatch(actionCreator(actionTypes.SAVE_EVALUATION_SUCCESS)({ ...payload })),
            error => dispatch(actionCreator(actionTypes.SAVE_EVALUATION_FAILED)({ ...payload })),
        );
    }
};


export const onEvaluationFormFieldUpdated =
    (field, value) => actionCreator(actionTypes.EVALUATION_FORM_FIELD_UPDATED)({ field, value });

export const onViewEditEvaluation =
    (formValues) => actionCreator(actionTypes.ON_VIEW_EDIT_EVALUATION)({ formValues });

export const onCancelEditEvaluation =
    () => actionCreator(actionTypes.ON_CANCEL_EDIT_EVALUATION)({});

export const onEvaluationFormInvalid =
    (validationErrorMessage) => actionCreator(actionTypes.ON_EVALUATION_FORM_INVALID)({ validationErrorMessage });