import { connect } from 'react-redux';
import DistinctionsForPerson from './EditDistinctionsForPerson.component';
import {
    goBackToPersonDistinctionList,
    requestSetPersonDistinction,
    setViewMode,
    getImportedResults,
} from './EditDistinctionsForPerson.actions';

const mapStateToProps = (state) => {
    const distinctionsForPerson = state.distinctionsForPerson || {};

    return {
        selectedPerson: distinctionsForPerson.selectedPerson,
        pointsByDistinctionTypeID: distinctionsForPerson.pointsByDistinctionTypeID,
        formDistinctionsByItemID: distinctionsForPerson.formDistinctionsByItemID,
        distinctionsByItemID: distinctionsForPerson.distinctionsByItemID,
        viewMode: distinctionsForPerson.viewMode,
    }
};

const mapDispatchToProps = (dispatch) => ({
    goBackToPersonDistinctionList: () => {
        dispatch(goBackToPersonDistinctionList());
    },
    onSetPersonDistinction: (personID, distinctionTypeID, distinctionItemID, year) => {
        dispatch(requestSetPersonDistinction(personID, distinctionTypeID, distinctionItemID, year));
    },
    setViewMode: (mode) => {
        dispatch(setViewMode(mode));
    },
    getImportedResults: (personID, mode) => {
        dispatch(getImportedResults(personID, mode));
    }
});


export default connect(mapStateToProps, mapDispatchToProps)(DistinctionsForPerson);