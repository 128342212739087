import React from 'react';
import NumberField from './NumberField.component';

class IntegerField extends React.Component {
    onChange = (ev) => {
        const value = ev.target.value;

        if(value.endsWith(',') || value.endsWith('.')) {
            return;
        }
        var newEv = {
            ...ev,
            target: {
                ...ev.target,
                value: value === '' ? '' : value,
            }
        }
        this.props.onChange && this.props.onChange(newEv);
    }

    onBlur = (ev) => {
        this.props.onBlur && this.props.onBlur({
            ...ev,
            target: {
                ...ev.target,
                name: ev.target.name,
                value: ev.target.value === '' ? '' : parseInt(ev.target.value),
            }
        });
    }

    render(){
        const {
            onChange,
            onBlur,
            ...passOnProps
        } = this.props;
        return (
            <NumberField
                onBlur={this.onBlur}
                onChange={this.onChange}
                {...passOnProps}
            />
        );
    }
}


export default IntegerField;