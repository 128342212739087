import { actionTypes } from '../components/Pages/Start/Start.actions';

const initState = {
    appLoading: true,
}

const startReducer = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.INIT_DATA:
            console.log("Initializing data");
            return {
                ...state,
                appLoading: true,
            }
        case actionTypes.INIT_DATA_FAILED:
            console.log("Initializing data failed");
            return {
                ...state,
                appLoading: false,
                errorMessage: action.payload.error,
            }
        case actionTypes.INIT_DATA_FINISHED:
            console.log("Initializing data");
            return {
                ...state,
                appLoading: false,
            }
        case actionTypes.REQUEST_CLASSES:
            console.log('Fetching classes');
            return {
                ...state,
                fetchErrorMessageClasses: null,
            };
        case actionTypes.REQUEST_CLASSES_SUCCESS:
            console.log('Classes fetch successful');
            const classesByID = action.payload.response.reduce((obj, item) => {
                obj[item.classID] = item;
                return obj;
            }, {});
            return {
                ...state,
                classes: action.payload.response,
                classesByID,
            };
        case actionTypes.REQUEST_CLASSES_FAILED:
            console.log('Classes fetch failed');
            return {
                ...state,
                fetchErrorMessageClasses: action.payload.error.message,
            };
        case actionTypes.REQUEST_ADMINS:
            console.log('Fetching admins');
            return {
                ...state,
                fetchErrorMessageClasses: null,
            };
        case actionTypes.REQUEST_ADMINS_SUCCESS:
            console.log('Admins fetch successful');
            const adminsByOrgID = action.payload.response.reduce((obj, item) => {
                obj[item.organizationID] = {
                    ...obj[item.organizationID],
                    [item.personID]: { ...item },
                };
                return obj;
            }, {});
            return {
                ...state,
                adminsByOrgID,
            };
        case actionTypes.REQUEST_ADMINS_FAILED:
            console.log('Admins fetch failed');
            return {
                ...state,
                fetchErrorMessageClasses: action.payload.error.message,
            };
        default:
            return state;
    }
}

export default startReducer;