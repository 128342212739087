import { actionTypes } from '../components/Pages/Admin/Admin.actions';

const initState = {
    isLoading: true,
}

const adminReducer = (state = initState, action) => {
    switch(action.type) {
        case actionTypes.REQUEST_ORG_ADMINS:
            console.log('Requesting roles');
            return {
                ...state,
                errorMessage: null,
                isLoading: true,
                
            };
        case actionTypes.REQUEST_ORG_ADMINS_SUCCESS:
            console.log('Roles request success');
            return {
                ...state,
                errorMessage: null,
                isLoading: false,
                roles: action.payload.response,
            };
        case actionTypes.REQUEST_ORG_ADMINS_FAILED:
            console.log('Role request failed');
            return {
                ...state,
                isLoading: false,
                errorMessage: action.payload.error.message,
            };
            case actionTypes.REQUEST_SEARCH:
                console.log('Requesting search member in org');
                return {
                    ...state,
                    searchErrorMessage: null,
                    isLoadingSearch: true,
                };
            case actionTypes.REQUEST_SEARCH_SUCCESS:
                console.log('Requesting search member in org success');
                return {
                    ...state,
                    searchErrorMessage: null,
                    isLoadingSearch: false,
                    searchResult: action.payload.response,
                };
            case actionTypes.REQUEST_SEARCH_FAILED:
                console.log('Requesting search member in org failed');
                return {
                    ...state,
                    isLoadingSearch: false,
                    searchErrorMessage: action.payload.error.message,
                };
            case actionTypes.CLEAR_SEARCH_RESULT:
                console.log('Clearing search result');
                return {
                    ...state,
                    searchResult: [],
                };
            case actionTypes.REQUEST_SET_ROLE:
                console.log('Requesting set role in org');
                return {
                    ...state,
                    loadingSettingRole: true,
                    setRoleError: null,
                };
            case actionTypes.REQUEST_SET_ROLE_SUCCESS:
                console.log('Requesting set role in org success');
                const newRoleList = []
                
                state.roles.forEach(role => {
                    if(role.organizationRoleID === action.payload.organizationRoleID && !role.allowMultiple) {
                        role.organizationRoleMembers = [action.payload.person];
                    } else if(role.organizationRoleID === action.payload.organizationRoleID && !role.organizationRoleMembers.some(person => person.personID === action.payload.person.personID)) {
                        role.organizationRoleMembers.push(action.payload.person);
                    }
                    newRoleList.push(role);
                });

                return {
                    ...state,
                    roles: newRoleList,
                    loadingSettingRole: false,
                };
            case actionTypes.REQUEST_SET_ROLE_FAILED:
                console.log('Requesting set role in org failed');
                return {
                    ...state,
                    loadingSettingRole: false,
                    setRoleError: action.payload.error.message,
                };
            case actionTypes.REQUEST_REMOVE_ROLE:
                console.log('Requesting remove role in org');
                return {
                    ...state,
                    removeRoleError: null,
                };
            case actionTypes.REQUEST_REMOVE_ROLE_SUCCESS:
                console.log('Requesting remove role in org success');
                const newRoleListRemove = []
                
                state.roles.forEach(role => {
                    if(role.organizationRoleID === action.payload.organizationRoleID && !role.allowMultiple) {
                        role.organizationRoleMembers = [];
                    } else if(role.organizationRoleID === action.payload.organizationRoleID) {
                        role.organizationRoleMembers = role.organizationRoleMembers.filter(persons => {
                            return persons.personID !== action.payload.person.personID;
                        });
                    }
                    newRoleListRemove.push(role);
                });

                return {
                    ...state,
                    roles: newRoleListRemove,
                };
            case actionTypes.REQUEST_REMOVE_ROLE_FAILED:
                console.log('Requesting remove role in org failed');
                return {
                    ...state,
                    removeRoleError: action.payload.error.message,
                };
        default:
            return state;
    }
}

export default adminReducer;