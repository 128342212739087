import { connect } from 'react-redux';
import ViewAndScore from './ViewAndScore.component';
import withLoadingIndicator from '../../../../LoadingIndicator/withLoadingIndicator';

import {
    fullScreenIndexChange,
    showFullscreen,
    showList,
} from './ViewAndScore.actions';

const mapStateToProps = (state) => {
    const viewAndScore = state.viewAndScore || {};
    return {
        ready: !viewAndScore.loading,
        classParticipations: viewAndScore.data,
        showFullscreen: viewAndScore.showFullscreen,
        fullscreenInitialIndex: viewAndScore.fullscreenInitialIndex,
        classesByID: state.metaData.classesByID,
    }
}

const mapDispatchToProps = (dispatch) => ({
    onFullscreenIndexChange: (index) => {
        dispatch(fullScreenIndexChange(index));
    },
    onShowFullscreen: (fullscreenInitialIndex) => {
        dispatch(showFullscreen(fullscreenInitialIndex));
    },
    onShowList: () => {
        dispatch(showList());
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(withLoadingIndicator()(ViewAndScore));