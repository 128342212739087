import React from 'react';
import {
    Select,
    MenuItem,
    OutlinedInput,
    withStyles,
    FormControl,
    InputLabel,
    Button,
    Typography
} from '@material-ui/core';
import withInternalChangeHandler from '../../../../../Fields/withInternalChangeHandler';
import { getAwardByValue } from '../../awards';
import ProgressButton from '../../../../../Button/ProgressButton.component';
import TextField from '../../../../../Fields/TextField/TextField.component';


const FormTextField = withInternalChangeHandler()(TextField);


const getStyles = (theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 600,
        width: '100%',
    },
    displayItem: {
        paddingTop: 10,
        paddingBottom: 10,
    },
    viewButton: {
        marginRight: 10,
    },
    juryItem: {
        margin: 10,
    },
    comment: {
        margin: 10,
        fontStyle: 'italic',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
});
class AwardFullscreen extends React.Component {    

    onBlur = (field, value) => {
        this.props.onAwardFormFieldUpdated(field,value);
    }

    getDefaultComment = () => {
        return this.props.classParticipation.comments.reduce((accComment, comment) => accComment+=(comment+'\n'),'');
    }

    handleShowEditAward = () => {
        const { award, awardComment } = this.props.classParticipation;
        this.props.onViewEditAward({
            award: award,
            comment: awardComment || this.getDefaultComment(),
        });
    }

    cancelEditAward = () => {
        this.props.onCancelEditAward();
    }

    handleRemoveAward = () => {
        const { classParticipation, competitionData } = this.props;
        this.props.removeAward(competitionData.competitionID, classParticipation.classParticipationID);
    }

    validateForm = () => {
        const formValues = this.props.formValues;
        if(formValues && formValues.award) {
            return true;
        }
        this.props.onAwardFormInvalid("Plassering må være satt");
    }

    handleSaveAward = (e) => {
        e.preventDefault();
        const { classParticipation, competitionData, formValues } = this.props;

        if(this.validateForm()) {
            this.props.saveAward(
                competitionData.competitionID,
                classParticipation.classParticipationID,
                formValues.award,
                formValues.comment
            );
        }
    }

    renderForExisting = () => {
        const { classParticipation, competitionData, showEditAward, classes } = this.props;

        const award = getAwardByValue(classParticipation.award);
        return showEditAward ? this.renderForm(true, "Oppdater") : (
            <div>
                <div className={classes.displayItem}>
                    <Typography variant="h6">
                        Plassering
                    </Typography>
                    <Typography variant="h4">
                        {award.formDisplayName}
                    </Typography>
                </div>
                <div className={classes.displayItem}>
                    <Typography variant="h6">
                        Kommentar
                    </Typography>
                    <div>
                        {classParticipation.awardComment}
                    </div>
                </div>
                <div className={classes.displayItem}>
                    <Typography variant="h6">
                        Antall jurypoeng
                    </Typography>
                    <Typography variant="h4">
                        {classParticipation.score}
                    </Typography>
                </div>
                {!competitionData.finished &&
                    <div className={classes.buttonContainer}>
                        <Button onClick={this.handleShowEditAward} variant="outlined" className={classes.viewButton}>Endre plassering</Button>
                        <Button onClick={this.handleRemoveAward} variant="outlined">Fjern plassering</Button>
                    </div>
                }
            </div>
        );
    }


    renderJuryComments = () => {
        const { classes, classParticipation} = this.props;

        return (
            <div>
                <Typography variant="h6">
                    Jurykommentarer
                </Typography>
                {classParticipation.comments.map((c, i) => {
                    const commentText = `“${c}”`;
                    return (
                        <Typography variant="body2" key={i} className={classes.comment}>
                            {commentText}
                        </Typography>
                    )
                })}
            </div>
        );
    }

    renderForm = (allowCancel, submitText) => {

        const { classes, classParticipation, validationErrorMessage, awards } = this.props;
        const formValues = this.props.formValues || {};
        const awardString = classParticipation.award && classParticipation.award.toString();
        const availableAwards = awards.filter(a => !a.notAvailable || a.value.toString() === awardString);

        return (
            <form name="form" onSubmit={this.handleSaveAward} noValidate className={classes.form}>
                <Typography variant="h6" className={classes.juryItem}>
                    Antall jurypoeng
                </Typography>
                <Typography variant="h4">
                    {classParticipation.score}
                </Typography>
                <div className={classes.juryItem}>
                    {this.renderJuryComments()}
                </div>
                <FormControl required variant="outlined" className={classes.formControl}>
                    <InputLabel>
                        Plassering
                    </InputLabel>
                    <Select
                        value={formValues.award || ''}
                        onChange={(ev) => { this.onBlur('award', ev.target.value); }}
                        input={<OutlinedInput labelWidth={95} name="organizationID" id="outlined-age-simple" />}
                    >
                        {availableAwards.map(award => (
                            <MenuItem key={award.value} value={award.value}>
                                {award.formDisplayName}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl required variant="outlined" className={classes.formControl}>
                    <FormTextField
                        name="comment"
                        label="Kommentar"
                        onBlur={(ev) => { this.onBlur('comment', ev.target.value);}}
                        multiline
                        rows="4"
                        value={formValues.comment || ''}
                        style={{margin: '10px 0px 10px 0px'}}
                    />
                </FormControl>
                {validationErrorMessage &&
                        <Typography color="error" variant="overline" display="block" align="center">
                            {validationErrorMessage}
                        </Typography>
                    }
                <div className={classes.buttonContainer}>
                    {allowCancel &&
                        <Button
                            variant="contained"
                            type="button"
                            className={classes.viewButton}
                            onClick={this.cancelEditAward}
                        >
                            Avbryt
                        </Button>
                    }
                    <div>
                        <ProgressButton variant="contained" type="submit" color="primary" className={classes.button} loading={this.props.saving} >
                            {submitText}
                        </ProgressButton>
                    </div>

                </div>


            </form>
        );
    }

    render() {
        const { classParticipation, competitionData } = this.props;

        const awardExists = !!classParticipation.award;

        if(!competitionData.juryingClosed) {
            return (
                <div>
                    Premiering er kun tilgjengelig når juryeringen er lukket. 
                </div>
            );
        }
        if(competitionData.finished && !awardExists) {
            return (
                <div>
                    Premieringen er lukket. 
                </div>
            );
        }


        return awardExists ? this.renderForExisting() : this.renderForm(false, "Premier");
    }

}


export default withStyles(getStyles)(AwardFullscreen);