import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CompetitionStatusDialog from './CompetitionStatusDialog.component';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';
import {
    Button,
    List,
    Collapse,
    Stepper,
    Step,
    StepLabel,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core';
import {
    ExpandLess,
    ExpandMore,
    Info
} from '@material-ui/icons';

const styles = theme => ({
    root: {

    },
    paper: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        marginBottom: 8,
    },
    juryStatusPaper: {
        padding: 0,
        margin: 0,
    },
    classItemPaper: {
        padding: 10,
        marginBottom: 10,
    },
    classItem: {
        display: 'flex',
    },
    classInfo: {
        flexGrow: 1,
    },
    juryMemberItem: {
        padding: 10,
    },
    progressBar: {
        marginTop: 5,
    },
    juryStatusHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: '16px 24px 16px 16px',
    },
    juryStatusContent: {
        padding: '16px 24px 16px 16px',
    },
    juryStatusHeaderText: {
        flexGrow: 1,
    },
    statusInfo: {
        textAlign: "center",
    },
});

class CompetitionStatus extends React.Component {
    state = {
        dialogOpen: false,
        openCompetitionClassMenu: null,
        juryStatusOpen: null,
    }

    calcProgressInPerCent = (amtScored, totalAmt) => {
        if(totalAmt === 0) return 100;
        return (amtScored / totalAmt)*100;
    }

    toggleOpenCompetitionClassMenu = (competitionClassID) => {
        if(this.state.openCompetitionClassMenu === competitionClassID) {
            this.setState({ openCompetitionClassMenu: null});
        } else {
            this.setState({ openCompetitionClassMenu: competitionClassID });
        }
    }

    toggleJuryStatusOpen = (juryStatusOpen) => {
        this.setState({ juryStatusOpen : !juryStatusOpen });
    }


    renderClassJuryMemberStatus = (classStatus, juryMemberStatus) => {
        const { classes } = this.props;
        return (
            <div key={juryMemberStatus.juryMemberID} className={classes.juryMemberItem}>
                <div>{`${juryMemberStatus.firstName} ${juryMemberStatus.lastName} (${juryMemberStatus.count}/${classStatus.totalCount})`}</div>
                <LinearProgress className={classes.progressBar} color="primary" variant="determinate" value={this.calcProgressInPerCent(juryMemberStatus.count, classStatus.totalCount)} />
            </div>
        );
    }

    goToResult = () => {
        this.props.seeResult(this.props.competitionData.competitionID);
    }



    renderCompetitionClassItem = (competitionClass) => {
        const { classesByID, classes } = this.props;
        const competitionClassID = competitionClass.competitionClassID;
        const classID = competitionClass.classID;
        const openCompetitionClassMenu = this.state.openCompetitionClassMenu;
        var classStatus = this.props.competitionStatus ? this.props.competitionStatus[competitionClassID] : { juryMembersStatus: []};

        const juryMembersScoreCount = classStatus.juryMembersStatus.reduce((sum, juryMemberStatus) => sum+juryMemberStatus.count,0);
        const totalParticipationCount = classStatus.totalCount * classStatus.juryMembersStatus.length;
        const percent =this.calcProgressInPerCent(juryMembersScoreCount, totalParticipationCount);
        return (
            <Paper
                className={classes.classItemPaper}
                key={competitionClassID}
                onClick={() => {this.toggleOpenCompetitionClassMenu(competitionClassID); }}
            >
                <div className={classes.classItem}>
                    <div className={classes.classInfo}>
                        {`${classesByID[classID].name} ${competitionClass.theme ? competitionClass.theme : ''} (${percent}%)`}
                        <LinearProgress className={classes.progressBar} color="primary" variant="determinate" value={percent} />
                    </div>
                    {openCompetitionClassMenu === competitionClassID ? <ExpandLess /> : <ExpandMore /> }
                </div>
                <Collapse in={openCompetitionClassMenu === competitionClassID} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {classStatus.juryMembersStatus.map(j => this.renderClassJuryMemberStatus(classStatus, j))}
                    </List>
                </Collapse>
            </Paper>
        );
    }

    getStatus = () => {
        const competitionData = this.props.competitionData;
        if(competitionData.juryingClosed) {
            if(competitionData.awardingClosed) {
                return "awardingClosed";
            }
            return "openForAwarding";
        }
        return "openForJurying";
    }

    getJuryStatusOpen = (competitionStatus) => {
        if(competitionStatus === "openForJurying" && this.state.juryStatusOpen === null) {
            return true;
        }
        return this.state.juryStatusOpen;

    }

    statusDefinitions = {
        openForJurying: {
            step: 0,
            renderContent: () => {
                return (
                    <div className={this.props.classes.statusInfo}>
                        <ListItem>
                            <ListItemIcon>
                                <Info />
                            </ListItemIcon>
                            <ListItemText
                                primary="Konkurransen er åpen for juryering"
                                secondary="Jurymedlemmer kan gi poeng."
                            />
                        </ListItem>
                        <div>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={this.openDialog}
                            >
                                Lukk juryering
                            </Button>
                        </div>
                    </div>
                );
            },
            dialogTitle: "Lukk juryering?",
            dialogContent: "Er du sikker på at du vil lukke juryering? Juryering kan ikke åpnes igjen etter at den har blitt lukket.",
            positiveButtonLable: "Lukk juryering",
            onClick: () => {this.props.closeJurying(this.props.competitionData.competitionID)},
        },
        openForAwarding: {
            step: 1,
            renderContent: () => {
                return (
                    <div className={this.props.classes.statusInfo}>
                        <ListItem>
                            <ListItemIcon>
                                <Info />
                            </ListItemIcon>
                            <ListItemText
                                primary="Konkurransen er åpen for premiering"
                                secondary="Juryleder kan se bilders sammlede poengsum og premeiere dem."
                            />
                        </ListItem>
                        <div>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={this.openDialog}
                            >
                                Fullfør premiering
                            </Button>
                        </div>
                    </div>
                );
            },
            dialogTitle: "Fullfør premiering?",
            dialogContent: "Er du sikker på at du vil fullføre premieringen? Premiering kan ikke åpnes igjen etter at den har blitt fullført.",
            positiveButtonLable: "Fullfør premiering",
            onClick: () => {this.props.closeAwarding(this.props.competitionData.competitionID)},
        },
        awardingClosed: {
            step: 2,
            renderContent: () => {
                return (
                <div className={this.props.classes.statusInfo}>
                    <ListItem>
                        <ListItemIcon>
                            <Info />
                        </ListItemIcon>
                        <ListItemText
                            primary="Premiering er nå fullført"
                            secondary="Poenggiving og premiering er nå lukket."
                        />
                    </ListItem>
                    <div>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={this.goToResult}
                        >
                            Se resultater
                        </Button>
                    </div>
                </div>
                );
            },
            dialogTitle: "",
            dialogContent: "",
            positiveButtonLable: "",
            onClick: null,
        }
    }

    openDialog = () => {
        this.setState({dialogOpen: true});
    }

    closeDialog = () => {
        this.setState({dialogOpen: false});
    }

    renderCompetitionStatus = () => {
        const competitionStatus = this.getStatus();
        return (
            <div>
                <Stepper activeStep={this.statusDefinitions[competitionStatus].step}>
                    <Step>
                        <StepLabel>Juryering</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>Premiering</StepLabel>
                    </Step>
                </Stepper>
                <div>
                    {this.statusDefinitions[competitionStatus].renderContent()}
                    <CompetitionStatusDialog
                        dialogOpen={this.state.dialogOpen}
                        confirmFunction={this.statusDefinitions[competitionStatus].onClick}
                        closeFunction={this.closeDialog}
                        dialogTitle={this.statusDefinitions[competitionStatus].dialogTitle}
                        dialogContent={this.statusDefinitions[competitionStatus].dialogContent}
                        positiveButtonLable={this.statusDefinitions[competitionStatus].positiveButtonLable}
                    />
                </div>
            </div>
        );
    }

    render() {
        const { classes, competitionData } = this.props;
        const status = this.getStatus(competitionData);
        const juryStatusOpen = this.getJuryStatusOpen(status);
        return (
            <div className={classes.root}>
                <Paper className={classes.paper} elevation={1}>
                    <Typography variant="h5" style={{marginBottom: 16}}>
                        Konkurransestatus
                    </Typography>
                    {this.renderCompetitionStatus()}
                </Paper>
                <Paper className={classes.juryStatusPaper} elevation={1}>
                    <div className={classes.juryStatusHeader} onClick={() =>this.toggleJuryStatusOpen(juryStatusOpen)}>
                    <Typography variant="h5" className={classes.juryStatusHeaderText}>
                        Juryeringsstatus
                    </Typography>
                        {juryStatusOpen ? <ExpandLess /> : <ExpandMore /> }
                    </div>

                    <Collapse in={juryStatusOpen} timeout="auto" unmountOnExit>
                        <div className={classes.juryStatusContent}>
                            {competitionData.competitionClasses.map(competitionClass => this.renderCompetitionClassItem(competitionClass))}
                        </div>
                        
                    </Collapse>
                </Paper>
            </div>
          );
    }
}

CompetitionStatus.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CompetitionStatus);