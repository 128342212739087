import React from 'react';
import withPaging from '../../../Table/withPaging';
import Table from '../../../Table/Table.component';
import { Paper, Typography, withStyles, TableCell } from '@material-ui/core';
import getConfig from '../../../../config/config';


const TableWithPaging = withPaging()(Table);

const config = getConfig();


const columns = [
    { 
        key: 'paymentDescription',
        displayName: 'Beskrivelse',
    },
    {
        key: 'amount',
        displayName: 'Beløp',
    },
    {
        key: 'paymentStatus',
        displayName: 'Status',
    },
    {
        key: 'paymentUrl',
        displayName: 'Faktura',
        getDataCell: (props, data) => {
            const url = `${config.rubicUrl}${data.paymentUrl}`;
            return (
                <TableCell key={data[props.dataKey]}>
                    <a href={url} target="_blank" rel="noopener noreferrer">Se faktura</a>
                </TableCell>
            );
        }
    },
]

const getStyles = theme => ({
    paper: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        marginBottom: 8,
    },
});
class Payments extends React.Component {

    getPaging = () => {
        const { paging } = this.props;
        if(paging) {
            return {
                startPosition: paging.pageSize * (paging.pageNo - 1),
                numberOfItems: paging.pageSize,
            }
        }
        return null;
    }

    handlePageChange = (paging) => {
        this.props.onPageChange({
            pageNo: (paging.startPosition / paging.numberOfItems) +1,
            pageSize: paging.numberOfItems,
        });
    }

    render() {
        const { classes, payments } = this.props;
        return (
            <Paper className={classes.paper}>
                <Typography variant="h5" style={{ marginBottom: 16 }}>
                    Betalinger
                </Typography>
                <TableWithPaging
                    columns={columns}
                    data={payments}
                    dataKey="invoiceID"
                    paging={this.getPaging()}
                >

                </TableWithPaging>
            </Paper>
        );
    }
}

export default withStyles(getStyles)(Payments);