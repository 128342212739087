import React from 'react';
import { Button, Typography, Paper, withStyles } from '@material-ui/core';
import DistinctionItem from '../../../Distinction/DistinctionItem.component';

const getStyles = (theme) => ({
    root: {

    },
    paper: {
        padding: 10, 
        marginTop: 10,
    },
    personDetails: {
        margin: 10,
    },
    distinctionTypesContainer: {
        margin: 10,
    },
    importButton: {
        float: 'right',
    },
    distinctionItemContainer: {
        maxWidth: 400,
    },
    distinctionTypeContainer: {
        paddingTop: 10,
        paddingBottom: 10,
    },
    distinctionTypeHeader: {
        marginBottom: 10,
    }
});


class DistinctionsWrapper extends React.Component {
    state = {
        expandedPanel: null,
    }

    handleBackClick = () => {
        this.props.goBackToPersonDistinctionList();
    }

    handlePanelChange = (id) => {
        const expandedPanel = this.state.expandedPanel;
        if(expandedPanel === id) {
            this.setState({ expandedPanel: null });
            return;
        }
        this.setState({ expandedPanel: id });
    }

    handleYearChange = (distinctionTypeID, distinctionItemID, year) => {
        this.props.onSetPersonDistinction(
            this.props.selectedPerson.personID,
            distinctionTypeID,
            distinctionItemID,
            year,
        );
    }

    handleSetViewMode = (mode) => {
        this.props.setViewMode(mode);
    }

    getImportedResults = (mode) => {
        this.props.getImportedResults(this.props.selectedPerson.personID, mode);
    }

    renderForDistinctionType = (distinctionType) => {
        const { pointsByDistinctionTypeID, classes } = this.props;

        const distinctionItems = distinctionType.distinctionItems.map(di => this.renderDistinctionItem(di)).filter(di => di !== null);

        const hasAnyItems = distinctionItems.length > 0;
        const distPoints = pointsByDistinctionTypeID[distinctionType.distinctionTypeID];
        const points = distPoints && distPoints.points ? distPoints.points : 0;
        const text = distinctionType.displaySettings && distinctionType.displaySettings.showEarnedPointsForPerson ? `${distinctionType.name} (${points}p)` : distinctionType.name;
        return hasAnyItems && (
            <div className={classes.distinctionTypeContainer}>
                <Typography variant="h6" className={classes.distinctionTypeHeader}>
                    {text}
                </Typography>
                {distinctionItems}
            </div>
        );
    }

    renderDistinctionItem = (distinctionItem) => {
        const { classes } = this.props;
        const distinction = this.props.formDistinctionsByItemID[distinctionItem.distinctionItemID];
        return distinction ? (
            <div className={classes.distinctionItemContainer}>
                <DistinctionItem
                    distinctionItem={distinctionItem}
                    distinction={distinction}
                />
            </div>
        ) : null;
    }

    renderDistinctionTypes = () => {
        const { distinctionTypes, classes } = this.props;
        return (
            <div className={classes.distinctionTypesContainer}>
                {distinctionTypes.map(dt => this.renderForDistinctionType(dt))}
            </div>
        );
    }

    render() {
        const { classes, selectedPerson } = this.props;
        return (
            <div>
                <Button onClick={this.handleBackClick} variant="outlined">Tilbake</Button>
                <Paper className={classes.paper}>
                    <div className={classes.personDetails}>
                        <Typography variant="h5">
                            {`${selectedPerson.firstName} ${selectedPerson.lastName}`}
                        </Typography>
                        <Typography variant="subtitle1" color="textSecondary">
                            {`${selectedPerson.mainOrganizationName}`}
                        </Typography>
                    </div>
                    {this.renderDistinctionTypes()}
                </Paper>
            </div>

        );
    }
}


export default withStyles(getStyles)(DistinctionsWrapper);