import React, { useState } from 'react';
import { Dialog, DialogContent, DialogTitle, Button, Typography } from '@material-ui/core';
import ProgressButton from '../../../Button/ProgressButton.component';
import withInternalChangeHandler from '../../../Fields/withInternalChangeHandler';
import TextField from '../../../Fields/TextField/TextField.component';
import CheckboxField from '../../../Fields/CheckboxField/CheckboxField.component';
import { withStyles } from '@material-ui/styles';

const FormTextField = withInternalChangeHandler()(TextField);


const getStyles = (theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
    },
    actionContainer: {
        display: 'flex',
        justifyContent: 'space-evenly',
        paddingTop: 10,
    }
});

const CopyCompetitionDialog = (props) => {
    const { isCopying, onCopyCompetition, selectedCompetition, classes, copyError } = props;
    const [formValues, updateFormValues ] = useState({ name: `${selectedCompetition.name} - kopi`});
    const [validationErrorMessage, setValidationErrorMessage ] = useState(null);

    const onFormValueChange = (name, e) => {
        let value = e.target.value;
        if(e.target.type === 'checkbox') {
            value = e.target.checked;
        }
        updateFormValues({ ...formValues, [name]: value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if(!formValues.name) {
            setValidationErrorMessage("Navn må fylles ut");
        } else {
            onCopyCompetition(formValues);
        }
    }

    return (
            <Dialog
                {...props}
            >
                <DialogTitle>{"Kopier konkurranse?"}</DialogTitle>
                <DialogContent>
                    <form name="form" onSubmit={handleSubmit} noValidate className={classes.form}>
                        <FormTextField
                            required
                            label="Tittel"
                            onBlur={(e) => onFormValueChange("name", e)}
                            fullWidth
                            value={formValues.name || ""}
                        />
                        <CheckboxField
                            label="Kopier innleveringer"
                            value={formValues.copyParticipations}
                            onChange={(e) => onFormValueChange("copyParticipations", e)}
                        
                        />
                        <CheckboxField
                            label="Kopier jury"
                            value={formValues.copyJury}
                            onChange={(e) => onFormValueChange("copyJury", e)}
                        
                        />
                        <div className={classes.actionContainer}>
                            <Button onClick={props.onClose}>
                                Avbryt
                            </Button>
                            <ProgressButton type="submit" color="primary" loading={isCopying} variant="contained" >
                                Kopier
                            </ProgressButton>
                        </div>

                        {validationErrorMessage &&
                            <Typography color="error" variant="overline" display="block" align="center">
                                {validationErrorMessage}
                            </Typography>
                        }
                        {copyError &&
                            <Typography color="error" variant="overline" display="block" align="center">
                                {copyError}
                            </Typography>
                        }
                    </form>
                </DialogContent>

            </Dialog>
    );
}

export default withStyles(getStyles)(CopyCompetitionDialog);

