

import {
    actionTypes
} from '../components/Pages/Competition/Score/ViewAndAward/ViewAndAward.actions';

import {
    actionTypes as competitionScoreActionTypes
} from '../components/Pages/Competition/Score/Score.actions';
import {
    actionTypes as fullscreenActionTypes
} from '../components/Pages/Competition/Score/ViewAndAward/AwardFullscreen/AwardFullscreen.actions';

const initState = {
    loading: true,
    showFullscreen: false,
}

const sortDataList = (data) => {
    if(data) {
        return data.sort((a, b) => {
            //make null the highest number to push it to the bottom
            const aAward = a.award == null ? Number.MAX_SAFE_INTEGER : a.award;
            const bAward = b.award == null ? Number.MAX_SAFE_INTEGER : b.award;

            if (aAward > bAward) {
                return 1;
            } else if (aAward < bAward) { 
                return -1;
            }

            if(a.score < b.score) {
                return 1;
            } else if(a.score > b.score) {
                return -1;
            }
            return 0;
        });
    }
    return null;

}


const createCompetitionReducer = (state = initState, action) => {
    switch(action.type) {
        case actionTypes.AWARD_FULLSCREEN_INDEX_CHANGE:
            console.log('fullscreen index changed')
            return {
                ...state,
                awardFullscreen: {},
            }

        case actionTypes.AWARD_SHOW_FULLSCREEN:
            console.log('show fullscreen')
            return {
                ...state,
                showFullscreen: true,
                fullscreenInitialIndex: action.payload.fullscreenInitialIndex,
                awardFullscreen: {},
            }

        case actionTypes.AWARD_SHOW_LIST:
                console.log('show list')
                return {
                    ...state,
                    showFullscreen: false,
                    fullscreenInitialIndex: null,
                    awardFullscreen: {},
                    data: sortDataList(state.data),
                }
        
        case actionTypes.AWARD_SET_CLASS_PARTICIPATION_FILTER:
            return {
                ...state,
                classParticipationFilter: action.payload.filter,
            }

        case fullscreenActionTypes.REQUEST_SAVE_AWARD:
            console.log('Is submitting award');
            return {
                ...state,
                awardFullscreen: {
                    ...state.awardFullscreen,
                    saving: true,
                    error: null,
                }
            };
        case fullscreenActionTypes.SAVE_AWARD_SUCCESS:
            console.log('Awarding successful');
            
            return {
                ...state,
                data: state.data.map(i => {
                    if(i.classParticipationID === action.payload.classParticipationID) {
                        return { ...i, award: action.payload.award, awardComment: action.payload.comment };
                    }
                    return i;
                }),
                awardFullscreen: {
                    saving: false,
                    values: null,
                    error: null,
                }
            };
        case fullscreenActionTypes.SAVE_AWARD_FAILED:
            console.log('Awarding failed');
            return {
                ...state,
                awardFullscreen: {
                    ...state.awardFullscreen,
                    saving: false,
                    error: action.payload.error.message,
                }
            };
        case fullscreenActionTypes.REQUEST_REMOVE_AWARD:
            console.log('Is removing award');
            return {
                ...state,
                awardFullscreen: {
                    ...state.awardFullscreen,
                    deleting: true,
                    error: null,
                }
            };
        case fullscreenActionTypes.REMOVE_AWARD_SUCCESS:
            console.log('removing award successful');
            return {
                ...state,
                data: state.data.map(i => {
                    if(i.classParticipationID === action.payload.classParticipationID) {
                        const { award, awardComment, ...newData } = i;
                        return { ...newData };
                    }
                    return i;
                }),
                awardFullscreen: {},
            };
        case fullscreenActionTypes.REMOVE_AWARD_FAILED:
            console.log('removing award failed');
            return {
                ...state,
                awardFullscreen: {
                    ...state.awardFullscreen,
                    deleting: false,
                    error: action.payload.error.message,
                }                
            };

        case fullscreenActionTypes.AWARD_FORM_FIELD_UPDATED:
            console.log('award form field updated');
            return {
                ...state,
                awardFullscreen: {
                    ...state.awardFullscreen,
                    formValues: {
                        ...state.awardFullscreen.formValues,
                        [action.payload.field]: action.payload.value,
                    }
                }
            };
        case fullscreenActionTypes.ON_VIEW_EDIT_AWARD: 
            console.log('Init view edit award');
            return {
                ...state,
                awardFullscreen: {
                    showEditAward: true,
                    formValues: {
                        ...action.payload.formValues,
                    },
                },
            };
        case fullscreenActionTypes.ON_CANCEL_EDIT_AWARD:
            return {
                ...state,
                awardFullscreen: {},
            };
        case fullscreenActionTypes.ON_AWARD_FORM_INVALID:
            return {
                ...state,
                awardFullscreen: {
                    ...state.awardFullscreen,
                    validationErrorMessage: action.payload.validationErrorMessage,
                }
            };
        case competitionScoreActionTypes.REQUEST_TOTAL_CLASS_PARTICIPATION_SCORING_STATUS:
            return {
                loading: true,
            };
        case competitionScoreActionTypes.GET_TOTAL_CLASS_PARTICIPATION_SCORING_SUCCESS:
            return {
                loading: false,
                data: sortDataList(action.payload.response),
            };
        case competitionScoreActionTypes.GET_TOTAL_CLASS_PARTICIPATION_SCORING_FAILED:
            return {
                loading: false,
                errorMessage: action.payload.error.message,
            };

        default:
            return state;
    }
}

export default createCompetitionReducer;