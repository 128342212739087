import { connect } from 'react-redux';
import EvaluationFullscreenContent from './EvaluationFullscreenContent.component';

import {
    saveEvaluation,
    onEvaluationFormFieldUpdated,
    onViewEditEvaluation,
    onCancelEditEvaluation,
    onEvaluationFormInvalid,
} from './EvaluationFullscreenContent.actions';

const mapStateToProps = (state, props) => {
    const viewAndEvaluation = state.viewAndEvaluate;
    const evaluationFullscreen = viewAndEvaluation.evaluationFullscreen || {};
    return {
        saving: evaluationFullscreen.saving,
        formValues: evaluationFullscreen.formValues,
        validationErrorMessage: evaluationFullscreen.validationErrorMessage,
        showEditEvaluation: evaluationFullscreen.showEditEvaluation,
    }
}

const mapDispatchToProps = (dispatch) => ({
    saveEvaluation: (competitionID, classParticipationID, comment) => {
        dispatch(saveEvaluation(competitionID, classParticipationID, comment));
    },
    onEvaluationFormFieldUpdated: (field, value) => {
        dispatch(onEvaluationFormFieldUpdated(field, value));
    },
    onViewEditEvaluation: (formValues) => {
        dispatch(onViewEditEvaluation(formValues));
    },
    onCancelEditEvaluation: () => {
        dispatch(onCancelEditEvaluation());
    },
    onEvaluationFormInvalid: (validationErrorMessage) => {
        dispatch(onEvaluationFormInvalid(validationErrorMessage));
    }

});

export default connect(mapStateToProps, mapDispatchToProps)(EvaluationFullscreenContent);