import React from 'react';
import PropTypes from 'prop-types';
import getCompetitionType from '../../../../../competition/getCompetitionType';
import {
    withStyles,
    Paper,
    Typography,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    ListItemIcon,
    Avatar,
    Table,
    TableHead,
    TableCell,
    TableBody,
    TableRow,
} from '@material-ui/core';
import {
    Home as HomeIcon,
    Photo as PhotoIcon,
    Money as MoneyIcon,
    Info as InfoIcon,
    EventBusy as EventBusyIcon,
    EventAvailable as EventAvailableIcon
} from '@material-ui/icons';
import moment from "moment";
import "moment/locale/nb";
import isCompetitionEvaluationType from '../../../../../competition/isCompetitionEvaluationType';
moment.locale("nb");

const styles = (theme) => ({
    root: {
        marginBottom: 8,
    },
    chip: {
        margin: 8,
    },
    sectionText: {
        marginTop: 16,
    },
    avatar: {
        backgroundColor: "#001789",
    },
    tablePaper: {
        padding: 0,
        marginBottom: 8,
    },
    paymentCompletedContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    paymentCompletedIcon: {
        marginRight: 10,
        color: theme.palette.success.main,
    },
    metadata: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    metadataPaper: {
        marginRight: 8,
        marginBottom: 8,
        flex: 'auto',
    },
});

class CompetitionDetails extends React.Component {

    renderMetaData = () => {
        const { classes, competitionData } = this.props;
        const metaData = [
            {icon: <HomeIcon />, title: "Arrangør", data: competitionData.organizationName},
            {icon: <EventAvailableIcon />, title: "Start", data: moment(competitionData.submissionStart).format('LLL')},
            {icon: <EventBusyIcon />, title: "Slutt", data: moment(competitionData.submissionEnd).format('LLL')},
            {icon: <PhotoIcon />, title: "Maks antall innleveringer", data: competitionData.maxItems},
        ];

        return(
            <List className={classes.metadata}>
                {metaData.map((data, index) => 
                    <Paper elevation={1} key={index} className={classes.metadataPaper}>
                        <ListItem>
                            <ListItemIcon>
                                {data.icon}
                            </ListItemIcon>
                            <ListItemText
                                primary={data.title}
                                secondary={data.data}
                            />
                        </ListItem>
                    </Paper>
                )}
            </List>
        );
    }

    renderClassSection = () => {
        const { classes, competitionData, classesByID } = this.props;
        return (
            <div>
                <Typography className={classes.sectionText} style={{marginBottom: 8}} variant="h6">
                    Klasser
                </Typography>
                <Paper className={classes.tablePaper} elevation={1}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Klasse</TableCell>
                                <TableCell align="right">Tema</TableCell>
                                <TableCell align="right">Maks antall bilder</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {competitionData.competitionClasses.map((singleClass, key) => (
                                <TableRow key={key}>
                                    <TableCell component="th" scope="row">
                                        {classesByID[singleClass.classID].name}
                                    </TableCell>
                                    <TableCell align="right">{singleClass.theme}</TableCell>
                                    <TableCell align="right">{singleClass.maxItems}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Paper>
            </div>
        );
    }

    render() {
        const { classes, competitionData } = this.props;
        return (
            <div className={classes.root}>
                <div>
                    <Typography variant="h5">
                        {competitionData.name}
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                        {getCompetitionType(competitionData)}
                    </Typography>
                </div>
                {this.renderMetaData()}
                <div>
                    <Typography variant="h6">
                        Beskrivelse
                    </Typography>
                    <List style={{paddingTop: 0, paddingBottom: 0}}>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar className={classes.avatar}>
                                    <MoneyIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Pris" secondary={!competitionData.participationPrice || competitionData.participationPrice === 0 ? 'Gratis' : 'kr ' + competitionData.participationPrice} />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar className={classes.avatar}>
                                    <InfoIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Beskrivelse" secondary={competitionData.description} />
                        </ListItem>
                    </List>
                </div>
                {!isCompetitionEvaluationType(competitionData) && this.renderClassSection()}

            </div>
        );
    }
}

CompetitionDetails.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CompetitionDetails);