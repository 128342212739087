import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const styles = theme => ({
    button: {
        margin: theme.spacing(0.5),
    },
});

class Competition extends React.Component {
    handleClick = () => {
        this.props.confirmFunction();
        this.props.closeFunction();
    }

    closeDialog = () => {
        this.props.closeFunction();
    }

    render() {
        const { classes, dialogOpen, dialogTitle, dialogContent, positiveButtonLable } = this.props;

        return (
            <Dialog
                open={dialogOpen}
                onClose={this.closeDialog}
            >
                <DialogTitle>{dialogTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {dialogContent}
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{justifyContent: "center"}}>
                    <Button onClick={this.closeDialog} color="primary">
                        Avbryt
                    </Button>
                    <Button onClick={this.handleClick} className={classes.button} color="primary" variant="contained" autoFocus>
                        {positiveButtonLable}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(Competition);