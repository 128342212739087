import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";

const styles = theme => ({
    list: {
        width: "90%",
    },
});


class VerticalNumberedList extends React.Component {

    render() {
        const { classes, listData } = this.props;
        return (
            <div className={classes.list}>
                <Stepper orientation="vertical">
                    {listData.map(step => (
                    <Step key={step.label} active={true}>
                        <StepLabel>{step.label}</StepLabel>
                        <StepContent>
                            <Typography>{step.description}</Typography>
                        </StepContent>
                    </Step>
                    ))}
                </Stepper>
            </div>
        );
    }
}

export default withStyles(styles)(VerticalNumberedList);