import { actionTypes } from "../components/Pages/Clubs/Clubs.actions";

const initState = {
  isLoading: true,
  isError: false,
};

const ClubsReducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.REQUEST_ORGANIZATIONS:
      console.log("Fetching organizations");
      return {
        ...state,
        fetchErrorMessageClasses: null,
      };
    case actionTypes.REQUEST_ORGANIZATIONS_SUCCESS:
      console.log("Organizations fetch successful");
      const orgsByID = action.payload.response.reduce((obj, org) => {
        obj[org.organizationID] = org;
        return obj;
      }, {});
      return {
        ...state,
        orgs: action.payload.response,
        orgsByID,
      };
    case actionTypes.REQUEST_ORGANIZATIONS_FAILED:
      console.log("Organizations fetch failed");
      return {
        ...state,
        fetchErrorMessageClasses: action.payload.error.message,
      };

    case actionTypes.REQUEST_ADMIN_ORGANIZATIONS:
      console.log("Fetching organizations");
      return {
        ...state,
        fetchErrorMessageClasses: null,
      };
    case actionTypes.REQUEST_ADMIN_ORGANIZATIONS_SUCCESS:
      console.log("Organizations fetch successful");
      return {
        ...state,
        adminOrgs: action.payload.response,
      };
    case actionTypes.REQUEST_ADMIN_ORGANIZATIONS_FAILED:
      console.log("Organizations fetch failed");
      return {
        ...state,
        fetchErrorMessageClasses: action.payload.error.message,
      };
    case actionTypes.REQUEST_SET_EXPIRE_DATE:
      console.log("Setting organization expire date");
      return {
        ...state,
        loadingSettingExpireDate: true,
        setExpireDateError: null,
      };
    case actionTypes.REQUEST_SET_EXPIRE_DATE_SUCCESS:
      console.log("Successfully set organization expire date");
      let orgs = [...state.orgs];

      const index = orgs.findIndex(
        (org) => org.organizationID === action.payload.orgID
      );

      orgs[index] = {
        ...orgs[index],
        organizationExpireDate: action.payload.organizationExpireDate,
      };

      let adminOrgs = [...state.adminOrgs];
      const indexAdmin = adminOrgs.findIndex(
        (org) => org.organizationID === action.payload.orgID
      );
      adminOrgs[indexAdmin] = {
        ...adminOrgs[indexAdmin],
        organizationExpireDate: action.payload.organizationExpireDate,
      };
      return {
        ...state,
        orgs,
        adminOrgs,
        loadingSettingOrganizations: false,
      };
    case actionTypes.REQUEST_SET_EXPIRE_DATE_FAILED:
      console.log("Could not set org expire date!");
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload.error.message,
      };

    default:
      return state;
  }
};
export default ClubsReducer;
