import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import TextField from '../../../../Fields/TextField/TextField.component';
import Button from '@material-ui/core/Button';

const styles = {
    dialogContent: {
        padding: 16,
    },
    title: {
      fontSize: 14,
    },
    buttonContainer: {
        textAlign: "center",
    },
};

class UploadImageDialog extends React.Component {
    state = {
        name: null,
        uploadedImage: null,
        imagePreviewUrl: null,
    };

    handleClose = () => {
        this.setState({ name: null, uploadedImage: null, imagePreviewUrl: null, saveError: null });
        this.props.onClose();
    };

    handleSave = () => {
        const { handleUpload, competitionClassID } = this.props;
        const { name, uploadedImage, imagePreviewUrl } = this.state;
        if(name && uploadedImage) {
            handleUpload(competitionClassID, name, uploadedImage, imagePreviewUrl);
            this.handleClose();
        } else {
            if(name && !uploadedImage) {
                this.setState({ saveError: "Bilde mangler" });
            } else if(!name && uploadedImage) {
                this.setState({ saveError: "Tittel mangler" });
            } else {
                this.setState({ saveError: "Tittel og bilde mangler" });
            }
        }
    };

    handleChangeName = (e) => {
        const { value } = e.target;
        this.setState({ name: value, saveError: null });
    }
    
    onChangeImg = (e) => {
        e.preventDefault();
        const _URL = window.URL || window.webkitURL;
        const file = e.target.files[0];
        const reader = new FileReader();

        if(file) {
            let img = new Image();

            img.onload = () => {
                var filesize = ((file.size/1024)/1024).toFixed(4);

                if(filesize <= 3.0000) {
                    reader.onloadend = () => {
                        this.setState({
                            uploadedImage: file,
                            imagePreviewUrl: reader.result,
                            saveError: null,
                            saveWarning: null,
                        });

                        if(img.width > 2400) {
                            this.setState({ saveWarning: "Bilde er over 2400px bredt og vil bli automatisk nedskalert." });
                        } else if(img.height > 2400) {
                            this.setState({ saveWarning: "Bilde er over 2400px høyt og vil bli automatisk nedskalert." });
                        } else if(img.width < 2400 && img.height < 2400) {
                            this.setState({ saveWarning: "Bilde er under 2400px bredt eller høyt." });
                        }
                    }
        
                    reader.readAsDataURL(file);
                } else if(filesize > 3.0000) {
                    this.setState({ saveError: "Bilde er over 3MB" });
                }
            };

            img.src = _URL.createObjectURL(file);

        }
    }
    
    render() {
        const { classes } = this.props;

        return (
            <Dialog onClose={this.handleClose} aria-labelledby="simple-dialog-title" open={this.props.open} fullWidth>
                <div className={classes.dialogContent}>
                    <Typography variant="h5" gutterBottom>
                        Last opp bilde
                    </Typography>
                    <TextField
                        required
                        name="name"
                        label="Tittel"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        style={{margin: '0px 0px 8px 0px'}}
                        onChange={this.handleChangeName}
                    />
                    <div>
                        {this.state.imagePreviewUrl &&
                            <img style={{width: "100%"}} alt="Test" src={this.state.imagePreviewUrl} />
                        }
                        <input id="imgInput"
                            type="file"
                            accept="image/jpeg"
                            ref={(ref) => this.upload = ref}
                            style={{display: 'none'}}
                            onChange={this.onChangeImg.bind(this)}
                        />
                        <Button onClick={()=>{this.upload.click()}} variant="outlined" color="primary" fullWidth size="large" style={{marginBottom: 16}}>
                            { !this.state.imagePreviewUrl ? "Velg bilde" : "Velg nytt bilde" }
                        </Button>
                    </div>
                    <div className={classes.buttonContainer}>
                        <Button variant="text" color="primary" onClick={this.handleClose}>
                            Avbryt
                        </Button>
                        <Button variant="contained" color="primary" onClick={this.handleSave} style={{marginLeft: 8}}>
                            Last opp
                        </Button>
                    </div>
                    {this.state.saveError &&
                        <Typography color="error" variant="overline" display="block" align="center">
                            {this.state.saveError}
                        </Typography>
                    }
                    {this.state.saveWarning &&
                        <Typography color="secondary" variant="overline" display="block" align="center">
                            {this.state.saveWarning}
                        </Typography>
                    }
                </div>
            </Dialog>
          );
    }
}

UploadImageDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UploadImageDialog);