import { connect } from 'react-redux';
import OrganizationParticipation from './OrganizationParticipation.component';
import { addOrgParticipation, removeOrgParticipation, updateAndGoBack, clearOrgParticipation, loadOrganizationParticipationPage } from './OrganizationParticipation.actions';
import withDataLoader from '../../../DataLoader/withDataLoader';
import { pushPreviousOrDefault } from '../../../../Utils/router.utils';

const mapStateToProps = (state) => ({
    isAlreadyParticipating: state.competitionOrgParticipation.isAlreadyParticipating,
    hasChanges: state.competitionOrgParticipation.hasChanges,
    competitionData: state.competition.selectedComp,
    selectedOrganization: state.competitionOrgParticipation.selectedOrganization,
    orgsByID: state.clubs.orgsByID,
    allParticipations: state.competitionOrgParticipation.allOrgParticipations,
    selectedOrganizationParticipations: state.competitionOrgParticipation.selectedOrgParticipations,
    amtOfParticipations: state.competitionOrgParticipation.amtOfParticipations,
    submitErrorMessage: state.competitionOrgParticipation.submitErrorMessage,
    dataLoading: state.competitionOrgParticipation.pageLoading,
    dataLoadErrorMessage: state.competitionOrgParticipation.fetchOrgParticipationErrorMessage || state.competition.loadSelectCompErrorMessage,
    previousLocation: state.location.previous,
});

const mapDispatchToProps = (dispatch) => ({
    addParticipation: (personID, participationID) => {
        dispatch(addOrgParticipation(personID, participationID));
    },
    removeParticipation: (personID, participationID) => {
        dispatch(removeOrgParticipation(personID, participationID));
    },
    submitParticipation: (participations, orgID, compID, previousLocation) => {
        dispatch(updateAndGoBack(participations, orgID, compID, previousLocation));
    },
    clearParticipations: () => {
        dispatch(clearOrgParticipation());
    },
    loadPage: (urlParams, queryParams) => {
        dispatch(loadOrganizationParticipationPage(urlParams.competitionID, urlParams.organizationID));
    },
    onCancel: (previousLocation) => {
        dispatch(pushPreviousOrDefault(previousLocation, "/competitions"));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(withDataLoader("loadPage")(OrganizationParticipation));