import { connect } from 'react-redux';
import Score from './Score.component';
import { 
    getMyStatus,
    getJuryStatus,
    getTotalClassParticipationScoring,
    getMyClassParticipationScoring,
    loadScorePage,
} from './Score.actions';
import withDataLoader from '../../../DataLoader/withDataLoader';
import { pushPreviousOrDefault } from '../../../../Utils/router.utils';

const mapStateToProps = (state) => ({
    competitionData: state.competition.selectedComp,
    dataLoading: !state.competition.selectedComp || state.score.pageLoading,
    classesByID: state.metaData.classesByID,
    errorMessage: state.competition.creatingJuryMemberError,
    previousLocation: state.location.previous,
});

const mapDispatchToProps = (dispatch) => ({
    fetchMyStatus: (competitionID) => {
        dispatch(getMyStatus(competitionID));
    },
    fetchJuryStatus: (competitionID) => {
        dispatch(getJuryStatus(competitionID));
    },
    fetchMyClassParticipationScoring: (competitionID, competitionClassID) => {
        dispatch(getMyClassParticipationScoring(competitionID, competitionClassID));
    },
    fetchTotalClassParticipationScoring: (competitionID, competitionClassID) => {
        dispatch(getTotalClassParticipationScoring(competitionID, competitionClassID));
    },
    loadScorePage: (urlParams, queryParams) => {
        dispatch(loadScorePage(urlParams.id));
    },
    onBackClick: (previousLocation) => {
        dispatch(pushPreviousOrDefault(previousLocation, "/competitions"));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(withDataLoader("loadScorePage")(Score));