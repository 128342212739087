import { simplePromiseAction, actionCreator } from '../../../Utils/actions.utils';
import { distinctionService } from '../../Services/distinctionService';

export const actionTypes = {
    INIT_DISTINCTION_DATA_STARTED: 'INIT_DISTINCTION_DATA_STARTED',
    INIT_DISTINCTION_DATA_FINISHED: 'INIT_DISTINCTION_DATA_FINISHED',
    REQUEST_DISTINCTION_TYPES: 'REQUEST_DISTINCTION_TYPES',
    REQUEST_DISTINCTION_TYPES_SUCCESS: 'REQUEST_DISTINCTION_TYPES_SUCCESS',
    REQUEST_DISTINCTION_TYPES_FAILED: 'REQUEST_DISTINCTION_TYPES_FAILED',
    REQUEST_PERSON_DISTINCTIONS: 'REQUEST_PERSON_DISTINCTIONS',
    REQUEST_PERSON_DISTINCTIONS_SUCCESS: 'REQUEST_PERSON_DISTINCTIONS_SUCCESS',
    REQUEST_PERSON_DISTINCTIONS_FAILED: 'REQUEST_PERSON_DISTINCTIONS_FAILED',
}

export const getDistinctionTypes = () => simplePromiseAction(
    () => distinctionService.getDistinctionTypes(),
    actionTypes.REQUEST_DISTINCTION_TYPES,
    actionTypes.REQUEST_DISTINCTION_TYPES_SUCCESS,
    actionTypes.REQUEST_DISTINCTION_TYPES_FAILED,
);

export const getPersonDistinctions = (paging, order, searchString, availableItemsDistinctionType, clubFilter) => {
    const metadata = { paging, order, searchString, availableItemsDistinctionType, clubFilter };
    return simplePromiseAction(
        () => distinctionService.getPersonDistinctions(paging, order, searchString, availableItemsDistinctionType, clubFilter),
        actionTypes.REQUEST_PERSON_DISTINCTIONS,
        actionTypes.REQUEST_PERSON_DISTINCTIONS_SUCCESS,
        actionTypes.REQUEST_PERSON_DISTINCTIONS_FAILED,
        metadata,
    );
}

export const initializeData = () => (dispatch) => {
    dispatch(actionCreator(actionTypes.INIT_DISTINCTION_DATA_STARTED)({}));
    return dispatch(getDistinctionTypes())
        .then(() => dispatch(getPersonDistinctions())
            .then(() => dispatch(actionCreator(actionTypes.INIT_DISTINCTION_DATA_FINISHED)({}))
            )
        );
}