import { connect } from 'react-redux';
import ScoreFullscreen from './ScoreFullscreen.component';

import {
    saveScore,
    onScoreFormFieldUpdated,
    onViewEditScore,
    onCancelEditScore,
    onScoreFormInvalid,
} from './ScoreFullscreen.actions';

const mapStateToProps = (state, props) => {
    const viewAndScore = state.viewAndScore;
    const scoreFullscreen = viewAndScore.scoreFullscreen || {};
    return {
        saving: scoreFullscreen.saving,
        formValues: scoreFullscreen.formValues,
        validationErrorMessage: scoreFullscreen.validationErrorMessage,
        showEditScore: scoreFullscreen.showEditScore,
    }
}

const mapDispatchToProps = (dispatch) => ({
    saveScore: (competitionID, classParticipationID, score, comment) => {
        dispatch(saveScore(competitionID, classParticipationID, score, comment));
    },
    onScoreFormFieldUpdated: (field, value) => {
        dispatch(onScoreFormFieldUpdated(field, value));
    },
    onViewEditScore: (formValues) => {
        dispatch(onViewEditScore(formValues));
    },
    onCancelEditScore: () => {
        dispatch(onCancelEditScore());
    },
    onScoreFormInvalid: (validationErrorMessage) => {
        dispatch(onScoreFormInvalid(validationErrorMessage));
    }

});

export default connect(mapStateToProps, mapDispatchToProps)(ScoreFullscreen);