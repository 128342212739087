import React from 'react';
import { DialogContent, DialogActions, Button, Dialog, DialogTitle } from '@material-ui/core';




const DownloadDialog = ({ open, fileCount, avgFileSize, onConfirm, onCancel }) => {
    let estimatedSize = fileCount * avgFileSize;
    let unit = "megabyte";
    if(estimatedSize > 1000) {
        unit = "gigabyte";
        estimatedSize = (estimatedSize / 1000).toFixed(1);
    }

    return (
        <Dialog open={open}>
            <DialogTitle style={{paddingBottom: 0}}>Last ned filer</DialogTitle>
            <DialogContent>
                <p>Du er nå i ferd med å laste ned <b>{fileCount} filer.</b></p>
                 <p>Total estimert størrelse er <b>{estimatedSize} {unit}.</b></p>
                 Ønsker du å fortsette?
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel}>Avbryt</Button>
                <Button onClick={onConfirm} color="primary" variant="contained">Last ned</Button>
            </DialogActions>
        </Dialog>
    );
}

export default DownloadDialog;