import { simplePromiseAction, actionCreator } from '../../../Utils/actions.utils';
import { organizationService } from '../../Services/organizationService';

export const actionTypes = {
    REQUEST_ORG_ADMINS: 'REQUEST_ORG_ADMINS',
    REQUEST_ORG_ADMINS_SUCCESS: 'REQUEST_ORG_ADMINS_SUCCESS',
    REQUEST_ORG_ADMINS_FAILED: 'REQUEST_ORG_ADMINS_FAILED',
    CLEAR_SEARCH_RESULT: 'CLEAR_SEARCH_RESULT',
    REQUEST_SEARCH: 'REQUEST_SEARCH',
    REQUEST_SEARCH_SUCCESS: 'REQUEST_SEARCH_SUCCESS',
    REQUEST_SEARCH_FAILED: 'REQUEST_SEARCH_FAILED',
    REQUEST_SET_ROLE: 'REQUEST_SET_ROLE',
    REQUEST_SET_ROLE_SUCCESS: 'REQUEST_SET_ROLE_SUCCESS',
    REQUEST_SET_ROLE_FAILED: 'REQUEST_SET_ROLE_FAILED',
    REQUEST_REMOVE_ROLE: 'REQUEST_REMOVE_ROLE',
    REQUEST_REMOVE_ROLE_SUCCESS: 'REQUEST_REMOVE_ROLE_SUCCESS',
    REQUEST_REMOVE_ROLE_FAILED: 'REQUEST_REMOVE_ROLE_FAILED',
}

const clearSearchResultAction = () => actionCreator(actionTypes.CLEAR_SEARCH_RESULT)({});

export const clearSearchResult = () => clearSearchResultAction();

export const getAdmins = (orgID) => simplePromiseAction(
    () => organizationService.getRolesForOrg(orgID),
    actionTypes.REQUEST_ORG_ADMINS,
    actionTypes.REQUEST_ORG_ADMINS_SUCCESS,
    actionTypes.REQUEST_ORG_ADMINS_FAILED,
);

export const searchPersonInOrg = (searchText, orgID) => simplePromiseAction(
    () => organizationService.searchPersonInOrg(searchText, orgID),
    actionTypes.REQUEST_SEARCH,
    actionTypes.REQUEST_SEARCH_SUCCESS,
    actionTypes.REQUEST_SEARCH_FAILED,
);

const requestSetRole = () => actionCreator(actionTypes.REQUEST_SET_ROLE)({});
const requestSetRoleSuccess = (response, orgID, organizationRoleID, person) => actionCreator(actionTypes.REQUEST_SET_ROLE_SUCCESS)({ response, orgID, organizationRoleID, person });
const requestSetRoleFailed = (error) => actionCreator(actionTypes.REQUEST_SET_ROLE_FAILED)({ error });

export const setRole = (orgID, organizationRoleID, person) => {
    return (dispatch) => {
        dispatch(requestSetRole());
        var promise = organizationService.setRole(orgID, organizationRoleID, person.personID);
        return promise.then(
            response => dispatch(requestSetRoleSuccess(response, orgID, organizationRoleID, person)),
            error => dispatch(requestSetRoleFailed(error)),
        );
    }
};

const requestRemoveRole = () => actionCreator(actionTypes.REQUEST_REMOVE_ROLE)({});
const requestRemoveRoleSuccess = (response, orgID, organizationRoleID, person) => actionCreator(actionTypes.REQUEST_REMOVE_ROLE_SUCCESS)({ response, orgID, organizationRoleID, person });
const requestRemoveRoleFailed = (error) => actionCreator(actionTypes.REQUEST_REMOVE_ROLE_FAILED)({ error });

export const removeRole = (orgID, organizationRoleID, person) => {
    return (dispatch) => {
        dispatch(requestRemoveRole());
        var promise = organizationService.removePersonFromRole(orgID, organizationRoleID, person.personID);
        return promise.then(
            response => dispatch(requestRemoveRoleSuccess(response, orgID, organizationRoleID, person)),
            error => dispatch(requestRemoveRoleFailed(error)),
        );
    }
};