import {
    actionTypes
} from '../components/Pages/Dashboard/Dashboard.actions';


const initState = {

}

const paymentsReducer = (state = initState, action) => {
    switch(action.type) {
        case actionTypes.REQUEST_GET_PAYMENTS:
            console.log('request get payments');
            return {
                ...state,
                loading: true,
            };

        case actionTypes.REQUEST_GET_PAYMENTS_SUCCESS:
            console.log('request get payments');
            return {
                ...state,
                loading: false,
                data: action.payload.response.result,
                paging: action.payload.response.pagination
            };

        case actionTypes.REQUEST_GET_PAYMENTS_FAILED:
            console.log('request get payments');
            return {
                ...state,
                loading: false,
                error: action.payload.error.message,
            };
        default:
            return state;
    }
}

export default paymentsReducer;



