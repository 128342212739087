import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';

const styles = theme => ({
  root: {

  },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  center: {
    textAlign: "center",
    margin: 20,
  },
  title: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  number: {
    backgroundColor: '#001789',
    borderRadius: 50,
    color: 'white',
    padding: '10px 20px',
  },
  clickable: {
    '&:hover': {
      backgroundColor: 'rgba(0, 23, 137, 0.08)',
    },
    cursor: 'pointer',

  }
});
function StatisticCard(props) {
  const { classes, cardData, onClick } = props;
  
  return (
    <div className={classes.root}>
        <Paper className={classNames(classes.paper, { [classes.clickable]: !!onClick})} elevation={1} onClick={onClick}>
            <Typography variant="subtitle1" className={classes.title}>
                { cardData.title }:
            </Typography>
            <Typography className={classes.center} variant="h5">
                <span className={classes.number}>{ cardData.value }</span>
            </Typography>
        </Paper>
    </div>
  );
}

StatisticCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(StatisticCard);