import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import GeneratedReportView from './GeneratedReportView.component';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import moment from "moment";
import "moment/locale/nb";
import Table from '../../../Table/Table.component';
import withPaging from '../../../Table/withPaging';
import TextField from '../../../Fields/TextField/TextField.component';
import withInternalChangeHandler from '../../../Fields/withInternalChangeHandler';
import withLoadingIndicator from '../../../LoadingIndicator/withLoadingIndicator';
import CheckboxField from '../../../Fields/CheckboxField/CheckboxField.component';
const FormTextField = withInternalChangeHandler()(TextField);
const PagingTable = withLoadingIndicator()(withPaging()(Table));
moment.locale("nb");

const styles = {
	paper: {
		margin: 10,
		padding: 16,

	},
	table: {
		minWidth: 650,
		marginBottom: 10,
	},
	headerText: {
		marginBottom: 10,
	},
	selectionItemContainer: {
		marginTop: 10,
	},
	generateReportButtonContainer: {
		display: 'flex',
    	flexDirection: 'column',
		alignItems: 'flex-end',
	},
	topButtonContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginRight: 10,
	}
};

const getAddButtonDataCell = (props, data, key) => {
	const classes = props.customClasses;
	return (
		<TableCell key={key} align="right" className={classes.cell}>
			<IconButton color="primary" onClick={() => props.selectCompetition(data)}>
				<AddIcon />
			</IconButton>
		</TableCell>
	);
};

const getRemoveButtonDataCell = (props, data, key) => {
	const classes = props.customClasses;
	return (
		<TableCell key={key} align="right" className={classes.cell}>
			<IconButton onClick={() => props.removeCompetition(data.competitionID)}>
				<DeleteIcon color="error" />
			</IconButton>
		</TableCell>
	);
};
const getDateDataCell = (props, data,key, code) => {
	const classes = props.customClasses;
	const { submissionStart, submissionEnd } = data;
	return (
		<TableCell key={key} className={classes.cell}>
			{moment(code === 'start' ? submissionStart : submissionEnd).format('LL')}
		</TableCell>
	);
};

const availableFields = [
	{
		value: "firstName",
		label: "Fornavn",
	},
	{
		value: "lastName",
		label: "Etternavn",
	},
	{
		value: "organizationName",
		label: "Klubb",
	},
	{
		value: "email",
		label: "Epost",
	},
	{
		value: "mobile",
		label: "Mobilnummer",
	},
	{
		value: "address",
		label: "Adresse",
	},
	{
		value: "zipCode",
		label: "Postnummer",
	},
	{
		value: "city",
		label: "Poststed",
	},
	{
		value: "position",
		label: "Yrke",
	}
];

const columnsAllCompetitions = [
	{
		key: 'name',
		displayName: 'Konkurransenavn',
	},
	{
		key: 'submissionStart',
		displayName: 'Start',
		getDataCell: (props, data, key) =>
			getDateDataCell(
				props,
				data,
				key,
				'start',
			),
	},
	{
		key: 'submissionEnd',
		displayName: 'Slutt',
		getDataCell: (props, data,key) =>
			getDateDataCell(
				props,
				data,
				key,
			),
	},
	{
		key: 'organizationName',
		displayName: 'Arrangør',
	},
	{
		key: null,
		headerAlignment: 'right',
		getDataCell: (props, data, key) =>
			getAddButtonDataCell(
				props,
				data,
				key,
			),
	},
];

const columnsAddedCompetitions = [
	{
		key: 'name',
		displayName: 'Konkurransenavn',
	},
	{
		key: 'submissionStart',
		displayName: 'Start',
		getDataCell: (props, data, key) =>
			getDateDataCell(
				props,
				data,
				key,
				'start',
			),
	},
	{
		key: 'submissionEnd',
		displayName: 'Slutt',
		getDataCell: (props, data, key) =>
			getDateDataCell(
				props,
				data,
				key,
			),
	},
	{
		key: 'organizationName',
		displayName: 'Arrangør',
	},
	{
		key: 'addbutton',
		headerAlignment: 'right',
		getDataCell: (props, data, key) =>
			getRemoveButtonDataCell(
				props,
				data,
				key,
			),
	},
];

class Report extends React.Component {
	state = {
		selectedCompetitions: [],
		selectedFieldsByValue: {},
	}

	handleSearchTextChange = (e) => {
		var value = e.target.value;
		if (value !== this.props.searchText) {
			this.props.requestFinishedCompetitions(e.target.value, this.props.paging);
		}
	}

	handleKeyDownSearch = (e) => {
		if (e.key === 'Enter') {
			this.handleSearchTextChange(e);
		}
	}

	handlePageChange = (paging) => {
		this.props.requestFinishedCompetitions(this.props.searchText, paging);
	}

	selectCompetition = (selectedCompetition) => {
		if (this.isCompetitionSelected(selectedCompetition.competitionID)) {
			return;
		}

		const selectedCompetitions = this.state.selectedCompetitions;
		selectedCompetitions.push(selectedCompetition);
		this.setState({ selectedCompetitions });
	};

	isCompetitionSelected = (competitionID) => {
		return !!this.state.selectedCompetitions.find(comp => {
			return comp.competitionID === competitionID
		});
	};

	handleBack = () => {
		this.props.onBackToCompetitions();
	}

	removeCompetition = (competitionID) => {
		let selectedCompetitions = this.state.selectedCompetitions;
		selectedCompetitions = selectedCompetitions.filter((comp) => {
			return comp.competitionID !== competitionID;
		});

		this.setState({ selectedCompetitions });
	};

	generateReport = () => {
		let errorMessages;
		if (this.state.selectedCompetitions.length === 0) {
			errorMessages = { competitions: "Velg minimum en konkurranse" };
		}
		if (this.getSelectedFields().length === 0) {
			errorMessages = {
				...errorMessages,
				selectedFields: "Velg minimum et felt som skal vises i rapporten",
			};
		}
		if (errorMessages) {
			this.setState({ errorMessages });
			return;
		}
		this.setState({ showReport: true, errorMessages: null });
	};

	backToCompetitionSelection = () => {
		this.setState({ showReport: false });
	};

	handleSetSelectedField = (value, checked) => {
		this.setState({
			selectedFieldsByValue: {
				...this.state.selectedFieldsByValue,
				[value]: checked,
			}
		});
	}

	renderSearchField = () => {
		return (
			<FormTextField
				label="Søk"
				className={this.props.classes.textField}
				variant="outlined"
				value={this.props.searchText || ""}
				onBlur={this.handleSearchTextChange}
				onKeyDown={this.handleKeyDownSearch}
				margin="none"
			/>
		)
	}
	renderSelectedCompetitions = () => {
		const { classes } = this.props;
		const { selectedCompetitions } = this.state;
		return (
			<div className={classes.selectionItemContainer}>
				<Typography className={classes.headerText} variant="h5">
					Valgte konkurranser
				</Typography>
				{selectedCompetitions.length > 0 ?
					<Table
						className={classes.table}
						dataKey="competitionID"
						columns={columnsAddedCompetitions}
						data={this.state.selectedCompetitions}
						removeCompetition={this.removeCompetition}
						customClasses={classes}
					/> :
					<div>Ingen valgte konkurranser</div>
				}
			</div>
		);
	}

	renderErrorMessages = (errorMessages) => {
		return (
			<React.Fragment>
				{errorMessages.competitions &&
					<Typography color="error" variant="overline" display="block" align="center">
						{errorMessages.competitions}
					</Typography>
				}
				{errorMessages.selectedFields &&
					<Typography color="error" variant="overline" display="block" align="center">
						{errorMessages.selectedFields}
					</Typography>
				}
			</React.Fragment>
		);
	}

	renderSelections = () => {
		const classes = this.props.classes;
		const errorMessages = this.state.errorMessages;
		return (
			<Paper className={classes.paper}>
				{this.renderSelectedCompetitions()}
				{this.renderSelectedFieldChoices()}
				<div className={classes.generateReportButtonContainer}>
					<Button onClick={this.generateReport} variant="outlined" color="primary">Generer rapport</Button>
					{errorMessages && this.renderErrorMessages(errorMessages)}
					<Typography color="error" variant="overline" display="block" align="center">
						{this.state.validationErrorMessage}
					</Typography>
			</div>
			</Paper>
		);
	}

	renderAvailableCompetitions = () => {
		const { classes, loading, competitions } = this.props;
		return (
			<Paper className={classes.paper}>
				<Typography className={classes.headerText} variant="h5">
					Finn konkurranser
				</Typography>
				{this.renderSearchField()}
				<PagingTable
					ready={!loading}
					className={classes.table}
					dataKey="competitionID"
					columns={columnsAllCompetitions}
					data={competitions}
					customClasses={classes}
					selectCompetition={this.selectCompetition}
					onPageChange={this.handlePageChange}
				/>
			</Paper>
		);
	}

	getSelectedFields = () => {
		return availableFields.filter(af => this.state.selectedFieldsByValue[af.value]);
	}

	renderSelectedFieldChoices = () => {
		const { selectedFieldsByValue } = this.state;
		const { classes } = this.props;
		const fields = availableFields.map(af => (
			<CheckboxField
				key={af.value}
				value={selectedFieldsByValue[af.value]}
				onChange={(e) => this.handleSetSelectedField(af.value, e.target.checked)}
				label={af.label}
			/>
		));
		return (
			<div className={classes.selectionItemContainer}>
				<Typography className={classes.headerText} variant="h6">
					Felter som skal vises i rapport
				</Typography>
				{fields}
			</div>
		);
	}
	render() {
		const { showReport, selectedCompetitions } = this.state;

		return (
			<div>
				{!showReport &&
					<div>
						<div className={this.props.classes.topButtonContainer}>
							<Button variant="outlined" onClick={this.handleBack} color="primary">Tilbake</Button>
						</div>
						
						{this.renderAvailableCompetitions()}
						{this.renderSelections()}
					</div>
				}
				{showReport &&
					<GeneratedReportView
						backFunction={this.backToCompetitionSelection}
						selectedCompetitions={selectedCompetitions}
						selectedFields={this.getSelectedFields()}
					isNsffAdmin={this.props.isNsffAdmin}
					/>
				}
			</div>
		);
	}
}

export default withStyles(styles)(Report);