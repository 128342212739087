import {
    actionTypes
} from '../components/Pages/Competition/Score/Score.actions';


const initState = {
    pageLoading: true,
}

const scoreReducer = (state = initState, action) => {
    switch(action.type) {
        case actionTypes.START_LOAD_SCORE_PAGE:
            return {
                pageLoading: true,
            };

        case actionTypes.FINISHED_LOADING_SCORE_PAGE:
            console.log('Requesting all finished competitions success');
            return {
                ...state,
                pageLoading: false,
            };
        default:
            return state;
    }
}

export default scoreReducer;



