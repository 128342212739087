import React, { useState } from 'react'
import {
    withStyles,
    TableCell,
    Button,
    IconButton,
    Menu,
    MenuItem,
} from '@material-ui/core';
import TextField from '../../../Fields/TextField/TextField.component';
import withInternalChangeHandler from '../../../Fields/withInternalChangeHandler';
import moment from 'moment';
import withLoadingIndicator from '../../../LoadingIndicator/withLoadingIndicator';
import Table from '../../../Table/Table.component';
import withPaging from '../../../Table/withPaging';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { Settings } from '@material-ui/icons';

const FormTextField = withInternalChangeHandler()(TextField);
const PagingTable = withLoadingIndicator()(withPaging()(Table));

const useStyles = makeStyles(() => ({
    root: {

    },
    searchText: {
        '@media(max-width: 580px)': {
            position: "relative",
        },
        '@media(min-width: 580px)': {
            position: "absolute",
            right: 5,
            top: 5,
        }
    },
    table: {
        minWidth: 650,
        marginBottom: 10,
    }
}));

const ComponentListView = (props) => {

    const [secondaryButtonEl, setSecondaryButtonEl] = useState();
    const classes = useStyles();
    const history = useHistory();

    const setUnFinished = (competitionID) => {
        props.unFinishCompetition(competitionID)
    }

    const secondaryButtons = [
        {
            key: "unFinishCompetition",
            getText: () => "Sett ikke fullført",
            onClick: (p, cd) => setUnFinished(cd.competitionID),
            shouldRender: (p, cd) => { return (cd.finished && cd.awardingClosed && cd.isAdmin && !cd.nsffPatronate && cd.competitionType !== 4) },
        },
        {
            key: 'participationList',
            getText: () => "Se deltakerliste",
            onClick: (p, cd) => history.push(`/competitions/${cd.competitionID}/participationList`),
            shouldRender: (p) => {
                return p.isAdmin
            },
        },
    ]

    const columnsAllCompetitions = [
        {
            key: 'name',
            displayName: 'Konkurransenavn',
        },
        {
            key: 'submissionEnd',
            displayName: 'Sluttdato',
            getDataCell: (props, data, key) =>
                getDateDataCell(
                    props,
                    data,
                    key,
                ),
        },
        {
            key: 'organizationName',
            displayName: 'Arrangør',
        },
        {
            key: 'resultList',
            getDataCell: (props, data, key) =>
                getButtonCell(
                    props,
                    data,
                    key,
                ),
        },
        {
            key: 'secondary',
            getDataCell: (props, data, key) =>
                getSettingsCell(
                    props,
                    data,
                    key,
                ),
        },
    ];

    const getDateDataCell = (props, data, key, code) => {
        const classes = props.customClasses;
        const { submissionStart, submissionEnd } = data;
        return (
            <TableCell key={key} className={classes.cell}>
                {moment(code === 'start' ? submissionStart : submissionEnd).format('LL')}
            </TableCell>
        );
    };
    const getSettingsCell = (props, data, key) => {
        const classes = props.customClasses;
        if (!data.isAdmin) {
            return <TableCell></TableCell>
        }
        return (
            <TableCell key={key} className={classes.cell}>
                <IconButton aria-controls="secondary-buttons-menu" aria-haspopup="true" onClick={(ev) => { setSecondaryButtonEl({ target: ev.currentTarget, id: data.competitionID }) }}>
                    <Settings />
                </IconButton>
                <Menu
                    anchorEl={secondaryButtonEl?.target}
                    open={secondaryButtonEl?.id === data.competitionID}
                    onClose={() => { setSecondaryButtonEl(null) }}
                    id="secondary-buttons-menu"
                    keepMounted
                    getContentAnchorEl={null}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    {secondaryButtons.filter(b => b.shouldRender(data, data)).map(b => (
                        <MenuItem
                            key={b.key}
                            onClick={() => b.onClick(data, data)}
                        >
                            {b.getText(data, data)}
                        </MenuItem>
                    ))}
                </Menu>
            </TableCell>
        );
    };

    const getButtonCell = (props, data, key) => {
        const classes = props.customClasses;
        const { competitionID } = data;
        return (
            <TableCell key={key} className={classes.cell}>
                <Button variant="outlined" color="primary" onClick={() => { history.push(`/competitions/${competitionID}/results`) }}>
                    Resultater
                </Button>
            </TableCell>
        );
    };

    // Need to get filtered competitions here.
    const handleSearchTextChange = async (e) => {
        var value = e.target.value;
        props.onSearch(value);
    }

    const handleKeyDownSearch = (e) => {
        if (e.key === 'Enter') {
            handleSearchTextChange(e);
        }
    }
    return (
        <div className={classes.root}>
            <FormTextField
                label="Søk på konkurranse"
                variant="outlined"
                value={props.searchText || ""}
                onBlur={handleSearchTextChange}
                onKeyDown={handleKeyDownSearch}
                margin="none"
                className={classes.searchText}
            />
            <PagingTable
                ready={!props.loadingFinished}
                dataKey="competitionID"
                columns={columnsAllCompetitions}
                data={props.competitions}
                customClasses={classes}
                selectCompetition={props.selectCompetition}
                onPageChange={props.handlePageChange}
                className={classes.table}
            />
        </div>
    )
}

export default withStyles()(ComponentListView);

