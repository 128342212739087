import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MediaCardList from '../../../../Media/MediaCardList.component';
import MediaFullscreen from '../../../../Media/MediaFullscreen.component';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Comment from '@material-ui/icons/Comment';
import EvaluationFullscreenContent from './EvaluationFullscreenContent/EvaluationFullscreenContent.container';

const styles = theme => ({
    root: {
    },
    paper: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        marginBottom: 8,
    },
    button: {
        float: "right",
    },
    cardContent: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        padding: 10,
        overflow: 'hidden',
    },
    cardContentButtonContainer: {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
    },
    cardContentButtonInnerContainer: {
        display: 'inline-block',
    },
    avatar: {
        backgroundColor: "#001789",
    },
    cardText: {
        whiteSpace: "nowrap",
    },
    list: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    listItem: {
        paddingTop: 0,
        paddingBottom: 0,
    },
  });

class ViewAndEvaluate extends React.Component {

    onShowList = () => {
        this.props.onShowList();
    }

    onShowFullscreen = (participationIndex) => {
        this.props.onShowFullscreen(participationIndex);
    }


    onFullsceenIndexChange = (index) => {
        this.props.onFullscreenIndexChange(index);
    }

    renderMediaItemCardContent = (mediaItem, index) => {
        const classes = this.props.classes;
        return (
            <div className={classes.cardContent}>
                <Typography variant="h5" component="h2">
                    {mediaItem.name}
                </Typography>
                <Typography>
                    Id: {mediaItem.classParticipationNumber}
                </Typography>
                {mediaItem.comment && 
                    <List className={classes.list}>
                        <ListItem className={classes.listItem}>
                            <ListItemAvatar>
                                <Avatar className={classes.avatar}>
                                    <Comment />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                className={classes.cardText}
                                primary="Kommentar"
                                secondary={mediaItem.comment} 
                                secondaryTypographyProps={{style: {overflow: "hidden", textOverflow: "ellipsis"}}}    
                            />
                        </ListItem>
                    </List>
                }
                <div className={classes.cardContentButtonContainer}>
                    <div className={classes.cardContentButtonInnerContainer}>
                        <Button size="small" color="primary" onClick={() => this.onShowFullscreen(index)}>
                            Åpne
                        </Button>
                    </div>

                </div>
            </div>
        );
    }

    renderFullScreenContent = (mediaItem, index) => {
        const { competitionData } = this.props;
        return (
            <EvaluationFullscreenContent
                competitionData={competitionData}
                classParticipation={mediaItem}
            />
        );
    }


    renderFullScreen = () => {
        const { classParticipations, fullscreenInitialIndex } = this.props;
        return (
            <MediaFullscreen
                mediaItems={classParticipations}
                initialIndex={fullscreenInitialIndex}
                getBottomContent={this.renderFullScreenContent}
                onIndexChange={this.onFullsceenIndexChange}
            />
        );
    }

    renderMediaCardList = () => {
        return (
            <MediaCardList
                mediaItems={this.props.classParticipations}
                getMediaItemContent={this.renderMediaItemCardContent}
                imageMinSize={150}
            />
        )
    }
    
    render() {
        const { classes, competitionClass, showFullscreen, classesByID } = this.props;
        const currentClass = classesByID[competitionClass.classID];
        return (
            <div className={classes.root}>
                <Paper className={classes.paper} elevation={1}>
                    <Typography variant="h5" style={{marginBottom: 16}}>
                        {currentClass.name}
                        {showFullscreen ? 
                            <Button variant="outlined" color="primary" className={classes.button} onClick={this.onShowList}>
                                Liste
                            </Button> :
                            <Button variant="outlined" color="primary" className={classes.button} onClick={() => this.onShowFullscreen()}>
                                Fullskjerm
                            </Button>
                        }
                    </Typography>
                    {showFullscreen ? this.renderFullScreen() : this.renderMediaCardList()}
                </Paper>
            </div>
          );
    }
}

ViewAndEvaluate.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ViewAndEvaluate);