import { simplePromiseAction, actionCreator } from '../../../Utils/actions.utils';
import { personService } from '../../Services/personService';

export const actionTypes = {
    REQUEST_GET_PROFILE: "REQUEST_GET_PROFILE",
    REQUEST_GET_PROFILE_SUCCESS: "REQUEST_GET_PROFILE_SUCCESS",
    REQUEST_GET_PROFILE_FAILED: "REQUEST_GET_PROFILE_FAILED",

    REQUEST_SAVE_PROFILE: 'REQUEST_SAVE_PROFILE',
    REQUEST_SAVE_PROFILE_SUCCESS: 'REQUEST_SAVE_PROFILE_SUCCESS',
    REQUEST_SAVE_PROFILE_FAILED: 'REQUEST_SAVE_PROFILE_FAILED',
    SHOW_EDIT_PROFILE: 'SHOW_EDIT_PROFILE',
    CANCEL_EDIT_PROFILE: 'CANCEL_EDIT_PROFILE',

    REQUEST_GET_PAYMENTS: "REQUEST_GET_PAYMENTS",
    REQUEST_GET_PAYMENTS_SUCCESS: "REQUEST_GET_PAYMENTS_SUCCESS",
    REQUEST_GET_PAYMENTS_FAILED: "REQUEST_GET_PAYMENTS_FAILED",
}

export const getProfile = () => simplePromiseAction(
    () => personService.getMe(),
    actionTypes.REQUEST_GET_PROFILE,
    actionTypes.REQUEST_GET_PROFILE_SUCCESS,
    actionTypes.REQUEST_GET_PROFILE_FAILED,
);

export const fetchPayments = (paging) => simplePromiseAction(
    () => personService.getPayments(paging),
    actionTypes.REQUEST_GET_PAYMENTS,
    actionTypes.REQUEST_GET_PAYMENTS_SUCCESS,
    actionTypes.REQUEST_GET_PAYMENTS_FAILED,
);


export const requestSaveProfile = (updatedUserData) =>
    (dispatch) => {
        const data = updatedUserData;
        dispatch(actionCreator(actionTypes.REQUEST_SAVE_PROFILE)({ data }));
        return personService.updateMe(updatedUserData).then(
            response => dispatch(actionCreator(actionTypes.REQUEST_SAVE_PROFILE_SUCCESS)({ response, data })),
            error => dispatch(actionCreator(actionTypes.REQUEST_SAVE_PROFILE_FAILED)({ error, data }))
        );
    }

export const cancelEditProfile = () => actionCreator(actionTypes.CANCEL_EDIT_PROFILE)({});
export const showEditProfile = () => actionCreator(actionTypes.SHOW_EDIT_PROFILE)({});