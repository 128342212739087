import {
    actionTypes
} from '../components/Pages/Dashboard/Dashboard.actions';


const initState = {

}

const createCompetitionReducer = (state = initState, action) => {
    switch(action.type) {
        case actionTypes.REQUEST_GET_PROFILE:
            console.log('Fetching profile');
            return {
                ...state,
                fetchError: null,
            };
        case actionTypes.REQUEST_GET_PROFILE_SUCCESS:
            console.log('Fetching profile');
            return {
                ...state,
                data: action.payload.response,
                fetchError: null,
            };
        case actionTypes.REQUEST_GET_PROFILE_FAILED:
            console.log('Fetching profile');
            return {
                ...state,
                fetchError: action.payload.error.message,
            };
        case actionTypes.REQUEST_SAVE_PROFILE:
            console.log('Saving profile');
            return {
                ...state,
                saving: true,
            };

        case actionTypes.REQUEST_SAVE_PROFILE_SUCCESS:
                console.log('profile saved');
                return {
                    ...state,
                    data: {
                        ...state.data,
                        ...action.payload.data,
                    },
                    saving: false,
                    showEdit: false,
                };
        case actionTypes.REQUEST_SAVE_PROFILE_FAILED:
            console.log('profile save failed');
            return {
                ...state,
                saving: false,
                saveError: action.payload.error.message,
            };
        case actionTypes.SHOW_EDIT_PROFILE:
            console.log('profile save failed');
            return {
                ...state,
                showEdit: true,
                saving: false,
                saveError: null,
            };
        case actionTypes.CANCEL_EDIT_PROFILE:
            console.log('profile save failed');
            return {
                ...state,
                showEdit: false,
                saving: false,
                saveError: null,
            };
        default:
            return state;
    }
}

export default createCompetitionReducer;



