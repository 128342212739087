// @flow

import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { push } from 'connected-react-router';




const LoginRoute = ({ component: Component, push: locationPush, ...rest }: any) => {
    const user = localStorage.getItem('user');
    if(user) {
        locationPush("/");
        return false;
    }

    return (
        <Route {...rest} render={props => (
            localStorage.getItem('user')
                ? <Redirect to={{ pathname: '/', state: { from: props.location } }} />
                : <Component {...props} />
        )} />
    );
}


export default connect(null, {push})(LoginRoute);