import React from 'react';
import { 
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    withStyles,
    Box
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ParticipationDetails from './ParticipationDetails.component';


const participantPanelStyles = () => ({
    panel: {
        borderRadius: '0px!important',
    },
    expansionPanelDetails: {
        display: 'flex',
        flexDirection: 'column',
    }
})

const ParticipantPanel = withStyles(participantPanelStyles)(
    ({participationsByPerson, classes, competition, ...props }) => {
        const { firstName, lastName, classParticipations } = participationsByPerson;

        return (
            <div>
                <ExpansionPanel className={classes.panel} TransitionProps={{ unmountOnExit: true }} 
                >
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <div>
                            <Box fontWeight={500}>{firstName} {lastName} ({classParticipations.length} innleveringer)</Box>
                        </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.expansionPanelDetails}>
                        <ParticipationDetails
                            classParticipations={classParticipations}
                            {...props}
                        />
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </div>

        );
    })

export default ParticipantPanel;