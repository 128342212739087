import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import getCompetitionType from '../../../../competition/getCompetitionType';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import SupervisorAccount from '@material-ui/icons/SupervisorAccount';
import PhotoFilter from '@material-ui/icons/PhotoFilter';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import FlagIcon from '@material-ui/icons/Flag';
import WarningIcon from '@material-ui/icons/Warning';
import Settings from '@material-ui/icons/Settings';
import Divider from '@material-ui/core/Divider';
import moment from "moment";
import "moment/locale/nb";
import { IconButton, Menu, MenuItem } from '@material-ui/core';

moment.locale("nb");

const styles = theme => ({
    content: {
        paddingBottom: 0,
        flexGrow: 1,
    },
    actions: {
        justifyContent: "center",
        flexWrap: 'wrap',
    },
    avatar: {
        backgroundColor: "#001789",
    },
    grid: {
        display: 'flex',
    },
    card: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    button: {
        margin: 2,
    },
    buttonLabel: {
        display: 'block',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    statusText: {
        padding: "5px 10px",
        borderRadius: 15,
    },
    warningIcon: {
        color: '#efbb09',
    },
    topContainer: {
        display: 'flex',
        alignItems: 'flex-start',
    },
    topRightContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginTop: -8,
    }
});

class CompetitionListCard extends React.Component {
    state = {
        secondaryButtonEl: null,
    };

    defaultButtonProps = {
        size: "small",
        variant: "outlined",
        color: "primary",
        className: this.props.classes.button,
        classes: {
            label: this.props.classes.buttonLabel,
        }
    }
    isSubmissionEnded = () => {
        return moment() > moment(this.props.competitionData.submissionEnd);
    }

    isSubmissionStarted = () => {
        return moment() > moment(this.props.competitionData.submissionStart);
    }

    secondaryButtons = [
        {
            key: 'edit',
            getText: () => 'Endre',
            onClick: (p, cd) => p.openEdit(cd.competitionID),
            shouldRender: (p, cd) => cd.isAdmin,
        },
        {
            key: 'participationList',
            getText: (p, cd) => "Se deltakerliste",
            onClick: (p, cd) => p.openParticipationList(cd.competitionID),
            shouldRender: (p, cd) => {
                return p.isAnyAdmin
            },
        }
    ];

    mainButtons = [
        {
            key: 'participation',
            getText: (p, cd) => cd.isParticipating ? 'Min deltagelse' : 'Bli med',
            onClick: (p, cd) => p.openParticipation(cd.competitionID),
            shouldRender: (p, cd) => !this.isSubmissionEnded() && p.clubIsActive,
        },
        {
            key: 'evaluation',
            getText: () => 'Gi vurdering',
            onClick: (p, cd) => p.openScore(cd.competitionID),
            shouldRender: (p, cd) => (cd.isJuryMember || cd.publicJurying) && !this.isSubmissionEnded(),
        },
        {
            key: 'results',
            getText: () => 'Se vurderinger',
            onClick: (p, cd) => p.openEvaluation(cd.competitionID),
            shouldRender: (p, cd) => true,
        },
    ];

    renderSecondaryButtons = () => {
        const competitionData = this.props.competitionData || {};
        return (
            <React.Fragment>
                <IconButton aria-controls="secondary-buttons-menu" aria-haspopup="true" onClick={(ev) => { this.setState({ secondaryButtonEl: ev.currentTarget }); }}>
                    <Settings />
                </IconButton>
                <Menu
                    anchorEl={this.state.secondaryButtonEl}
                    open={!!this.state.secondaryButtonEl}
                    onClose={() => { this.setState({ secondaryButtonEl: null }); }}
                    id="secondary-buttons-menu"
                >
                    {this.secondaryButtons.filter(b => b.shouldRender(this.props, competitionData)).map(b => (
                        <MenuItem
                            key={b.key}
                            onClick={() => b.onClick(this.props, competitionData)}
                        >
                            {b.getText(this.props, competitionData)}
                        </MenuItem>
                    ))}
                </Menu>
            </React.Fragment>
        );
    }

    renderButtons = () => {
        const competitionData = this.props.competitionData || {};
        return this.mainButtons
            .filter(b => b.shouldRender(this.props, competitionData))
            .map(b => {
                const text = b.getText(this.props, competitionData);
                return (
                    <Button
                        key={b.key}
                        title={text}
                        onClick={() => b.onClick(this.props, competitionData)}
                        {...this.defaultButtonProps}
                    >
                        {text}
                    </Button>
                );
            });
    }

    getStatus = () => {
        const submissionHasStarted = this.isSubmissionStarted();
        const submissionHasPassed = this.isSubmissionEnded();

        if (!submissionHasStarted) {
            return { text: "Ikke startet", color: "#001789", textColor: "white" };
        } else if (!submissionHasPassed) {
            return { text: "Åpen for innlevering", color: "#27ae60", textColor: "white" };
        }
        return { text: "Ferdig", color: "#001789", textColor: "white" };
    }

    getWarning = () => {
        const competitionData = this.props.competitionData || {};
        const { competitionJuryMembers, isAdmin, publicJurying } = competitionData;
        const submissionHasPassed = this.isSubmissionEnded();

        if (!publicJurying && competitionJuryMembers.length <= 0 && isAdmin && !submissionHasPassed) {
            return "Vurdering mangler jurymedlemmer";
        }
        return null;
    }

    render() {
        const { classes, competitionData, classesByID, xs, sm, md, lg } = this.props;
        const classIDsInCompetition = competitionData.competitionClasses ? competitionData.competitionClasses.map(cc => cc.classID).filter((val, i, list) => list.indexOf(val) === i) : [];
        const status = this.getStatus();
        const warning = this.getWarning();

        return (
            <Grid item xs={xs || 12} sm={sm || 6} md={md || 4} lg={lg || 4} className={classes.grid}>
                <Card className={classes.card}>
                    <CardContent className={classes.content}>
                        <div className={classes.topContainer}>
                            <Typography variant="h6" style={{ flexGrow: 1 }}>
                                {competitionData.name}
                            </Typography>
                            <div className={classes.topRightContainer}>
                                {warning &&
                                    <Tooltip title={warning} placement="bottom">
                                        <WarningIcon className={classes.warningIcon} />
                                    </Tooltip>
                                }
                                {this.renderSecondaryButtons()}
                            </div>
                        </div>
                        <Typography variant="body2" component="span" className={classes.statusText} style={{ color: status.textColor ? status.textColor : "", backgroundColor: status.color ? status.color : "" }}>
                            {status.text}
                        </Typography>
                        <List>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar className={classes.avatar}>
                                        <SupervisorAccount />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="Arrangør" secondary={competitionData.organizationName} />
                            </ListItem>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar className={classes.avatar}>
                                        <FlagIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="Konkurransetype" secondary={getCompetitionType(competitionData)} />
                            </ListItem>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar className={classes.avatar}>
                                        <EventAvailableIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="Innlevering åpner" secondary={moment(competitionData.submissionStart).format('LLL')} />
                            </ListItem>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar className={classes.avatar}>
                                        <EventBusyIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="Innlevering stenger" secondary={moment(competitionData.submissionEnd).format('LLL')} />
                            </ListItem>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar className={classes.avatar}>
                                        <PhotoFilter />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary="Klasser"
                                    secondary={classesByID && classIDsInCompetition.map((classID, index) =>
                                        <span key={classID}>
                                            {classIDsInCompetition.length === index + 1 &&
                                                <span>{classesByID[classID].name}</span>
                                            }
                                            {classIDsInCompetition.length !== index + 1 &&
                                                <span>{classesByID[classID].name}, </span>
                                            }
                                        </span>
                                    )}
                                />
                            </ListItem>
                        </List>
                    </CardContent>
                    <CardActions className={classes.actions}>
                        {this.renderButtons()}
                    </CardActions>
                </Card>
            </Grid>
        );
    }
}

export default withStyles(styles)(CompetitionListCard);