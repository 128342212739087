import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import withInternalChangeHandler from '../../../Fields/withInternalChangeHandler';
import CompetitionClassSelection from './CompetitionClassSelection.container';
import CompetitionAdminSelection from './CompetitionAdminSelection.container';
import CompetitionJurySelection from './CompetitionJurySelection.component';
import CompetitionDateTimePicker from './CompetitionDateTimePicker.component';
import CompetitionOrganizationSelection from './CompetitionOrganizationSelection.component';
import CompTypeDropdownField from './CompTypeDropdownField.component';
import DropdownField from './DropdownField.component';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import NumberField from '../../../Fields/NumberField/NumberField.component';
import IntegerField from '../../../Fields/NumberField/IntegerField.component';
import TextField from '../../../Fields/TextField/TextField.component';
import ProgressTextField from '../../../Fields/TextField/ProgressTextField.component';
import CheckboxField from '../../../Fields/CheckboxField/CheckboxField.component';

import moment from "moment";
import "moment/locale/nb";
import { FormHelperText } from '@material-ui/core';
import isCompetitionEvaluationType from '../../../../competition/isCompetitionEvaluationType';
moment.locale("nb");

const FormIntegerField = withInternalChangeHandler()(IntegerField);
const FormNumberField = withInternalChangeHandler()(NumberField);
const FormTextField = withInternalChangeHandler()(TextField);

const styles = theme => ({
    root: {
        padding: 10,
    },
    paper: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    buttonContainer: {
        textAlign: "center",
    },
    button: {
        margin: theme.spacing(0.5),
    },
    participationPriceField: {
        boxSizing: 'border-box',
    },
    formHelperText: {
        marginTop: 0,
        marginLeft: 20,
        marginBottom: 8,
    },
});

class CompetitionForm extends React.Component {
    state = {
        deleteDialogOpen: false,
    };

    handleChange = (key, e) => {
        const { value } = e.target;
        this.props.updateFormSigleField(key, value);
    }

    handleOrgChange = (e) => {
        const { organizationsByID, competitionForm, updateFormSigleField } = this.props;
        const { name, value } = e.target;

        if(organizationsByID[value]) {
            this.props.getPaymentConfigured(value);
        }
  
        if(organizationsByID[value].isNsff) {
            updateFormSigleField('isPublic', true);
        } else if(competitionForm.organizationID && competitionForm.organizationID !== value && organizationsByID[competitionForm.organizationID].isNsff){
            updateFormSigleField('isPublic', false);
        }

        this.props.updateFormSigleField(name, value);
        this.props.updateFormSigleField("competitionAdmins", []);
    }

    handleCompTypeChange = (e) => {
        const { value } = e.target;
        if(parseInt(value) === 4) {
            const nsffOrg = this.props.organizations.find( org => org.isNsff );
            this.props.updateFormSigleField('organizationID', nsffOrg.organizationID);
            this.props.updateFormSigleField('competitionAdmins', []);
            this.props.updateFormSigleField('isPublic', true);
            this.props.getPaymentConfigured(nsffOrg.organizationID);
        }

        this.props.updateFormSigleField("competitionType", parseInt(value));
    }

    handleToggleChange = name => event => {
        this.props.updateFormSigleField(name, event.target.checked);
    }

    handleClassSelectionChange = (competitionClasses) => {
        this.props.updateFormSigleField("competitionClasses", competitionClasses);
    }

    handleAdminSelectionChange = (selectedUsers) => {
        this.props.updateFormSigleField("competitionAdmins", selectedUsers);
    }

    handleCompetitionOrganizationChange = (competitionOrganizations) => {
        this.props.updateFormSigleField("competitionOrganizations", competitionOrganizations);
    }

    handleJurySearch = (firstName, lastName) => {
        this.props.searchPerson(firstName, lastName);
    }

    handleJuryChange = (person) => {
        this.props.updateFormSigleField("competitionJuryMembers", person);
    }

    handleJuryLeaderChange = (person) => {
        this.props.updateFormSigleField("competitionJuryLeader", person);
    }

    handleCancel = () => {
        const { previousLocation, onCancel } = this.props;
        onCancel(previousLocation);
    }

    validClassSelections = () => {
        const {competitionClasses} = this.props.competitionForm;

        if(!competitionClasses) {
            return false;
        }

        let hasAllVars = true;
        Object.keys(competitionClasses).forEach(classID => {
            if(!competitionClasses[classID].theme || !competitionClasses[classID].maxItems) {
                hasAllVars = false;
            }
        });
        return hasAllVars;
    }

    validForm = () => {
        const {competitionType, name, organizationID, description, submissionStart, submissionEnd, maxItems} = this.props.competitionForm;
        if(!competitionType || !name || !organizationID || !description || !submissionStart || !submissionEnd || !maxItems) {
            this.setState({ validationErrorMessage: "Vennligst fyll ut alle felt markert med *" });
            return false;
        } else if( (competitionType === 1 && !this.validClassSelections()) || (competitionType === 4 && !this.validClassSelections())) {
            this.setState({ validationErrorMessage: "Vennligst velg minst én klasse og fyll ut ut alle felt markert med *" });
            return false;
        } else if(submissionStart && submissionEnd && moment(submissionStart).diff(moment(submissionEnd)) > 0) {
            this.setState({ validationErrorMessage: "Slutt dato er før start dato" });
            return false;
        }
        return true;
    }

    handleSubmit = (e) => {
        const { competitionForm, previousLocation } = this.props;
        if(this.validForm()) {
            this.props.onSubmit(competitionForm, previousLocation);
        }
    }

    renderPriceField = () => {
        const { competitionForm, classes } = this.props;
        const { organizationID, participationPrice, hasParticipants, paymentConfigured, paymentConfiguredLoading } = competitionForm;
        if(!isCompetitionEvaluationType(competitionForm) && organizationID) {
            const content = (
                <FormNumberField
                    label="Deltakergebyr"
                    helperText="Pris for å delta i konkurransen."
                    onBlur={(e) => this.handleChange("participationPrice", e)}
                    fullWidth
                    InputProps={{ startAdornment: <InputAdornment position="start">kr</InputAdornment>, inputProps: { }, className: classes.participationPriceField }}
                    value={participationPrice || ""}
                    TextFieldComponent={ProgressTextField}
                    loading={paymentConfiguredLoading}
                    disabled={hasParticipants || !paymentConfigured}
                />
            );
    
            return !paymentConfigured && !paymentConfiguredLoading ? (
                <Tooltip title={"Stripebetaling i Rubic er ikke konfigurert for denne klubben."}>
                    {content}
                </Tooltip>
            ) : content;
        }
    }

    getCompTypeText = (capitalFirstLetter) => {
        let text = "";
        if(this.props.competitionForm.competitionType === "1") {
            text = "konkurranse";
        } else if(this.props.competitionForm.competitionType === "2") {
            text = "bildevurdering";
        } else if(this.props.competitionForm.competitionType === "3") {
            text = "videovurdering";
        } else if(this.props.competitionForm.competitionType === "4") {
            text = "NM";
        }

        if(capitalFirstLetter) {
            return text.charAt(0).toUpperCase() + text.slice(1);
        }

        return text;
    }

    renderFirstStepFields = () => {
        const { competitionForm, isNsffAdmin } = this.props;
        return (
            <React.Fragment>
                <CompTypeDropdownField
                    callBackFunction={this.handleCompTypeChange}
                    selectedID={competitionForm.competitionType || ""}
                    isNsffAdmin={isNsffAdmin} disabled={competitionForm.competitionID}
                />
                <DropdownField
                    callBackFunction={this.handleOrgChange}
                    selectedID={competitionForm.organizationID || ""}
                    organizations={this.props.organizations}
                    compIsNM={competitionForm.competitionType === 4} disabled={competitionForm.competitionID}
                />
            </React.Fragment>
        );
    }

    renderSecondStepFields = () => {
        const { classes, competitionForm, organizationsByID, isNsffAdmin, competitionData } = this.props;
        return (
            <React.Fragment>
                <FormTextField
                    required
                    label="Tittel"
                    onBlur={(e) => this.handleChange("name", e)}
                    fullWidth
                    value={competitionForm.name || ""}
                />
                {this.renderPriceField()}
                <FormTextField
                    required
                    label="Beskrivelse"
                    onBlur={(e) => this.handleChange("description", e)}
                    multiline
                    rows="4"
                    fullWidth
                    value={competitionForm.description || ""}
                />
                <FormIntegerField
                    label="Øvre aldersgrense"
                    min={1}
                    onBlur={(e) => this.handleChange("ageLimit", e)}
                    fullWidth
                    value={competitionForm.ageLimit || ""}
                />
                <CompetitionDateTimePicker
                    onChange={(e) => this.handleChange(e.target.name, e)}
                    compStartDate={competitionForm.submissionStart || null}
                    compEndDate={competitionForm.submissionEnd || null}
                />
                <FormIntegerField
                    required
                    label="Maks total antall innleveringer"
                    min={1}
                    onBlur={(e) => this.handleChange("maxItems", e)}
                    fullWidth
                    value={competitionForm.maxItems || ""}
                />
                <CheckboxField
                    value={competitionForm.isPublic}
                    onChange={this.handleToggleChange('isPublic')}
                    disabled={(competitionForm.organizationID && organizationsByID[competitionForm.organizationID].isNsff) || competitionForm.hasParticipants}
                    label={"Offentlig " + this.getCompTypeText(false)}
                />
                {!competitionForm.isPublic && 
                    <CompetitionOrganizationSelection
                        organizationID={competitionForm.organizationID}
                        onChange={this.handleCompetitionOrganizationChange}
                        selectedOrganizations={competitionForm.competitionOrganizations}
                    />
                }
                {isNsffAdmin && !isCompetitionEvaluationType(competitionForm) &&
                    <React.Fragment>
                        <FormTextField
                            label="Patronage"
                            onBlur={(e) => this.handleChange("nsffPatronate", e)}
                            fullWidth
                            value={competitionForm.nsffPatronate || ""}
                        />
                        <FormHelperText className={classes.formHelperText}>Konkurranser med patronage vil bli tellende i hederstegn og plaketter</FormHelperText>
                    </React.Fragment>
                }
                <CompetitionAdminSelection
                    onChange={this.handleAdminSelectionChange}
                    selectedOrganizationId={competitionForm.organizationID}
                    selectedAdmins={competitionForm.competitionAdmins}
                />
                {!isCompetitionEvaluationType(competitionForm) &&
                    <CompetitionClassSelection competitionClasses={competitionForm.competitionClasses || []} onChange={this.handleClassSelectionChange} disabled={competitionForm.hasParticipants} />
                }
                <CompetitionJurySelection
                    jurySearchResult={competitionForm.searchPersonResult}
                    selectedJuryMembers={competitionForm.competitionJuryMembers}
                    selectedJuryLeader={competitionForm.competitionJuryLeader}
                    changeFunction={this.handleJuryChange}
                    changeLeaderFunction={this.handleJuryLeaderChange}
                    searchFunction={this.handleJurySearch}
                    isSearching={competitionForm.searching}
                    competitionType={competitionForm.competitionType}
                    publicJurying={competitionForm.publicJurying}
                    setPublicJurying={this.handleToggleChange('publicJurying')}
                    existingPublicJurying={competitionData && competitionData.publicJurying}
                />
            </React.Fragment>
        )
    }

    render() {
        const { classes, competitionForm, header } = this.props;

        return (
        <div className={classes.root}>
            <Paper className={classes.paper} elevation={1}>
                {header}
                <form name="form" noValidate autoComplete="fghfghfgh">
                    {this.renderFirstStepFields()}
                    {competitionForm.organizationID && competitionForm.competitionType && this.renderSecondStepFields()}
                    <div className={classes.buttonContainer}>
                        <Button onClick={this.handleCancel} className={classes.button} >
                            Avbryt
                        </Button>
                        <Button variant="contained" onClick={this.handleSubmit} color="primary" className={classes.button} >
                            Lagre
                        </Button>
                        {this.state.validationErrorMessage &&
                            <Typography color="error" variant="overline" display="block" align="center">
                                {this.state.validationErrorMessage}
                            </Typography>
                        }
                    </div>
                </form>
            </Paper>
        </div>
        );
    }
}

export default withStyles(styles)(CompetitionForm);