import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CardActionArea from '@material-ui/core/CardActionArea';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';
import PhotoFilter from '@material-ui/icons/PhotoFilter';
import PlayArrow from '@material-ui/icons/PlayArrow';
import MediaDialog from '../../../Media/MediaDialog.component';

const styles = theme => ({
    root: {
        marginBottom: 8,
        display: "flex",
    },
    actionArea: {
        height: 150,
        width: 150,
        minWidth: 150,
    },
    media: {
        height: 150,
        width: 150,
        minWidth: 150,
        borderRadius: 5,
    },
    details: {
        marginLeft: 16,
    },
    avatar: {
        backgroundColor: "#001789",
    },
  });

class OrganizationParticipationImgPreview extends React.Component {
    state = {
        openDialog: false,
        imageStatusDialog: "loading",
    };

    handleMediaClick = () => {
        this.setState({openDialog: true});
    }

    handleCloseDialog = () => {
        this.setState({ openDialog: false });
    }

    handleSelectImageForOrgParticipation = patricipationID => event => {
        this.props.addParticipation(this.props.personID, patricipationID, event.target.checked);
    }

    renderListItem = (ListItemIcon, primary, secondary) => {
        const classes = this.props.classes;
        return (
            <ListItem>
                <ListItemAvatar>
                    <Avatar className={classes.avatar}>
                        <ListItemIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={primary} secondary={secondary} />
            </ListItem>
        );
    }

    disableCheckbox = () => {
        const { mediaItem, personID, orgParticipations, amtOfParticipations } = this.props;
        if(orgParticipations[personID] && orgParticipations[personID][mediaItem.classParticipationID]) {
            return false; 
        } else if((orgParticipations[personID] && Object.keys(orgParticipations[personID]).length >= 4) || amtOfParticipations >= 20) {
            return true;
        }
        return false;
    }

    render() {
        const { classes, mediaItem, personID, orgParticipations } = this.props;
        
        return (
            <div className={classes.root}>
                <CardActionArea onClick={this.handleMediaClick} className={classes.actionArea}>
                    <img
                        className={classes.media}
                        src={mediaItem.thumbnailUrl}
                        title={mediaItem.name}
                        alt={mediaItem.name}
                    />
                </CardActionArea>
                <div className={classes.details}>
                    <Typography style={{marginLeft: 16}} variant="h5" component="h2">
                        {mediaItem.name}
                    </Typography>
                    <List style={{paddingTop: 0, paddingBottom: 0}}>
                        {mediaItem.class && this.renderListItem(PhotoFilter, 'Klasse', mediaItem.class)}
                        {mediaItem.videoUrl && this.renderListItem(PlayArrow, 'Video', mediaItem.videoUrl)}
                    </List>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={(orgParticipations[personID] && !!orgParticipations[personID][mediaItem.classParticipationID]) || false}
                                onChange={this.handleSelectImageForOrgParticipation(mediaItem.classParticipationID)}
                                color="primary"
                                disabled={this.disableCheckbox()}
                            />
                        }
                        label={mediaItem.videoUrl ? "Video valgt" : "Bilde valgt"}
                        style={{ margin: 8 }}
                    />
                </div>
                <MediaDialog mediaItem={mediaItem} isOpen={this.state.openDialog} onClose={this.handleCloseDialog} />
          </div>
        );
    }
}

export default withStyles(styles)(OrganizationParticipationImgPreview);