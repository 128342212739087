import React from 'react';
import classNames from 'classnames';
import TextField from './TextField.component';
import {
    withStyles,
    CircularProgress,
} from '@material-ui/core';

import {
    Check
} from '@material-ui/icons';




const getStyles = (theme) => ({
    wrapper: {
        position: 'relative',
    },
    textFieldIcon: {
        position: 'absolute',
        top: 37,
        right: 0,
        marginTop: -12,
        marginRight: 10,
    },
    textFieldIconDense: {
        position: 'absolute',
        top: 28,
        right: 0,
        marginTop: -12,
        marginRight: 10,
    },
    input: {
        paddingRight: 38,
    },
    success: {
        color: theme.palette.success.main,
    }
});


class ProgressTextField extends React.Component {
    state = {
        internalSuccess: false,
    }
    inputProps = {
        className: this.props.classes.input,
    }

    componentDidUpdate(prevProps) {
        if(prevProps.success !== this.props.success) {
            this.setState({ internalSuccess: this.props.success });
        }
    }

    onChange = (ev) => {
        this.setState({ internalSuccess: false });
        this.props.onChange && this.props.onChange(ev);
    }

    render(){
        const {
            loading,
            success,
            disabled,
            children,
            classes,
            textFieldProps,
            textFieldClassName,
            className,
            checkOnComplete,
            onChange,
            margin,
            ...passOnProps
        } = this.props;
        const internalSuccess = this.state.internalSuccess;

        const textFieldIconClass = margin === "dense" ? classes.textFieldIconDense : classes.textFieldIcon;

        return (
            <div className={classNames(classes.wrapper, className)}>
                <TextField
                    disabled={loading || disabled}
                    className={textFieldClassName}
                    inputProps={this.inputProps}
                    onChange={this.onChange}
                    margin={margin}
                    {...passOnProps}
                >
                    {children}
                </TextField>
                {!loading && internalSuccess && <Check className={classNames(textFieldIconClass, classes.success)} />}
                {loading && <CircularProgress size={24} className={textFieldIconClass} />}
            </div>
        );
    }
}


export default withStyles(getStyles)(ProgressTextField);