import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import MediaCardList from "../../../../Media/MediaCardList.component";
import MediaFullscreen from "../../../../Media/MediaFullscreen.component";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import AwardFullscreen from "./AwardFullscreen/AwardFullscreen.container";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { getAwardByValue } from "../awards";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Star from "@material-ui/icons/Star";
import FormatListNumbered from "@material-ui/icons/FormatListNumbered";
import {
  Select,
  MenuItem,
  InputLabel,
  OutlinedInput,
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
} from "@material-ui/core";
import CheckboxField from "../../../../Fields/CheckboxField/CheckboxField.component";
const styles = (theme) => ({
  root: {},
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginBottom: 8,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  button: {
    height: 49,
    width: 129,
  },
  cardContent: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    padding: 10,
  },
  cardContentButtonContainer: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  cardContentButtonInnerContainer: {
    display: "inline-block",
  },
  cardContentItem: {
    display: "flex",
    justifyContent: "space-between",
  },
  cardContentItemHeader: {
    padding: 5,
  },
  cardContentItemValue: {
    padding: 5,
    paddingLeft: 10,
    fontWeight: 600,
  },
  topButtonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  cardButtonContainer: {
    padding: 10,
    flexGrow: 1,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  filterSelectContainer: {
    marginRight: 10,
  },
  selectNotchedOutline: {
    borderColor: `${fade(theme.palette.primary.main, 0.5)}!important`,
    "&:hover": {
      borderColor: `${(theme.palette.primary.main, 0.5)}!important`,
    },
  },
  filterLabel: {
    color: theme.palette.primary.main,
  },
  filterFormControl: {
    minWidth: 150,
    marginBottom: 0,
    marginTop: 0,
  },
  avatar: {
    backgroundColor: "#001789",
  },
  cardText: {
    whiteSpace: "nowrap",
    margin: 0,
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
});

class ViewAndAward extends React.Component {
  state = {
    filterValue: "",
    checkedParticipations: [],
    dialogOpen: false,
    award: null,
  };
  onShowList = () => {
    this.props.onShowList();
  };

  onShowFullscreen = (participationIndex) => {
    this.props.onShowFullscreen(participationIndex);
  };

  onFullsceenIndexChange = (index) => {
    this.props.onFullscreenIndexChange(index);
  };

  handleCheckedChange = (classParticipationID) => {
    let checkedParticipations = [...this.state.checkedParticipations];
    if (!checkedParticipations.includes(classParticipationID)) {
      checkedParticipations.push(classParticipationID);
    } else {
      checkedParticipations = checkedParticipations.filter(
        (i) => i !== classParticipationID
      );
    }
    this.setState({ checkedParticipations });
  };

  onRewardClick = () => {
    // Error handling ?
    this.state.checkedParticipations.forEach((e) => {
      this.props.saveAward(
        this.props.competitionData.competitionID,
        e,
        this.state.award.value,
        null
      );
    });
    this.setState({
      dialogOpen: false,
      checkedParticipations: [],
      award: null,
    });
  };

  // Map mediaItems over checkedparticipations and render.
  renderAwardingDialog = () => {
    const array = this.props.displayClassParticipations.filter((m) =>
      this.state.checkedParticipations.includes(m.classParticipationID)
    );
    return (
      <Dialog open={this.state.dialogOpen}>
        <DialogTitle>{`Tildel ${
          this.state.award ? this.state.award.displayName : ""
        }`}</DialogTitle>
        <DialogContent>
          {array.map((p) => (
            <div>
              <ListItem>{p.name}</ListItem>
              <Divider />
            </div>
          ))}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => this.setState({ dialogOpen: false })}
            color="error"
          >
            Lukk
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => this.onRewardClick()}
          >
            Premier
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  renderMediaItemCardContent = (mediaItem, index) => {
    const { classes } = this.props;
    const award = getAwardByValue(mediaItem.award);
    return (
      <React.Fragment>
        <div className={classes.cardContent}>
          <Typography variant="h5" component="h2">
            {mediaItem.name}
          </Typography>
          <Typography>Id: {mediaItem.classParticipationNumber}</Typography>
          <List className={classes.list}>
            {award && (
              <ListItem
                className={classes.listItem}
                style={{ marginBottom: 10 }}
              >
                <ListItemAvatar>
                  <Avatar className={classes.avatar}>
                    <FormatListNumbered />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  className={classes.cardText}
                  primary="Plassering"
                  secondary={award.formDisplayName}
                  secondaryTypographyProps={{
                    style: { overflow: "hidden", textOverflow: "ellipsis" },
                  }}
                />
              </ListItem>
            )}
            <ListItem className={classes.listItem}>
              <ListItemAvatar>
                <Avatar className={classes.avatar}>
                  <Star />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                className={classes.cardText}
                primary="Antall jurypoeng"
                secondary={mediaItem.score}
                secondaryTypographyProps={{
                  style: { overflow: "hidden", textOverflow: "ellipsis" },
                }}
              />
            </ListItem>
          </List>
        </div>
        <div className={classes.cardButtonContainer}>
          <CheckboxField
            value={
              this.state.checkedParticipations.includes(
                mediaItem.classParticipationID
              )
                ? true
                : false
            }
            onChange={() =>
              this.handleCheckedChange(mediaItem.classParticipationID)
            }
          />
          <Button
            size="small"
            color="primary"
            onClick={() => this.onShowFullscreen(index)}
          >
            Åpne
          </Button>
        </div>
      </React.Fragment>
    );
  };

  renderFullScreenContent = (mediaItem, index) => {
    const { competitionData, awards } = this.props;
    return (
      <AwardFullscreen
        awards={awards}
        competitionData={competitionData}
        classParticipation={mediaItem}
      />
    );
  };

  renderFullScreen = () => {
    const { displayClassParticipations, fullscreenInitialIndex } = this.props;
    return (
      <MediaFullscreen
        mediaItems={displayClassParticipations}
        initialIndex={fullscreenInitialIndex}
        getBottomContent={this.renderFullScreenContent}
        onIndexChange={this.onFullsceenIndexChange}
      />
    );
  };

  renderCardList = () => {
    const { displayClassParticipations } = this.props;
    return (
      <MediaCardList
        mediaItems={displayClassParticipations}
        getMediaItemContent={this.renderMediaItemCardContent}
        imageMinSize={150}
      />
    );
  };

  handleSelectFilter = (ev) => {
    this.props.onSetClassParticipationFilter(ev.target.value);
  };

  renderFilterSelector = () => {
    const { classParticipationFilter, classes } = this.props;
    return (
      <div className={classes.filterSelectContainer}>
        <>
          <FormControl
            variant="outlined"
            margin="dense"
            className={classes.filterFormControl}
          >
            <InputLabel
              disabled={
                !this.state.checkedParticipations ||
                this.state.checkedParticipations.length === 0
              }
              htmlFor="filter-select"
              className={classes.filterLabel}
            >
              Premier
            </InputLabel>
            <Select
              disabled={
                !this.state.checkedParticipations ||
                this.state.checkedParticipations.length === 0
              }
              value={classParticipationFilter || ""}
              onChange={(e) =>
                this.setState({ dialogOpen: true, award: e.target.value })
              }
              input={
                <OutlinedInput
                  classes={{ notchedOutline: classes.selectNotchedOutline }}
                  labelWidth={45}
                  name="classParticipationFilter"
                  id="filter-select"
                />
              }
            >
              <MenuItem value={getAwardByValue(4)}>Hederlig omtale</MenuItem>
              <MenuItem value={getAwardByValue(5)}>Antatt</MenuItem>
            </Select>
          </FormControl>

          <FormControl
            variant="outlined"
            margin="dense"
            className={classes.filterFormControl}
          >
            <InputLabel htmlFor="filter-select" className={classes.filterLabel}>
              Filter
            </InputLabel>
            <Select
              value={classParticipationFilter || ""}
              onChange={this.handleSelectFilter}
              input={
                <OutlinedInput
                  classes={{ notchedOutline: classes.selectNotchedOutline }}
                  labelWidth={45}
                  name="classParticipationFilter"
                  id="filter-select"
                />
              }
            >
              <MenuItem value="">
                <em>Alle</em>
              </MenuItem>
              <MenuItem value="awarded">Premierte</MenuItem>
              <MenuItem value="notawarded">Ikke premierte</MenuItem>
            </Select>
          </FormControl>
        </>
      </div>
    );
  };

  render() {
    const { classes, competitionClass, showFullscreen, classesByID } =
      this.props;
    const currentClass = classesByID[competitionClass.classID];

    return (
      <div className={classes.root}>
        <Paper className={classes.paper} elevation={1}>
          <div className={classes.header}>
            <Typography variant="h5">{currentClass.name}</Typography>
            {this.renderAwardingDialog()}
            {showFullscreen ? (
              <Button
                variant="outlined"
                color="primary"
                className={classes.button}
                onClick={this.onShowList}
              >
                Liste
              </Button>
            ) : (
              <div className={classes.topButtonsContainer}>
                {this.renderFilterSelector()}
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => this.onShowFullscreen()}
                >
                  Fullskjerm
                </Button>
              </div>
            )}
          </div>
          {showFullscreen ? this.renderFullScreen() : this.renderCardList()}
        </Paper>
      </div>
    );
  }
}

ViewAndAward.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ViewAndAward);
