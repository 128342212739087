import React from 'react';
import {
    ListItem,
    withStyles,
} from '@material-ui/core';
import ProgressTextField from '../../../Fields/TextField/ProgressTextField.component';
import withInternalChangeHandler from '../../../Fields/withInternalChangeHandler';
import withErrorMessageHandler from '../../../Fields/withErrorMessageHandler';
import isValidYear from '../../../../Utils/validations/year.validator';
import { NewReleases } from '@material-ui/icons';

const TextField = withInternalChangeHandler()(withErrorMessageHandler()(ProgressTextField));

const getStyles = (theme) => ({
    distinctionItemDetails: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
    },
    distinctionItemName: {
        flexGrow: 1,
    },
    distinctionItemDescription: {
        fontStyle: 'italic',
    },
    distinctionItemContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    availableIndicator: {
        width: 24,
        height: 24,
        marginLeft: 10,
    }
});

class DistinctionItemListItem extends React.Component {
    state = {
        errorMessage: null,
    };


    handleBlur = (year) => {
        if(!year || isValidYear(year)) {
            this.setState({ errorMessage: null });
            this.props.onYearChange(this.props.distinctionItem.distinctionItemID, year);
            return;
        }
        this.setState({ errorMessage: 'Ikke et gyldig år' });
    }
    renderAvailableIndicator = () => {
        const { displayAvailableIndicator, distinctionItem, distinction, points } = this.props;
        if(displayAvailableIndicator) {
            const isAvailable = (!distinction || !distinction.archievedYear) && distinctionItem.pointsToArchieve <= points;
            return !!isAvailable && (<NewReleases color="secondary" />);
        }
        return null;
    }
    render() {
        const {
            classes,
            distinction,
            formDistinction,
            distinctionItem,
            index,
            lastItemIndex,            
        } = this.props;
        const { errorMessage } = this.state;
        const useDivider = index !== lastItemIndex;
        return (
            <ListItem divider={useDivider} className={classes.distinctionItemContainer}>
                <div className={classes.distinctionItemDetails}>
                    <div className={classes.distinctionItemName}>{distinctionItem.name}</div>
                    <div>
                        <TextField
                            label="Årstall"
                            variant="outlined"
                            margin="dense"
                            loading={formDistinction.saving}
                            success={formDistinction.success}
                            value={formDistinction.archievedYear || (distinction && distinction.archievedYear) || ''}
                            errorMessage={errorMessage || formDistinction.errorMessage}
                            onBlur={(ev) => this.handleBlur(ev.currentTarget.value)}
                        />
                    </div>
                    <div className={classes.availableIndicator}>
                        {this.renderAvailableIndicator()}
                    </div>
                    
                </div>
                <div className={classes.distinctionItemDescription}>{distinctionItem.description}</div>

            </ListItem>
        );
    }
}

export default withStyles(getStyles)(DistinctionItemListItem);