import { actionCreator } from '../../../Utils/actions.utils';
import { userService } from '../../Services/userService';
import { initializeData } from '../Start/Start.actions';
import { push } from 'connected-react-router';

export const actionTypes = {
    REQUEST_LOGIN: 'RequestLogin',
    LOGIN_SUCCESS: 'LoginSuccess',
    LOGIN_FAILED: 'LoginFailed',
    LOGOUT: 'LOGOUT'
}

export const login = (username, password) =>
    (dispatch) => {
        dispatch(actionCreator(actionTypes.REQUEST_LOGIN)({}));
        return userService.login(username, password).then(
            (response) => {
                dispatch(actionCreator(actionTypes.LOGIN_SUCCESS)({ response }));
                dispatch(initializeData());
                dispatch(push("/"));
            },
            error => dispatch(actionCreator(actionTypes.LOGIN_FAILED)({ error }))
        );
    }

const logoutAction = () => actionCreator(actionTypes.LOGOUT)();

export const logout = () => logoutAction();
