import { connect } from "react-redux";
import ViewAndAward from "./ViewAndAward.component";
import withLoadingIndicator from "../../../../LoadingIndicator/withLoadingIndicator";
import {
  makeGetAwards,
  makeGetDisplayClassParticipations,
} from "./ViewAndAward.selectors";

import {
  fullScreenIndexChange,
  showList,
  showFullscreen,
  setClassParticipationFilter,
  saveAward,
} from "./ViewAndAward.actions";

const makeMapStateToProps = () => {
  const getDisplayClassParticipations = makeGetDisplayClassParticipations();
  const getAwards = makeGetAwards();
  return (state, props) => {
    const viewAndAward = state.viewAndAward || {};
    return {
      ready: !viewAndAward.loading,
      classParticipations: viewAndAward.data,
      awards: getAwards(state),
      showFullscreen: viewAndAward.showFullscreen,
      fullscreenInitialIndex: viewAndAward.fullscreenInitialIndex,
      displayClassParticipations: getDisplayClassParticipations(state),
      classParticipationFilter: viewAndAward.classParticipationFilter,
      classesByID: state.metaData.classesByID,
    };
  };
};
const mapDispatchToProps = (dispatch) => ({
  onFullscreenIndexChange: (index) => {
    dispatch(fullScreenIndexChange(index));
  },
  onShowFullscreen: (fullscreenInitialIndex) => {
    dispatch(showFullscreen(fullscreenInitialIndex));
  },
  onShowList: () => {
    dispatch(showList());
  },
  onSetClassParticipationFilter: (filter) => {
    dispatch(setClassParticipationFilter(filter));
  },
  saveAward: (competitionID, classParticipationID, award, comment) => {
    dispatch(saveAward(competitionID, classParticipationID, award, comment));
  },
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(withLoadingIndicator()(ViewAndAward));
