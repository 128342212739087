import React from 'react';
import PaymentForm from "./PaymentForm.component"
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { 
    Elements,
    StripeProvider,
} from 'react-stripe-elements';
import getConfig from '../../config/config';


const appConfig = getConfig();

const getStyles = (theme) => ({
    dialogContent: {
        width: 400,
        marginRight: 20,
    }
});

const PaymentDialog = (props) => {
    const { open, ...passOnProps } = props;
    return (
        <Dialog open={open}>
            <DialogTitle>Betaling</DialogTitle>
            <DialogContent className={props.classes.dialogContent}>
                <StripeProvider apiKey={appConfig.stripeKey}>
                    <Elements locale="no">
                        <PaymentForm {...passOnProps} />
                    </Elements>
                </StripeProvider>
            </DialogContent>
        </Dialog>
    );
}

export default withStyles(getStyles)(PaymentDialog);