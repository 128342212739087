import Api from './apiService';

export const competitionOrgParticipationService = {
    getOrgParticipations,
    getCollectiveOrgParticipation,
    submitOrgParticipations,
};

function getOrgParticipations(orgID, compID) {
    return Api.get(`competitions/${compID}/participants/${orgID}`);
}

function getCollectiveOrgParticipation(orgID, compID) {
    return Api.get(`competitions/${compID}/organizationParticipations/${orgID}`);
}

function submitOrgParticipations(participations, orgID, compID) {
    const listOfParticipations = convertToServer(participations);
    console.log(listOfParticipations);
    return Api.post(`competitions/${compID}/organizationParticipations/${orgID}`, listOfParticipations);
}

function convertToServer(participations) {
    let convertedList = [];
    Object.keys(participations).forEach(personID => {
        Object.keys(participations[personID]).forEach(participationID => {
            convertedList.push(participationID);
        });
    });

    return convertedList;
}