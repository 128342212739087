import { connect } from 'react-redux';
import Report from './Report.component';
import { requestFinishedCompetitions } from './Report.actions';
import { setViewMode } from '../Competition.actions';

const mapStateToProps = (state) => {
    return {
        competitions: state.reports.data,
        paging: state.reports.paging,
        searchText: state.reports.searchText,
        loading: state.reports.loading,
        isNsffAdmin: state.userProfile.data.isNsffAdmin,
    };
};

const mapDispatchToProps = (dispatch) => ({
    requestFinishedCompetitions: (searchText, paging) => {
        dispatch(requestFinishedCompetitions(searchText, paging));
    },
    onBackToCompetitions: () => {
        dispatch(setViewMode("compList"));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Report);