import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import UserProfileDisplay from './UserProfile/UserProfileDisplay.component';
import UserProfileEdit from './UserProfile/UserProfileEdit.container';
import UserDistinctions from './Distinctions/UserDistinctions.component';
import CompetitionList from '../Competition/CompetitionsList/CompetitionList.container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Statistics from './Statistics/Statistics.container';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import competitionListKeys from '../Competition/competitionListKeys';
import Payments from './Payments/Payments.container';
import moment from 'moment';
import "moment/locale/nb";

const styles = theme => ({
    root: {
        padding: 10,
    },
    paper: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        marginBottom: 8,
    },
});

class UserProfile extends React.Component {
    state = {
        openOrgParticipationDialog: false,
    }

    componentDidMount() {
        this.props.fetchActive();
        this.props.fetchPayments();
    }

    handleShowEdit = (value) => {
        this.props.onShowEditProfile({ ...this.props.userData });
    }

    handleOpenOrgParticipationDialog = (selectedCompetition) => {
        const selectableOrgs = this.getAvailableOrgsForParticipation();

        if(selectableOrgs.length === 1) {
            this.handleOpenOrganizationParticipation(selectableOrgs[0].organizationID, selectedCompetition);
        } else {
            this.props.setSelectedComp(selectedCompetition);
            this.setState({openOrgParticipationDialog: true});
        }
    }

    handleCloseOrgParticipationDialog = () => {
        this.setState({openOrgParticipationDialog: false});
    }

    getAvailableOrgsForParticipation = () => {
        //TODO: Check if org has already participated
        return this.props.orgs.filter(org => org.isAdmin);
    }
    
    isActiveClub = () => {
        const mainClub = this.props.orgs.find(org => org.organizationID === this.props.mainOrganizationID);
        if(!mainClub) return false;
        if (mainClub.organizationExpireDate < moment().format("YYYY-MM-DD")) {
            return false;
        } else {
            return true;
        }
    }

    renderOrgParticipationDialog = () => {
        const selectableOrgs = this.getAvailableOrgsForParticipation();

        return (
            <Dialog open={this.state.openOrgParticipationDialog} onClose={this.handleCloseOrgParticipationDialog}>
                <DialogTitle style={{paddingBottom: 0}}>Velg klubb</DialogTitle>
                <List>
                    {selectableOrgs.map(org => (
                        <ListItem button onClick={() => this.handleOpenOrganizationParticipation(org.organizationID)} key={org.organizationID}>
                            <ListItemText primary={org.organizationName}/>
                        </ListItem>
                    ))}
                </List>
            </Dialog> 
        )
    }

    render() {
        const { classes, viewMode, userData, orgs, showEdit, distinctionTypes } = this.props;
        return (
            <div>
                {viewMode === "compList" &&
                    <div className={classes.root}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={4}>
                                <Paper className={classes.paper} elevation={3}>
                                    {!showEdit && <UserProfileDisplay handleClick={this.handleShowEdit} userData={userData} userOrgs={orgs.filter(o => o.isMember)} />}
                                    {showEdit && <UserProfileEdit userData={userData} />}
                                </Paper>
                                <UserDistinctions distinctionsByItemID={userData.distinctionsByItemID} distinctionTypes={distinctionTypes} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={8}>
                                <CompetitionList
                                    competitionListKey={competitionListKeys.ACTIVE}
                                    headerText='Aktive konkurranser'
                                    noItemsText='Ingen aktive konkurranser'
                                    onOpenParticipation={this.handleOpenParticipation}
                                    onOpenScoring={this.handleOpenScoring}
                                    onOpenEditCompetition={this.handleOpenEditCompetition}
                                    onOpenResults={this.handleOpenResults}
                                    onOpenEvaluations={this.handleOpenEvaluations}
                                    showCreateNew={false}
                                    sm={12}
                                    md={6}
                                    onOrganizationParticipation={this.handleOpenOrgParticipationDialog}
                                    isAnyAdmin={userData.isAnyAdmin}
                                clubIsActive={this.isActiveClub()}
                                />
                                <Statistics />
                                <Payments />
                            </Grid>
                        </Grid>
                    </div>
                }
                {this.renderOrgParticipationDialog()}
            </div>
        );
    }
}

export default withStyles(styles)(UserProfile);