import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Person from '@material-ui/icons/Person';
import Close from '@material-ui/icons/Close';

const styles = theme => ({
    list: {
        paddingTop: 0,
    },
});

class AdminCard extends React.Component {
    
    handleRemove = () => {
        this.props.removeRoleFunction(this.props.role.organizationRoleID, this.props.person)
    };

    render() {
        const { classes, person, role } = this.props;

        return (
            <List className={classes.list}>
                <Paper elevation={1} key={person.personID} className={classes.personPaper}>
                    <ListItem>
                        <ListItemIcon>
                            <Person />
                        </ListItemIcon>
                        <ListItemText
                            primary={person.firstName + ' ' + person.lastName}
                            secondary={role.roleName}
                        />
                        <ListItemSecondaryAction>
                            <IconButton color="primary" edge="end" aria-label="Fjern" onClick={this.handleRemove}>
                                <Close />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                </Paper>
            </List>
        );
    }
}

AdminCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AdminCard);