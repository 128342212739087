import { connect } from 'react-redux';
import EvaluationListCard from './EvaluationListCard.component';
import { push } from 'connected-react-router';

const mapStateToProps = (state) => ({
    classesByID: state.metaData.classesByID,
    orgs: state.clubs.orgs,
});

const mapDispatchToProps = (dispatch) => ({
    openEvaluation: (compID) => {
        dispatch(push(`/competitions/${compID}/evaluations`));
    },
    openEdit: (compID) => {
        dispatch(push(`/competitions/${compID}/edit`));
    },
    openParticipation: (compID) => {
        dispatch(push(`/competitions/${compID}/participation`));
    },
    openOrgParticipation: (compID) => {
        dispatch(push(`/competitions/${compID}/organizationParticipation`));
    },
    openResult: (compID) => {
        dispatch(push(`/competitions/${compID}/results`));
    },
    openScore: (compID) => {
        dispatch(push(`/competitions/${compID}/score`));
    },
    openParticipationList: (compID) => {
        dispatch(push(`/competitions/${compID}/participationList`));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(EvaluationListCard);