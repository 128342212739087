import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import startReducer from './startReducer';
import authReducer from './authReducer';
import competitionReducer from './competitionReducer';
import createCompetitionReducer from './createCompetitionReducer';
import userParticipationReducer from './userParticipationReducer';
import competitionOrgParticipationReducer from './competitionOrgParticipationReducer';
import competitionFormReducer from './competitionFormReducer';
import competitionScoreReducer from './competitionScoreReducer';
import viewAndAwardReducer from './viewAndAwardReducer';
import viewAndScoreReducer from './viewAndScoreReducer';
import viewAndEvaluateReducer from './viewAndEvaluateReducer';
import formReducer from './formReducer';
import userProfileReducer from './userProfileReducer';
import distinctionReducer from './distinctionReducer';
import distinctionsForPersonReducer from './distinctionsForPersonReducer';
import adminReducer from './adminReducer';
import paymentsReducer from './paymentsReducer';
import importResultsReducer from './importResultsReducer';
import reportReducer from './reportReducer';
import scoreReducer from './scoreReducer';
import locationReducer from './locationReducer';
import competitionResultReducer from './competitionResultReducer';
import clubsReducer from "./clubsReducer"

const createRootReducer = (history) =>
    combineReducers({
        router: connectRouter(history),
        auth: authReducer,
        competition: competitionReducer,
        createCompetition: createCompetitionReducer,
        metaData: startReducer,
        competitionParticipation: userParticipationReducer,
        competitionOrgParticipation: competitionOrgParticipationReducer,
        competitionForm: competitionFormReducer,
        competitionScore: competitionScoreReducer,
        viewAndAward: viewAndAwardReducer,
        viewAndScore: viewAndScoreReducer,
        viewAndEvaluate: viewAndEvaluateReducer,
        forms: formReducer,
        userProfile: userProfileReducer,
        distinctions: distinctionReducer,
        distinctionsForPerson: distinctionsForPersonReducer,
        roles: adminReducer,
        payments: paymentsReducer,
        importedResults: importResultsReducer,
        reports: reportReducer,
        score: scoreReducer,
        location: locationReducer,
        competitionResults: competitionResultReducer,
        clubs: clubsReducer,
    });

export default createRootReducer;