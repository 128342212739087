import { connect } from 'react-redux';
import { userService } from '../Services/userService';
import { logout } from '../Pages/Login/Login.actions';
import Navbar from './Navbar.component';
import { push, replace } from 'connected-react-router';

const mapStateToProps = (state) => ({
    userProfile: state.userProfile.data,
});

const mapDispatchToProps = (dispatch) => ({
    onLogout: () => {
        userService.logout();
        dispatch(logout());
    },
    pushLocation: (url) => {
        dispatch(push(url));
    },
    replaceLocation: (url) => {
        dispatch(replace(url));
    }
});


export default connect(mapStateToProps, mapDispatchToProps)(Navbar);