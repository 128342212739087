import { connect } from 'react-redux';
import CompetitionStatus from './CompetitionStatus.component';
import {
    closeCompetitionJurying,
    closeCompetitionAwarding,
} from './CompetitionStatus.actions';
import withLoadingIndicator from '../../../../LoadingIndicator/withLoadingIndicator';
import { push } from 'connected-react-router';

const mapStateToProps = (state) => {
    return {
        ready: state.competitionScore.competitionStatus && !state.competitionScore.competitionStatus.loading,
        competitionStatus: state.competitionScore.competitionStatus && state.competitionScore.competitionStatus.data,
    };
}

const mapDispatchToProps = (dispatch) => ({
    closeJurying: (competitionID) => {
        dispatch(closeCompetitionJurying(competitionID));
    },
    closeAwarding: (competitionID) => {
        dispatch(closeCompetitionAwarding(competitionID));
    },
    seeResult: (competitionID) => {
        dispatch(push(`/competitions/${competitionID}/results`));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(withLoadingIndicator()(CompetitionStatus));