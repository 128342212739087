import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = {
    card: {
        display: 'flex',
        height: 64,
    },
    details: {
        display: 'contents',
        flexDirection: 'column',
    },
    content: {
        flexGrow: 1,
        display: "flex",
        alignItems: "center",
        "&:last-child": {
            paddingBottom: 16
        },
    },
    img: {
        width: 64,
        minWidth: 64,
    },
    text: {
        flexGrow: 1,
    },
};

class UploadedImageCard extends React.Component {

    handleClick = (imageID) => {
        this.props.handleDelete(imageID);
    }

    render() {
        const { classes, name, imgRef, imgID, noDelete, imgNumber, onImageClick } = this.props;

        return (

            <Card className={classes.card}>
                <CardMedia
                    className={classes.img}
                    image={imgRef}
                    title={name}
                    onClick={onImageClick}
                />
                <CardContent className={classes.content}>
                    <Typography className={classes.text} component="span" variant="h5">
                        {name}
                    </Typography>
                    {imgID && <Typography>Id: {imgNumber}</Typography>}
                    {!noDelete && imgID &&
                        <IconButton onClick={() => this.handleClick(imgID)} aria-label="Slett" disabled={!imgID}>
                            <DeleteIcon fontSize="small" />
                        </IconButton>
                    }
                    {!noDelete && !imgID && <CircularProgress className={classes.progress} />
                    }
                </CardContent>
            </Card>
        );
    }
}

UploadedImageCard.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UploadedImageCard);