import Api from './apiService';

export const organizationService = {
    getRolesForOrg,
    setRole,
    removePersonFromRole,
    searchPersonInOrg,
    getOrganizations,
    getAdminOrganizations,
    setOrganizationExpireDate,
};

function getRolesForOrg(orgID) {
    return Api.get(`organizations/${orgID}/roles`);
}

function setRole(orgID, organizationRoleID, personID) {
    return Api.post(`organizations/${orgID}/roles/${organizationRoleID}?personID=${personID}`);
}

function removePersonFromRole(orgID, organizationRoleID, personID) {
    return Api.delete(`organizations/${orgID}/roles/${organizationRoleID}?personID=${personID}`);
}

function searchPersonInOrg(searchText, orgID) {
    return Api.get(`persons/search/${orgID}?searchString=${searchText}`);
}

function getOrganizations() {
    return Api.get('organizations');
}

function getAdminOrganizations(dt) {
    if(typeof dt == "undefined"){dt = new Date().toISOString();}
    return Api.get('organizations/admin/' + dt);
}

function setOrganizationExpireDate(orgID, org) {
    return Api.put(`organizations/${orgID}`, org)
}
