import { connect } from 'react-redux';
import ViewAndEvaluate from './ViewAndEvaluate.component';
import withLoadingIndicator from '../../../../LoadingIndicator/withLoadingIndicator';

import {
    fullScreenIndexChange,
    showFullscreen,
    showList,
} from './ViewAndEvaluate.actions';

const mapStateToProps = (state) => {
    const viewAndEvaluate = state.viewAndEvaluate || {};
    return {
        ready: !viewAndEvaluate.loading,
        classParticipations: viewAndEvaluate.data,
        showFullscreen: viewAndEvaluate.showFullscreen,
        fullscreenInitialIndex: viewAndEvaluate.fullscreenInitialIndex,
        classesByID: state.metaData.classesByID,
    }
}

const mapDispatchToProps = (dispatch) => ({
    onFullscreenIndexChange: (index) => {
        dispatch(fullScreenIndexChange(index));
    },
    onShowFullscreen: (fullscreenInitialIndex) => {
        dispatch(showFullscreen(fullscreenInitialIndex));
    },
    onShowList: () => {
        dispatch(showList());
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(withLoadingIndicator()(ViewAndEvaluate));