import apiService from "../../components/Services/apiService";
import getConfig from "../../config/config";

const config = getConfig();

export default function downloadFile(url, queryParams) {
    queryParams = queryParams || [];
    apiService.get("download/key").then((key) => {
        queryParams.push(`downloadKey=${key}`);
        const downloadUrl = config.apiUrl+"/"+url+"?"+queryParams.join('&');
        //window.open(downloadUrl, '_blank');
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', "");
        // 3. Append to html page
        document.body.appendChild(link);
        // 4. Force download
        link.click();
        // 5. Clean up and remove the link
        link.parentNode.removeChild(link);
    });
}