import Clubs from "./Clubs.component";
import { connect } from "react-redux";
import {
  getAdminOrganizations,
  setOrganizationExpireDate,
} from "./Clubs.actions";

const mapStateToProps = (state) => ({
  adminOrgs: state.clubs.adminOrgs,
});

const mapDispatchToProps = (dispatch) => ({
  setOrganizationExpireDate: (orgID, org) => {
    dispatch(setOrganizationExpireDate(orgID, org));
  },
  getAdminOrganizations: (dt) => {
    dispatch(getAdminOrganizations(dt));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Clubs);
