import { connect } from 'react-redux';
import CompetitionListCard from './CompetitionListCard.component';
import { setPatronate, deletePatronate, finishCompetition, unFinishCompetition, openAwarding, openJurying } from './CompetitionListCard.actions';
import moment from 'moment';
import { push } from 'connected-react-router';


const getAge = (birthDate) => {
    if (birthDate) {
        const momentBirthDate = moment(birthDate);
        return moment().diff(momentBirthDate, 'years');
    }
    return -1;
}

const mapStateToProps = (state) => ({
    classesByID: state.metaData.classesByID,
    isNsffAdmin: state.userProfile.data.isNsffAdmin,
    orgs: state.clubs.orgs,
    age: getAge(state.userProfile.data.birthDate),
});

const mapDispatchToProps = (dispatch) => ({
    setPatronate: (nsffPatronate, compID, competitionListKey) => {
        dispatch(setPatronate(nsffPatronate, compID, competitionListKey));
    },
    deletePatronate: (compID, competitionListKey) => {
        dispatch(deletePatronate(compID, competitionListKey));
    },
    openEdit: (compID) => {
        dispatch(push(`/competitions/${compID}/edit`));
    },
    openParticipation: (compID) => {
        dispatch(push(`/competitions/${compID}/participation`));
    },
    openOrganizationParticipation: (compID, orgID) => {
        dispatch(push(`/competitions/${compID}/organizationParticipation/${orgID}`));
    },
    openResult: (compID) => {
        dispatch(push(`/competitions/${compID}/results`));
    },
    openScore: (compID) => {
        dispatch(push(`/competitions/${compID}/score`));
    },
    openParticipationList: (compID) => {
        dispatch(push(`/competitions/${compID}/participationList`));
    },
    finishCompetition: (competitionID) => {
        dispatch(finishCompetition(competitionID));
    },
    unFinishCompetition: (competitionID) => {
        dispatch(unFinishCompetition(competitionID));
    },
    openAwarding: (competitionID) => {
        dispatch(openAwarding(competitionID))
    },
    openJurying: (competitionID) => {
        dispatch(openJurying(competitionID))
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(CompetitionListCard);