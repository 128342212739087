import {
    actionCreator,
} from '../../../../../../Utils/actions.utils';

import {
    competitionResultService
} from '../../../../../Services/competitionResultService';

export const actionTypes = {
    REQUEST_SAVE_AWARD: 'REQUEST_SAVE_AWARD',
    SAVE_AWARD_SUCCESS: 'SAVE_AWARD_SUCCESS',
    SAVE_AWARD_FAILED: 'SAVE_AWARD_FAILED',
    REQUEST_REMOVE_AWARD: 'REQUEST_REMOVE_AWARD',
    REMOVE_AWARD_SUCCESS: 'REMOVE_AWARD_SUCCESS',
    REMOVE_AWARD_FAILED: 'REMOVE_AWARD_FAILED',
    AWARD_FORM_FIELD_UPDATED: 'AWARD_FORM_FIELD_UPDATED',
    ON_VIEW_EDIT_AWARD: 'ON_VIEW_EDIT_AWARD',
    ON_AWARD_FORM_INVALID: 'ON_AWARD_FORM_INVALID',
    ON_CANCEL_EDIT_AWARD: 'ON_CANCEL_EDIT_AWARD',
}


export function saveAward(competitionID, classParticipationID, award, comment) {
    return (dispatch) => {
        const payload = { competitionID, classParticipationID, award, comment };

        dispatch(actionCreator(actionTypes.REQUEST_SAVE_AWARD)({ ...payload }));

        var promise = competitionResultService.saveAward(competitionID, classParticipationID, award, comment);

        return promise.then(
            response => dispatch(actionCreator(actionTypes.SAVE_AWARD_SUCCESS)({ ...payload })),
            error => dispatch(actionCreator(actionTypes.SAVE_AWARD_FAILED)({ ...payload })),
        );
    }
}

export function removeAward(competitionID, classParticipationID) {
    return (dispatch) => {
        var payload = { competitionID, classParticipationID };
        dispatch(actionCreator(actionTypes.REQUEST_REMOVE_AWARD)({ ...payload }));

        var promise = competitionResultService.removeAward(competitionID, classParticipationID);

        return promise.then(
            response => dispatch(actionCreator(actionTypes.REMOVE_AWARD_SUCCESS)({ ...payload })),
            error => dispatch(actionCreator(actionTypes.REMOVE_AWARD_FAILED)({ ...payload })),
        );
    }
}

export const onAwardFormFieldUpdated =
    (field, value) => actionCreator(actionTypes.AWARD_FORM_FIELD_UPDATED)({ field, value });

export const onViewEditAward =
    (formValues) => actionCreator(actionTypes.ON_VIEW_EDIT_AWARD)({ formValues });

export const onCancelEditAward =
    () => actionCreator(actionTypes.ON_CANCEL_EDIT_AWARD)({});

export const onAwardFormInvalid =
    (validationErrorMessage) => actionCreator(actionTypes.ON_AWARD_FORM_INVALID)({ validationErrorMessage });
