import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const styles = theme => ({
    formControl: {
        margin: 8,
        width: "100%"
    },
});


class DropdownField extends React.Component {
    handleChange = (e) => {
        this.props.callBackFunction(e);
    }

    render() {
        const { classes, selectedID, isNsffAdmin, disabled } = this.props;
        return (
            <FormControl required variant="outlined" className={classes.formControl}>
                <InputLabel htmlFor="outlined-age-simple">
                    Type
                </InputLabel>
                <Select
                    value={selectedID}
                    onChange={this.handleChange}
                    input={<OutlinedInput labelWidth={45} name="organizationID" id="outlined-age-simple" />}
                    disabled={!!disabled}
                >
                    <MenuItem value="1">Konkurranse</MenuItem>
                    <MenuItem value="2">Bildevurdering</MenuItem>
                    <MenuItem value="3">Videovurdering</MenuItem>
                    {isNsffAdmin && 
                        <MenuItem value="4">NM</MenuItem>
                    }
                </Select>
            </FormControl>
        );
    }
}

export default withStyles(styles)(DropdownField);