import { connect } from 'react-redux';
import { getClasses, getAdmins } from './Start.actions';
import { getOrganizations } from "../Clubs/Clubs.actions"
import Start from './Start.component';

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) => ({
    fetchClasses: () => {
        dispatch(getClasses());
    },
    fetchAdmins: () => {
        dispatch(getAdmins());
    },
    fetchOrganizations: () => {
        dispatch(getOrganizations());
    },
});


export default connect(mapStateToProps, mapDispatchToProps)(Start);