import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { getAwardByValue } from '../Score/awards';
import Typography from '@material-ui/core/Typography';
import CardActionArea from '@material-ui/core/CardActionArea';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Person from '@material-ui/icons/Person';
import PlayArrow from '@material-ui/icons/PlayArrow';
import Comment from '@material-ui/icons/Comment';
import MediaDialog from '../../../Media/MediaDialog.component';

const styles = theme => ({
    root: {
        marginBottom: 8,
        display: "flex",
    },
    actionArea: {
        height: 150,
        width: 150,
        minWidth: 150,
    },
    media: {
        /*height: 150,*/
        width: 150,
        minWidth: 150,
        borderRadius: 5,
    },
    details: {
        marginLeft: 16,
    },
    avatar: {
        backgroundColor: "#001789",
    },
  });

class ResultCard extends React.Component {
    state = {
        openDialog: false,
        imageStatusDialog: "loading",
    };

    handleMediaClick = () => {
        this.setState({openDialog: true});
    }

    handleCloseDialog = () => {
        this.setState({ openDialog: false });
    }

    renderListItem = (ListItemIcon, primary, secondary) => {
        const classes = this.props.classes;
        return (
            <ListItem>
                <ListItemAvatar>
                    <Avatar className={classes.avatar}>
                        <ListItemIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={primary} secondary={secondary} />
            </ListItem>
        );
    }

    renderClickableListItem = (ListItemIcon, primary, link) => {
        const classes = this.props.classes;
        return (
            <ListItem button component="a" target="_blank" href={link} aria-label="Spill video">
                <ListItemAvatar>
                    <Avatar className={classes.avatar}>
                        <ListItemIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={primary} secondary={link} />
            </ListItem>
        );
    }

    render() {
        const { classes, mediaItem } = this.props;
        let award = {};
        if(mediaItem.award) {
            award = getAwardByValue(mediaItem.award);
        }

        return (
            <div className={classes.root}>
                <CardActionArea onClick={this.handleMediaClick} className={classes.actionArea}>
                    <img
                        className={classes.media}
                        src={mediaItem.thumbnailUrl}
                        title={mediaItem.itemName}
                        alt={mediaItem.itemName}
                    />
                </CardActionArea>
                <div className={classes.details}>
                    <Typography style={{marginLeft: 16}} variant="h5" component="h2">
                        {award.formDisplayName &&
                            <strong>{award.formDisplayName + ": "}</strong>
                        }
                        {mediaItem.itemName}
                    </Typography>
                    <List style={{paddingTop: 0, paddingBottom: 0}}>
                        {this.renderListItem(Person, 'Fotograf', `${mediaItem.firstName} ${mediaItem.lastName}`)}
                        {mediaItem.videoUrl && this.renderClickableListItem(PlayArrow, 'Video', mediaItem.videoUrl)}
                        {mediaItem.comment && this.renderListItem(Comment, 'Kommentar', mediaItem.comment)}
                    </List>
                </div>
                <MediaDialog mediaItem={mediaItem} isOpen={this.state.openDialog} onClose={this.handleCloseDialog} />
          </div>
        );
    }
}

export default withStyles(styles)(ResultCard);