import { simplePromiseAction, actionCreator } from '../../../../Utils/actions.utils';
import { competitionScoringService } from '../../../Services/competitionScoringService';
import { createPublicJuryMember, getCompetition } from '../Competition.actions';

export const actionTypes = {
    START_LOAD_SCORE_PAGE: 'START_LOAD_SCORE_PAGE',
    FINISHED_LOADING_SCORE_PAGE: 'FINISHED_LOADING_SCORE_PAGE',
    REQUEST_MY_SCORE_STATUS: 'REQUEST_MY_SCORE_STATUS',
    GET_MY_SCORE_STATUS_SUCCESS: 'GET_MY_SCORE_STATUS_SUCCESS',
    GET_MY_SCORE_STATUS_FAILED: 'GET_MY_SCORE_STATUS_FAILED',
    REQUEST_JURY_SCORE_STATUS: 'REQUEST_JURY_SCORE_STATUS',
    GET_JURY_SCORE_STATUS_SUCCESS: 'GET_JURY_SCORE_STATUS_SUCCESS',
    GET_JURY_SCORE_STATUS_FAILED: 'GET_JURY_SCORE_STATUS_FAILED',
    REQUEST_MY_CLASS_PARTICIPATION_SCORING_STATUS: "REQUEST_MY_CLASS_PARTICIPATION_SCORING_STATUS",
    GET_MY_CLASS_PARTICIPATION_SCORING_SUCCESS: "GET_MY_CLASS_PARTICIPATION_SCORING_SUCCESS",
    GET_MY_CLASS_PARTICIPATION_SCORING_FAILED: "GET_MY_CLASS_PARTICIPATION_SCORING_FAILED",
    REQUEST_TOTAL_CLASS_PARTICIPATION_SCORING_STATUS: "REQUEST_TOTAL_CLASS_PARTICIPATION_SCORING_STATUS",
    GET_TOTAL_CLASS_PARTICIPATION_SCORING_SUCCESS: "GET_TOTAL_CLASS_PARTICIPATION_SCORING_SUCCESS",
    GET_TOTAL_CLASS_PARTICIPATION_SCORING_FAILED: "GET_TOTAL_CLASS_PARTICIPATION_SCORING_FAILED",
    GO_BACK_TO_COMPETITION_LIST_FROM_SCORE: "GO_BACK_TO_COMPETITION_LIST_FROM_SCORE",
}

export const getMyStatus = (competitionID) => simplePromiseAction(
    () => competitionScoringService.getMyStatus(competitionID),
    actionTypes.REQUEST_MY_SCORE_STATUS,
    actionTypes.GET_MY_SCORE_STATUS_SUCCESS,
    actionTypes.GET_MY_SCORE_STATUS_FAILED,
);

export const getJuryStatus = (competitionID) => simplePromiseAction(
    () => competitionScoringService.getJuryStatus(competitionID),
    actionTypes.REQUEST_JURY_SCORE_STATUS,
    actionTypes.GET_JURY_SCORE_STATUS_SUCCESS,
    actionTypes.GET_JURY_SCORE_STATUS_FAILED,
);


export const getMyClassParticipationScoring = (competitionID, competitionClassID) => simplePromiseAction(
    () => competitionScoringService.getMyClassParticipationScoring(competitionID, competitionClassID),
    actionTypes.REQUEST_MY_CLASS_PARTICIPATION_SCORING_STATUS,
    actionTypes.GET_MY_CLASS_PARTICIPATION_SCORING_SUCCESS,
    actionTypes.GET_MY_CLASS_PARTICIPATION_SCORING_FAILED,
);

export const getTotalClassParticipationScoring = (competitionID, competitionClassID) => simplePromiseAction(
    () => competitionScoringService.getTotalClassParticipationScoring(competitionID, competitionClassID),
    actionTypes.REQUEST_TOTAL_CLASS_PARTICIPATION_SCORING_STATUS,
    actionTypes.GET_TOTAL_CLASS_PARTICIPATION_SCORING_SUCCESS,
    actionTypes.GET_TOTAL_CLASS_PARTICIPATION_SCORING_FAILED,
);

const finishedLoadingScorePage = () => actionCreator(actionTypes.FINISHED_LOADING_SCORE_PAGE)({});

export const loadScorePage = (competitionID) => (dispatch) => {
    dispatch(actionCreator(actionTypes.START_LOAD_SCORE_PAGE)({}));
    return dispatch(getCompetition(competitionID))
        .then((action) => {
            const competition = action.payload.response;
            if(!action.payload.error && competition.publicJurying && !competition.isJuryMember) {
                return dispatch(createPublicJuryMember(competitionID)).then(() => dispatch(finishedLoadingScorePage()));
            }
            return dispatch(finishedLoadingScorePage());
        });
}

export const goBackToCompetitionListFromScore = () => actionCreator(actionTypes.GO_BACK_TO_COMPETITION_LIST_FROM_SCORE)({});