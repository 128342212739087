import React from 'react';
import TextField from '../TextField/TextField.component';

class NumberField extends React.Component {

    greaterThanMax = (numberValue) => {
        const max = this.props.max;
        return max != null && numberValue > max;
    }

    lessThanMin = (numberValue) => {
        const min = this.props.min;
        return min != null && numberValue < min;
    }

    getParsedValue = (value) => {
        if(value) {
            if(value.startsWith("-") && value.length === 1) {
                return "0";
            }

            let parsedValue = value.replace(',', '.');
            return parsedValue;
        }
        return value;
    }

    onChange = (ev) => {
        const originalValue = ev.target.value;
        const parsedValue = this.getParsedValue(originalValue);
        if(isNaN(parsedValue)) {
            return;
        }

        const numberValue = parseFloat(parsedValue);
        if(this.lessThanMin(numberValue) || this.greaterThanMax(numberValue)) {
            return;
        }

        var newEv = {
            ...ev,
            target: {
                ...ev.target,
                id: ev.target.id,
                name: ev.target.name,
                value: originalValue === '' ? '' : originalValue,
            }
        }
        this.props.onChange && this.props.onChange(newEv);
    }

    onBlur = (ev) => {
        var parsedValue = this.getParsedValue(ev.target.value);
        var newEv = {
            ...ev,
            target: {
                ...ev.target,
                id: ev.target.id,
                name: ev.target.name,
                value: parsedValue === '' ? '' : parseFloat(parsedValue),
            }
        }
        this.props.onBlur && this.props.onBlur(newEv);
    }

    render(){
        const {
            onChange,
            children,
            onBlur,
            min,
            max,
            value,
            TextFieldComponent,
            ...passOnProps
        } = this.props;

        const parsedValue = value.toString().replace(/\./g, ',');

        const CurrentTextFieldComponent = TextFieldComponent || TextField;

        return (
            <CurrentTextFieldComponent
                onBlur={this.onBlur}
                onChange={this.onChange}
                value={parsedValue}
                {...passOnProps}
            >
                {children}
            </CurrentTextFieldComponent>
        );
    }
}


export default NumberField;