import { simplePromiseAction } from '../../../../Utils/actions.utils';
import { competitionService } from '../../../Services/competitionService';
import { pushPreviousOrDefault } from '../../../../Utils/router.utils';

export const actionTypesCreateComp = {
    REQUEST_CREATE_COMPETITION: 'RequestCreateCompetition',
    CREATE_COMPETITION_SUCCESS: 'CreateCompetitionSucces',
    CREATE_COMPETITION_FAILED: 'CreateCompetitionFailed',
}

const create = (competition) => simplePromiseAction(
    () => competitionService.create(competition),
    actionTypesCreateComp.REQUEST_CREATE_COMPETITION,
    actionTypesCreateComp.CREATE_COMPETITION_SUCCESS,
    actionTypesCreateComp.CREATE_COMPETITION_FAILED
);

export const createAndGoBack = (competition, previousLocation) =>
    (dispatch) => {
        return dispatch(create(competition)).then((action) => {
            if(!action.payload.error) {
                return dispatch(pushPreviousOrDefault(previousLocation, "/competitions"));
            }
        });
    }