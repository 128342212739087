import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';

const styles = theme => ({
    root: {
        padding: 10,
    },
    paper: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        marginBottom: 8,
    },
    classItem: {
        padding: 10,
    },
    progressBar: {
        marginTop: 5,
    }
});

class ScoreStatus extends React.Component {
    calcProgressInPerCent = (amtScored, totalAmt) => {
        return (amtScored / totalAmt)*100;
    }

    renderCompetitionClassItem = (competitionClass) => {
        const { classesByID, classes } = this.props;
        const competitionClassID = competitionClass.competitionClassID;
        var classStatus = this.props.myScoreStatus ? this.props.myScoreStatus[competitionClassID] : { };
        return (
            <div key={competitionClassID} className={classes.classItem}>
                {competitionClass.theme && `${classesByID[competitionClass.classID].name} - ${competitionClass.theme} (${classStatus.juryMemberCount}/${classStatus.totalCount})`}
                {!competitionClass.theme && `${classesByID[competitionClass.classID].name} (${classStatus.juryMemberCount}/${classStatus.totalCount})`}
                <LinearProgress className={classes.progressBar} color="primary" variant="determinate" value={this.calcProgressInPerCent(classStatus.juryMemberCount, classStatus.totalCount)} />
            </div>
        );
    }

    render() {
        const { classes, competitionData } = this.props;

        return (
            <div className={classes.root}>
                <Paper className={classes.paper} elevation={1}>
                    <Typography variant="h5" style={{marginBottom: 16}}>
                        Status
                    </Typography>
                    {competitionData.competitionClasses.map(competitionClass => this.renderCompetitionClassItem(competitionClass))}
                </Paper>
            </div>
          );
    }
}

ScoreStatus.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ScoreStatus);