import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '../../Fields/TextField/TextField.component';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Person from '@material-ui/icons/Person';
import Add from '@material-ui/icons/Add';
import moment from "moment";
import "moment/locale/nb";

moment.locale("nb");

const styles = theme => ({
    personPaper: {
        marginBottom: 8,
    },
    loadingContainer: {
        textAlign: "center",
    },
    dialogContent: {
        paddingBottom: 0,
    },
    searchContainer: {
        display: "flex",
        marginTop: 8,
    },
    searchTextField: {
        flexGrow: 1,
        marginRight: 16,
    },
    searchResultList: {
        marginTop: 16,
    },
});

class AddAdminMember extends React.Component {
    state = {
        searchText: "",
    };

    handleChangeSearchText = (e) => {
        this.setState({searchText: e.target.value});
    }

    handleClickSearch = () => {
        this.props.searchFunction(this.state.searchText);
    }

    handleClose = () => {
        this.props.closeDialogFunction();
    }

    handleAddPerson = (e, currentSelectedPerson) => {
        this.setState({ anchorEl: e.currentTarget, currentSelectedPerson });
    }

    handleCloseRoleMenu = (e) => {
        this.setState({ anchorEl: null });
    }

    handleSetRole = (role) => {
        this.setState({ anchorEl: null });
        this.props.closeDialogFunction();

        this.props.setRoleFunction(role.organizationRoleID, this.state.currentSelectedPerson);
    }

    render() {
        const { classes, open, roles, searchResult, isLoading } = this.props;

        

        return (
            <Dialog
                open={open}
                onClose={this.handleClose}
            >
                <DialogTitle>{"Legg til i styret"}</DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <DialogContentText>
                        Søk etter klubbmedlemer og velg hvilken rolle de skal ha i klubben.
                    </DialogContentText>
                    <div className={classes.searchContainer}>
                        <TextField
                            className={classes.searchTextField}
                            autoFocus
                            label="Navn"
                            type="text"
                            onChange={this.handleChangeSearchText}
                            fullWidth
                        />
                        <Button color="primary" onClick={this.handleClickSearch}>
                            Søk
                        </Button>
                    </div>
                    {isLoading &&
                        <div className={classes.loadingContainer}>
                            <CircularProgress />
                        </div>
                    }
                    {searchResult && searchResult.length > 0 && !isLoading &&
                        <List className={classes.searchResultList}>
                            <Typography variant="h6">
                                Søkeresultat
                            </Typography>
                            {searchResult.map(person =>
                                <Paper elevation={1} key={person.personID} className={classes.personPaper}>
                                    <ListItem>
                                        <ListItemIcon>
                                            <Person />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={person.firstName + ' ' + person.lastName}
                                            secondary={person.birthDate ? moment(person.birthDate).format('LL') : null}
                                        />
                                        <ListItemSecondaryAction>
                                            <IconButton color="primary" edge="end" aria-label="Legg til" onClick={(e) => this.handleAddPerson(e, person)}>
                                                <Add />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                </Paper>
                            )}
                        </List>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose} color="primary">
                        Lukk
                    </Button>
                </DialogActions>
                <Menu
                    id="roleMenu"
                    anchorEl={this.state.anchorEl}
                    keepMounted
                    open={Boolean(this.state.anchorEl)}
                    onClose={this.handleCloseRoleMenu}
                    style={{paddingTop: 0}}
                >
                    {roles.map(role => 
                        <MenuItem key={role.organizationRoleID} onClick={() => this.handleSetRole(role)}>{role.roleName}</MenuItem>
                    )}
                </Menu>
            </Dialog>
        );
    }
}

AddAdminMember.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AddAdminMember);