import { connect } from 'react-redux';
import CompetitionListView from './CompetitionListView.component';
import { unFinishCompetition } from './CompetitionListCard.actions';

const mapStateToProps = () => ({
});

const mapDispatchToProps = (dispatch) => ({
    unFinishCompetition: (competitionID) => {
        dispatch(unFinishCompetition(competitionID));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(CompetitionListView);