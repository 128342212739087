import React from "react";
import Table from "../../../Table/Table.component";
import withPaging from "../../../Table/withPaging";
import {
  Paper,
  FormControl,
  InputLabel,
  withStyles,
  Select,
  OutlinedInput,
  MenuItem,
  FormHelperText,
  Typography,
  Button,
} from "@material-ui/core";
import withInternalChangeHandler from "../../../Fields/withInternalChangeHandler";
import TextField from "../../../Fields/TextField/TextField.component";
import withLoadingIndicator from "../../../LoadingIndicator/withLoadingIndicator";
import columns from "./PersonDistinctionTableConfig";
import downloadFile from "../../../../Utils/download/downloadFile";

const LoadingTable = withLoadingIndicator()(withPaging()(Table));

const SearchTextField = withInternalChangeHandler()(TextField);
const getStyles = (theme) => ({
  cellContent: {
    paddingRight: 30,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    position: "relative",
  },
  headerCell: {
    paddingRight: 70,
  },
  availableDistinctionsContainer: {
    position: "absolute",
    height: 24,
    right: 0,
  },
  container: {
    padding: 10,
  },
  topContainer: {
    display: "flex",
    marginBottom: 10,
    justifyContent: "space-between",
    alignItems: "stretch",
  },
  selectLabel: {
    transform: "translate(14px, 13px) scale(1)",
  },
  topActionContainer: {
    marginTop: 8,
    alignSelf: "flex-end",
  },
});

class PersonDistinctionList extends React.Component {
  state = {
    order: null,
    orderBy: null,
  };

  handleRowClick = (data) => {
    this.props.openDistinctionsForPerson(data);
  };

  handleSort = (order) => {
    this.handleFetchPersonDistinctionList({ order });
  };
  handlePageChange = (paging) => {
    this.handleFetchPersonDistinctionList({ paging });
  };

  handleSearch = (ev) => {
    const searchString = ev.currentTarget.value;
    const currentSearchString = this.props.searchString || "";
    if (searchString !== currentSearchString) {
      this.handleFetchPersonDistinctionList({ searchString });
    }
  };

  handleFetchPersonDistinctionList = (newData) => {
    const data = {
      paging: this.props.paging,
      order: this.props.order,
      searchString: this.props.searchString,
      availableItemsDistinctionType: this.props.availableItemsDistinctionType,
      clubFilter: this.props.clubFilter,
      ...newData,
    };
    this.props.fetchPersonDistinctionList(
      data.paging,
      data.order,
      data.searchString,
      data.availableItemsDistinctionType,
      data.clubFilter
    );
  };

  handleAvailableItemsDistinctionTypeChange = (ev) => {
    this.handleFetchPersonDistinctionList({
      availableItemsDistinctionType: ev.target.value,
    });
  };

  handleClubChange = (ev) => {
    this.handleFetchPersonDistinctionList({ clubFilter: ev.target.value });
  };

  exportAsExcel = () => {
    const searchParams = {
      paging: this.props.paging,
      order: this.props.order,
      searchString: this.props.searchString,
      availableItemsDistinctionType: this.props.availableItemsDistinctionType,
      clubFilter: this.props.clubFilter,
    };
    const s = JSON.stringify(searchParams);
    const url = "distinction/personDistinctions/export";
    downloadFile(url, [`querySettings=${s}`]);
  };

  renderClubs = () => {
    const uniqueClubs = this.props.orgs.filter(
      (pd, i, arr) =>
        arr.findIndex((t) => t.organizationName === pd.organizationName) === i
    );

    return uniqueClubs.map((pd) => (
      <MenuItem key={pd.organizationName} value={pd.organizationID}>
        {pd.organizationName}
      </MenuItem>
    ));
  };

  render() {
    const {
      personDistinctions,
      searchString,
      classes,
      availableItemsDistinctionType,
      editMode,
      clubFilter,
      ...passOnProps
    } = this.props;
    console.log("ClubFilter here : ", clubFilter);
    return (
      <Paper>
        <div className={classes.container}>
          <Typography variant="h5">{"Hederstegn & plaketter"}</Typography>
          <div className={classes.topContainer}>
            <div
              style={{
                display: "flex",
                alignSelf: "flex-start",
                marginTop: 8,
                marginLeft: 10,
              }}
            >
              <div>
                <SearchTextField
                  value={searchString || ""}
                  label="Søk"
                  onBlur={this.handleSearch}
                  variant="outlined"
                  margin="dense"
                />
              </div>
              {editMode && (
                <div
                  style={{
                    display: "flex",
                    alignSelf: "flex-start",
                    marginTop: 8,
                    marginLeft: 10,
                  }}
                >
                  <FormControl variant="outlined">
                    <InputLabel
                      htmlFor="available-items-distinction-type"
                      className={classes.selectLabel}
                    >
                      Filter
                    </InputLabel>
                    <Select
                      value={availableItemsDistinctionType || ""}
                      onChange={this.handleAvailableItemsDistinctionTypeChange}
                      input={
                        <OutlinedInput
                          labelWidth={45}
                          name="availableItemsDistinctionType"
                          id="available-items-distinction-type"
                          margin="dense"
                        />
                      }
                    >
                      <MenuItem value="">Ingen</MenuItem>
                      <MenuItem value="ansff">ANSFF</MenuItem>
                      <MenuItem value="ensff">ENSFF</MenuItem>
                      <MenuItem value="nasjonalplakett">
                        Nasjonal plakett
                      </MenuItem>
                      <MenuItem value="internasjonalplakett">
                        Internasjonal plakett
                      </MenuItem>
                    </Select>
                    <FormHelperText>
                      Filtrer på utildelte hederstegn og plaketter
                    </FormHelperText>
                  </FormControl>
                  <FormControl variant="outlined" style={{ marginLeft: 10 }}>
                    <InputLabel
                      htmlFor="club-filter"
                      className={classes.selectLabel}
                    >
                      Filter
                    </InputLabel>
                    <Select
                      value={clubFilter || ""}
                      onChange={this.handleClubChange}
                      input={
                        <OutlinedInput
                          labelWidth={45}
                          name="clubFilter"
                          id="club-filter"
                          margin="dense"
                        />
                      }
                    >
                      <MenuItem value="">Ingen</MenuItem>
                      {this.renderClubs()}
                    </Select>
                    <FormHelperText>Filtrer på klubb</FormHelperText>
                  </FormControl>
                </div>
              )}
            </div>
            <div className={classes.topActionContainer}>
              <Button onClick={this.exportAsExcel} variant="outlined">
                Eksporter til excel
              </Button>
            </div>
          </div>
          <LoadingTable
            dataKey="personID"
            headerClassName={classes.headerCell}
            columns={columns}
            data={personDistinctions}
            onRowClick={this.handleRowClick}
            customClasses={classes}
            onSort={this.handleSort}
            onPageChange={this.handlePageChange}
            editMode={editMode}
            {...passOnProps}
          />
        </div>
      </Paper>
    );
  }
}

export default withStyles(getStyles)(PersonDistinctionList);
