import { connect } from 'react-redux';
import { copyCompetition, setDeleteDialogOpen, setCopyDialogOpen, loadEditCompetition, updateAndGoBack, deleteAndGoBack } from './EditCompetition.actions';
import EditCompetition from './EditCompetition.component';
import withDataLoader from '../../../DataLoader/withDataLoader';

const mapStateToProps = (state) => ({
    selectedCompetition: state.competition.selectedComp,
    dataLoading: !state.competition.selectedComp || state.competition.loadingSelectedComp,
    dataLoadErrorMessage: state.competition.loadSelectedCompErrorMessage,
    isSubmitting: state.createCompetition.isSubmitting,
    submitErrorMessage: state.createCompetition.submitErrorMessage,
    organizations: state.clubs.orgs,
    organizationsByID: state.clubs.orgsByID,
    isDeleting: state.competition.isDeleting,
    isCopying: state.competition.isCopying,
    copyDialogOpen: state.competition.copyDialogOpen,
    deleteDialogOpen: state.competition.deleteDialogOpen,
    isReady: state.competition.selectedCompetition && state.selectedCompetition.isReady,
    copyError: state.competition.copyError,
    deleteError: state.competition.deleteCompError,
});

const mapDispatchToProps = (dispatch) => ({
    onSubmit: (competition, previousLocation) => {
        // dispatch(push("/competitions"));
        dispatch(updateAndGoBack(competition, previousLocation));
    },
    onDelete: (competitionID, previousLocation) => {
        dispatch(deleteAndGoBack(competitionID, previousLocation));
    },
    onCopyCompetition: (competitionID, copySettings) => {
        dispatch(copyCompetition(competitionID, copySettings));
    },
    setDeleteDialogOpen: (open) => {
        dispatch(setDeleteDialogOpen(open));
    },
    setCopyDialogOpen: (open) => {
        dispatch(setCopyDialogOpen(open));
    },
    loadCompetition: (urlParams, queryParams) => {
        const id = urlParams.id;
        dispatch(loadEditCompetition(id));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(withDataLoader("loadCompetition")(EditCompetition));