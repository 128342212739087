import { push } from "connected-react-router";



export function pushPreviousOrDefault(previousLocation, defaultPath) {
    if(previousLocation) {
        return push(previousLocation.pathname);
    }
    if(defaultPath) {
        return push(defaultPath);
    }
    return push("/");
}